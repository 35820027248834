import React from "react";
import Accordion from "react-bootstrap/Accordion";
const GeneraFaq = () => {
  return (
    <div className="ac-faq">
      <h5>General Questions</h5>
      {/* <p>
        Our Organisation offers innovative investment options which provides
        4-5X higher returns than Fixed Deposits. Our paramount objective is to
        serve the nation and for its people to prosper
      </p> */}

      <Accordion>
        <Accordion.Item eventKey="0" className="my-3">
          <div className="acd-before">
            <Accordion.Header>What is an SPV?</Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              A Special Purpose Vehicle is an entity incorporated/created under
              the law, being a Partnership firm, LLP, a private company, etc.,
              for a specific lawful purpose. Any investment opportunity listed
              on the RIOFIN platform will be owned by an SPV being a limited
              liability company LLP set up for this specific purpose.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="my-3">
          <div className="acd-before">
            <Accordion.Header>How are my repayments secured?</Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              We make sure that we have securities such as security deposits or
              post-dated cheques at the time of the investing into the assets.{" "}
            </p>
            <p>
              {" "}
              Additionally, we have the ability to reclaim assets for selling or
              re-leasing. However, investors must note that while we have these
              safeguards in place, it does not guarantee 100% returns in case
              the Leasing partner does not fulfil the payment obligations.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="my-3">
          <div className="acd-before">
            <Accordion.Header>How is ROI different from IRR?</Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              ROI is absolute growth on investment over the investment period.
              IRR is an annual return considering value of money.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              Can I Invest in more than one asset?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Yes, you can invest in any number of assets. Diversification is
              always an advisable investment strategy and by offering you the
              ability to make smaller investments we encourage you to invest in
              more than one asset.{" "}
            </p>
            <p>
              {""}Keep an eye for Combo Deals which consist of multiple assets.
              {""}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              When is my investment process Complete?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Your investment is completed as soon as the opportunity is fully
              funded and private placement of your investment is done in the SPV
              that shall acquire the asset listed. RIOFIN generally has a time
              frame of 60 days to ensure that the property receives complete
              funding.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              How does a property get listed in RIOFIN?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              RIOFIN follows a rigorous regime of due diligence, financial
              modelling, extensive data mining and market analysis to shortlist
              the best assets for investors.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        {/* <Accordion.Item eventKey="6" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            What is the authenticity of RIOFIN?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              RIOFIN's leasing structure falls under the purview of the Ministry of Corporate Affairs, currently, more than 2000+ unique investors have already invested with our track record of 100% on time-payment. {" "}
            </p>
          </Accordion.Body>
        </Accordion.Item> */}
        <Accordion.Item eventKey="7" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              What is the authenticity of RIOFIN?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              RIOFIN's leasing structure falls under the purview of the Ministry
              of Corporate Affairs, currently, more than 2000+ unique investors
              have already invested with our track record of 100% on
              time-payment.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8" className="my-3">
          <div className="acd-before">
            <Accordion.Header>What is the investment idea?</Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Once your account is KYC verified, you can invest in any
              opportunity listed on our platform. You'll need to e-sign a
              binding Expression of Interest and transfer the initial token
              advance of your investment amount to your virtual account to block
              your investment. This virtual account is linked to an escrow
              account where funds from investors shall be parked until
              deployment for acquisition of the proposed asset. Once 100%
              commitment is received from interested investors, the opportunity
              is said to be fully funded. You will be required to transfer the
              remaining amount into your virtual account. Your investment amount
              will then be routed through the escrow mechanisms in place to the
              share subscription accounts and ultimately to the current account
              of the SPV account and the SPV will proceed to purchase the
              property You will receive all necessary property and SPV related
              documents for your perusal and scrutiny.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              How does a property get listed in RIOFIN?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              You can track and monitor your investments through RIOFIN’s
              user-friendly dashboard. We will also send you information
              regarding your investments on a periodic basis. Our support team
              is always available if you require further assistance.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              Is portfolio diversification possible through RIOFIN?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Yes, RIOFIN allows investors to diversify their portfolio by
              offering investor-friendly deals and custom product solutions.
              Flexible tenure, and ticket sizes provide our investors with an
              easy choice for investment portfolio diversification.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="11" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              What are the documents that NRI needs to submit for KYC?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              All the standard documents that are needed to register as an
              investor, along with an NRE or NRO account number from a savings
              bank account in India.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="12" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
              How does RIOFIN keep my personal information secure?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              The security of our platform and privacy of all your data is our
              utmost priority. RIOFIN’s platform is built keeping the
              best-in-class security and privacy features in mind.{" "}
            </p>
            <p>
              {" "}
              All your data is hosted on secure cloud networks and all sensitive
              client data is encrypted and stored with 256 bit SHA encryption.
              Also, RIOFIN will never share your data with any third party. For
              more details, please refer to our privacy policy.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
export default GeneraFaq;
