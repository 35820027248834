import React from "react";
import { Image } from "react-bootstrap";
import { Cokies, Coins } from "../assets/img";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import TopBanner from "../Frontendcomponent/TopBanner";
import Footer from "../Frontendcomponent/Footer";
const CookiesPolicy = () => {
    return (
        <div className="privact-page margin-t">
            <div className="top-bans">
                <TopBanner />
                <FrontHeading type="cookiespolicy" />
            </div>
            <div className="my-4 w-90   privacy-ses">
                <div className="text-center">

                    <Image src={Cokies} className="pri-img" />
                </div>

                <h5>Riofin Cookies Policy</h5>
                <ul className="term-cond">
                    <li> <Image src={Coins} />This document is an electronic record in terms of Information Technology Act, 2000 and rules made there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                    <li> <Image src={Coins} />This Privacy Policy (“Privacy Policy”) applies to the collection, receipt, storage, usage, processing, disclosure, transfer and protection (“Utilization”) of your Personal Information (defined below) when the user of the Website (“User” or “you”) uses our Website or avails any services offered by Riofin through the Website.</li>
                    <li> <Image src={Coins} />Thank you for visiting [www.riofin.in] (the “Website”). Riofin is registered as RIO GLOBAL FINTECH PRIVATE LIMITED having its registered office address at No.15A, 4th Floor, City Vista, Tower A, Suite No.178, Fountain Road, Kharadi Pune Pune MH 411014 IN (“Riofin”, “we”, “us” or “our”). We are committed to protecting and respecting the privacy of every person who shares information with it or whose information it receives.</li>
                    <li> <Image src={Coins} />This Cookies Policy explains what Cookies are and how We use them. You should read this policy so You can understand what type of cookies We use, or the information We collect using Cookies and how that information is used</li>
                    <li> <Image src={Coins} />Cookies do not typically contain any information that personally identifies a user, but personal information that we store about You may be linked to the information stored in and obtained from Cookies. For further information on how We use, store and keep your personal data secure, see our Privacy Policy.</li>
                    <li> <Image src={Coins} />We do not store sensitive personal information, such as mailing addresses, account passwords, etc. in the Cookies We use.</li>
                </ul>
            </div>
            <div className="my-4 w-90   privacy-ses">
                <h5>INTERPRETATION AND DEFINITIONS</h5>
                <h4 className="  ps-3">INTERPRETATION</h4>
                <p className="  ps-3">For the purposes of this Cookies Policy:</p>
                <ul className="term-cond">
                    <li> <Image src={Coins} />Company (referred to as either “the Company”, “We”, “Us” or “Our” in this Cookies Policy) refers to RIO GLOBAL FINTECH PRIVATE LIMITED with corporate identification number (CIN) U74140PN2022PTC211767, No.15A, 4th Floor, City Vista, Tower A, Suite No.178, Fountain Road, Kharadi Pune MH 411014 IN.</li>
                    <li> <Image src={Coins} />Cookies means small files that are placed on Your computer, mobile device or any other device by a website, containing details of your browsing history on that website among its many uses.</li>
                    <li> <Image src={Coins} />You means the individual accessing or using the Website, or a company, or any legal entity on behalf of which such individual is accessing or using the Website, as applicable.</li>
                </ul>
                <h5>TYPE OF COOKIES WE USE</h5>
                <p>Cookies can be “Persistent” or “Session” Cookies. Persistent Cookies remain on your personal computer or mobile device when You go offline, while Session Cookies are deleted as soon as You close your web browser.</p>
                <ul className="term-cond">
                    <li>  We use both session and persistent Cookies for the purposes set out below:</li>
                    <li> <Image src={Coins} />Necessary / Essential Cookies</li>
                    <li>Type: Session Cookies</li>
                    <li>Administered by: Us</li>
                    <li>Purpose: These Cookies are essential to provide You with services available through the Website and to enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use these Cookies to provide You with those services.</li>
                    <li className="ps-3"><Image src={Coins} />Functionality Cookies</li>
                    <li>Type: Persistent Cookies</li>
                    <li>Administered by: Us</li>
                    <li>Purpose: These Cookies allow us to remember choices You make when You use the Website, such as remembering your login details or language preference. The purpose of these Cookies is to provide You with a more personal experience and to avoid You having to re-enter your preferences every time You use the Website.</li>
                </ul>
                <h5>YOUR CHOICES REGARDING COOKIES</h5>
                <ul className="term-cond">
                    <li>If You prefer to avoid the use of Cookies on the Website, first You must disable the use of Cookies in your browser and then delete the Cookies saved in your browser associated with this website. You may use this option for preventing the use of Cookies at any time.</li>
                    <li>
                        If You do not accept Our Cookies, You may experience some inconvenience in your use of the Website and some features may not function properly.

                    </li>
                    <li>If You’d like to delete Cookies or instruct your web browser to delete or refuse Cookies, please visit the help pages of your web browser.</li>
                    <li>
                        <ul className="ps-3">
                            <li><Image src={Coins} />
                                For the Chrome web browser, please visit this page from Google:
                            </li>
                            <li  ><Image src={Coins} />For the Internet Explorer web browser, please visit this page from Microsoft: </li>
                            <li  ><Image src={Coins} /> For the Firefox web browser, please visit this page from Mozilla</li>
                            <li  ><Image src={Coins} />For the Safari web browser, please visit this page from Apple: </li>
                        </ul>
                    </li>
                    <li>For any other web browser, please visit your web browser’s official web pages.</li>
                </ul>
                <h5>MORE INFORMATION ABOUT COOKIES</h5>
                <p>You can learn more about cookies</p>
                <h5>CONTACT US</h5>
                <ul className="term-cond">
                    <li>If you have any questions about this Cookies Policy, You can contact us:</li>
                    <li>
                        <ul className="ps-3">
                            <li><Image src={Coins} />
                                By email: support@riofinassets.com
                            </li>
                            <li  ><Image src={Coins} />By visiting this page on our website www.riofin.in </li>

                        </ul>
                    </li>
                    <li>By mail: No.15A, 4th Floor, City Vista, Tower A, Suite No.178, Fountain Road, Kharadi Pune MH 411014 IN</li>
                </ul>

            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};
export default CookiesPolicy;