import React, { useState } from "react";
// import { toast, Toaster } from "react-hot-toast";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const ToggleSwitch = ({ label, tax, setTax, btn }) => {
  // const [tax, setTax] = useState(false);
  const [pop, setPop] = useState();

  const handleChange = () => {
    setTax(!tax);
  };

  const popUp = () => {
    !tax
      ? toast(
          "Tax deducted! means that your returns are tax-free. As per Income Tax act, Partners receiving profits of the LLP after deducting tax will be considered as tax free income",
          { position: toast.POSITION.TOP_RIGHT, autoClose: false }
        )
      : toast(
          "Remember to pay tax on your returns. In case of Pretax your income will be subject to TDS or other deduction under applicable laws.",
          { position: toast.POSITION.TOP_RIGHT, autoClose: false }
        );
  };

  const popUps = () => {
    !tax
      ? Swal.fire({
          position: "top-end",
          icon: "info",
          showCloseButton: true,
          html: "<small>Tax deducted! means that your returns are tax-free. As per Income Tax act, Partners receiving profits of the LLP after deducting tax will be considered as tax free income</small>",
          showConfirmButton: false,
          width: 400,
        })
      : Swal.fire({
          position: "top-end",
          icon: "info",
          html: "<small>Remember to pay tax on your returns. In case of Pretax your income will be subject to TDS or other deduction under applicable laws.</small>",
          showConfirmButton: false,
          width: 400,
          showCloseButton: true,
        });
  };

  return (
    <div className="toggle-switch d-flex-al">
      <h6>Pre Tax</h6>
      <input
        onClick={handleChange}
        type="checkbox"
        className="checkbox"
        name={label}
        id={label}
      />
      {/* {tax ? (
        <small>
          Tax deducted! means that your returns are tax-free. As per Income Tax
          act, Partners receiving profits of the LLP after deducting tax will be
          considered as tax free income
        </small>
      ) : (
        <small>
          Remember to pay tax on your returns. In case of Pretax your income
          will be subject to TDS or other deduction under applicable laws
        </small>
      )} */}

      <label className="label" htmlFor={label}>
        <span className="inner" onClick={popUp} />
        <span className="switch" onClick={popUp} />
      </label>
      <h6>Post Tax</h6>
    </div>
  );
};
export default ToggleSwitch;
