import React, { useRef, useState } from "react";
import { Row, Col, Card, Modal, Form, Image } from "react-bootstrap";
import Description from "../component/Description";
import { LessLogo, InvestBan } from "../assets/img";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { saveAs } from "file-saver";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";
import toast, { Toaster } from "react-hot-toast";
import useToken from "../services/Storage/useToken";
import axios from "axios";
const ProjectDetails = ({ data }) => {
  const { token } = useToken();
  const ref = useRef();

  // console.log("ProjectDetails", data);

  const [proof, setProof] = useState();
  const [transactionId, setTransactionId] = useState();
  const [Tid, setTid] = useState();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleShows = () => {
    setTid(data?.user_transaction_no);
    setShow(true);
  };

  // const fileClick = (e) => {
  //   ref.current.click();
  // };

  const fileClick = (e, id, url, status) => {
    if (status == 2 || url) {
      saveAs(url, "document.jpg");
    } else {
      setTransactionId(id);
      ref.current.click();
    }
  };

  // console.log("Tid", Tid);

  const uploadProof = () => {
    investmentServices
      .uploadProof(transactionId, {
        proof: proof,
        transaction_no: Tid,
      })
      .then((res) => {
        fetch();
        toast.success(res.data.message);
        handleClose();
        window.location.reload();
      })
      .catch((e) => ErrorHandler(e));
  };

  const imageclick = () => {
    let url = "/static/media/document.40cc17328763004eb4bd.jpg";
    saveAs(url, "document.jpg");
  };

  const downloadInvoice = (id) => {
    investmentServices
      .invoiceDownload(id)
      .then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "SamplePDF.pdf";
          alink.click();
        });
      })
      .catch((e) => ErrorHandler(e));
  };

  const downloadPdfFile = async () => {
    let id = data?.id;
    toast("wait for downloading...", {
      duration: 8000,
    });
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      "Content-Type": "blob",
      Authorization: `Bearer ${token}`,
    };
    const config = {
      method: "get",
      url: `https://app-script.riofinassets.com/public/index.php/crm/invoice-download/${id}`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);

      const outputFilename = `invoice.pdf`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  // const imageclick = () => {
  //   let url = "/static/media/document.40cc17328763004eb4bd.jpg";
  //   saveAs(url, "document.jpg");
  // };

  // const downloadPdfFile = async () => {
  //   // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
  //   const headers = {
  //     "Content-Type": "blob",
  //     Authorization: `Bearer ${token}`,
  //   };
  //   const config = {
  //     method: "POST",
  //     url: `https://app-script.riofinassets.com/public/index.php/crm/invoice-download/${data?.id}`,
  //     responseType: "arraybuffer",
  //     headers,
  //   };

  //   try {
  //     const response = await axios(config);

  //     const outputFilename = `invoice.pdf`;

  //     // If you want to download file automatically using link attribute.
  //     const url = URL.createObjectURL(new Blob([response.data]));
  //     const link = document.createElement("a");
  //     link.href = url;
  //     link.setAttribute("download", outputFilename);
  //     document.body.appendChild(link);
  //     link.click();
  //   } catch (error) {
  //     throw Error(error);
  //   }
  // };

  // const uploadProof = () => {
  //   investmentServices
  //     .uploadProof(data?.transaction_id, { proof: proof })
  //     .then((res) => {
  //       toast.success(res.data.message);
  //     })
  //     .catch((e) => ErrorHandler(e));
  // };

  return (
    <div className="inves-head">
      <Toaster position="top-right" reverseOrder={false} />

      <h5>{data?.project?.title}</h5>
      {/* <Image src={LessLogo} /> */}
      <div className="inves-baner">
        <Image src={data?.project?.img_url} className="w-100" />
      </div>
      <div className="d-flex-al-jb inv-details my-3">
        <Col md={3}>
          <p>Pre Tax Return</p>
          <h6>
            {data?.project?.interest_percentage ||
              data?.project?.tax_percentage}{" "}
            %{" "}
          </h6>
        </Col>
        <Col md={3}>
          <p>Tenure</p>
          <h6>{data?.project?.tenure} months</h6>
        </Col>
        <Col md={6}>
          <ProgressBar now={data?.project?.total_percentage} />
          <div className="d-flex-al-jb mt-3 compl-leas">
            <h6>
              {Math.round(data?.project?.total_invested_amount)} /{" "}
              {Math.round(data?.project?.total_value)}
            </h6>
            <h4>{data?.project?.total_percentage}% Completed</h4>
          </div>
        </Col>
      </div>
      <div className="my-3  ">
        {data?.project_details == null ? (
          <>
            <div className="my-2">
              <span>
                <b>Download your Investment Invoice</b>
              </span>
            </div>
            <Button
              className="my-2 invoice-btn "
              onClick={() => downloadPdfFile()}>
              <i className="fa fa-download"></i>
              Invoice
            </Button>
          </>
        ) : (
          ""
        )}

        {data?.project_details == null ? (
          ""
        ) : (
          <>
            <div className="my-2">
              <span>
                <b>Kindly Upload your Transaction reciept here</b>
              </span>
            </div>
            <Button className="my-2 invoice-btn" onClick={handleShows}>
              Upload
            </Button>
          </>
        )}

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Proof Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="d-flex-al-jb">
                {/* <div className="my-3 d-flex"> */}

                {/* </div> */}
                {/* <Image src={data?.project?.img_url} className="inves_img" /> */}
                <Col sm={12}>
                  <div className="form-group fill">
                    <label className="floating-label" htmlFor="Title">
                      Transaction Id
                    </label>
                    <div className="input-group mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="Title"
                        value={Tid}
                        placeholder="Enter Transaction Id"
                        onChange={(e) => setTid(e.target.value)}
                        // disabled={Tid == null ? false : true}
                      />
                    </div>
                  </div>
                </Col>
                <Button
                  className="mx-2 invoice-btn"
                  onClick={(e) => {
                    fileClick(e, data?.id, data?.img_url, data?.status);
                  }}>
                  {data?.status == 3 || !data?.img_url ? "Upload" : "Proof"}

                  {/* {data?.img_url ? "Proof" : "Upload"} */}
                </Button>
                <input
                  onChange={(e) => setProof(e.target.files[0])}
                  ref={ref}
                  type="file"
                  className="d-none"
                />
                {/*
                <Button onClick={() => downloadPdfFile(data?.id)}>
                  Invoice
                </Button> */}
                {data?.status == 3 ? (
                  <h6 className="text-danger">
                    Your proof Has been rejected. Kindly check Re-upload
                  </h6>
                ) : null}
              </div>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="border-0"
              style={{ background: "rgb(95,188,95)" }}
              variant="secondary"
              onClick={handleClose}>
              Close
            </Button>

            <Button
              className="border-0"
              style={{ background: "rgb(10,63,127)" }}
              variant="primary"
              // disabled={data?.status !== 3 || data?.img_url}
              onClick={uploadProof}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      {/* <Description data={data?.project} />  */}
    </div>
  );
};
export default ProjectDetails;
