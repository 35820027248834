import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const login = (data) => {
  return httpClient.post(URL.REQUEST_OTP, data);
};

const verifyOtp = (data) => {
  return httpClient.post(URL.VERIFY_OTP, data);
};

const register = (data) => {
  return httpClient.post(URL.REGISTER, data);
};

const logout = (data) => {
  return httpClient.post(URL.LOGOUT, data);
};

const LoginServices = {
  verifyOtp,
  register,
  login,
  logout,
};

export default LoginServices;
