import React, { useState, useEffect } from "react";
import { Col, Image, Nav } from "react-bootstrap";
// import { GrClose } from "react-icons/gr";
import { ReferFriend } from "../assets/img";
import moment from "moment/moment";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// import { icons } from "react-icons";

const ReferDetatils = ({ data }) => {
  // const [show, setShow] = useState(false);
  const [referalList, setReferalList] = useState([]);
  console.log(
    "🚀 ~ file: ReferDetatils.js:11 ~ ReferDetatils ~ referalList:",
    referalList
  );

  const details = [];

  useEffect(() => {
    setReferalList(data?.refferals);
  }, [data]);
  // console.log("refer-detail", data);
  return (
    <>
      <Col xs={12} xl={11} className="px-2 my-3 mx-auto">
        <div
          style={{ width: "100%", overflowX: "scroll" }}
          className="your-ref w-100"
        >
          <h5>Your Referrals</h5>
          <Tab.Container defaultActiveKey="List">
            <div className=" my-3">
              <Nav variant="pills" className="invest-type">
                <Nav.Item>
                  <Nav.Link eventKey="List" className="d-flex-al-jb">
                    Referrals List
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Invested" className="d-flex-al-jb">
                    Referrals not yet Invested
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="List">
                  <table>
                    <tr>
                      <th className="p-2">S.No</th>
                      <th className="text-nowrap p-2">User Name</th>
                      <th
                        className="text-nowrap p-2"
                        style={{ minWidth: "330px" }}
                      >
                        Project Name
                      </th>
                      <th className="text-nowrap p-2">Invested Date</th>
                      <th className="text-nowrap p-2">Invested Amount</th>
                      <th className="text-nowrap p-2">Earned Amount</th>
                    </tr>
                    <tbody className="refel-table-body">
                      {referalList &&
                        referalList?.map((list, index) => (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <td>{index + 1}</td>
                            <td className="d-flex align-items-center gap-2 flex-nowrap">
                              <p className="init-tab d-flex-al-jc   text-uppercase">
                                {list?.user_investment?.user?.name[0] +
                                  list?.user_investment?.user?.name[1]}
                              </p>
                              <p className="mb-0 text-nowrap">
                                {list?.user_investment?.user?.name}
                              </p>
                            </td>
                            <td>{list?.user_investment?.project?.title}</td>
                            <td>
                              {moment(list?.user_investment?.created_at).format(
                                "MMM Do YY"
                              )}
                            </td>
                            <td>
                              {
                                list?.user_investment?.invested_amount?.split(
                                  "."
                                )[0]
                              }
                            </td>
                            <td>{list?.amount}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Tab.Pane>
                <Tab.Pane eventKey="Invested">
                  <table>
                    <tr>
                      <th className="p-2">S.No</th>
                      <th className="text-nowrap p-2">User Name</th>
                      <th className="text-nowrap p-2">Registration Date</th>
                      <th className="text-nowrap p-2">Status</th>
                      <th className="text-nowrap p-2">Eligible UpTo</th>
                    </tr>
                    <tbody className="refel-table-body">
                      {referalList &&
                        details?.map((list, index) => (
                          <tr key={index} style={{ cursor: "pointer" }}>
                            <td>{index + 1}</td>
                            <td className="d-flex align-items-center gap-2 flex-nowrap">
                              <p className="init-tab d-flex-al-jc   text-uppercase">
                                {list?.user_investment?.user?.name[0] +
                                  list?.user_investment?.user?.name[1]}
                              </p>
                              <p className="mb-0 text-nowrap">
                                {list?.user_investment?.user?.name}
                              </p>
                            </td>
                            <td>
                              {moment(list?.user_investment?.created_at).format(
                                "MMM Do YY"
                              )}
                            </td>
                            <td>
                              {
                                list?.user_investment?.invested_amount?.split(
                                  "."
                                )[0]
                              }
                            </td>
                            <td>{list?.amount}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
          {/* <table>
            <tr>
              <th className="p-2">S.No</th>
              <th className="text-nowrap p-2">User Name</th>
              <th className="text-nowrap p-2" style={{ minWidth: "330px" }}>
                Project Name
              </th>
              <th className="text-nowrap p-2">Invested Date</th>
              <th className="text-nowrap p-2">Invested Amount</th>
              <th className="text-nowrap p-2">Earned Amount</th>
            </tr>
            <tbody className="refel-table-body">
              {referalList &&
                referalList?.map((list, index) => (
                  <tr key={index} style={{ cursor: "pointer" }}>
                    <td>{index + 1}</td>
                    <td className="d-flex align-items-center gap-2 flex-nowrap">
                      <p className="init-tab d-flex-al-jc   text-uppercase">
                        {list?.user_investment?.user?.name[0] +
                          list?.user_investment?.user?.name[1]}
                      </p>
                      <p className="mb-0 text-nowrap">
                        {list?.user_investment?.user?.name}
                      </p>
                    </td>
                    <td>{list?.user_investment?.project?.title}</td>
                    <td>
                      {moment(list?.user_investment?.created_at).format(
                        "MMM Do YY"
                      )}
                    </td>
                    <td>
                      {list?.user_investment?.invested_amount?.split(".")[0]}
                    </td>
                    <td>{list?.amount}</td>
                  </tr>
                ))}
            </tbody>
          </table> */}
        </div>
      </Col>
      <Col md={6} xs={12} className="px-2">
        <Image src={ReferFriend} className="w-100" />
      </Col>

      {/* <Modal
        show={show}
        onHide={() => setShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="refer-modal-box">
        <div className="refer-modal-header-box">
          <p onClick={() => setShow(false)}>
            <GrClose />
          </p>
        </div> */}

      {/* <Modal.Body className="show-grid">
          <Container> */}
      {/* <div className="d-flex mb-1 pb-1">
              <Col
                xs={6}
                md={3}
                className="fw-bold"
                style={{ width: "131px", display: "inline-block" }}>
                Project Name
              </Col>
              <Col className="m-0 text-wrap" xs={6} md={9}>
                {data?.refferals[0]?.user_investment?.project?.title}
              </Col> */}
      {/* <p>
                <span style={{ width: "131px", display: "inline-block" }}>
                  Project Name
                </span>
                <span>
                  : {data?.refferals[0]?.user_investment?.project?.title}
                </span>
              </p> */}
      {/* </div> */}
      {/* <div className="d-flex justify-content-between">
              <Col xs={6} md={6} className="my-1 py-1">
                <span className="fw-bold pe-2 me-1">Invested Amount</span>
                <span>
                  {data?.refferals[0]?.user_investment?.invested_amount}
                </span>
              </Col>
              <Col
                xs={12}
                md={6}
                className="my-1 py-1 d-flex justify-content-lg-end">
                <span className="fw-bold pe-2 me-2">Invested Date</span>
                <span className="px-2 mx-2">
                  {moment(
                    data?.refferals[0]?.user_investment?.created_at
                  ).format("MMM Do YY")}
                </span>
              </Col>
            </div> */}
      {/* <Col xs={6} md={6} className="my-1 py-1">
              <span className="fw-bold pe-2 me-1">Earned Amount</span>
              <span className="px-2 mx-1">{data?.earned_amount}</span>
            </Col> */}
      {/* </Container>
        </Modal.Body> */}
      {/* </Modal> */}
    </>
  );
};
export default ReferDetatils;
