import React from "react";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import PartnerDetails from "../Frontendcomponent/PartnerDetails";
const OurPartners = () => {
    return (
        <div className="our-deal margin-t">
            <div className="top-bans">
                <TopBanner />
                <FrontHeading type="ourpartners" />
            </div>
            <div className="my-4 how-inv w-90">
                <h5>Partner Curation and Diligence</h5>
                <PartnerDetails />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};
export default OurPartners;