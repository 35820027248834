import React from "react";
import { Col, Image } from "react-bootstrap";
import { Nosupport, Finacial, Finacial1, Finacial2 } from "../assets/img";
const PartnerDetails = () => {
    return (

        <div className="d-flex-al my-5">
            <Col md={7} xs={12} className="px-4 my-3">
                <div className="part-box d-flex my-4">
                    
                    <Col md={4} className="d-flex-al-ja" >
                        <Image src={Finacial} /> 
                        <div className="line-prj"></div>
                    </Col> 

                    <Col md={8}>
                    <div className="cir-prj d-flex-al-jc">01</div>
                        <h6>Financials</h6>
                        <p>Analysis of annual reports and MIS for the previous 12-18 months to understand scale, growth of business, unit economics, profitability patterns, current cash position and outstanding debts and loans</p>
                    </Col>

                </div>
                <div className="part-box d-flex my-4">
                    
                    <Col md={4} className="d-flex-al-ja" >
                        <Image src={Finacial1} /> 
                        <div className="line-prj"></div>
                    </Col> 

                    <Col md={8}>
                    <div className="cir-prj d-flex-al-jc">02</div>
                        <h6>Industries Profile</h6>
                        <p>Professional backgrounds of Founders/ CXOs,  ongoing litigations as well as reference calls with key customers and other stakeholders</p>
                    </Col>

                </div>
                <div className="part-box d-flex my-4">
                    
                    <Col md={4} className="d-flex-al-ja" >
                        <Image src={Finacial2} /> 
                        <div className="line-prj"></div>
                    </Col> 

                    <Col md={8}>
                    <div className="cir-prj d-flex-al-jc">03</div>
                        <h6>Business Performance</h6>
                        <p>Review of key contracts, industry trends, asset and OEM quality to enable estimation of future performance for the leasea</p>
                    </Col>

                </div>
            </Col>
            <Col md={5} xs={12} className="px- my-3" >
                <Image src={Nosupport} className="w-100" />
            </Col>

        </div>

    );
};
export default PartnerDetails;