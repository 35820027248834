export const LoginImage = require("./login.png");
export const FavIcon = require("./favicon.png");
export const Logo = require("./riofin.png");
export const LoginBg = require("./loginbg.png");
export const RegBg = require("./regbg.png");
export const DashTolam = require("./icon/dashta.png");
export const DashTolin = require("./icon/dashti.png");
export const DashOnproject = require("./icon/dashog.png");
export const DashComproject = require("./icon/dashcp.png");
export const ProfileImg = require("./icon/profile.png");
export const UserImg = require("./icon/user.png");
export const Logout = require("./icon/logout.png");
export const CirDash = require("./icon/cir-dash.png");
export const CirDashy = require("./icon/cir-dash1.png");
export const CirDashb = require("./icon/cir-dash2.png");
export const CirDasho = require("./icon/cir-dash3.png");
export const Dashearn = require("./icon/earn.png");
export const DashLeas = require("./icon/leas.png");
export const DashLeas1 = require("./icon/engineering_28x28.png");
export const Dashreal = require("./icon/real.png");
export const PlatForm = require("./icon/platform.png");
export const PassOff = require("./icon/passoff.png");
export const ActiveOff = require("./icon/activeoff.png");
export const LessLogo = require("./icon/leasing-logo.png");
export const Increase = require("./icon/increase.png");
export const InvestTot = require("./icon/Invest-Tot.png");
export const InvestBan = require("./icon/invest-ban.png");
export const Document = require("./icon/document.jpg");
export const DownArrow = require("./icon/right.png");
export const Calendar = require("./icon/calendar.png");
export const Kycimage = require("./icon/kyc.png");
export const Growpay = require("./icon/grow.png");
export const Ongoing = require("./icon/ongoing.png");
export const Completed = require("./icon/checked.png");
export const Manual = require("./icon/manu.png");
export const NoInvest = require("./icon/noinves.png");
export const Avis = require("./icon/avis.png");
export const EditIcon = require("./icon/edit.png");
export const Nosupport = require("./icon/support.png");
export const Refer = require("./icon/refer.png");
export const ReferFriend = require("./icon/refer-friend.png");
export const ReferMoney = require("./icon/ref-money.png");
export const ReferUser = require("./icon/ref-user.png");
export const ReferSpread = require("./icon/spread.png");
export const Raise = require("./icon/raise.png");
export const SucRasise = require("./icon/succes.png");
export const Docs = require("./icon/docs.png");
export const Log = require("./icon/log.png");
export const Photo = require("./icon/photo.png");
export const Profi = require("./icon/profi.png");
export const ProfileLine = require("./icon/profile-line.png");
export const RaiseIc = require("./icon/raise-ic.png");
export const Referico = require("./icon/referico.png");
export const Sup = require("./icon/sup.png");
export const UserFirs = require("./icon/user-1.png");
export const Bank = require("./icon/bank.png");
export const KYCImag = require("./icon/kycs.png");
export const paymenAsset = require("./icon/paymen-asset.png");
// Frontend //
export const BanerWay = require("./FEimg/way.png");
export const Banner = require("./FEimg/banner.jpg");
export const Growth = require("./FEimg/grow.png");
export const GrowthNo = require("./FEimg/grow-no.png");
export const GrowthFund = require("./FEimg/grow-fund.png");
export const GrowthIrr = require("./FEimg/grow-irr.png");
export const Nofalt = require("./FEimg/nodef.png");
export const WorkTrans = require("./FEimg/work-trans.png");
export const WorkAna = require("./FEimg/work-anal.png");
export const WorkRep = require("./FEimg/work-rep.png");
export const WorkSeam = require("./FEimg/work-seam.png");
export const Howriofin = require("./FEimg/how-wrk.png");
export const HowReal = require("./FEimg/real.png");
export const Layer = require("./FEimg/lay.png");
export const UseClient = require("./FEimg/use.png");
export const Clilogo = require("./FEimg/cli-logo1.png");
export const Clilogo1 = require("./FEimg/cli-logo.png");
export const Blog = require("./FEimg/blog.jpg");
export const FooterBg = require("./FEimg/footer.jpg");
// export const Instagram = require("./FEimg/instagram.png");
export const Instagram = require("./instagram_logo.png");
export const Twitter = require("./twitter.png");
// export const Facebook = require("./FEimg/facebook.png");
export const Facebook = require("./facebook.png");
export const Email = require("./FEimg/email.png");
export const Linkedin = require("./linkedin.png");
export const Youtube = require("./youtube.png");
export const Map = require("./FEimg/map.png");
export const Call = require("./FEimg/call.png");
export const TopBan = require("./FEimg/topban.png");
export const Step1 = require("./FEimg/step1.png");
export const Step2 = require("./FEimg/step2.png");
export const Step3 = require("./FEimg/step3.png");
export const Step4 = require("./FEimg/step4.png");
export const Step5 = require("./FEimg/step5.png");
export const Step6 = require("./FEimg/step6.png");
export const Step7 = require("./FEimg/step7.png");
export const ProcReg = require("./FEimg/reg.png");
export const Benifit = require("./FEimg/benifit.png");
export const Riofin = require("./FEimg/riofin.jpg");
export const Finacial = require("./FEimg/finacial.png");
export const Finacial1 = require("./FEimg/finacial1.png");
export const Finacial2 = require("./FEimg/finacial2.png");
export const BeLeas = require("./FEimg/be-leas.png");
export const BeLeas1 = require("./FEimg/be-leas1.png");
export const BeLeas2 = require("./FEimg/be-leas2.png");
export const FLease = require("./FEimg/lease.png");
export const Coins = require("./FEimg/coin-fill.png");
export const Dots = require("./FEimg/record-circle-fill.png");
export const FaqImage = require("./FEimg/faq.png");
export const Notepad = require("./FEimg/notepad.png");
export const GenerFaqimag = require("./FEimg/gene-faq.png");
export const RealFaqimag = require("./FEimg/real-faq.png");
export const LeasFaqimag = require("./FEimg/leas-faq.png");
export const Funds = require("./FEimg/funds.png");
export const OurMis = require("./FEimg/ourmis.png");
export const Contact = require("./FEimg/contact.png");
export const Privacy = require("./FEimg/privacy.png");
export const Terms = require("./FEimg/terms-cond.png");
export const Cokies = require("./FEimg/cokies.png");
export const NonMark = require("./FEimg/marked.png");
export const Non = require("./FEimg/what-we-do.png");
export const Fixeds = require("./FEimg/fixed.png");
export const CRE = require("./FEimg/app_cre.png");
export const Flipping = require("./FEimg/short.png");
export const Gifing = require("./FEimg/steps.gif");
export const Digital = require("./FEimg/digita.png");
export const Apps = require("./FEimg/app.png");
export const Apple = require("./FEimg/apple.png");
export const Tree = require("./FEimg/bas.png");
export const Missipn = require("./FEimg/miss.png");
export const Chart = require("./FEimg/piechart.png");
export const SeqLogo = require("./FEimg/logos.png");
export const Invest = require("./FEimg/bank.png");
export const InvestImg = require("./FEimg/corporate-domes.png");
export const Invtree = require("./FEimg/leatree.png");
export const Investment = require("./FEimg/investment.png");
export const Loan = require("./FEimg/loan.png");
export const Assone = require("./FEimg/as-one.png");
export const AssTwo = require("./FEimg/as-teo.png");
export const AssThr = require("./FEimg/as-th.png");
export const AssFou = require("./FEimg/as-f.png");
export const AssFiv = require("./FEimg/as-fiv.png");
export const AssSix = require("./FEimg/as-six.png");
export const AssSev = require("./FEimg/as-se.png");
export const Whowe = require("./FEimg/whow.png");
export const Whos = require("./FEimg/who.png");
export const Secure = require("./FEimg/secure.png");
export const Transparency = require("./FEimg/transparency.png");
export const Liq = require("./FEimg/liq.png");
export const Profit = require("./FEimg/profit.png");
export const ForInv = require("./FEimg/for-inv.png");
export const Arrow = require("./FEimg/arow.png");
export const Partner = require("./FEimg/part.png");
export const Query = require("./FEimg/query.png");
export const RelAs = require("./FEimg/rel-a.png");
export const RelBs = require("./FEimg/rel-b.png");
export const RelCs = require("./FEimg/rel-c.png");
export const RelDs = require("./FEimg/rel-d.png");
export const RelEs = require("./FEimg/rel-e.png");
export const RelFs = require("./FEimg/rel-f.png");
export const RelGs = require("./FEimg/rel-g.png");
export const RelHs = require("./FEimg/rel-h.png");
export const Data = require("./FEimg/data.png");
export const Trans = require("./FEimg/transparen.png");
export const End = require("./FEimg/end.png");
export const Chain = require("./FEimg/chain.png");
export const FlowChart = require("./FEimg/flow-chart.png");
export const Realpro = require("./FEimg/real-pro.png");

// Frontend End//
