import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Blog } from "../assets/img";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import TopBanner from "../Frontendcomponent/TopBanner";
import Footer from "../Frontendcomponent/Footer";
import frontendService from "../services/service/frontEnd";
import Button from "react-bootstrap/Button";
import ErrorHandler from "../services/Constant/ErrorHandler";
import DOMPurify from "dompurify";
const BlogPage = () => {
  const [data, setData] = useState([]);
  const DATE_OPTIONS = {
    // weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const navigate = useNavigate();

  const blogdata = () => {
    let temp = [];
    frontendService
      .blogList()
      .then((res) => {
        // res.data.blogs.reverse().map((item) => temp.push(item.id));
        let rever = res?.data?.blogs.reverse();
        setData(rever);
      })
      .catch((e) => ErrorHandler(e));
  };

  const blogInd = (data) => {
    // console.log("data", data);
    // navigate("/blogdetails");
    navigate("/blogdetails", { state: data });
  };

  useEffect(() => {
    // setTimeout(() => {
    blogdata();
    // }, 1000);
  }, []);
  return (
    <div className="blog-page margin-t">
      <div className="top-bans">
        <TopBanner />
        <FrontHeading type="blog" />
      </div>
      <div className="my-4 blog-sess w-90 text-center">
        <h5>OUR BLOG</h5>
        <div className="d-flex">
          {data?.map((list, index) => {
            return (
              <Col key={index} md={4} xs={12} className="px-2 my-2">
                <div className="blog-box">
                  <Image src={list.img_url} className="w-100" />
                  <div className="blog-ses ">
                    <div style={{ maxHeight: "3rem" }} className="d-flex">
                      <h6 className="me-auto blog-title-box">
                        {list?.heading}
                      </h6>
                      <p
                        style={{ fontSize: "13px", fontFamily: "f1" }}
                        className="mb-1 pb-1 ms-auto">{`${list?.duration} read`}</p>
                    </div>

                    <p
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          list?.description?.slice(0, 500)
                        ),
                      }}></p>
                    <Button
                      style={{ maxHeight: "2.3rem" }}
                      className="btn-web my-2"
                      onClick={() => blogInd(list.id)}>
                      View More
                    </Button>
                    <div className="d-flex">
                      <p
                        style={{
                          fontSize: "13px",
                          fontFamily: "f1",
                          maxHeight: "2rem",
                        }}
                        className="mt-1 pt-1 me-auto">
                        {list?.author}
                      </p>

                      <p
                        style={{
                          fontSize: "13px",
                          fontFamily: "f1",
                          maxHeight: "2rem",
                        }}
                        className="mt-1 pt-1 ms-auto">
                        {new Date(list?.created_at).toLocaleDateString(
                          "en-US",
                          DATE_OPTIONS
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </Col>
            );
          })}

          {/* <Col md={4} xs={12} className="px-2 my-2">
                        <div className="blog-box">
                            <Image src={Blog} className="w-100" />
                            <div className="blog-ses ">
                                <h4>Which kind of features and functionalities we should
                                    emphasis as per your business requirements? </h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. enim ad minim veniam, quis nostrud exercitation.</p>
                                <Link className="btn-web  mt-3" to="/blogdetails">View More</Link>
                            </div>
                        </div>
                    </Col>
                    <Col md={4} xs={12} className="px-2 my-2">
                        <div className="blog-box">
                            <Image src={Blog} className="w-100" />
                            <div className="blog-ses ">
                                <h4>Which kind of features and functionalities we should
                                    emphasis as per your business requirements? </h4>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. enim ad minim veniam, quis nostrud exercitation.</p>
                                <Link className="btn-web  mt-3" to="/blogdetails">View More</Link>
                            </div>
                        </div>
                    </Col> */}
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default BlogPage;
