import React from "react";
import { Col, Image } from "react-bootstrap";
import {
  InvestImg,
  Invest,
  BeLeas2,
  FLease,
  Realpro,
  SeqLogo,
  HowReal,
  RelAs,
  RelBs,
  RelCs,
  RelDs,
  RelEs,
  RelFs,
  RelGs,
  RelHs,
  Data,
  Trans,
  End,
  Chain,
  FlowChart,
  Arrow,
} from "../assets/img";

const WhyRealEsate = () => {
  return (
    <div className="leas-page">
      <div className="d-flex-al   my-3">
        {/* <h5> Provide Animation for the below mentioned Points</h5> */}

        <Col md={4} xs={12} className="px-3 my-2">
          <div className="due_di">
            <ul className="ps-3" style={{ marginTop: "20px" }}>
              <li>
                <i class="fa-solid fa-coins cc_ic"></i> Real-estate
              </li>
              <li>
                <i class="fa-solid fa-coins cc_ic"></i> Acquisition from Banks
              </li>
            </ul>
          </div>
        </Col>

        <Col md={8} xs={12} className="px-2 my-2">
          <div className="img_pos">
            <div className="abs_img">
              <Image src={SeqLogo} />
            </div>

            <div className="d-flex-al my-4">
              <Col md={6} xs={12} className="px-3 my-3">
                <div className="des-box">
                  <h5>Property Screening</h5>
                  <ul>
                    <li>
                      Strict property screening with a geographic advantage,
                      superior specifications and Metro-Cities
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="px-3 my-3">
                <div className="des-box">
                  <h5>Due Diligence</h5>
                  <ul>
                    <li>
                      Our legal counsel conduct scrupulous checks to ensure
                      every asset is risk free
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="px-3 my-md-5 my-3">
                <div className="des-box">
                  <h5>In-depth Financial Analysis</h5>
                  <ul>
                    <li>
                      Data-driven investment decisions can help predict future
                      growth prospects for assets, which can then be used to
                      make more informed decisions.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="px-3 my-md-5 my-3">
                <div className="des-box">
                  <h5>Listing</h5>
                  <ul>
                    <li>Recognizing asset utilisation Order</li>
                    <li>
                      Listed properties have a perfect balance between yield,
                      stability and capital appreciation
                    </li>
                  </ul>
                </div>
              </Col>
            </div>
          </div>
        </Col>
      </div>
      <div className="dif-leas my-3 d-flex-al">
        <Col md={8} xs={12} className="px-3 my-2">
          <div className="dif_po">
            <h5>Riofin Strength in Real Estate</h5>
            <p>
              We Board of directors have 23+ year’s Experience in CRE & handling
              Bank Auction Properties
            </p>
          </div>

          <div className="dif_po1">
            <h5>Transparent Asset Class Security</h5>
            <p>
              Transparency to every investor to access the Detailed information
              about the properties such as their prices, market position,
              location and Legal documents
            </p>
          </div>
          <div className="dif_po2">
            <h5>High Appreciation</h5>
            <p>
              We purchase properties,which increases the value within a limited
              time period to get higher appreciation.
            </p>
          </div>
        </Col>
        <Col md={4} xs={12} className="px-3 my-2">
          <Image src={HowReal} className="w-100" />
        </Col>
      </div>
      {/* assets design */}
      <div className="rio_fin my-4 text-center">
        <h5>How Riofin Assets works</h5>
        <div className="d-flex-jc my-2">
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelAs} className="my-2" />
              <h6>01. Selection of Property </h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelBs} className="my-2" />
              <h6>02. Document Verification</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelCs} className="my-2" />
              <h6>03. Mutual Agreement b/w Company & Property owner </h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelDs} className="my-2" />
              <h6>04. Listing Project in Platform</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelEs} className="my-2" />
              <h6>05. Accumulation of Funds</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelFs} className="my-2" />
              <h6>06. Purchase of Assets</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelGs} className="my-2" />
              <h6>07. Selling of ASSETS</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={RelHs} className="my-2" />
              <h6>08. Sharing Profits </h6>
            </div>
          </div>
        </div>
      </div>
      {/* assets design end */}
      {/* Flow Chart*/}
      <div className=" w-90 my-4">
        <h5 className="text-center">Real Estate Assets Classes</h5>
        <div className="d-flex ">
          <Col className="col-md-6 col-12 px-2 my-2">
            <Image src={FlowChart} className="w-100" />
          </Col>
          <Col className="col-md-6 col-12 px-4 my-2">
            <div className="flow-conten my-4">
              <h1>01</h1>
              <div className="flow-abs">
                <h4>High Rental Yeild</h4>
                <p>
                  8% - 15% rental yeild with statable and Predictable cash flow
                </p>
              </div>
            </div>
            <div className="flow-conten my-4  pad-legy">
              <h1>02</h1>
              <div className="flow-abs">
                <h4>Capital Appreciation</h4>
                <p> Year on year capital appreciation (17% - 25% IRR)</p>
              </div>
            </div>
            <div className="flow-conten my-4  pad-third">
              <h1>03</h1>
              <div className="flow-abs">
                <h4>Capital Preservation</h4>
                <p>Real Estate is a hard asset that preserves capital</p>
              </div>
            </div>
            <div className="flow-conten my-4  pad-fit">
              <h1>04</h1>
              <div className="flow-abs">
                <h4>Stable and Non-correlated</h4>
                <p>
                  The performance of commercial real estate is not closely with
                  stocks and bonds.
                </p>
              </div>
            </div>
          </Col>
        </div>
      </div>
      {/* Flow Chart end*/}
      <div className="ben-lea  my-4 w-90">
        <h5>MORE PROFITS with MINIMUM INVESTMENT</h5>
        <p className="text-center">
          Participate in real estate projects with minimum investment as
          co-investor and get more profits as the landlord earns.{" "}
        </p>
        <div className="d-flex my-4 real_easta">
          <Col md={6} xs={12} className="px-3 my-2">
            <div className="leas-box">
              <Image src={InvestImg} className=" " />
              <h5>Residentail Properties</h5>
              <p>
                Exploring the world of Real-estate by fractional funding. RIOFIN
                ASSETS Purchase properties from an individual is cumbersome if
                he/she wishes to sale it for Emergency Need of funds. Our
                organization is seeking funds from the investors for the purpose
                of buying residential flats available in Tire I, II and III
                cities with a view to hold and resale the property as and when
                the value of property appreciates. The profits earned in turn
                are distributed to the investors.
              </p>
            </div>
          </Col>
          <Col md={6} xs={12} className="px-3 my-2">
            <div className="leas-box">
              <Image src={Invest} className=" " />
              <h5> CRE- Commercial Real estate</h5>
              <p>
                {" "}
                Riofin Assets is an excellent choice for those looking to invest
                in commercial real estate (CRE).
              </p>
              <p>
                Investing in Riofin Assets provides several benefits, including
                the potential for steady rental income, long-term capital
                appreciation, and portfolio diversification. The company's focus
                on acquiring well-located properties with strong fundamentals
                and high-quality tenants ensures stable cash flows and enhances
                the potential for future growth.
              </p>
            </div>
          </Col>
        </div>
      </div>
      {/* advatage */}
      <div className="my-2   w-90 abouts_iconss ">
        <h4 className="text-center">
          Advantages of RIOFIN ASSETS in REAL-ESTATE
        </h4>
        <div className="  d-flex  ">
          <Col md={3} sm={6} xs={12} className="px-2 my-2">
            <div className="box-iocn my-3">
              <Image src={Data} className=" " />
            </div>
            <h5>Data-Driven</h5>
            <p>
              We leverage data and on-the-ground experience to identify and list
              only the best opportunities.
            </p>
          </Col>
          <Col md={3} sm={6} xs={12} className="px-2 my-2">
            <div className="box-iocn my-3">
              <Image src={Trans} className=" " />
            </div>
            <h5>Complete Transparency</h5>
            <p>
              Our investment process is completely transparent and comes with
              detailed reporting at every step of the way.
            </p>
          </Col>
          <Col md={3} sm={6} xs={12} className="px-2 my-2">
            <div className="box-iocn my-3">
              <Image src={End} className=" " />
            </div>
            <h5>End to End Management</h5>
            <p>
              We manage the asset from acquisition to exit. Investors enjoy a
              hands-off investment experience.
            </p>
          </Col>
          <Col md={3} sm={6} xs={12} className="px-2 my-2">
            <div className="box-iocn my-3">
              <Image src={Chain} className=" " />
            </div>
            <h5>Fully Digital Experience</h5>
            <p>
              Investing with is completely online, no physical paperwork. Invest
              and track your portfolio anytime, from anywhere.
            </p>
          </Col>
        </div>
      </div>
      {/* advatage end */}
      {/* flipping */}
      <div className="my-3  ">
        <div className="text-center">
          <h5>PROPERTY FLIPPING</h5>
          <Image src={Realpro} className="w-80" />
        </div>
        <p>
          Riofin Assets offers opportunities for property flipping, a popular
          investment strategy in the real estate market. With a team of
          experienced professionals and a focus on value-add strategies, Riofin
          Assets is well-equipped to identify and acquire undervalued properties
          with potential for renovation and resale from BANK Auctions.
        </p>
        <p>
          {" "}
          Property flipping involves purchasing a property, improving it through
          renovations or other value-add strategies, and selling it for a profit
          within a relatively short period. Riofin Assets' expertise in
          identifying properties with potential for improvement and its hands-on
          approach to asset management make it a strong partner for property
          flipping investments.
        </p>
        <p>
          {" "}
          Investing in property flipping with Riofin Assets can provide several
          benefits, including the potential for significant returns on
          investment, the ability to generate cash flow from rental income
          during the holding period, and the ability to diversify one's
          investment portfolio with exposure to the real estate market. Riofin
          Assets is well-positioned to deliver superior returns for investors in
          this exciting investment opportunity.
        </p>
        <div className="d-flex-al flip-design w-80 my-4">
          <Col>
            <h6>Property Flipping</h6>
          </Col>
          <Col>
            <Image src={Arrow} />
          </Col>
          <Col>
            {" "}
            <h6> House Flipping</h6>{" "}
          </Col>
          <Col>
            <Image src={Arrow} />
          </Col>
          <Col>
            <h6>Flipping in Real Estate</h6>
          </Col>
        </div>
        <ul className="w-95" style={{ listStyleType: "square" }}>
          <li>
            It is a real estate investment strategy of purchasing an asset and
            holding it for a short period with intention of selling it for a
            quick profit rather than holding for long-term appreciation.
          </li>
          <li>Flipping is also called as wholesale real estate investing.</li>
          <li>
            RIOFIN choose BANK Auctioned Properties, ready to Sale plots,
            Residential Apartment Buildings for its Property Flipping.
          </li>
        </ul>
      </div>
      {/* flipping end */}
    </div>
  );
};
export default WhyRealEsate;
