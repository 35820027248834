import React from "react";
import { Image, Col } from "react-bootstrap";
import { Raise } from "../assets/img";
const RaiseExpand = () => {
    return (

        <div className="raise-exp">
            <h5>Riofin Expand Your Business!</h5>
            <p>Convert your upfront capital expenditure into micro open payments
                by leasing assets or inventory finance</p>
            <Image src={Raise} className="w-100" />
        </div>



    );
};
export default RaiseExpand;