import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import MoreActiveInvest from "../component/MoreActiveInvest";
import InvestList from "../component/InvestList";
import PastInvest from "../component/PastInvest";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { PassOff, ActiveOff } from "../assets/img";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { useLocation } from "react-router-dom";
import ActiveInvest from "../component/ActiveInvest";

const MoreInvest = () => {
  const [data, setData] = useState([]);
  const [pastData, setPastData] = useState([]);

  const location = useLocation();

  const investList = () => {
    investmentServices
      .moreInvestments(location?.state?.id, { params: { status: 1 } })
      .then((res) => {
        setData(res.data?.investment_with_projects);
      })
      .catch((e) => ErrorHandler(e));

    investmentServices
      .moreInvestments(location?.state?.id, { params: { status: 2 } })
      .then((res) => {
        setPastData(res.data?.investment_with_projects);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    investList();
  }, []);

  return (
    <div className="inve-page">
      <TopHeading type="invest" />
      <div className="my-3 w-90">
        <Tab.Container defaultActiveKey="activeoff">
          <div className="d-flex flex-wrap">
            <InvestList />

            <Tab.Content className="col-12">
              <Tab.Pane eventKey="activeoff">
                <MoreActiveInvest
                  data={data}
                  length={location?.state?.len}
                  //  leasing={leasing} realEstate={realEstate}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="pastoff">
                <PastInvest />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};
export default MoreInvest;
