import React from "react";

const InvestNow = ({ data }) => {
  return (
    <div className="invest-now">
      <div className="inve-calc past-cal">
        <h6>Investment Details</h6>
        <p>Returns for the investment of</p>
        <h6>₹ {Math.round(data?.total_value)}</h6>
      </div>
      <div className="leasing-calc">
        <div className="d-flex-al-jb my-2">
          <h6>Pre Tax Returns(PM %)</h6>
          <h5>{data?.interest_percentage || data?.monthly_percentage}%</h5>
        </div>
        <div className="d-flex-al-jb my-2">
          <h6>Returns More than FD*</h6>
          {/* <h6>Pre-Tax Returns</h6> */}
          <h5>{data?.bank_rate_percentage}</h5>
          {/* <h5>₹ {data?.min_value}</h5> */}
        </div>

        <div className="d-flex-al-jb my-2 tol-des">
          <h6>Post tax (Tax Applicable Per Annum)</h6>
          <h4>{data?.tax_percentage}%</h4>
        </div>
      </div>
    </div>
  );
};
export default InvestNow;
