import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Increase, InvestTot } from "../assets/img";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Transpatent from "../component/Transpatent";
import ActiveLeasing from "./ActiveLeasing";
import ActiveRealEst from "./ActiveRealEst";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { useEffect } from "react";
import { useMemo } from "react";

const ActiveInvest = () => {
  // { realEstate, leasing }
  const location = useLocation();
  const [leasing, setLeasing] = useState([]);
  const [realEstate, setRealEstate] = useState([]);
  const [offset, setOffset] = useState(1);

  const [leasePagination, setLeasePagination] = useState();
  const [realPagination, setRealPagination] = useState();

  const investList = () => {
    investmentServices
      .investList({ params: { status: 1, page: parseInt(offset) } })
      .then((res) => {
        // console.log("ActiveInvest.res", res?.data);
        setOffset(offset + 1);
        setLeasing((pre) => [...pre, ...res.data?.leasing_investments]);
        setLeasePagination(res.data?.leasing_pagination_meta);
        setRealPagination(res.data?.real_estate_pagination_meta);
        setRealEstate((pre) => [...pre, ...res.data?.real_estate_investments]);
      })
      .catch((e) => ErrorHandler(e));
  };

  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const scrollHeight = e.target.documentElement.scrollHeight;
  //     const currentHeight =
  //       e.target.documentElement.scrollTop + window.innerHeight;
  //     if (currentHeight + 1 >= scrollHeight) {
  //       setOffset(offset + 1);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [offset]);

  useEffect(() => {
    // if (leasePagination?.last_page <= offset || realPagination?.last_page) {
    investList();
    // }
  }, []);

  return (
    <div className="invest-conten">
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={
          location?.state?.key ? location?.state?.key : "leasing"
        }
      >
        <div className="d-flex">
          <Col lg={4} md={5} xs={12} className="px-2 my-3">
            <div className="sticky-fun">
              <Nav variant="pills" className="flex-column invest-list">
                <Nav.Item>
                  <Nav.Link eventKey="leasing" className="d-flex-al-jb">
                    <span>Leasing </span>
                    {leasing?.length}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="real" className="d-flex-al-jb">
                    <span>Real Estate </span>
                    {realEstate?.length}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Transpatent /> */}
            </div>
          </Col>
          <Col lg={8} md={7} xs={12} className="px-2 my-3">
            <Tab.Content>
              <Tab.Pane eventKey="leasing">
                <ActiveLeasing
                  fetch={investList}
                  leasingList={leasing}
                  realEstate={realEstate}
                  daatLength={leasePagination}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="real">
                <ActiveRealEst
                  fetch={investList}
                  daatLength={realPagination}
                  realEstate={realEstate}
                  leasingList={leasing}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </div>
      </Tab.Container>
    </div>
  );
};
export default ActiveInvest;
