import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import LoginLeft from "../component/LoginLeft";
import { Logo } from "../assets/img";
import LoginServices from "../services/service/login";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { Toaster, toast } from "react-hot-toast";

const LogIn = () => {
  const [user, setUser] = useState();
  const navigate = useNavigate();

  const verifyOtp = () => {
    let regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const validMobilNo = new RegExp("^[6-9]{1}[0-9]{9}$");

    let number = false;
    let email = false;

    if (regex.test(user)) {
      email = true;
    }

    if (validMobilNo.test(user)) {
      number = true;
    }

    if (number || email) {
      LoginServices.login({ email_mobile: user })
        .then((res) => {
          localStorage.setItem("USER", user);
          console.log(res);
          navigate("/verifyotp", {
            state: {
              otp: res.data.message,
              user: user,
            },
          });
        })
        .catch((e) => ErrorHandler(e));
    } else if (!number) {
      if (
        /^[a-zA-Z]+$/.test(user) ||
        /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(user)
      ) {
        toast.error("Please Enter valid Email Address");
      } else {
        toast.error("Please Enter valid Mobile Number");
      }
    }
  };

  return (
    <div className="login-page  ">
      <Toaster position="top-right" reverseOrder={false} />

      {/* <div className="logo-img my-3 ">
        <Link to="/home">
          <Image className="" src={Logo} />
        </Link>
      </div> */}

      <div className="login-ses cent   ">
        <Col xs={12} md={5}>
          <LoginLeft />
        </Col>
        <Col xs={12} md={5}>
          <div className="login-form  ">
            <Link to="/home">
              <Image className="" src={Logo} />
            </Link>
            <h5>SignUp with Riofin</h5>
            <label className="my-2">Enter your registered phone number</label>
            <input
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 10) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 10) {
                  e.target.setCustomValidity("");

                  setUser(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              maxLength={10}
              onChange={(e) => setUser(e.target.value)}
              type="number"
              className="form-control"
              placeholder="Mobile Number"
            />

            <Link
              to="#"
              onClick={verifyOtp}
              //  to="/verifyotp"
              className="login-btn"
            >
              {" "}
              Continue{" "}
            </Link>
            <p className="log-dont">
              Already have an account?
              <Link to="/login" className="ps-2">
                LogIn
              </Link>
            </p>
          </div>
        </Col>
      </div>
    </div>
  );
};
export default LogIn;
