import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { Increase, InvestTot } from "../assets/img";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Transpatent from "../component/Transpatent";
import MoreActiveLeasing from "./MoreActiveLeasing";
import ActiveRealEst from "./ActiveRealEst";

const MoreActiveInvest = ({ data }) => {
  const location = useLocation();
  return (
    <div className="invest-conten">
      <Tab.Container id="left-tabs-example" defaultActiveKey="leasing">
        <div className="d-flex">
          <Col lg={4} md={5} xs={12} className="px-2 my-3">
            <div className="sticky-fun">
              <Nav variant="pills" className="flex-column invest-list">
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    state={{ key: "leasing" }}
                    to="/invest"
                    eventKey="leasing"
                    className="d-flex-al-jb"
                  >
                    {/* <Link state={{ key: "leasing" }} to="/invest"> */}
                    <span>Leasing</span>
                    {/* </Link> */}
                    {location?.state?.leaselen}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    as={Link}
                    state={{ key: "real" }}
                    to="/invest"
                    eventKey="real"
                    className="d-flex-al-jb"
                  >
                    {/* <Link state={{ key: "real" }} to="/invest"> */}
                    <span>Real Estate </span>
                    {/* </Link> */}
                    {location?.state?.reallen}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Transpatent /> */}
            </div>
          </Col>
          <Col lg={8} md={7} xs={12} className="px-2 my-3">
            <Tab.Content>
              <Tab.Pane eventKey="leasing">
                <MoreActiveLeasing data={data} />
              </Tab.Pane>
              <Tab.Pane
                eventKey="rea"
                //  eventKey="real"
              >
                <div className="leasing-ti">
                  <div className="d-flex-al-jb my-2">
                    <h5>Real Estate Deals with Riofin</h5>
                    <Link to="/invest" className="login-btn">
                      Know More
                    </Link>
                  </div>

                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.{" "}
                  </p>
                </div>

                <ActiveRealEst />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </div>
      </Tab.Container>
    </div>
  );
};
export default MoreActiveInvest;
