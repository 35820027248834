import React from "react";
import { Col, Image } from "react-bootstrap";
import { Document } from "../assets/img";
import { saveAs } from "file-saver";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import DownloadLink from "react-download-link";
import axios from "axios";
import documentPng from "../assets/img/doc.png";

const Description = ({ data }) => {
  const imageclick = (url) => {
    // let url = "/static/media/document.40cc17328763004eb4bd.jpg";
    saveAs(url, "document.pdf");
  };

  const pdfClick = (urlLink, e) => {
    // const url = urlLink;
    // const link = document.createElement("a");
    // link.href = url;
    // link.setAttribute("download", "file.pdf");
    // document.body.appendChild(link);
    // link.click();
    //   saveImg(urlArr){
    //     (async () => {
    //        let name = 'img' + 0 + '.jpg';
    //        let blob = await fetch(urlArr[0]).then((r) => r.blob());
    //        saveAs(blob, name);
    //     })();
    //     (async () => {
    //        let name2 = 'img' + 2 + '.jpg';
    //        let blob2 = await fetch(urlArr[2]).then((r) => r.blob());
    //        saveAs(blob2, name2);
    //     })();
    // }
  };

  const saveImg = (urlArr) => {
    // (async () => {
    //   let name = "doc" + ".pdf";
    //   let blob = await fetch(urlArr).then((r) => r.blob());
    //   saveAs(blob, name);
    // })();
    // const url = urlArr;

    // axios
    //   .get(url, { mode: "cors" })
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const link = document.createElement("a");
    //     link.href = URL.createObjectURL(blob);
    //     link.download = "file.pdf";
    //     link.click();
    //   });
    saveAs(urlArr, "document.pdf" || "document.xls");
  };

  return (
    <div className="inve-descrip">
      <div className="abt-company">
        <h5>Description</h5>
        <p
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(data?.content),
          }}
        ></p>
      </div>
      <div className="inves-baner">
        <Image src={data?.img1_url} className="w-80" />
      </div>
      <div className="proj-doc my-3">
        <h5>Project Documents</h5>
        <div className="d-flex">
          {data?.project_document?.map((list, i) => {
            return (
              <Col key={i} md={4} sm={6} xs={12} className="px-3 my-2">
                <Link className="d-flex-al bg-sha">
                  <img
                    src={documentPng}
                    // className="w-50"
                    width={30}
                    style={{ margin: "auto;" }}
                    onClick={(e) => saveImg(list?.img_url, e)}
                  />
                  <h6
                    className="mb-0 px-3"
                    onClick={(e) => saveImg(list?.img_url, e)}
                  >
                    {" "}
                    {list?.display_name}
                  </h6>
                </Link>
                {/* <button
                  style={{ color: "white", margin: "25px" }}
                  onClick={(e) => saveImg(list?.img_url, e)}
                >
                  {list?.display_name}
                </button> */}

                {/* <DownloadLink
                  label="Save"
                  filename="document.pdf"
                  exportFile={() => list?.img_url}
                /> */}
                {/* <a download="foo.pdf" target="_blank" href={list?.img_url}>
                  download
                </a> */}

                {/* <a target="blank" href={list?.img_url} download>
                  download
                </a> */}
              </Col>
            );
          })}

          <Col md={4} xs={12} className="px-3 my-2">
            <Link onClick={imageclick}>
              {/* <Image src={data?.img_url} className="w-50" /> */}
            </Link>
          </Col>
          {/*
          <Col md={4} xs={12} className="px-3 my-2">
            <Link onClick={imageclick}>
              <Image src={Document} className="w-50" />
            </Link>
          </Col> */}
        </div>
      </div>
      <div className="inv-faq my-3">
        <h5>FAQ's</h5>

        {data?.project_faq?.map((item) => {
          return (
            <>
              <h6>{item?.question}?</h6>
              <p>{item?.description}</p>
            </>
          );
        })}
      </div>
    </div>
  );
};
export default Description;
