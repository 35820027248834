import React from "react";
import { Image } from "react-bootstrap";
import { Privacy, Coins } from "../assets/img";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import TopBanner from "../Frontendcomponent/TopBanner";
import Footer from "../Frontendcomponent/Footer";
const PrivacyPolicy = () => {
    return (
        <div className="privact-page margin-t">
            <div className="top-bans">
                <TopBanner />
                <FrontHeading type="privacypolicy" />
            </div>
            <div className="my-4 w-90   privacy-ses">
                <Image src={Privacy} className="pri-img" />
                <h5>Riofin Privacy Policy</h5>
                <ul className="term-cond">
                    <li> <Image src={Coins} />This document is an electronic record in terms of Information Technology Act, 2000 and rules made there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                    <li> <Image src={Coins} />This Privacy Policy (“Privacy Policy”) applies to the collection, receipt, storage, usage, processing, disclosure, transfer and protection (“Utilization”) of your Personal Information (defined below) when the user of the Website (“User” or “you”) uses our Website or avails any services offered by Riofin through the Website.</li>
                    <li> <Image src={Coins} />Thank you for visiting [www.riofin.in] (the “Website”). Riofin is registered as RIO GLOBAL FINTECH PRIVATE LIMITED having its registered office address at No.15A, 4th Floor, City Vista, Tower A, Suite No.178, Fountain Road, Kharadi Pune Pune MH 411014 IN (“Riofin”, “we”, “us” or “our”). We are committed to protecting and respecting the privacy of every person who shares information with it or whose information it receives.</li>
                    <li><Image src={Coins} />This document is an electronic record in terms of Information Technology Act, 2000 and rules made there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                    <li><Image src={Coins} />Thank you for visiting [www.riofinassets.com] (the “Website”). RIOFIN ASSETS is registered as RIO GLOBAL FINTECH PRIVATE LIMITED having its registered office address at No.15A, 4th Floor, City Vista, Tower A, Suite No.178, Fountain Road, Kharadi Pune Pune MH 411014 IN (“RIOFIN ASSETS”, “we”, “us” or “our”). We are committed to protecting and respecting the privacy of every person who shares information with it or whose information it receives.</li>
                    <li><Image src={Coins} />This Privacy Policy (“Privacy Policy”) applies to the collection, receipt, storage, usage, processing, disclosure, transfer and protection (“Utilization”) of your Personal Information (defined below) when the user of the Website (“User” or “you”) uses our Website or avails any services offered by RIOFIN ASSETS through the Website.</li>
                    <li><Image src={Coins} />The capitalized terms that are used but not defined in this Privacy Policy will have the same meaning as in our User Terms.</li>
                    <li><Image src={Coins} />BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD) OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I) ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH INDIVIDUAL OR ENTITY TO OUR COLLECTION, USE AND DISCLOSURE OF SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY.</li>

                </ul>
                <h5>Consent</h5>
                <ul className="term-cond">
                    <li><Image src={Coins} />You acknowledge that this Privacy Policy is a part of the User Terms of the Website and the other services, by accessing the Website or by otherwise providing Us with your Personal Information or by making use of the services provided by the Website, You unconditionally signify Your (i) assent to the Privacy Policy, and (ii) consent to the Utilization of your Personal Information in accordance with the provisions of this Privacy Policy.</li>
                    <li><Image src={Coins} />You acknowledge that you are over 18 years of age and are providing your Personal Information out of your free will.</li>
                    <li><Image src={Coins} />If you use the services on behalf of someone else (including but not limited to, as a legal representative of an individual with mental illness) or an entity (such as your employer), you represent that you are authorized by such individual or entity to (i) accept this Privacy Policy on such individual’s or entity’s behalf, and (ii) consent on behalf of such individual or entity to our Utilization of such individual’s Personal Information as described in this Privacy Policy. Further, you hereby acknowledge that the Utilization of your Personal Information by RIOFIN ASSETS is necessary for the purposes identified hereunder.</li>
                    <li><Image src={Coins} />You have the option not to provide us the Personal Information sought to be collected. You will also have an option to withdraw your consent at any point, provided such withdrawal of the consent is intimated to us in writing at. If you do not provide us your Personal Information or if you withdraw the consent to provide us with your Personal Information at any point in time, please note that your withdrawal of consent, may result in RIOFIN ASSETS being unable to provide you with its services or to terminate any existing relationship RIOFIN ASSETS may have with you.</li>
                    <li><Image src={Coins} />Our Website is accessible to children under the age of 18. RIOFIN ASSETS strongly encourages parents and guardians to supervise the online activities of their minor children and consider using parental control tools. These tools also can prevent minors from disclosing their name, address, and other personally identifiable information online without parental permission. Although the Website and services are not intended for use by minors, RIOFIN ASSETS respects the privacy of minors who may inadvertently use the Website. We do not knowingly collect any Personal Information from children (without the consent of their parents/guardians). Please contact us at invest@.in, if you are aware that we may have inadvertently collected Personal Information from a child, and we will delete that information as soon as possible.</li>
                </ul>
                <h5>PERSONAL INFORMATION COLLECTED</h5>
                <ul className="term-cond">
                    <li>In order to provide services to you we might require you to voluntarily provide us certain information that personally identifies you. You hereby consent to the collection of such information by RIOFIN ASSETS. The information that we may collect from you, or about you, may include but are not limited to, the following:</li>
                    <li>
                        <ul className="ps-3">
                            <li>1. Name of the User;</li>
                            <li>2. Birth date/age;</li>
                            <li>3. Gender;</li>
                            <li>4. Address (including country and pin/postal code);</li>
                            <li>5. Financial and KYC information (such as passport, driving license, proof of possession of Aadhaar number, Voter’s Identity Card issued by the Election Commission of India, job card issued by NREGA duly signed by an officer of the State Government, or letter issued by the National Population Register containing details of name and address);</li>
                            <li>6. Phone number/mobile number;</li>
                            <li>7. Email address;</li>
                            <li>8. Records of interaction with RIOFIN ASSETS’s representatives;</li>
                            <li>9. Your usage details such as time, frequency, duration and pattern of use, features used and the amount of storage used;</li>
                            <li>10. Internet protocol address, browser type, browser language, referring URL, files accessed, errors generated, time zone, operating system and other visitor details collected in Our log files, the pages of our Website that You visit, the time and date of Your visit, the time spent on those pages and other statistics (“Log Data”); and</li>
                            <li>11. Any other information that is willingly shared by you.</li>
                            <li>12. (Collectively referred to as “Personal Information”).</li>
                        </ul>
                    </li>
                </ul>
                <h5>HOW WE COLLECT PERSONAL INFORMATION:</h5>
                <ul className="term-cond">
                    <li>The methods by which we collect your Personal Information include but are not limited to the following:</li>
                    <li>
                        <ul className="ps-3">
                            <li>1. When you access our Website or use any features of our Website;</li>
                            <li>2. When you provide your Personal Information to us;</li>
                            <li>3. During the course of services provided to you by us;</li>
                            <li>4. Through your computer/device, once you have granted permissions to our Website;</li>
                            <li>5. Through RIOFIN ASSETS’s representatives;</li>
                            <li>6. Through use of cookies (also discussed below); or</li>
                            <li>7. We collect information that your browser/app sends whenever you visit our Website, such as, the Log Data. In addition, we may use third party services to collect, monitor and analyse such Log Data. This information is kept completely secure.</li>
                        </ul>
                    </li>
                </ul>
                <h5>USE OF PERSONAL INFORMATION</h5>
                <ul className="term-cond">
                    <li>Your Personal Information may be used for various purposes including but not limited to the following:</li>
                    <li>
                        <ul className="ps-3">
                            <li>1. To provide effective services;</li>
                            <li>2. To resolve any customer support related issues;</li>
                            <li>3. To operate and improve the Website;</li>
                            <li>4. To perform research and analysis for our understanding, information, analysis, services and technologies in order to provide all users improved quality of care; and ensuring that the content and advertising displayed are customized to your interests and preferences;</li>
                            <li>5. To contact you via phone, SMS or email for appointments, technical issues, payment reminders, obtaining feedback and other security announcements;</li>
                            <li>6. To send promotional and marketing emails from us or any of our channel partners via SMS, email, snail mail or third-party communication services such as Mailchimp, Facebook etc.;</li>
                            <li>7. To advertise products and services of RIOFIN ASSETS and third parties;</li>
                            <li>8. To transfer information about you, if we are merged with another company;</li>
                            <li>9. To share with our business partners for provision of specific services you have ordered so as to enable them to provide effective services to you;</li>
                            <li>10. To administer or otherwise carry out our obligations in relation to any agreement you have with Us;</li>
                            <li>11. For purposes of KYC verification;</li>
                            <li>12. To build your profile/user account on the Website;</li>
                            <li>13. To investigate, prevent, or take action regarding illegal activities, suspected fraud, violations of our Terms of Use, breach of our agreement with You or as otherwise required by law.(collectively referred to as “Purpose(s)”).</li>
                        </ul>
                    </li>
                </ul>
                <h5>SHARING AND TRANSFERRING OF PERSONAL INFORMATION:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li>1. We may disclose Personal Information about you to our representatives and other third parties to comply with applicable laws and regulations. We also reserve the right to disclose your Personal Information to third parties in an anonymous or aggregate form, to understand customer trends and patterns and manage and improve our business relationships. We provide your Personal Information to our business partners, representatives and third party processors to administer and process for the purposes notified to you in this Privacy Policy, and we may also share such details with third parties (such as auditors or legal advisors) to obtain professional advice. Any such processing will be governed by an agreement in the form required by law, preserving any and all of your statutory data protection rights. You authorize us to exchange, transfer, share, part with all or any of your Personal Information, across borders and from Your country to any other countries across the world with our affiliates/agents/third party service providers/partners/banks and financial institutions for the Purposes specified under this Privacy Policy or as may be required by applicable law. </li>
                            <li>2. You hereby consent and authorize us to publish feedback obtained by you on our Website.</li>
                            <li>3. You acknowledge that some countries where We may transfer your Personal Information may not have data protection laws which are as stringent as the laws of your own country. You acknowledge that it is adequate that when RIOFIN ASSETS transfers your Personal Information to any other entity within or outside India, RIOFIN ASSETS will place contractual obligations on the transferee which will oblige the transferee to adhere to the provisions of this Privacy Policy.</li>
                            <li>4. User’s financial information’s are transacted upon secure sites of approved payment gateways which are digitally under encryption, thereby providing the highest possible degree of care as per current technology. However, User is advised to exercise discretion while saving the payment details.</li>
                            <li>5. You acknowledge that RIOFIN ASSETS may be obligated by law to disclose or transfer your Personal Information with Courts and Government agencies in certain instances such as for verification of identity, or for prevention, detection, investigation, prosecution, and punishment for offence, or in compliance with laws. You hereby consent to disclosure or transfer of your Personal Information in these instances.</li>
                            <li>6. Notwithstanding the above, we are not responsible for the confidentiality, security or distribution of Your Personal Information by third-parties outside the scope of our agreement with such third-parties. Further, We will not be responsible for any breach of security or for any actions of any third-parties or events that are beyond the reasonable control of us including but not limited to, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption, poor quality of Internet service or telephone service of the User etc.</li>
                            <li>7. We may share your Personal Information with our other corporate and/or associate entities and affiliates to (i) help detect and prevent identity theft, fraud and other potentially illegal acts and cyber security incidents, and (ii) help and detect co-related/related or multiple accounts to prevent abuse of our services.</li>
                        </ul>
                    </li>

                </ul>
                <h5>USE OF COOKIES:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li>1. We, our third party service providers and our partners may send “cookies” to your computer or use similar technologies to enhance your online experience at our Website. “Cookies” are files that can identify you as a unique customer and store your personal preferences as product preferences to tells us which site pages you have visited and in what order. We use cookies and other technical information to personalise your visit to the Website, to analyse traffic on the Website and to track user trends, patterns and selections for authorised download and for technical reasons connected with your use of our Website. </li>
                            <li>2. Cookies can either be permanent (i.e. they remain on your computer until you delete them) or temporary (i.e. they last only until you close your browser). We may store both temporary and permanent ‘cookies’ on Your computer/device to store certain data (that is not sensitive Personal Information). You can erase or choose to block these cookies from your computer. You can configure your computer’s browser to alert you when we attempt to send you a cookie with an option to accept or refuse the cookie. If you have turned cookies off, you may be prevented from using certain features of the Website.</li>
                            <li>3. We do not control the use of cookies by third parties.</li>
                        </ul>
                    </li>

                </ul>
                <h5>SECURITY:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li>1. The security of your Personal Information is important to us. We have adopted reasonable security practices and procedures including role-based access, secure communication, password protection, encryption, etc. to ensure that the Personal Information collected is secure.</li>
                            <li>2. We restrict access to your Personal Information to us and our affiliates’ employees, agents, third party service providers, partners, and agencies who need to know such Personal Information in relation to the Purposes as specified above in this Privacy Policy, provided that such entities agree to abide by this Privacy Policy.</li>
                            <li>3. While we will Endeavour to take all reasonable and appropriate steps to keep secure any information which we hold about you and prevent unauthorized access, you acknowledge that the internet is not 100% secure. We will not be liable in any way in relation to any breach of security or unintended loss or disclosure of information caused in relation to your Personal Information.</li>
                        </ul>
                    </li>

                </ul>
                <h5>THIRD PARTY LINKS:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li> <Image src={Coins} /> During your interactions with us, it may happen that we provide/include links and hyperlinks of third-party websites not owned or managed by us (“Third-party Websites”). It may also happen that you or other Users may include links and hyperlinks of third-party Websites. The listing of such third-party websites (by you, other Users or by Us) does not imply endorsement of such third-party websites by RIOFIN ASSETS. Such third-party websites are governed by their own terms and conditions and when you access such Third-party Websites, you will be governed by the terms of such third-party websites. You must use your own discretion while accessing or using third-party websites. We do not make any representations regarding the availability and performance of any of the third-party websites. We are not responsible for the content, terms of use, privacy policies and practices of such third-party websites. We do not bear any liability arising out of your use of third-party websites.</li>
                        </ul>
                    </li>

                </ul>
                <h5>ACCESS:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li> <Image src={Coins} /> If you need to update your Personal Information or have any grievance with respect to the processing or use of your Personal Information, or request that we no longer use your Personal Information to provide you services, or opt-out of receiving communications such as promotional and marketing-related information regarding the services, for any reason, You may send Us an email at support@riofinassets.com and we will take all reasonable efforts to incorporate the changes within a reasonable period of time.</li>
                        </ul>
                    </li>

                </ul>
                <h5>COMPLIANCE WITH LAWS:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li> <Image src={Coins} /> The contents of this Privacy Policy are governed by the laws of India. You are not allowed to use the services of the Website if any of the terms of this Privacy Policy are not in accordance with the applicable laws of your country.</li>
                        </ul>
                    </li>

                </ul>
                <h5>TERM OF STORAGE OF PERSONAL INFORMATION:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li>1. RIOFIN ASSETS may keep records of communications, including phone calls received and made for making enquiries, orders, feedback or other purposes for rendering services effectively and efficiently. RIOFIN ASSETS will be the exclusive owner of such data and records. However, all records are regarded as confidential. Therefore, will not be divulged to any third party, unless required by law.</li>
                            <li>2. RIOFIN ASSETS will store your Personal Information at least for a reasonable from the last date of use of the services, Website or for such period as may be required by law.</li>
                            <li>3. You are responsible for maintaining the accuracy of the information you submit to us, such as your contact information provided as part of account registration. If your personal information changes, you may correct, delete inaccuracies, or amend information by making the change on our member information page or by contacting us through support@riofinassets.com We will make good faith efforts to make requested changes in our then active databases as soon as reasonably practicable. If you provide any information that is untrue, inaccurate, out of date or incomplete (or becomes untrue, inaccurate, out of date or incomplete), or RIOFIN ASSETS has reasonable grounds to suspect that the information provided by you is untrue, inaccurate, out of date or incomplete, RIOFIN ASSETS may, at its sole discretion, discontinue the provision of the services to you. There may be circumstances where RIOFIN ASSETS will not correct, delete or update your Personal Information, including (a) where the Personal Information is opinion data that is kept solely for evaluative purpose; and (b) the Personal Information is in documents related to a prosecution if all proceedings relating to the prosecution have not been completed.</li>
                        </ul>
                    </li>

                </ul>
                <h5>CHANGES TO THE PRIVACY POLICY:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li>1. We reserve the right to update (change, modify, add and/or delete) the terms of this Privacy Policy from time to time, at our sole discretion, to reflect company initiatives, changes in the law or technology or changes in our practices regarding the collection and use of Personal Information.</li>
                            <li>2. When we update our Privacy Policy, we will intimate you of the amendments on your email address registered with us or on the Website. If you do not agree to the amendments, please do not use the Website or services any further. By continuing to use the Website, you will be deemed to have consented to the latest version of the Privacy Policy.</li>
                        </ul>
                    </li>

                </ul>
                <h5>Contact Us</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li>For any query or assistance, feel free to Contact Us with Our customer service team at <a href="mailto:support@riofinassets.com"> support@riofinassets.com</a></li>
                        </ul>
                    </li>

                </ul>
                <h5>GRIEVANCE OFFICER:</h5>
                <ul className="term-cond">

                    <li>
                        <ul className="ps-3">
                            <li>We have appointed a grievance officer, whose details are set out below, to address any concerns or grievances that you may have regarding the processing of your Personal Information. If you have any such grievances, please write to our grievance officer at grievance@riofinassets.com  and our officer will attempt to resolve your issues in a timely manner</li>
                        </ul>
                    </li>

                </ul>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};
export default PrivacyPolicy;