import React, { useEffect, useState } from "react";
import { Button, Form, Col } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import ErrorHandler from "../services/Constant/ErrorHandler";
import userProfileServices from "../services/service/profile";
import usePermissions from "../services/Storage/user";

const EditProfile = ({ image }) => {
  const { user, setUser } = usePermissions();

  const [userDetails, setUserDetails] = useState();
  const [mobile, setMobile] = useState(user?.mobile);
  const [nomineemobile, setNomineeMobile] = useState(
    user?.user_detail?.nominee_mobile
  );

  // console.log(user);

  const [nomineeDetails, setNomineeDetails] = useState(user?.user_detail);

  const flattenObj = (ob) => {
    let result = {};

    for (const i in ob) {
      if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          result[i + "." + j] = temp[j];
        }
      } else {
        result[i] = ob[i];
      }
    }
    setUserDetails(result);
  };

  useEffect(() => {
    flattenObj(user);
  }, []);

  // console.log(nomineeDetails);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setUserDetails((values) => ({ ...values, [name]: value }));
  };

  const handleNominee = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNomineeDetails((values) => ({ ...values, [name]: value }));
  };

  const updateProfile = () => {
    let formdata = new FormData();

    //REQUIRED FIELDS

    formdata.append("name", userDetails?.name);
    formdata.append("mobile", mobile);
    formdata.append("email", userDetails?.email);
    formdata.append("kyc_verified", userDetails?.kyc_verified);
    formdata.append("country", nomineeDetails?.country);
    formdata.append("state", nomineeDetails?.state);
    formdata.append("city", nomineeDetails?.city);
    formdata.append("address", nomineeDetails?.address);
    formdata.append("pincode", nomineeDetails?.pincode);

    if (nomineeDetails?.nominee_name) {
      formdata.append("nominee_name", nomineeDetails?.nominee_name);
    }
    if (nomineeDetails?.nominee_relation) {
      formdata.append("nominee_relation", nomineeDetails?.nominee_relation);
    }

    if (nomineemobile) {
      formdata.append("nominee_mobile", nomineemobile);
    }

    if (image) {
      formdata.append("image", image);
    }

    userProfileServices
      .userUpdate(formdata)
      .then((res) => {
        toast.success(res.data.message);
        setUser(res.data.user_detail);

        setUserDetails(res.data.user_detail);
        setNomineeDetails(res.data.user_detail?.userDetail);
      })
      .catch((e) => ErrorHandler(e));
  };

  return (
    <div className="edit-prf my-2">
      <Toaster position="top-right" reverseOrder={false} />

      <Form>
        <div className="d-flex">
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              Name <span className="text-danger">*</span>
            </label>
            <input
              onChange={handleChange}
              value={userDetails?.name}
              name="name"
              className="form-control"
              type="name"
              placeholder="Name"
            />
          </Col>
          {/* <Col md={6} xs={12} className="  px-2">
            <label className="my-2">
              User Name <span className="text-danger">*</span>
            </label>
            <input
              value={userDetails?.name}
              className="form-control"
              type="name"
              placeholder="JohnRolpher"
            />
          </Col> */}
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              Email <span className="text-danger">*</span>
            </label>
            <input
              onChange={handleChange}
              disabled
              value={userDetails?.email}
              name="email"
              className="form-control"
              style={{ cursor: "not-allowed" }}
              type="email"
              placeholder="Email"
            />
          </Col>
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              Mobile <span className="text-danger">*</span>
            </label>
            <input
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 10) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 10) {
                  e.target.setCustomValidity("");

                  setMobile(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              maxlength={10}
              value={mobile}
              disabled
              onChange={(e) => setMobile(e.target.value)}
              className="form-control"
              style={{ cursor: "not-allowed" }}
              type="number"
              placeholder="Number"
            />
          </Col>
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">Nominee (optional)</label>
            <input
              onChange={handleNominee}
              value={nomineeDetails?.nominee_name}
              name="nominee_name"
              className="form-control"
              type="name"
              placeholder="Nominee Name"
            />
          </Col>
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">Nominee Relationship</label>
            <input
              onChange={handleNominee}
              value={nomineeDetails?.nominee_relation}
              name="nominee_relation"
              className="form-control"
              type="name"
              placeholder="Nominee Relation"
            />
          </Col>

          <Col md={6} xs={12} className="px-2">
            <label className="my-2">Nominee Mobile</label>
            <input
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 10) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 10) {
                  e.target.setCustomValidity("");

                  setNomineeMobile(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              maxlength={10}
              value={nomineemobile}
              onChange={(e) => setNomineeMobile(e.target.value)}
              className="form-control"
              type="number"
              placeholder="Number"
            />
          </Col>
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              Country <span className="text-danger">*</span>
            </label>
            <input
              onChange={handleNominee}
              value={nomineeDetails?.country}
              name="country"
              className="form-control"
              type="name"
              placeholder="Country"
            />
          </Col>
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              State <span className="text-danger">*</span>
            </label>
            <input
              onChange={handleNominee}
              value={nomineeDetails?.state}
              name="state"
              className="form-control"
              type="name"
              placeholder="State"
            />
          </Col>
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              City <span className="text-danger">*</span>
            </label>
            <input
              onChange={handleNominee}
              name="city"
              value={nomineeDetails?.city}
              className="form-control"
              type="name"
              placeholder="City"
            />
          </Col>
        </div>
        <Button onClick={updateProfile} className="my-3">
          Update
        </Button>
      </Form>
    </div>
  );
};
export default EditProfile;
