import React, { useState } from "react";
import TopHeading from "../component/TopHeading";
import { Image, Button } from "react-bootstrap";
import { FavIcon } from "../assets/img";
import userProfileServices from "../services/service/profile";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { useEffect } from "react";
import { Link, useLocation, uselocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";

const notify = [
  {
    title: "Kyc",
    describe: "Kindly check updates for the leasing investment for your wish.",
    time: "9.30am",
  },
  {
    title: "Invest",
    describe: "Kindly check updates for the leasing investment for your wish.",
    time: "9.30am",
  },
  {
    title: "Kyc",
    describe: "Kindly check updates for the leasing investment for your wish.",
    time: "9.30am",
  },
  {
    title: "Invest",
    describe: "Kindly check updates for the leasing investment for your wish.",
    time: "9.30am",
  },
];
const Notification = () => {
  const navigate = useNavigate();
  const [notifyView] = useState(notify);
  const [message, setMessage] = useState("");
  const [notifications, setNotifications] = useState([]);

  const handleChange = (event) => {
    setMessage(event.target.value);
  };
  const handleClick = () => {
    setMessage("");
  };

  const Clear = () => {
    userProfileServices.notificationsClear().then((res) => {
      // console.log("Clear", res);
      messages();
    });
  };

  const messages = () => {
    userProfileServices
      .notifications_msg()
      .then((res) => {
        // console.log("res", res.data.notifications);
        setNotifications(res.data.notifications);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    messages();
  }, []);

  return (
    <div className="notify-page">
      <TopHeading type="notification" />
      <div className="w-90 my-2 ">
        <table>
          <tbody>
            {notifications?.map((list, index) => (
              <tr
                id="message"
                key={index}
                onChange={handleChange}
                value={message}
              >
                <td>
                  <Image src={FavIcon} />
                </td>
                <td className="d-grid">
                  {/* <span className="til-span">{list.title}</span> */}
                  <span>{list?.message}</span>
                </td>

                <td>{moment(list?.updated_at).format("hh:mm A")}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button className="login-btn my-3" onClick={Clear}>
          All Clear
        </Button>
      </div>
    </div>
  );
};
export default Notification;
