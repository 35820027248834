import React from "react";
import { Col, Image } from "react-bootstrap";
import {
  BeLeas,
  BeLeas1,
  BeLeas2,
  FLease,
  Coins,
  SeqLogo,
  Howriofin,
  Investment,
  Loan,
  Assone,
  AssTwo,
  AssThr,
  AssFou,
  AssFiv,
  AssSix,
  AssSev,
} from "../assets/img";
const WhyLeasing = () => {
  return (
    <div className="leas-page">
      {/* <div className="why-leas d-flex-al">
                <Col md={6} xs={12} className="px-3 my-2">
                    <Image src={FLease} className="w-100" />
                </Col>
                <Col md={6} xs={12} className="px-3 my-2">
                    <h5>Why Leasing?</h5>
                    <p>Leasing is a simple contractual arrangement calling for the lessee (user) to pay the lessor (owner) regular payments in exchange for use of an asset over an agreed period of time. Buildings, vehicles and equipment are among the most common assets that are leased.</p>
                    <h6>For instance, imagine that you and 5 of your friends (owners/lessors) were to co-invest in bikes that were then leased to a restaurant (users/lessee) to use for delivery and pay all of you a monthly rental. It is as simple as that.</h6>
                    <p>Leasing is a simple contractual arrangement calling for the lessee (user) to pay the lessor (owner) regular payments in the agreed time.</p>
                </Col>

            </div> */}
      <div className="ben-lea my-4 w-90">
        <h1>Why invest in an Asset Backed Leasing?</h1>
        <div className="d-flex my-4">
          <Col md={4} xs={12} className="px-3 my-2">
            <div className="leas-box">
              <div className="cir-beni my-3">
                <Image src={BeLeas} className=" " />
              </div>
              <h5>Predictable returns</h5>
              <p>
                Pre-agreed monthly payments for the full lease tenure with no
                day to day volatility like stock markets
              </p>
            </div>
          </Col>
          <Col md={4} xs={12} className="px-3 my-2">
            <div className="leas-box">
              <div className="cir-beni my-3">
                <Image src={BeLeas1} className=" " />
              </div>
              <h5>Strong Collateral</h5>
              <p>
                Upfront security deposit as well as the ability to recover,
                re-lease or sell assets to mitigate risk
              </p>
            </div>
          </Col>
          <Col md={4} xs={12} className="px-3 my-2">
            <div className="leas-box">
              <div className="cir-beni my-3">
                <Image src={BeLeas2} className=" " />
              </div>
              <h5>Tax Benefits</h5>
              <p>
                Ability to claim depreciation and expenses to reduce the
                effective tax rate for lessor (you)
              </p>
            </div>
          </Col>
        </div>
      </div>
      <div className="d-flex-al   my-3">
        <Col md={4} xs={12} className="px-3 my-2">
          <div className="due_di">
            <h1>Due Diligence</h1>
            <p>
              We Riofin filter Higher Profit Generating MSME Industries by doing
              Rigorous Due diligence
            </p>
          </div>
        </Col>
        <Col md={8} xs={12} className="px-2 my-2">
          <div className="img_pos">
            <div className="abs_img">
              <Image src={SeqLogo} />
            </div>

            <div className="d-flex my-4">
              <Col md={6} xs={12} className="px-3 my-2">
                <div className="des-box">
                  <h5>Lease Profile</h5>
                  <ul>
                    <li>Background of the Lessee Top Level Management.</li>
                    <li>
                      Track Record and Credibility of Promoters & Management.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="px-3 my-2">
                <div className="des-box">
                  <h5>Experience of the organisation</h5>
                  <ul>
                    <li>
                      Extensive experience in various areas of the business,
                      including finance, marketing, and operations.
                    </li>
                    <li>
                      Organization proven track record of delivering
                      high-quality products.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="px-3 my-5">
                <div className="des-box">
                  <h5>Business Track Records</h5>
                  <ul>
                    <li>
                      Organization proven track record of delivering
                      high-quality products.
                    </li>
                    <li>
                      Strong financial performance, with steady revenue growth
                      and healthy profit margins.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xs={12} className="px-3 my-5">
                <div className="des-box">
                  <h5>Order Book size</h5>
                  <ul>
                    <li>Strong demand for the products and services.</li>
                    <li>
                      Visibility into the future earnings, allowing to plan and
                      invest with greater confidence.
                    </li>
                    <li>
                      Consistent track record of the Business Minimum 2- 5
                      Years.
                    </li>
                  </ul>
                </div>
              </Col>
            </div>
          </div>
        </Col>
      </div>
      <div className="dif-leas my-3  text-center">
        <h5>Highly secured Asset Leasing</h5>
        <div className="d-flex my-2">
          <div className="col-md-6 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={Investment} className=" my-2" />
              <h6> Physical asset security</h6>
            </div>
          </div>
          <div className="col-md-6 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={Loan} className=" my-2" />
              <h6>Purchsing Equipment/ Machineries from OME</h6>
            </div>
          </div>
        </div>
      </div>
      <div className="rio_fin my-4 text-center">
        <h5>How Riofin Assets works</h5>
        <div className="d-flex-jc my-2">
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={Assone} className="my-2" />
              <h6>01. Project Selection </h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={AssTwo} className="my-2" />
              <h6>02. Due-Diligence / MSME's Background Verification</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={AssThr} className="my-2" />
              <h6>03. Lease Agreement </h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={AssFou} className="my-2" />
              <h6>04. Listing Project in Platform</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={AssFiv} className="my-2" />
              <h6>05. Contribution from Multi - Investors</h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={AssSix} className="my-2" />
              <h6>
                06. Purchase of Assets from Quality OME and Leasing them to Partners
              </h6>
            </div>
          </div>
          <div className="col-md-3 col-12 px-3 my-2">
            <div className="box-leas">
              <Image src={AssSev} className="my-2" />
              <h6>07. Distributing Rental Income </h6>
            </div>
          </div>
        </div>
      </div>
      <div className="dif-leas my-3 d-flex-al">
        <Col md={8} xs={12} className="px-3 my-2">
          <h5>Diversification of Asset Leasing</h5>
          <p>
            Riofin focuses Equipment Leasing in Multiple sectors to reduce the
            risk and to diversify your portfolio
          </p>
          <ul>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Manufacturing and Production Equipment.
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Construction Equipment (cranes, tractors, forklifts, machine
              tools)
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Energy Equipment, HVAC’s, and Lighting.
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Heavy Machinery.
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Transportation Equipment (trailers, delivery vehicles)
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Refuse Trucks and Equipment.
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Computers and Technology (hardware, software)
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Communications Equipment (Telephone systems)
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Computers and Technology (Hardware, software)
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Audio-Visual Equipment.
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Office Furniture and Equipment.
            </li>
            <li>
              {" "}
              <Image src={Coins} className=" me-3" />
              Medical Equipment.
            </li>
          </ul>
          <p>
            In addition to traditional loans, leasing should be considered as a
            strategic tool to finance micro, small and medium-sized enterprises
            (MSMEs).  Although it is a well-known instrument for financing the
            acquisition of all types of equipment in countries around the world,
            in emerging markets, it is generally more accessible to larger
            companies than to MSMEs.
          </p>
        </Col>
        <Col md={4} xs={12} className="px-3 my-2">
          <Image src={Howriofin} className="w-100" />
        </Col>
      </div>
    </div>
  );
};
export default WhyLeasing;
