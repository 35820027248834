import React from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  Contact,
  Call,
  Email,
  Map,
  ForInv,
  Arrow,
  Partner,
  Query,
} from "../assets/img";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import TopBanner from "../Frontendcomponent/TopBanner";
import Footer from "../Frontendcomponent/Footer";
import frontendService from "../services/service/frontEnd";
import { useState } from "react";
import { toast, Toaster } from "react-hot-toast";
const ContactUs = () => {
  const [data, setData] = useState();
  const [input, setInput] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [subject, setSubject] = useState();
  const [msg, setMsg] = useState();

  //   const handleChange = (e) => {
  //     const value = e.target.value;
  //     const name = e.target.name;
  //     setInput((values) => ({ ...values, [name]: value }));
  //   };

  const addContact = () => {
    let formdata = new FormData();
    formdata.append("name", name);
    formdata.append("email", email);
    formdata.append("mobile", number);
    formdata.append("subject", subject);
    formdata.append("message", msg);

    if (name && email && number && subject && msg) {
      frontendService.contactUs(formdata).then((res) => {
        setData(res.data);
        toast.success(res.data?.message);
        setName("");
        setEmail("");
        setNumber("");
        setSubject("");
        setMsg("");
        //   window.location.reload();
      });
    } else if (!name) {
      toast.error("enter the name");
    } else if (!email) {
      toast.error("enter the email");
    } else if (!number) {
      toast.error("enter the mobile");
    } else if (!subject) {
      toast.error("enter the subject");
    } else if (!msg) {
      toast.error("enter the message");
    }
  };
  const [visible, setVisible] = React.useState(false);
  return (
    <div className="cont-page margin-t">
      <Toaster position="top-right" reverseOrder={false} />
      <div className="top-bans">
        <TopBanner />
        <FrontHeading type="contactus" />
      </div>

      <div className="my-4 w-90 cont-sess">
        <h5>Feel free to contact us</h5>
        <div className="d-flex">
          <Col md={5} xs={12} className=" my-2 px-3">
            <div className="cont-box">
              <h5>Get In Touch</h5>
              <div className="cont-cir">
                <ul>
                  <li>
                    <Image src={Call} />
                    <a href="tel:+91 65987 45695">+91 6374712624</a>
                  </li>
                  <li>
                    <Image src={Email} />
                    {/* <Link to="mailto:support@eaple.com">support@riofin.in</Link> */}
                    <a href="mailto:support@riofinassets.com">
                      support@riofinassets.com
                    </a>
                    {/* info@eaple.com */}
                  </li>
                  <li></li>
                  <h6>Corporate Head office</h6>
                  <li>
                    <Image src={Map} />
                    <Link to="#">
                      # 15A, 4th Floor, City Vista, Kharadi, Pune,
                      Maharashtra-411014.
                    </Link>
                  </li>
                </ul>
                <Image src={Contact} className="w-50 px-3 pos-abss" />
              </div>

              <div style={{ width: "100%" }}>
                <iframe
                  width="100%"
                  height="230"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://maps.google.com/maps?width=100%25&height=300&hl=en&q=26%20B,%20Cityvista,%20Kharadi,%20Pune,%20Maharashtra%20411014+(RioFin)&t=&z=15&ie=UTF8&iwloc=B&output=embed"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure area map
                  </a>
                </iframe>
              </div>
            </div>
          </Col>
          <Col md={7} xs={12} className="my-2 px-3 pos-rela">
            <div className="my-2 click-here">
              <div className="for-box d-flex-al-jc my-3">
                <div className="img-box px-3 col-4 text-center">
                  <Image src={ForInv} />
                </div>
                <div className="img-box col-8">
                  <h6>For Investments</h6>
                  <p>
                    Interested in our investment opportunities? Contact us
                    today.
                  </p>
                  <Button
                    className="btn-web m-0"
                    onClick={() => setVisible(true)}
                  >
                    Click Here
                  </Button>
                </div>
              </div>
              <div className="for-box d-flex-al-jc my-3">
                <div className="img-box px-3 col-4 text-center">
                  <Image src={Partner} />
                </div>
                <div className="img-box col-8">
                  <h6>For Partnerships</h6>
                  <p>
                    Let's explore partnership opportunities together - If you
                    are a distributor/financial advisor/broker etc., get in
                    touch with us today.
                  </p>
                  <Button
                    className="btn-web m-0"
                    onClick={() => setVisible(true)}
                  >
                    Click Here
                  </Button>
                </div>
              </div>
              <div className="for-box d-flex-al-jc my-3">
                <div className="img-box px-3 col-4 text-center">
                  <Image src={Query} />
                </div>
                <div className="img-box col-8">
                  <h6>For Other Queries</h6>
                  <p>
                    If you are a MSME Industry or Property developer interested
                    in liquidating your property.
                  </p>
                  <Button
                    className="btn-web m-0"
                    onClick={() => setVisible(true)}
                  >
                    Click Here
                  </Button>
                </div>
              </div>
              {visible && (
                <div className="d-des">
                  <div className="form-box">
                    <div className="d-flex">
                      <button onClick={() => setVisible(false)}>
                        {" "}
                        <Image src={Arrow} />
                      </button>
                      <h5>Send Us a message</h5>
                    </div>
                    <from>
                      <input
                        className="form-control"
                        style={{ color: "white" }}
                        placeholder="Your Name *"
                        type="name"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <input
                        className="form-control"
                        placeholder="Your Email *"
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <input
                        className="form-control"
                        placeholder="Your Mobile No *"
                        type="number"
                        name="mobile"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                      />
                      <input
                        className="form-control"
                        placeholder="Subject *"
                        type="text"
                        name="subject"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <input
                        className="form-control"
                        placeholder="Your message *"
                        type="type"
                        name="message"
                        value={msg}
                        onChange={(e) => setMsg(e.target.value)}
                      />
                      <Button className="sub-bnt" onClick={addContact}>
                        Send Message
                      </Button>
                    </from>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </div>
        <div className="my-3">
          <p>
            Rio Global Fintech Private Limited ( CIN: U74140PN2022PTC211767 )
            operates the website www.riofinassets.com, and by accessing the site
            and its pages, you agree to be bound by its terms of service and
            privacy policy. Rio Global Fintech Private Limited does not endorse
            or promote any opportunities listed on the website or offer any
            recommendations to investors. The information provided on the
            website is not to be construed as investment, business, legal, or
            tax advice, and the content contained on the site does not
            constitute an offer to sell securities by Rio Global Fintech Private
            Limited. Any information provided on the website or linked sites
            does not represent a solicitation to buy or sell any Machineries,
            property, nor does it constitute an offer to provide investment
            advice, services, or assistance for any particular investment or
            transaction. Investing in real property and machineries, directly or
            indirectly, carries significant risks, and investments may lose
            value without any insurance or guarantee from any governmental
            agency or Rio Global Fintech Private Limited. It is the
            responsibility of the recipient to verify the accuracy and
            authenticity of the information provided. Historical returns,
            expected returns, or probability projections may not reflect actual
            future performance, and investors must be able to afford potential
            losses on any investment made.
          </p>
        </div>
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default ContactUs;
