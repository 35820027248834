import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const investList = (id) => {
  return httpClient.get(URL.INVESTMENT, id);
};

const investView = (id) => {
  return httpClient.get(URL.INVESTMENT_EDIT + id);
};

const moreInvestments = (id, data) => {
  return httpClient.get(URL.MORE_INVESTMENTS + id, data);
};

const myInvestment = () => {
  return httpClient.get(URL.MY_INVESTMENT);
};

const myInvestmentView = (id) => {
  return httpClient.get(URL.MY_INVESTMENT + "/" + id);
};

const manualPaymentView = (id) => {
  return httpClient.get(URL.MANUAL_PAY_VIEW + id);
};

const userInvest = (data) => {
  return httpClient.post(URL.USER_INVEST, data);
};

const transactionStatus = (data) => {
  return httpClient.post(URL.TRANSACTION_STATUS, data);
};

const invoiceDownload = (id) => {
  return httpClient.get(URL.MYIVESTMENT_INVOICE + id);
};

const uploadProof = (id, data) => {
  return httpClient.post(URL.USER_PROOF_UPLOAD + id, data);
};

const pdfDownloader = (id, data) => {
  return httpClient.post(URL.USER_PROOF_UPLOAD + id, data);
};

const investmentServices = {
  transactionStatus,
  manualPaymentView,
  moreInvestments,
  invoiceDownload,
  uploadProof,
  userInvest,
  investList,
  pdfDownloader,
  investView,
  myInvestmentView,
  myInvestment,
};

export default investmentServices;
