import React from "react";
import { Col, Image } from "react-bootstrap";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import { Howriofin } from "../assets/img";
import AboutGoal from "../Frontendcomponent/AboutGoal";
import HowRiofinWork from "../Frontendcomponent/HowRiofinWork";
const AboutUs = () => {
    return (
        <div className="faq-deal margin-t">
            <div className="top-bans">
                <TopBanner />
                <FrontHeading type="about" />
            </div>

            <AboutGoal />
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};
export default AboutUs;