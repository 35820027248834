import React from "react";
const FrontHeading = (props) => {
    return (
        <div className="front-head">
            {props.type === "home"}
            {props.type === "quickinvest" && <h5>Quick Invest</h5>} 
            {props.type === "ourdeals" && <h5>Our Deals</h5>} 
            {props.type === "ourpartners" && <h5>Our Partners</h5>} 
            {props.type === "frontleasing" && <h5>Leasing</h5>} 
            {props.type === "frontrealestate" && <h5>Real Estate</h5>} 
            {props.type === "faq" && <h5>FAQ's</h5>} 
            {props.type === "about" && <h5>About Us</h5>} 
            {props.type === "ownership" && <h5>Fractional ownership</h5>} 
            {props.type === "blog" && <h5>Blog</h5>} 
            {props.type === "blogdetails" && <h5>Blog Details</h5>} 
            {props.type === "contactus" && <h5>Contact Us</h5>} 
            {props.type === "privacypolicy" && <h5>Privacy Policy</h5>} 
            {props.type === "cookiespolicy" && <h5>Cookies Policy</h5>} 
            {props.type === "termscondition" && <h5>Terms Condition</h5>} 


        </div>
    );
};

export default FrontHeading;
