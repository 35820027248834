import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const referalView = () => {
  return httpClient.get(URL.REFRAL_VIEW);
};

const referalRequest = (data) => {
  return httpClient.post(URL.REFERAL_REQUEST, data);
};

const referalInvite = (data) => {
  return httpClient.post(URL.REFERAL_INVITE, data);
};

const referalServices = {
  referalView,
  referalRequest,
  referalInvite,
};

export default referalServices;
