import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LessLogo } from "../assets/img";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/fontawesome-free-regular";
import InfiniteScroll from "react-infinite-scroll-component";
import DOMPurify from "dompurify";
const PastLeasing = ({ daatLength, fetch, realEstate, leasingList }) => {
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  console.log("leasingList", leasingList);

  return (
    // <div className="active-leas d-flex">
    //     <Col lg={6} xs={12} className="px-2 my-2  ">

    //             <div className="inves-box">
    //                 <Image src={LessLogo} />
    //                 <h6>The lorem ipsum is a placeholder text used in publishing and graphic design. </h6>
    //                 <p>Due Date - <span>12 Nov 2022</span></p>
    //                 <div className="d-flex-al-jb">
    //                     <div className="due-date">
    //                         <p>Pre Tax Return</p>
    //                         <h5>5.5%</h5>
    //                     </div>
    //                     <div className="due-date">
    //                         <p>Tenure</p>
    //                         <h5>20 months</h5>
    //                     </div>
    //                 </div>
    //                 <h4>Return Completed</h4>
    //                 <ProgressBar now={40} />
    //                 <div className="d-flex-al-jb mt-3 compl-leas">
    //                     <p>1 Cr / 3.3 Cr</p>
    //                     <h6>45% Completed</h6>
    //                 </div>
    //                 <Button>Minimum Investment ₹ 10,473.1
    //                 </Button>
    //                 <div className="view-detail d-flex-al-jc">
    //                     <Link to="/invest/pastleasview" className="d-flex-al">View Details <FontAwesomeIcon icon={faArrowAltCircleRight} /></Link>
    //                 </div>
    //             </div>

    //     </Col>
    //     <Col lg={6} xs={12} className="px-2 my-2  ">

    //             <div className="inves-box">
    //                 <Image src={LessLogo} />
    //                 <h6>The lorem ipsum is a placeholder text used in publishing and graphic design. </h6>
    //                 <p>Due Date - <span>12 Nov 2022</span></p>
    //                 <div className="d-flex-al-jb">
    //                     <div className="due-date">
    //                         <p>Pre Tax Return</p>
    //                         <h5>5.5%</h5>
    //                     </div>
    //                     <div className="due-date">
    //                         <p>Tenure</p>
    //                         <h5>20 months</h5>
    //                     </div>
    //                 </div>
    //                 <h4>Return Completed</h4>
    //                 <ProgressBar now={40} />
    //                 <div className="d-flex-al-jb mt-3 compl-leas">
    //                     <p>1 Cr / 3.3 Cr</p>
    //                     <h6>45% Completed</h6>
    //                 </div>
    //                 <Button>Minimum Investment ₹ 10,473.1
    //                 </Button>
    //                 <div className="view-detail d-flex-al-jc">
    //                     <Link to="/invest/pastleasview" className="d-flex-al">View Details <FontAwesomeIcon icon={faArrowAltCircleRight} /></Link>
    //                 </div>
    //             </div>

    //     </Col>

    // </div>
    <div className="active-leas d-flex">
      <InfiniteScroll
        next={fetch}
        dataLength={5}
        hasMore={daatLength?.current_page <= daatLength?.last_page}
      >
        {leasingList?.map((item, index) => {
          return (
            <>
              <Col key={index} sm={12}>
                <div className="leasing-ti">
                  <div className="d-flex-al-jb my-2">
                    <h5>{item?.title}</h5>
                    <Link
                      state={{
                        id: item?.id,
                        reallen: realEstate?.length,
                        leaselen: leasingList?.length,
                      }}
                      to="/invest/moreinvest"
                      className="login-btn"
                    >
                      Know More
                    </Link>
                  </div>{" "}
                  <p
                    className="line-control-paragragh-box"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(item?.description),
                    }}
                  ></p>
                </div>
              </Col>

              {item?.project?.map((list, index) => {
                return index < 2 ? (
                  <Col lg={6} xs={12} className="px-2 my-2  " key={index}>
                    <div className="inves-box">
                      <Image src={list.img_url} className="inves_img" />
                      <p
                        className="modernWay"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(list?.content),
                        }}
                      ></p>
                      <h6>{list?.title}</h6>

                      <div className="d-flex-al-jb">
                        {/* {item?.project?.map((list, index) => {
                return index < 2 ? ( */}
                        {list?.project?.map((data, ind) => (
                          <>
                            <p>
                              Monthly Return -{" "}
                              <span>{data?.interest_percentage} %</span>
                            </p>
                            <p>
                              Due Date -{" "}
                              <span>
                                {new Date(
                                  list?.enrolment_deadline
                                ).toLocaleDateString("en-US", DATE_OPTIONS)}
                              </span>
                            </p>
                          </>
                        ))}

                        {/* )})} */}
                      </div>
                      <div className="d-flex-al-jb">
                        <div className="due-date">
                          <p>Pre Tax Return</p>
                          <h5>
                            {list?.interest_percentage ||
                              list?.monthly_percentage}{" "}
                            %
                          </h5>
                        </div>
                        {/* 

                        {/* <div className="due-date">
                          <p>Exp.Return</p>
                          <h5>{list?.interest_range} </h5>
                        </div> */}
                        <div className="due-date">
                          <p>Tenure</p>
                          <h5>{list?.tenure} months</h5>
                        </div>
                      </div>

                      <ProgressBar now={list?.total_percentage} />
                      <div className="d-flex-al-jb mt-3 compl-leas">
                        <h5>
                          {Math.round(list?.total_invested_amount)} /{" "}
                          {Math.round(list?.total_value)}
                        </h5>
                        <h6>{list?.total_percentage} % Completed</h6>
                      </div>
                      <Button>
                        Minimum Investment ₹ {Math.round(list?.min_value)}
                      </Button>
                      <div className="view-detail d-flex-al-jc">
                        <Link
                          state={{ id: list?.id }}
                          to="/invest/pastrealview"
                          className="d-flex-al"
                        >
                          View Details{" "}
                          <FontAwesomeIcon icon={faArrowAltCircleRight} />
                        </Link>
                      </div>
                    </div>
                  </Col>
                ) : null;
              })}
            </>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};
export default PastLeasing;
