import React from "react";
import { Col, Image } from "react-bootstrap";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import { Howriofin, Chart } from "../assets/img";
import AboutGoal from "../Frontendcomponent/AboutGoal";
import HowRiofinWork from "../Frontendcomponent/HowRiofinWork";
const Ownership = () => {
    return (
        <div className="faq-deal margin-t">
            <div className="top-bans">
                <TopBanner />
                <FrontHeading type="ownership" />
            </div>

            <div className="d-flex-al my-3 w-90 own_des">
                <Col md={6} xs={12} className="px-2 my-2">
                    <h5>
                        Fractional ownership
                    </h5>
                    <p>Fractional ownership is an investment approach in which the cost of an asset is
                        split between individual shareholders. All the shareholders split the benefits of the
                        asset, such as income sharing, reduced rates, and usage rights.</p>
                    <p>RIOFIN identifies various investment opportunities in the said 3 sectors and select esteemed projects by thorough Due diligence in finance, Operation, Management and compliance.</p>
                    <p>Then RIOFIN forms SPV (Special Purpose Vehicle) in the form LLP(Limited Liability Partnership) for investment pools. Investors funds will be considered as contribution to the LLP and profits will be shared as returns (Post tax returns). </p>
                    <ul>
                        <h5> We provide fractional ownership in</h5>
                        <li><i class="fa-solid fa-check pc_ic"></i> Leasing of Machinery and Equipment</li>
                        <li><i class="fa-solid fa-check pc_ic"></i> Corporate & Domestic Real-estate</li>
                        <li>
                            <i class="fa-solid fa-check pc_ic"></i> Acquisition of Auctioned properties from Banks
                        </li>

                    </ul>
                </Col>
                <Col md={6} xs={12} className="px-2 my-2 text-center">
                    <Image src={Chart} className="w-80" />
                </Col>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};
export default Ownership;