import axios from "axios";
import { async } from "q";
import React, { useState } from "react";
import { useEffect } from "react";
import { Button, Form, Col } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { Triangle } from "react-loader-spinner";
import ErrorHandler from "../services/Constant/ErrorHandler";
import userProfileServices from "../services/service/profile";
import useKyc from "../services/Storage/userKyc";
const KYCDetails = ({ name }) => {
  const { userKyc, setUserKyc } = useKyc();
  const [pan, setPan] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [loading, setLoading] = useState(false);
  const [panVerified, setPanVerified] = useState(false);
  const [aadharVerified, setAadharVerified] = useState(false);
  const [otp, setOtp] = useState("");
  const [clientID, setClientID] = useState("");
  const [kycDetails, setKycDetails] = useState();

  const [AadhaarNo, setAadhaarNo] = useState([]);
  const [PanNo, setPanNo] = useState([]);
  const [list, setList] = useState();

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const kycView = () => {
    userProfileServices.KycView().then((res) => {
      setKycDetails(res.data);
      if (res.data?.aadhar_number?.length) {
        setAadharVerified(true);
      }

      if (res.data?.pan_number?.length) {
        setPanVerified(true);
      }
      setAadhar(res.data?.aadhar_number[0]?.doc_number);
      name(res.data?.aadhar_number[0]?.other_details?.aadhaar_name);
      setPan(res.data?.pan_number[0]?.doc_number);
      // setList(res?.data);
      setUserKyc(res.data?.user?.kyc_verified);
      let temp = {};
      let data = res?.data?.aadhar_number[0]?.other_details;
      let data1 = res?.data?.pan_number[0]?.other_details;
      if (data == null) {
        temp.pan = res.data?.pan_number[0].other_details || JSON.parse(pan);
      } else if (data1 == null) {
        temp.aadhaar =
          res.data?.aadhar_number[0].other_details || JSON.parse(data);
      } else {
        temp.aadhaar =
          res.data?.aadhar_number[0].other_details || JSON.parse(data);
        temp.pan = res.data?.pan_number[0].other_details || JSON.parse(pan);
      }

      // temp.aadhaar_name =
      //   res.data?.aadhar_number[0]?.other_details?.aadhaar_name;
      // temp.aadhaar_dob =
      //   res.data?.aadhar_number[0]?.other_details?.DOB_in_aadhaar;
      // temp.gender = res.data?.aadhar_number[0]?.other_details?.gender;
      // temp.zip = res.data?.aadhar_number[0]?.other_details?.zip;
      // temp.no = res.data?.aadhar_number[0]?.other_details?.address?.house;
      // temp.street =
      //   res.data?.aadhar_number[0]?.other_details?.address?.street;
      // temp.vtc = res.data?.aadhar_number[0]?.other_details?.address?.vtc;
      // temp.state = res.data?.aadhar_number[0]?.other_details?.address?.state;
      // temp.dist = res.data?.aadhar_number[0]?.other_details?.address?.dist;
      // temp.country =
      //   res.data?.aadhar_number[0]?.other_details?.address?.country;
      // temp.landmark =
      //   res.data?.aadhar_number[0]?.other_details?.address?.landmark;

      // temp.full_name = res.data?.pan_number[0]?.other_details?.full_name;
      // temp.pan_number = res.data?.pan_number[0]?.other_details?.pan_number;

      setList(temp);
    });
    // .catch((e) => ErrorHandler(e));
  };

  console.log("list", list);

  useEffect(() => {
    setTimeout(() => {
      kycView();
    }, 800);
  }, []);

  const baseURL = "https://kyc-api.surepass.io";

  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MTI1ODQ2NSwianRpIjoiYTU3ZmM3MTgtYjUxNC00MGEwLWJmOWUtMjRlNTk0ZmRlOWMzIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnJpb2ZpbkBzdXJlcGFzcy5pbyIsIm5iZiI6MTY3MTI1ODQ2NSwiZXhwIjoxOTg2NjE4NDY1LCJ1c2VyX2NsYWltcyI6eyJzY29wZXMiOlsid2FsbGV0Il19fQ.DZ3DdJ6lzxPzwYgLerYoqNJ7ZD5xC9-P3FpQNraT3nc";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  function verifyPan() {
    setLoading(true);
    axios
      .post(
        `${baseURL}/api/v1/pan/pan`,
        {
          id_number: pan,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // console.log("pan", response);
        setPanNo(response);
        setPanVerified(true);
        // kycView()
        setLoading(false);
        // userProfileServices
        //   .verifyKyc({ doc_number: pan, doc_type: 1, status: 2, id: 1 })
        //   .then((res) => {
        toast.success("PAN Number Verified successfully");
        //     alert(res.data.message);
        //   })
        //   .catch((e) => ErrorHandler(e));
      })
      .catch((e) => {
        ErrorHandler(e);
        setLoading(false);
        //   userProfileServices
        //     .verifyKyc({ doc_number: pan, doc_type: 2, status: 1 })
        //     .then((res) => {
        //       toast.error(res.data.message);
        //     });
      });
  }

  // console.log("pan", PanNo);

  const verifyAadhar = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${baseURL}/api/v1/aadhaar-v2/generate-otp`,
        {
          id_number: aadhar,
        },
        {
          headers: headers,
        }
      );
      setLoading(false);
      setClientID(res.data.data.client_id);
      alert("Your Aadhaar OTP has been sent to your Registered Mobile Number");
      // console.log("aadhaar ver", res);
      //   console.log("res", res.data.data.client_id);
    } catch (error) {
      setLoading(false);
      ErrorHandler(error);
    }
  };

  const verifyOtp = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        `${baseURL}/api/v1/aadhaar-v2/submit-otp`,
        {
          client_id: clientID,
          otp: otp,
        },
        {
          headers: headers,
        }
      );
      setClientID("");
      setOtp("");
      setAadhaarNo(res);
      alert("Aadhar Has Been Verified Successfully");
      setAadharVerified(true);
      setLoading(false);
      // console.log("aadhaar", res);
      // userProfileServices
      //   .verifyKyc({ doc_number: aadhar, doc_type: 2, status: 2 })
      //   .then((res) => {
      //     toast.success(res.data.message);
      //     alert(res.data.message);
      //   })
      //   .catch((e) => ErrorHandler(e));
    } catch (error) {
      ErrorHandler(error);
      setLoading(false);
      //   userProfileServices
      //     .verifyKyc({ doc_number: aadhar, doc_type: 1, status: 1 })
      //     .then((res) => {
      //       toast.success(res.data.message);
      //       alert(res.data.message);
      //     });
    }
  };

  // console.log("AadhaarNo", AadhaarNo);

  const updateDetails = () => {
    let addhar_data = {
      doc_number: aadhar,
      doc_type: 2,
      status: 2,
      extra_details: {
        name_from_aadhaar: AadhaarNo?.data?.data?.full_name,
        gender: AadhaarNo?.data?.data?.gender,
        dob_aadhar: AadhaarNo?.data?.data?.dob,
        aadhar_address: AadhaarNo?.data?.data?.address,
        pan_name: PanNo?.data?.data?.full_name,
      },
    };

    // console.log("addhar_data", addhar_data);

    let pan_data = {
      doc_number: pan,
      doc_type: 1,
      status: 2,
      extra_details: {
        name_from_aadhaar: AadhaarNo?.data?.data?.full_name,
        gender: AadhaarNo?.data?.data?.gender,
        DOB: AadhaarNo?.data?.data?.dob,
        aadhar_address: AadhaarNo?.data?.data?.address,
        pan_name: PanNo?.data?.data?.full_name,
        pan_number: PanNo?.data?.data?.pan_number,
      },
    };

    // console.log("pan_data", pan_data);

    if (kycDetails?.aadhar_number?.length >= 1) {
      addhar_data.id = kycDetails.aadhar_number[0]?.id;
    }

    if (kycDetails?.pan_number?.length >= 1) {
      pan_data.id = kycDetails.pan_number[0]?.id;
    }

    if (aadharVerified) {
      userProfileServices
        .verifyKyc(addhar_data)
        .then((res) => {
          toast.success(res.data.message);
          alert(res.data.message);
          // console.log("aadharVerified", res);
          kycView();
        })
        .catch((e) => ErrorHandler(e));
    }

    if (panVerified) {
      userProfileServices
        .verifyKyc(pan_data)
        .then((res) => {
          toast.success(res.data.message);
          alert(res.data.message);
          kycView();
        })
        .catch((e) => ErrorHandler(e));
    } else if (!aadharVerified && !panVerified) {
      toast.error("Please Verify Aadhar & PAN");
    }
  };

  return (
    <div className="edit-prf my-2">
      <Triangle
        height={100}
        width={100}
        radius={5}
        color="#4fa94d"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle={style}
        visible={loading}
      />
      <Form>
        <div>
          <Col md={6} xs={12} className="px-2">
            <Toaster position="top-right" reverseOrder={false} />

            <label className="my-2">
              Aadhaar No <span className="text-danger">*</span>
            </label>
            <div className="input-group mb-3">
              <input
                onInput={(e) => {
                  if (e.target.value.length > e.target.maxLength)
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  if (e.target.value.length !== 12) {
                    e.target.setCustomValidity("invalid Number");
                  } else if (e.target.value.length == 12) {
                    e.target.setCustomValidity("");

                    setAadhar(e.target.value);
                  }
                }}
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                maxlength={12}
                onChange={(e) => setAadhar(e.target.value)}
                disabled={aadharVerified}
                value={aadhar}
                type="text"
                className="form-control"
                placeholder="Enter 12 Digit Aadhaar Number"
              />
              <div className="input-group-append">
                {!aadharVerified ? (
                  <Button onClick={verifyAadhar} className="input-group-text">
                    Check
                  </Button>
                ) : null}
              </div>
            </div>
          </Col>
          {aadharVerified ? (
            <>
              <Col md={6} xs={12} className="px-2">
                <label className="my-2">Aadhaar Name</label>
                <input
                  disabled
                  value={list?.aadhaar?.name_from_aadhaar}
                  name="aadhaar_name"
                  className="form-control"
                  style={{ cursor: "not-allowed" }}
                  type="email"
                  placeholder="Aadhaar Name"
                />
              </Col>
              <Col md={6} xs={12} className="px-2">
                <label className="my-2">DOB</label>
                <input
                  disabled
                  value={list?.aadhaar?.dob_aadhar}
                  name="dob_aadhar"
                  className="form-control"
                  style={{ cursor: "not-allowed" }}
                  type="date"
                  placeholder="DOB_aadhar"
                />
              </Col>
              <Col md={6} xs={12} className="px-2">
                <label className="my-2">GENDER</label>
                <input
                  disabled
                  value={
                    list?.aadhaar?.gender == "m"
                      ? "Male"
                      : list?.aadhaar?.gender == "male"
                      ? "Male"
                      : list?.aadhaar?.gender == "M"
                      ? "Male"
                      : list?.aadhaar?.gender == "MALE"
                      ? "Male"
                      : "Female"
                  }
                  name="aadhaar_name"
                  className="form-control"
                  style={{ cursor: "not-allowed" }}
                  type="email"
                  placeholder="Aadhaar Name"
                />
              </Col>
            </>
          ) : null}
        </div>

        {!aadharVerified ? (
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">Enter OTP </label>
            <p>OTP sent to aadhar registered number</p>
            <div className="input-group mb-3">
              <input
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                type="tel"
                className="form-control"
                placeholder="Enter OTP"
              />
              {clientID && (
                <div className="input-group-append">
                  <Button onClick={verifyOtp} className="input-group-text">
                    Check
                  </Button>
                </div>
              )}
            </div>
          </Col>
        ) : null}

        <Col md={6} xs={12} className="px-2">
          <label className="my-2">
            PAN No <span className="text-danger">*</span>
          </label>
          <div className="input-group mb-3">
            <input
              onInput={(e) => {
                if (e.target.value.length > e.target.maxLength)
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                if (e.target.value.length !== 10) {
                  e.target.setCustomValidity("invalid Number");
                } else if (e.target.value.length == 10) {
                  e.target.setCustomValidity("");

                  setPan(e.target.value);
                }
              }}
              onFocus={(e) =>
                e.target.addEventListener(
                  "wheel",
                  function (e) {
                    e.preventDefault();
                  },
                  { passive: false }
                )
              }
              maxlength={10}
              type="text"
              value={pan}
              onChange={(e) => setPan(e.target.value)}
              disabled={panVerified}
              style={{ textTransform: "uppercase" }}
              className="form-control"
              placeholder="Enter 10 Digit PAN Number"
            />
            <div className="input-group-append">
              {!panVerified ? (
                <Button onClick={verifyPan} className="input-group-text">
                  Check
                </Button>
              ) : null}
            </div>
          </div>
        </Col>
        {panVerified ? (
          <>
            <Col md={6} xs={12} className="px-2">
              <label className="my-2">PAN Name</label>
              <input
                disabled
                value={list?.pan?.pan_name}
                name="Pan_name"
                className="form-control"
                style={{ cursor: "not-allowed" }}
                type="email"
                placeholder="PAN Name"
              />
            </Col>
            {/* <Col md={6} xs={12} className="px-2">
                <label className="my-2">DOB</label>
                <input
                  disabled
                  value={list?.aadhaar_dob}
                  name="aadhaar_name"
                  className="form-control"
                  style={{ cursor: "not-allowed" }}
                  type="email"
                  placeholder="Aadhaar Name"
                />
              </Col> */}
          </>
        ) : null}
        {kycDetails?.aadhar_number?.length < 1 ||
        kycDetails?.pan_number?.length < 1 ? (
          <>
            <Button onClick={updateDetails} className="my-3">
              Update
            </Button>
            <Col>
              <span style={{ color: "tomato", fontSize: "15px" }}>
                Please Click on Update Button to Complete the KYC
              </span>
            </Col>
          </>
        ) : null}
      </Form>
    </div>
  );
};
export default KYCDetails;
