import PNotify from 'pnotify/dist/es/PNotify';

const ErrorHandler = (error) => {
  if (error.response && navigator.onLine) {
    // Request made and server responded
    console.log(error.response);
    if (error.response.data?.message) {
      if (!navigator.onLine) {
        // window.location.href = './views/maintenance/OfflineUI';
        window.location.href = '/maintenance/offline-ui';
      }
      if (error.response.data?.message == 'Unauthenticated.') {
        // alert('You have already logged in another device');
        
        PNotify.error({
          title: false,
          text: 'You have already logged in another device',
          icon: false,
          delay: 1200
        });
        setTimeout(() => {
          localStorage.clear();
          window.location.href = '/';
        },2000)
      } else {
        PNotify.error({
          title: false,
          text: error.response.data.message,
          icon: false,
          delay: 800
        });
        // alert(error.response.data.message);
      }
    } else {
      PNotify.error({
        title: false,
        text: error.response.data,
        icon: false,
        delay: 800
      });
      // alert(error.response.data);
      // request
    }
  } else if (error.request) {
    console.log(error.request);
    // The request was made but no response was received
    PNotify.error({
      title: false,
      text: error.request,
      icon: false,
      delay: 800
    });
    // alert(error.request);
  } else {
    console.log(error);
    PNotify.error({
      title: false,
      text: error.message,
      icon: false,
      delay: 800
    });
    // Something happened in setting up the request that triggered an Error
    // alert(error.message);
  }
};
export default ErrorHandler;
