import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import "./App.scss";
import "./responsive.scss";
import LogIn from "./signin/LogIn";
import Log from "./signin/signUp";
import Register from "./signin/register";
import VerifyOtp from "./signin/verifyotp";
import Dashboard from "./routes/Dashboard";
import Invest from "./routes/Invest";
import HowIts from "./routes/HowIts";
import Benifits from "./routes/Benifits";
import MoreInvest from "./routes/MoreInvest";
import ActiveLeasView from "./routes/ActiveLeasView";
import ActiveRealView from "./routes/ActiveRealView";
import PastLeasView from "./routes/PastLeasView";
import PastRealView from "./routes/PastRealView";
import MyInvestment from "./routes/MyInvestment";
import RealDetails from "./routes/RealDetails";
import LeasDetails from "./routes/LeasDetails";
import SupporTicket from "./routes/SupporTicket";
import EditSupport from "./routes/EditSupport";
import ReferEarn from "./routes/ReferEarn";
import RaiseCapital from "./routes/RaiseCapital";
import SuccesRaise from "./routes/SuccesRaise";
import Notification from "./routes/Notification";
import Profile from "./routes/Profile";
import Layout from "./Layout/layout";
import FrontLayout from "./Layout/FrontLayout";
import Home from "./FrontendRoutes/Home";
import QuickInvest from "./FrontendRoutes/QuickInvest";
import OurDeals from "./FrontendRoutes/OurDeals";
import OurPartners from "./FrontendRoutes/OurPartners";
import FrontLeasing from "./FrontendRoutes/FrontLeasing";
import FrontRealEstate from "./FrontendRoutes/FrontRealEstate";
import FAQ from "./FrontendRoutes/FAQ";
import AboutUs from "./FrontendRoutes/AboutUs";
import Ownership from "./FrontendRoutes/Ownership";
import BlogPage from "./FrontendRoutes/BlogPage";
import BlogDetails from "./FrontendRoutes/BlogDetails";
import ContactUs from "./FrontendRoutes/ContactUs";
import PrivacyPolicy from "./FrontendRoutes/PrivacyPolicy";
import CookiesPolicy from "./FrontendRoutes/CookiesPolicy";
import TermsCondition from "./FrontendRoutes/TermsCondition";
import PrivateRoutes from "./routes/privateRoutes";
import useToken from "./services/Storage/useToken";
import { UserContext } from "./services/Context/userContext";

const ReactRoutes = () => {
  const { token } = useToken();
  const [user, setUser] = useState();

  return (
    <Routes>
      {/* <Route path="/" index element={<LogIn />} /> */}
      <Route path="/login" index element={<LogIn />} />
      <Route path="/signup" index element={<Log />} />
      <Route path="/register" element={<Register data={setUser} />} />
      <Route path="/verifyotp" element={<VerifyOtp data={setUser} />} />
      <Route element={<PrivateRoutes token={token} />}>
        <Route element={<Layout data={user} />}>
          <Route path="/" element={<Dashboard />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invest" element={<Invest />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/howits" element={<HowIts />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/bninoenifits" element={<Benifits />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invest/activeleasview" element={<ActiveLeasView />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invest/moreInvest" element={<MoreInvest />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invest/activerealview" element={<ActiveRealView />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invest/pastleasview" element={<PastLeasView />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/invest/pastrealview" element={<PastRealView />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/myinvestment" element={<MyInvestment />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/myinvestment/realdetails" element={<RealDetails />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/myinvestment/leasdetails" element={<LeasDetails />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/supporticket" element={<SupporTicket />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/supporticket/editsupport" element={<EditSupport />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/referearn" element={<ReferEarn />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/raisecapital" element={<RaiseCapital />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/raisecapital/succesraise" element={<SuccesRaise />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/notification" element={<Notification />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/profile" element={<Profile data={user} />} />
        </Route>
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/home" element={<Home />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/quickinvest" element={<QuickInvest />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/ourdeals" element={<OurDeals />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/ourpartners" element={<OurPartners />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/frontleasing" element={<FrontLeasing />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/frontrealestate" element={<FrontRealEstate />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/faq" element={<FAQ />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/about" element={<AboutUs />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/ownership" element={<Ownership />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/blog" element={<BlogPage />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/blog" element={<BlogPage />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/blogdetails" element={<BlogDetails />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/contactus" element={<ContactUs />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/cookiespolicy" element={<CookiesPolicy />} />
      </Route>
      <Route element={<FrontLayout />}>
        <Route path="/termscondition" element={<TermsCondition />} />
      </Route>
    </Routes>
  );
};
export default ReactRoutes;
