import React, { useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import ProfileDetails from "../component/ProfileDetails";
import EditProfile from "../component/EditProfile";
import KYCDetails from "../component/KYCDetails";
import BanKYc from "../component/BanKYc";
import { Docs, UserFirs, Bank } from "../assets/img";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";

import { useContext } from "react";
import { UserContext } from "../services/Context/userContext";
import userProfileServices from "../services/service/profile";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { useEffect } from "react";

const Profile = ({ data }) => {
  const user = useContext(UserContext);

  const [image, setImage] = useState();

  const [name, setName] = useState();

  const kycDetails = () => {
    userProfileServices
      .KycView()
      .then((res) => {})
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    setTimeout(() => {
      // kycDetails();
    }, 800);
  });

  //   console.log(data);

  return (
    <div className="prof-page d-flex my-4 w-90">
      <Col md={4} xs={12} className="px-3 my-2">
        <ProfileDetails data={data} image={setImage} name={name} />
      </Col>
      <Col md={8} xs={12} className="px-3 my-2">
        <div className="prof-nav">
          <Tab.Container defaultActiveKey="profile">
            <div className=" my-3">
              <Nav variant="pills" className="profile-type">
                <Nav.Item>
                  <Nav.Link eventKey="profile" className="d-flex-al-jb">
                    <Image src={UserFirs} className="me-2" /> Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="kyc" className="d-flex-al-jb">
                    <Image src={Docs} className="me-2" />
                    Personal KYC
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="bank" className="d-flex-al-jb">
                    <Image src={Bank} className="me-2" />
                    Bank KYC
                  </Nav.Link>
                </Nav.Item>
              </Nav>

              <Tab.Content>
                <Tab.Pane eventKey="profile">
                  <EditProfile data={data} image={image} />
                </Tab.Pane>
                <Tab.Pane eventKey="kyc">
                  <KYCDetails data={data} name={setName} />
                </Tab.Pane>
                <Tab.Pane eventKey="bank">
                  <BanKYc data={data} />
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </Col>
    </div>
  );
};
export default Profile;
