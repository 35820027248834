import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Form, Col } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import ErrorHandler from "../services/Constant/ErrorHandler";
import userProfileServices from "../services/service/profile";
import usePermissions from "../services/Storage/user";

const BanKYc = () => {
  const { user, setUser } = usePermissions();
  const [accountNumber, setAccountNumber] = useState("");
  const [ifsc, setIfsc] = useState("");
  const [bank, setBank] = useState(user?.user_detail);
  const [kycDetails, setKycDetails] = useState();

  const [bankVerified, setBankVerified] = useState(false);
  const [bankName, setBankName] = useState();
  const [bankNameData, setBankNameData] = useState(false);
  const [brachName, setBranchName] = useState();
  const [brachNameData, setBranchNameData] = useState(false);

  // console.log("bank", bank);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setBank((values) => ({ ...values, [name]: value }));
  };

  const kycView = () => {
    userProfileServices
      .KycView()
      .then((res) => {
        setKycDetails(res.data);
        if (res.data?.user_detail?.account_no) {
          setIfsc(res.data?.user_detail?.ifsc_code);
          setAccountNumber(res.data?.user_detail?.account_no);
          setBankVerified(true);
          setBankName(res.data?.user_detail?.bank_name);
          setBranchName(res.data?.user_detail?.branch_name);
          setBankNameData(true);
          setBranchNameData(true);
        }
        if (res.data?.bank_number?.length) {
          // setBankVerified(true);
          // setAccountNumber(res.data?.bank_number[0]?.doc_number);
          // setIfsc(res.data?.user_detail?.ifsc_code);
          // console.log("ifc", res.data.user_detail?.ifsc_code);
        }
      })
      .catch((e) => ErrorHandler(e));
  };

  // console.log("setAccountNumber", accountNumber);
  // console.log("setIfsc", ifsc);

  useEffect(() => {
    setTimeout(() => {
      kycView();
    }, 800);
  }, []);

  const editBankDetails = () => {
    let formdata = new FormData();

    formdata.append("account_no", accountNumber);
    formdata.append("bank_name", bankName);
    formdata.append("branch_name", brachName);
    formdata.append("ifsc_code", ifsc);
    formdata.append("doc_type", 3);
    formdata.append("status", 2);

    if (kycDetails.bank_number?.length >= 1) {
      formdata.append("id", kycDetails.bank_number[0]?.id);
    }

    if (bankVerified && bankName && brachName && accountNumber && ifsc) {
      userProfileServices
        .bankDetails(formdata)
        .then((res) => {
          toast.success(res.data.message);
          kycView();
        })
        .catch((e) => ErrorHandler(e));
    } else if (!bankVerified) {
      toast.error("Please Verify Bank Account Number");
    } else if (!accountNumber) {
      toast.error("Please Enter Account Number");
    } else if (!ifsc) {
      toast.error("Please Enter IFSC Code");
    } else if (!bankName) {
      toast.error("Please Enter Bank Name");
    } else if (!brachName) {
      toast.error("Please Enter Branch Name");
    }
  };

  const baseURL = "https://kyc-api.surepass.io";

  const token =
    "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJmcmVzaCI6ZmFsc2UsImlhdCI6MTY3MTI1ODQ2NSwianRpIjoiYTU3ZmM3MTgtYjUxNC00MGEwLWJmOWUtMjRlNTk0ZmRlOWMzIiwidHlwZSI6ImFjY2VzcyIsImlkZW50aXR5IjoiZGV2LnJpb2ZpbkBzdXJlcGFzcy5pbyIsIm5iZiI6MTY3MTI1ODQ2NSwiZXhwIjoxOTg2NjE4NDY1LCJ1c2VyX2NsYWltcyI6eyJzY29wZXMiOlsid2FsbGV0Il19fQ.DZ3DdJ6lzxPzwYgLerYoqNJ7ZD5xC9-P3FpQNraT3nc";

  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };

  function verifyBank() {
    let bank_data = {
      doc_number: accountNumber,
      doc_type: 3,
      status: 2,
    };

    if (kycDetails?.bank_number?.length >= 1) {
      bank_data.id = kycDetails?.bank_number[0]?.id;
    }

    axios
      .post(
        `${baseURL}/api/v1/bank-verification/`,
        {
          id_number: accountNumber,
          ifsc: ifsc,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        // alert(response.data.data.message_code);
        console.log("bank", response);
        alert("Bank account Has been Verified Successfully");
        setBankVerified(true);
        kycView();
        // userProfileServices
        //   .verifyKyc(bank_data)
        //   .then((res) => {
        //     toast.success(res.data.message);
        //   })
        //   .catch((e) => ErrorHandler(e));

        // setAccountNumber("");
        // setIfsc("");
      })
      .catch((e) => {
        ErrorHandler(e);
        //   userProfileServices
        //     .verifyKyc({ doc_number: accountNumber, doc_type: 3, status: 1 })
        //     .then((res) => {
        //       toast.error(res.data.message);
        //     })
        //     .catch((e) => ErrorHandler(e));
      });
  }

  return (
    <div className="edit-prf my-2">
      <Toaster position="top-right" reverseOrder={false} />

      <Form>
        <div className="d-flex">
          {!bankVerified ? (
            <>
              <Col md={6} xs={12} className="  px-2">
                <label className="my-2">
                  Account Number <span className="text-danger">*</span>
                </label>
                <input
                  value={accountNumber}
                  disabled={bankVerified}
                  onChange={(e) => setAccountNumber(e.target.value)}
                  type="number"
                  className="form-control"
                  placeholder="Enter Account Number"
                />
              </Col>
              <Col md={6} xs={12} className="px-2">
                <label className="my-2">
                  Bank IFSC Code <span className="text-danger">*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    value={ifsc}
                    disabled={bankVerified}
                    onChange={(e) => setIfsc(e.target.value)}
                    type="name"
                    className="form-control"
                    placeholder="Enter IFSC Code"
                  />
                </div>
              </Col>
            </>
          ) : null}
          {!bankVerified ? (
            <Col xs={12} className="px-2 my-2 text-center">
              <div className="input-group-append">
                <Button onClick={verifyBank} className="input-group-text">
                  Verify
                </Button>
              </div>
            </Col>
          ) : null}

          {bankVerified ? (
            <>
              <Col md={6} xs={12} className="  px-2">
                <label className="my-2">
                  Account Number <span className="text-danger">*</span>
                </label>
                <input
                  // onChange={handleChange}
                  // value={bank?.account_no}
                  value={accountNumber}
                  disabled
                  name="account_no"
                  type="tel"
                  className="form-control"
                  placeholder="Enter Account Number"
                />
              </Col>
              <Col md={6} xs={12} className="px-2">
                <label className="my-2">
                  Bank IFSC Code <span className="text-danger">*</span>
                </label>
                <div className="input-group mb-3">
                  <input
                    value={ifsc}
                    // onChange={handleChange}
                    // value={bank?.ifsc_code}
                    disabled
                    name="ifsc_code"
                    type="name"
                    style={{ textTransform: "uppercase" }}
                    className="form-control"
                    placeholder="Enter IFSC Code"
                  />
                </div>
              </Col>
            </>
          ) : null}
          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              Branch Name <span className="text-danger">*</span>
            </label>
            <input
              onChange={(e) => setBranchName(e.target.value)}
              name="brach_name"
              value={brachName}
              className="form-control"
              type="name"
              placeholder="Enter Branch Name"
              disabled={
                bankNameData == false && brachNameData == false ? false : true
              }
            />
          </Col>

          <Col md={6} xs={12} className="px-2">
            <label className="my-2">
              Bank Name <span className="text-danger">*</span>
            </label>
            <input
              onChange={(e) => setBankName(e.target.value)}
              name="bank_name"
              value={bankName}
              className="form-control"
              type="name"
              placeholder="Enter Bank Name"
              disabled={
                bankNameData == false && brachNameData == false ? false : true
              }
            />
          </Col>
        </div>
        {bankNameData == false && brachNameData == false ? (
          <>
            <Button onClick={editBankDetails} className="my-3">
              Update
            </Button>
            <Col>
              <span style={{ color: "tomato", fontSize: "15px" }}>
                Please Click on Update Button to Complete the Bank KYC
              </span>
            </Col>
          </>
        ) : null}
      </Form>
      <Form></Form>
    </div>
  );
};
export default BanKYc;
