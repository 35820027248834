import React, { useEffect, useMemo, useState } from "react";
import { Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import ActiveInvest from "../component/ActiveInvest";
import InvestList from "../component/InvestList";
import PastInvest from "../component/PastInvest";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { PassOff, ActiveOff } from "../assets/img";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";

const Invest = () => {
  // const [leasing, setLeasing] = useState([]);
  // const [realEstate, setRealEstate] = useState([]);
  // const [offset, setOffset] = useState(1);

  // const investList = () => {
  //   investmentServices
  //     .investList({ params: { status: 1, page: parseInt(offset) } })
  //     .then((res) => {
  //       setLeasing((pre) => [...pre, ...res.data?.leasing_investments]);
  //       setRealEstate((pre) => [...pre, ...res.data?.real_estate_investments]);
  //     })
  //     .catch((e) => ErrorHandler(e));
  // };

  // useEffect(() => {
  //   const handleScroll = (e) => {
  //     const scrollHeight = e.target.documentElement.scrollHeight;
  //     const currentHeight =
  //       e.target.documentElement.scrollTop + window.innerHeight;
  //     if (currentHeight + 1 >= scrollHeight) {
  //       setOffset(offset + 1);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => window.removeEventListener("scroll", handleScroll);
  // }, [offset]);

  // useMemo(() => {
  //   investList();
  // }, [offset]);

  return (
    <div className="inve-page">
      <TopHeading type="invest" />
      <div className="my-3 w-90">
        <Tab.Container defaultActiveKey="activeoff">
          <div className="d-flex flex-wrap">
            <InvestList />

            <Tab.Content className="col-12">
              <Tab.Pane eventKey="activeoff">
                <ActiveInvest
                // leasing={leasing} realEstate={realEstate}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="pastoff">
                <PastInvest />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};
export default Invest;
