import React, { useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { Col, Image } from "react-bootstrap";

import { Link, useLocation } from "react-router-dom";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import ErrorHandler from "../services/Constant/ErrorHandler";
import supportServices from "../services/service/support";
import { toast, Toaster } from "react-hot-toast";
const ticket = [
  {
    id: "#01",
    title: "BASKEYNJNJK",
    subject: "baskey issue",
    department: "Login Issue",
    description: "HI ISSUE",
    status: "Low",
    priority: "open",
  },
];
const SupporDetails = () => {
  const location = useLocation();
  const [data, setData] = useState();
  const [message, setMessage] = useState("");
  const aRef = useRef(null);
  const [image, setImage] = useState();

  const container = {
    overflow: "hidden",
    paddingTop: "56.25%",
    position: "relative",
  };

  const iframe_res = {
    border: 0,
    height: "100%",
    left: 0,
    position: "absolute",
    top: "0",
    width: "100%",
  };

  const getData = () => {
    supportServices
      .ticketReplyView(location?.state?.id)
      .then((res) => {
        setData(res.data.ticket);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    if (location?.state?.id) {
      getData();
    }
  }, []);

  const resetInput = () => {
    aRef.current.value = null;
  };

  const replyMessage = () => {
    let formdata = new FormData();

    formdata.append("message", message);
    formdata.append("ticket_id", location?.state?.id);

    if (image) {
      formdata.append("attachment", image);
    }

    supportServices
      .ticketReply(formdata)
      .then((res) => {
        toast.success(res.data.message);
        setMessage("");
        // setImage(null);
        resetInput();
        getData();
      })
      .catch((e) => ErrorHandler(e));
  };

  return (
    <div className="edit-ticket w-90">
      <Toaster position="top-right" reverseOrder={false} />

      <table>
        <thead>
          <th>Support Ticket Details</th>
          <th>
            <Link className="btn" to="/supporticket">
              All Ticket
            </Link>
          </th>
        </thead>
        <tbody>
          <tr className="col-6">
            <td>Ticket ID </td>
            <td>Title </td>
            <td>Subject</td>
            <td>Department</td>
            <td>Description</td>
            <td>Status</td>
            <td>Priority</td>
          </tr>
          {/* {ticketView.map((list, index) => ( */}
          <tr className="col-6">
            <td>#0{data?.id}</td>
            <td>{data?.title}</td>
            <td>{data?.subject}</td>
            <td>{data?.ticketDepartment?.name}</td>
            <td>{data?.description}</td>
            <td>
              <Button>{data?.status}</Button>
            </td>
            <td>
              {" "}
              <CircularProgressbar value={66} text={`${data?.priority}`} />
            </td>
          </tr>
          {/* ))} */}
        </tbody>
      </table>
      <div className="my-3 conv-sation">
        <form>
          {data?.ticketMessage?.map((list) => {
            return (
              <div
                className={
                  list?.user_id ? " user-chat my-3" : "client-chat my-3"
                }
              >
                <p>{list?.message}</p>

                {list?.img_url ? (
                  <div className={container}>
                    <iframe
                      className={iframe_res}
                      width="200"
                      height="205"
                      src={list?.img_url}
                      frameborder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      loading="lazy"
                      allowfullscreen
                    ></iframe>
                  </div>
                ) : null}
              </div>
            );
          })}
          {/* <div className="">
            <p>
              Lorem ipsum dolor sit amet, consr elit, sed do eiusmod tempor
              incidunt ut labore et dolore magna aliqua. Ut enim ad minim
              veniam, quis
            </p>
          </div> */}
          <div>
            <label>Replay To Message</label>
          </div>
          <div class="form-group">
            {/* <label for="exampleFormControlTextarea1">Message</label> */}
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              class="form-control"
              id="exampleFormControlTextarea1"
              rows="3"
            ></textarea>
          </div>{" "}
          <div className="d-flex-al-jb my-2">
            <label>File </label>
            <span className="text-danger">
              max file size 200mb, only zip file is allowed
            </span>
          </div>
          <input
            ref={aRef}
            onChange={(e) => setImage(e.target.files[0])}
            type="file"
            className="form-control"
          />
          <Button onClick={replyMessage} className="my-2">
            Send Message
          </Button>
        </form>
      </div>
    </div>
  );
};
export default SupporDetails;
