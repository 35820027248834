import React from "react";
import { Link } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
const HowRiofinWork = () => {
    return (
        // <div className=" d-flex ">
        //     <Col md={6} xs={12} className="px-2 my-3">
        //         <div className="hover-rio">
        //             <p>Partner on boarding and listing the investment
        //                 opportunity</p>
        //         </div>
        //     </Col>
        //     <Col md={6} xs={12} className="px-2 my-3">
        //         <div className="hover-rio">
        //             <p>Partner on boarding and listing the investment
        //                 opportunity</p>
        //         </div>
        //     </Col>
        //     <Col md={6} xs={12} className="px-2 my-3">
        //         <div className="hover-rio">
        //             <p>Partner on boarding and listing the investment
        //                 opportunity</p>
        //         </div>
        //     </Col>
        //     <Col md={6} xs={12} className="px-2 my-3">
        //         <div className="hover-rio">
        //             <p>Partner on boarding and listing the investment
        //                 opportunity</p>
        //         </div>
        //     </Col>

        // </div>
        <div className="sets_des">


            <div className="part-box   my-4">

                <div className="cir-prj d-flex-al-jc">01</div>
                <div className="my-3">
                    <h5>Step</h5>
                    <p>Sign up to create your account.</p>
                </div>
            </div>
            <div className="part-box   my-4">

                <div className="cir-prj d-flex-al-jc">02</div>
                <div className="my-3">
                    <h5>Step</h5>
                    <p>Enjoy completely AI integrated KYC verification (Instant KYC Verification)</p>
                </div>
            </div>
            <div className="part-box   my-4">

                <div className="cir-prj d-flex-al-jc">03</div>
                <div className="my-3">
                    <h5>Step</h5>
                    <p>Start Exploring Exclusive opportunities and Invest.</p>
                </div>
            </div>
        </div>
    );
};

export default HowRiofinWork;