import React from "react";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LoginImage, LoginBg, FavIcon } from "../assets/img";
const LoginLeft = () => {
    return (

        // <div className="log-img" >
        //     <Image src={LoginBg} className="login-bg" />
        //     <div className="login-ab">
        //         <Image src={LoginImage} />

        //     </div>
        // </div>

        <div className="login-bg mb-4">
            <Image className="log_fav" src={FavIcon} />
            <Image className="" src={LoginImage} />
        </div>


    );
};
export default LoginLeft;

