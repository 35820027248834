import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { KYCImag } from "../assets/img";
const CompleteKyc = () => {
  return (
    <div className="comple-kyc d-fle-al w-90">
      <Col md={6} className="px-2 text-center">
        <Image src={KYCImag} className="w-80" />
      </Col>
      <Col md={6} className="px-2 text-center">
        <h5>Complete the KYC form</h5>
        <Link className="login-btn mt-3" to="/profile">
          View KYC Form
        </Link>
      </Col>
    </div>
  );
};
export default CompleteKyc;
