import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import NoInvestment from "../component/NoInvestment";
import OngoingInvest from "../component/OngoingInvest";
import CompletedInvest from "../component/CompletedInvest";
import Manualinvest from "../component/Manualinvest";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Completed, Ongoing, Manual } from "../assets/img";
import ErrorHandler from "../services/Constant/ErrorHandler";
import investmentServices from "../services/service/investment";
import { json } from "react-router-dom";

const MyInvestment = () => {
  const [ongoing, setOngoing] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [manual, setManual] = useState([]);
  const [manualPayments, setManualPayments] = useState([]);

  const getData = () => {
    investmentServices
      .myInvestment()
      .then((res) => {
        // console.log("myInvestment", res);
        setOngoing(res.data?.my_investment_ongoing);
        setCompleted(res.data?.my_investment_completed);
        setManual(res.data?.my_investment_completed);

        let temp = [];

        res.data?.manual_pay_my_investments?.map((list) => {
          temp.push({
            id: list?.id,
            project_details: JSON.parse(list.project_details),
            project: list.project,
            img_url: list?.img_url,
            status: list?.status,
            date: list?.created_at,
          });
        });
        setManualPayments(temp);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="my-invest">
      <TopHeading type="myinvestment" />
      <div className="w-90 my-3">
        <Tab.Container defaultActiveKey="manual">
          <div className=" my-3">
            <Nav variant="pills" className="invest-type">
              <Nav.Item>
                <Nav.Link eventKey="manual" className="d-flex-al-jb">
                  <Image src={Manual} className="me-2" />
                  Manual Payments
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ongoing" className="d-flex-al-jb">
                  <Image src={Ongoing} className="me-2" />
                  On Going Projects
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="completed" className="d-flex-al-jb">
                  <Image src={Completed} className="me-2" />
                  Completed Projects
                </Nav.Link>
              </Nav.Item>
            </Nav>

            <Tab.Content>
              <Tab.Pane eventKey="manual">
                {/* <NoInvestment /> */}
                <Manualinvest
                  fetch={getData}
                  manual={manualPayments}
                  ongoing={ongoing}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="ongoing">
                {/* <NoInvestment /> */}
                <OngoingInvest
                  fetch={getData}
                  manual={manualPayments}
                  ongoing={ongoing}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="completed">
                {/* <NoInvestment /> */}
                <CompletedInvest completed={completed} />
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </div>
    </div>
  );
};
export default MyInvestment;
