import { URL } from "../Constant/URL";
import httpClient from "../Constant/httpClient";

const userUpdate = (data) => {
  return httpClient.post(URL.PROFILE_UPDATE, data);
};

const bankDetails = (data) => {
  return httpClient.post(URL.BANK_UPDATE, data);
};

const verifyKyc = (data) => {
  return httpClient.post(URL.KYC_VERIFY, data);
};

const KycView = () => {
  return httpClient.get(URL.KYC_VIEW);
};

const notifications_msg = () => {
  return httpClient.get(URL.NOTIFICATIONS);
};

const notificationsClear = () => {
  return httpClient.get(URL.NOTIFICATIONS_CLEAR);
};

const notificationsStatus = () => {
  return httpClient.get(URL.NOTIFICATIONS_STATUS);
};

const userProfileServices = {
  userUpdate,
  bankDetails,
  verifyKyc,
  KycView,
  notifications_msg,
  notificationsClear,
  notificationsStatus,
};

export default userProfileServices;
