import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel";
import { Col, Image, Row } from "react-bootstrap";
import { Banner, Apple, Apps } from "../assets/img";
import { init } from "ityped";
import { render } from "react-dom";
import Typed from "react-typed";
import ErrorHandler from "../services/Constant/ErrorHandler";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import frontendService from "../services/service/frontEnd";
import { homebg, righthomeimg } from "../assets/img/FEimg";

const BannerCarousels = () => {
  const [banners, setBanners] = useState([]);

  const bannersData = () => {
    frontendService
      .bannersList()
      .then((res) => {
        setBanners(res.data.banners);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    bannersData();
  }, []);

  return (
    <div className="caro-sel">
      {/* <Carousel slide={false} >
        {banners.map((list, index) => {
          return (
            <Carousel.Item key={index} className="hv-100">
              <Image src={list?.img_url} className="w-100" />
              <Image src={Banner} className="w-100 h-100 object-cover" />

              <Carousel.Caption>
                <div className="ban-cap">

                  <h5>MORE SECURITY, MORE PROFITS</h5>
                  <h6>
                    Surf the World of

                    <Typed
                      strings={[
                        'High yields',
                        'High Security',
                        'On-time Payments','Complete Transparency ']}
                      typeSpeed={40}
                      backSpeed={100}
                      attr="placeholder"
                      loop >
                      <input type="text" />
                    </Typed>
                  </h6>
                  <Link to="/" className="btn-web my-3">
                    Enroll now
                  </Link>
                </div>
              </Carousel.Caption>
            </Carousel.Item>
          );
        })}
      </Carousel> */}

      <div className="hom_ban h-90">
        <div className="w-100 h-100"
        >
          <Image src={homebg} className="w-100 h-100" />
        </div>
        <div className="hom_bancap carousel-captions">
          <div className="ban-cap">
            <Row className="cont-img w-100 h-100 d-flex justify-content-center align-items-center"
            >
              <Col className="mb-5">
                <div className="mb-5">
                  <h5 className="text-start ps-5 font-size-cust " style={{
                    // padding: '6px 15px'
                  }}>INVESTMENT CURATED FOR NEW AGE INVESTORS</h5>
                  {/* <h5>Investment Curated For New Age Invastors</h5> */}
                  <div className="">
                    {/* <h6> Surf the World of</h6> */}
                    <h6 className="text-start ps-5 text-dark text-shadow">
                      {/* Surf the World of */}
                      Outperforming Traditional
                      Investments Like FDs and Gold,
                      While Earning a Fixed Annual
                      Return Of 18%.
                      <Typed
                        strings={[
                          "High yields",
                          "High Security",
                          "On-time Payments",
                          "Complete Transparency",
                        ]}
                        typeSpeed={40}
                        backSpeed={100}
                        attr="placeholder"
                        loop
                        style={{ padding: '0 0' }}
                      >
                        <input type="text" disabled={true} />
                      </Typed>
                    </h6>
                    {/* <Carousel fade className="my-2">
                <Carousel.Item interval={3000}>
                  <Carousel.Caption>

                    <h6>
                      <span>High Yields</span>
                    </h6>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <Carousel.Caption>
                    <h6>
                      <span>High Security</span>
                    </h6>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <Carousel.Caption>
                    <h6>
                      <span>On-time Payments</span>
                    </h6>
                  </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item interval={3000}>
                  <Carousel.Caption>
                    <h6>
                      <span>Complete Transparency</span>
                    </h6>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel> */}
                  </div>
                  <Link to="/ourdeals" className="btn-web my-3 ms-3 text-start ms-5"
                  >
                    View Opportunities
                  </Link>
                </div>
              </Col>
              <Col className="col-custom">
                <div className="cont-img w-100 h-100 d-flex justify-content-center align-items-center mb-5"
                >
                  <Image src={righthomeimg} className="h-90 w-90 object-fit-cover" />
                </div>
              </Col>
            </Row>
            <div className="logo-bang position-absolute pb-3">
              <Link
                to="https://apps.apple.com/in/app/riofin/id6447463863"
                className="px-2 "
              >
                <Image src={Apple} className="" />
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=com.riofin"
                className="px-2   "
              >
                <Image src={Apps} className="" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerCarousels;
