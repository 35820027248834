import React, { useState, useEffect } from "react";
import dashboardServices from "../services/service/dashboardServices";
import moment from "moment/moment";
const review = [
  {
    projectname: "Heavy Vehicle Leasing",
    category: "Leasing",
    mininvest: "₹1,00,000",
    date: "20/12/2022",
    totalamount: "₹1,00,000",
  },
  {
    projectname: "Ambattur Industrial Estate",
    category: "Real Esate",
    mininvest: "₹1,00,000",
    date: "20/12/2022",
    totalamount: "₹1,00,000",
  },
  {
    projectname: "Heavy Vehicle Leasing",
    category: "Leasing",
    mininvest: "₹1,00,000",
    date: "20/12/2022",
    totalamount: "₹1,00,000",
  },
  {
    projectname: "Ambattur Industrial Estate",
    category: "Real Esate",
    mininvest: "₹1,00,000",
    date: "20/12/2022",
    totalamount: "₹1,00,000",
  },
];
const OverviewTable = () => {
  const [view, setView] = useState([]);
  const [list, setList] = useState([]);
  const [investReview] = useState(review);

  // OVERVIEW  INVESTMENT

  const overViewList = () => {
    const details = [];
    let param = {
      status: 1,
    };
    dashboardServices.overView({ params: param }).then((res) => {
      let temp = res.data?.leasing_investments.slice(-2);
      let temps = res.data?.real_estate_investments.slice(-2);

      temp?.map((i) => {
        details.push(i);
      });

      temps?.map((i) => {
        details.push(i);
      });

      let rever = details.reverse();
      setView(rever);
      const data = [];
      rever?.map((i) => {
        i.project?.map((p) => {
          data.push(p);
        });
      });
      setList(data?.slice(-4));
    });
  };

  useEffect(() => {
    overViewList();
  }, []);

  return (
    <table className="my-3 project-view">
      <thead>
        <tr>
          <th>Project Name</th>
          <th>Category</th>
          <th>Min Investment</th>
          <th>Due date</th>
          <th>Total Amount</th>
        </tr>
      </thead>
      <tbody>
        {list?.map((lists, index) => (
          <tr key={index}>
            <td>{lists?.title}</td>
            <td>{lists?.type == 1 ? "Lease" : "Real Estate"}</td>
            <td>{Math.round(lists?.min_value)}</td>
            <td>{moment(lists?.project_deadline).format("DD/MM/YYYY")}</td>
            <td>{Math.round(lists?.total_value)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
export default OverviewTable;
