import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Image } from "react-bootstrap";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import dashboardServices from "../services/service/dashboardServices";
import DOMPurify from "dompurify";
import usePermissions from "../services/Storage/user";

const InvestScroll = () => {
  const { user } = usePermissions();
  // console.log("data", data);

  const options = {
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1000: {
        items: 3,
      },
    },
  };

  const [deal, setDeal] = useState();

  // OUR DEALS

  const deals = () => {
    let param = {
      status: 1,
    };
    dashboardServices.overView({ params: param }).then((res) => {
      let temp = [];
      res.data?.leasing_investments.map((l) => temp.push(l));
      res.data?.real_estate_investments.map((r) => temp.push(r));
      setDeal(temp);
    });
  };

  // console.log("res", deal);

  useEffect(() => {
    deals();
  }, []);

  return (

    <div className="custom-index">
      <OwlCarousel className="owl-theme" loop margin={10} nav {...options}>
        {deal?.map((list, index) => {
          return (
            <div key={index} className="item">
              <div className="inve-box">
                <div className="inv_oh">
                  <Image src={list?.img_url} className="w-100" />
                </div>
                <div className="d-flex-al-jb my-3">
                  <table>
                    <thead>
                      <tr style={{ color: "rgb(10, 63, 137);" }}>
                        <td>
                          {list?.type == 1
                            ? "Pre Tax ROI"
                            : "Pre Tax ROI Monthly"}
                        </td>
                        <td>Tenure</td>
                        <td>Minimum Investment</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr key={index}>
                        <th>
                          {list?.type == 1
                            ? list?.project[0]?.tax_percentage ||
                            list?.project[0]?.interest_percentage
                            : list?.project[0]?.monthly_percentage}{" "}
                          %
                        </th>
                        <th>{list?.project[0]?.tenure}</th>
                        <th>{list?.project[0]?.min_value}</th>
                      </tr>
                    </tbody>
                  </table>
                  {user == null ? (
                    <Link className="btn-web  mt-3" to="/login">
                      Invest Now
                    </Link>
                  ) : (
                    <Link className="btn-web  mt-3" to="/">
                      Invest Now
                    </Link>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </OwlCarousel>
    </div>
  );
};

export default InvestScroll;
