import React from "react";
import { Image, Col } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import RaiseExpand from "../component/RaiseExpand";
import { SucRasise } from "../assets/img";
const SuccesRaise = () => {
    return (
        <div className="raise-cap">
            <TopHeading type="raisecapital" />
            <div className="my-4 w-90 d-flex-al suc-he">
                <Col md={6} xs={12} className="px-3 my-2">
                    <RaiseExpand />
                </Col>
                <Col md={6} xs={12} className="px-3 my-2">
                    <div className="raise-box text-center">
                        <Image src={SucRasise} className="w-50" />
                        <h6>Successfully Applied!</h6>
                        <p>Our team member will connect with you soon.</p>
                    </div>


                </Col>

            </div>
        </div>
    );
};
export default SuccesRaise;