import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import ProjectDetails from "../component/ProjectDetails";
import LeasPaymentDetails from "../component/LeasPaymentDetails";
import { useLocation } from "react-router";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";

const LeasDetails = () => {
  const [data, setData] = useState([]);

  const location = useLocation();
  // console.log("location-investmentServices", location);
  const viewInvestment = () => {
    if (location?.state?.type) {
      investmentServices
        .manualPaymentView(location?.state?.id)
        .then((res) => {
          // console.log("type", res.data.manual_pay_my_investment[0]);
          let list = res.data.manual_pay_my_investment[0];
          // console.log("list", list);
          let temp = [];
          temp.push({
            id: list?.id,
            post_tax: list?.post_tax,
            project_details: JSON.parse(list?.project_details),
            project_detailsPayment: list?.user_investment_monthly_payment,
            project: list?.project,
            img_url: list?.img_url,
            status: list?.status,
            date: list?.created_at,
            transaction_id: list?.transaction_id,
            user_transaction_no: list?.user_transaction_no,
          });
          // console.log("temp", temp[0]);
          setData(temp[0]);
        })
        .catch((e) => ErrorHandler(e));
    } else {
      investmentServices
        .myInvestmentView(location?.state?.id)
        .then((res) => {
          // console.log("myInvestmentView", res.data.my_investment[0]);
          // let list = res.data.my_investment[0];
          // let temp = [];
          // temp.push({
          //   id: list?.id,
          //   project_details: list?.project_details,
          //   project: list?.project,
          //   img_url: list?.img_url,
          //   status: list?.status,
          //   date: list?.created_at,
          //   transaction_id: list?.transaction_id,
          // });
          // setData(temp[0]);
          setData(res.data.my_investment[0]);
        })
        .catch((e) => ErrorHandler(e));
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      setTimeout(() => {
        viewInvestment();
      }, 600);
    }
  }, []);

  return (
    <div className="inve-view">
      <TopHeading type="ongoingreal" />
      <div className="d-flex w-90">
        <Col lg={8} xs={12} className="px-2  my-3">
          <ProjectDetails data={data} />
        </Col>
        <Col lg={4} xs={12} className="px-2 my-3">
          <LeasPaymentDetails data={data} />
        </Col>
      </div>
    </div>
  );
};
export default LeasDetails;
