import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import {
  Log,
  Photo,
  Profi,
  ProfileLine,
  RaiseIc,
  Referico,
  Sup,
} from "../assets/img";
import ErrorHandler from "../services/Constant/ErrorHandler";
import LoginServices from "../services/service/login";
import usePermissions from "../services/Storage/user";

const ProfileDetails = ({ data, image, name }) => {
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const { user } = usePermissions();
  const navigate = useNavigate();

  const handleImageUpload = (e) => {
    const [file] = e.target.files;

    image(e.target.files[0]);
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const logout = () => {
    LoginServices.logout()
      .then((res) => {
        // toast.success(res.data.message);

        localStorage.clear();
        navigate("/home");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => ErrorHandler(e));
  };

  return (
    <div className="my-2 profile-box">
      <div className="prof-imag">
        <Image
          ref={uploadedImage}
          src={user?.user_detail?.img_url ? user?.user_detail?.img_url : Profi}
          className="prf-img"
        />
        <input
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
          ref={imageUploader}
          className="d-none"
        />
        <Image
          style={{ cursor: "pointer" }}
          src={Photo}
          onClick={() => imageUploader.current.click()}
          className="prf-abs"
        />
      </div>
      <div className="profile-list my-3">
        <h5>{name || user?.name}</h5>
        <Link href="#">{user?.email}</Link>
        <div className="my-3 text-start">
          <ul>
            <li>
              <Link to="/raisecapital">
                <Image src={RaiseIc} className="me-2" />
                Raise Capital
              </Link>
            </li>
            <li>
              {" "}
              <Link to="/supporticket">
                <Image src={Sup} className="me-2" />
                Support Ticket
              </Link>
            </li>
            {/* <li>
              {" "}
              <Link to="/referearn">
                <Image src={Referico} className="me-2" />
                Refer & Earn
              </Link>
            </li> */}
            <li>
              <Link to="/termscondition">
                <Image src={ProfileLine} className="me-2" />
                Terms & Condition
              </Link>
            </li>
            <li>
              <Link onClick={logout}>
                <Image src={Log} className="me-2" />
                Signout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ProfileDetails;
