import React from "react";
import { Col, Image } from "react-bootstrap";
import { WorkTrans, WorkAna, WorkRep, WorkSeam } from "../assets/img";
const Riowork = () => {
  return (
    <div className=" d-flex ">
      <Col md={6} xs={12} className="px-3 my-3">
        <div className="rio-box">
          <div className="cir-design my-2">
            <Image src={WorkAna} />
          </div>
          <h6>Detailed Analysis</h6>
          <p>
            Unique investment opportunities qualified through rigorous due
            diligence.
          </p>
        </div>
      </Col>
      <Col md={6} xs={12} className="px-3 my-3">
        <div className="rio-box">
          <div className="cir-design my-2">
            <Image src={WorkTrans} />
          </div>
          <h6>Complete Transparency</h6>
          <p>
            Disclosure of all aspects of each investment, terms of lease and
            potential risks.
          </p>
        </div>
      </Col>
      <Col md={6} xs={12} className="px-3 my-3">
        <div className="rio-box">
          <div className="cir-design my-2">
            <Image src={WorkRep} />
          </div>
          <h6>Reputed Co-Investors</h6>
          <p>
            Reputed investors invest about 30% of the total investment amount
            for each asset.
          </p>
        </div>
      </Col>
      <Col md={6} xs={12} className="px-3 my-3">
        <div className="rio-box">
          <div className="cir-design my-2">
            <Image src={WorkSeam} />
          </div>
          <h6>Seamless Process</h6>
          <p>
            Investment identification, execution and management delivered at
            your fingertips by our expert advisors.
          </p>
        </div>
      </Col>
    </div>
  );
};

export default Riowork;
