import React from "react";
import { Col, Image } from "react-bootstrap"; 
import { TopBan } from "../assets/img";
const TopBanner = () => {
    return (
        <div className="baner-page">
            <Image src={TopBan} /> 
             
        </div>
    );
};
export default TopBanner;