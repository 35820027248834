import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import supportServices from "../services/service/support";
import ErrorHandler from "../services/Constant/ErrorHandler";
import PNotify_1 from "pnotify/dist/es/PNotify";
import { toast, Toaster } from "react-hot-toast";

const TicketModal = ({ getData }) => {
  const [department, setDepartment] = useState([]);
  const [ticketModal, setTicketModal] = useState(false);
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    supportServices
      .departmentList()
      .then((res) => {
        setDepartment(res.data.ticket_departments);
      })
      .catch((e) => ErrorHandler(e));
  }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const addTicket = () => {
    let formdata = new FormData();

    formdata.append("title", inputs?.title);
    formdata.append("subject", inputs?.subject);
    formdata.append("priority", inputs?.priority);
    formdata.append("ticket_department_id", inputs?.ticket_department_id);
    formdata.append("description", inputs?.description);

    if (
      inputs?.title &&
      inputs?.subject &&
      inputs?.priority &&
      inputs?.ticket_department_id &&
      inputs?.description
    ) {
      supportServices
        .addTicket(formdata)
        .then((res) => {
          getData();
          toast.success(res.data.message);
          setTicketModal(false);
          setInputs({});
        })
        .catch((e) => ErrorHandler(e));
    } else if (!inputs?.title) {
      toast.error("Please Enter Title");
    } else if (!inputs?.subject) {
      toast.error("Please Enter subject");
    } else if (!inputs?.priority) {
      toast.error("Please Select priority");
    } else if (!inputs?.ticket_department_id) {
      toast.error("Please Select department");
    } else if (!inputs?.description) {
      toast.error("Please Enter description");
    }
  };
  return (
    <div className="mt-3 con-btn">
      <Toaster position="top-right" reverseOrder={false} />

      <Button className="my-3" onClick={() => setTicketModal(true)}>
        {" "}
        New Ticket
      </Button>
      <Modal
        className="payment-modal"
        show={ticketModal}
        size="md"
        centered
        onHide={() => setTicketModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="sup-modal">
          <h5>Create New Support Ticket</h5>
          <Form>
            <Form.Control
              onChange={handleChange}
              value={inputs?.title}
              type="text"
              name="title"
              placeholder="Title"
            />
            <Form.Control
              onChange={handleChange}
              value={inputs?.subject}
              type="text"
              name="subject"
              placeholder="Subject"
            />
            <Form.Select
              onChange={handleChange}
              value={inputs?.priority}
              name="priority"
            >
              <option value="">Priorioty </option>
              <option value="Low">Low</option>
              <option value="High">High</option>
              <option value="Medium">Medium</option>
            </Form.Select>
            <Form.Select
              onChange={handleChange}
              value={inputs?.ticket_department_id}
              name="ticket_department_id"
            >
              <option value="">select Deperment </option>
              {department.map((list) => (
                <option value={list.value}>{list.label}</option>
              ))}
              {/* <option value="1">Login Issue</option>
              <option value="2">Kyc</option>
              <option value="3">Aadhar</option> */}
            </Form.Select>
            <Form.Control
              onChange={handleChange}
              value={inputs?.description}
              name="description"
              as="textarea"
              placeholder="Description"
            />
          </Form>
          <Button onClick={addTicket} aria-label="close">
            Submit Ticket
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default TicketModal;
