import React, { useEffect, useState } from "react";
import { Col } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import ProjectDetails from "../component/ProjectDetails";
import RealPaymentDetails from "../component/RealPaymentDetails";
import investmentServices from "../services/service/investment";
import { useLocation } from "react-router";
import ErrorHandler from "../services/Constant/ErrorHandler";

const RealDetails = () => {
  const [data, setData] = useState([]);

  const location = useLocation();

  const viewInvestment = () => {
    if (location?.state?.type) {
      investmentServices
        .manualPaymentView(location?.state?.id)
        .then((res) => {
          // res.data?.manual_pay_my_investments?.map((list) => {
          //   temp.push({
          //     id: list?.id,
          //     project_details: JSON.parse(list.project_details),
          //     project: list.project,
          //     tenure:list.tenure
          //   });
          // });

          let list = res.data.manual_pay_my_investment[0];
          let temp = [];
          temp.push({
            id: list?.id,
            post_tax: list?.post_tax,
            project_details: JSON.parse(list?.project_details),
            project: list?.project,
            img_url: list?.img_url,
            status: list?.status,
            date: list?.created_at,
            transaction_id: list?.transaction_id,
          });

          // console.log("temp", temp);

          setData(temp[0]);
        })
        .catch((e) => ErrorHandler(e));
    } else {
      investmentServices
        .myInvestmentView(location?.state?.id)
        .then((res) => {
          setData(res.data.my_investment[0]);
        })
        .catch((e) => ErrorHandler(e));
    }
  };

  useEffect(() => {
    if (location?.state?.id) {
      viewInvestment();
    }
  }, []);

  return (
    <div className="inve-view">
      <TopHeading type="ongoingreal" />
      <div className="d-flex w-90">
        <Col lg={8} xs={12} className="px-2  my-3">
          <ProjectDetails data={data} />
        </Col>
        <Col lg={4} xs={12} className="px-2 my-3">
          <RealPaymentDetails data={data} />
        </Col>
      </div>
    </div>
  );
};
export default RealDetails;
