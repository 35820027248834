import React, { useEffect, useState } from "react";
import { Triangle } from "react-loader-spinner";
import ReactRoutes from "./routes";
import httpClient from "./services/Constant/httpClient";
import { UserContext } from "./services/Context/userContext";
import useToken from "./services/Storage/useToken";
import disableScroll from "disable-scroll";

const App = () => {
  const { token, setToken } = useToken();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const paymentSessionId = "your payment session id";
  // const cashfree = new cashfree(paymentSessionId);

  function render() {
    if (paymentSessionId == "") {
      alert("No session_id specified");
      return;
    }
    const cf = new global.Cashfree(paymentSessionId);
    cf.redirect();
  }

  useEffect(() => {
    // cashfrees.redirect();
    // render();
  }, []);

  const style = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  if (token) {
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || "";
  }

  useEffect(() => {
    httpClient.interceptors.request.use(
      function (config) {
        // spinning start to show
        setLoading(true);
        // disableScroll.on();

        return config;
      },
      function (error) {
        setLoading(false);
        // disableScroll.off();
        return Promise.reject(error);
      }
    );

    httpClient.interceptors.response.use(
      function (response) {
        // spinning hide
        setTimeout(() => {
          setLoading(false);
          // disableScroll.off();
        }, 2000);
        return response;
      },
      function (error) {
        setLoading(false);
        // disableScroll.off();

        return Promise.reject(error);
      }
    );
  }, []);

  return (
    <React.Fragment>
      <Triangle
        height={100}
        width={100}
        radius={5}
        color="#4fa94d"
        ariaLabel="ball-triangle-loading"
        wrapperClass={{}}
        wrapperStyle={style}
        visible={loading}
      />

      <UserContext.Provider value={{ user, setUser }}>
        <ReactRoutes />
      </UserContext.Provider>
    </React.Fragment>
  );
};

export default App;
