import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";

import {
  Blog,
  Instagram,
  Twitter,
  Facebook,
  Youtube,
  Linkedin,
  Log,
} from "../assets/img";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import TopBanner from "../Frontendcomponent/TopBanner";
import Footer from "../Frontendcomponent/Footer";
import frontendService from "../services/service/frontEnd";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { useEffect } from "react";
import { useState } from "react";
import DOMPurify from "dompurify";
import { GrNext } from "react-icons/gr";
import moment from "moment/moment";
const BlogDetails = () => {
  // const location = useLocation();

  const { state } = useLocation();
  // console.log("state", state);

  const [data, setData] = useState();
  const [blogId, setBlogId] = useState();
  const [blogList, setBlogList] = useState();
  const [Images, setImage] = useState();
  // console.log("bog-details", data);
  // console.log("blog-list", blogList);

  const DATE_OPTIONS = {
    // weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // {new Date(
  //   list?.enrolment_deadline
  // ).toLocaleDateString("en-US", DATE_OPTIONS)}
  const list = () => {
    let temp = [];
    frontendService
      .blogList()
      .then((res) => {
        // console.log("list-detail", res);
        res?.data?.blogs?.map((item) => temp.push(item.id));
      })
      .catch((e) => ErrorHandler(e));
    temp = temp.reverse();
    setBlogList(temp);
  };
  // created_at

  const blogdata = (id) => {
    frontendService
      .blogView(id)
      .then((res) => {
        setData(res.data?.blog);
        setImage(res.data?.blog.img_url);
        // console.log("res", res.data.blog);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    list();
    if (state) {
      blogdata(state);
      setBlogId(state);
    }
  }, [state]);

  const nextBlogHandler = () => {
    // console.log("blog-list", blogList);
    // console.log("blog-leng", blogList.length - 1);
    // console.log("current_blog_id", blogId);
    let next_blog_id = blogList.indexOf(blogId);
    // console.log("next-blog-id", next_blog_id);
    blogList.length - 1 === next_blog_id
      ? (next_blog_id = 0)
      : (next_blog_id = +next_blog_id + 1);
    next_blog_id = blogList[next_blog_id];
    // console.log("next-blog-id", next_blog_id);
    if (!next_blog_id) {
      next_blog_id = state;
    }
    setBlogId(next_blog_id);
    blogdata(next_blog_id);
  };

  return (
    <div className="blog-page margin-t">
      <div className="top-bans">
        <TopBanner />
        <FrontHeading type="blogdetails" />
      </div>
      <div className="my-4 w-90  d-flex">
        <Col md={12} xs={12} className="px-2 my-2">
          <div className="blog-details">
            <h5>{data?.heading}</h5>
            <div className="d-flex">
              <Col lg={6} sm={6} xs={12} className="px-3 my-4 ">
                <Image src={Images} className="w-100" />
              </Col>
              <Col lg={6} sm={6} xs={12} className="d-flex flex-column">
                <div className="d-flex justify-content-between my-md-4">
                  <div>
                    <h6 className="blue-text ps-md-5 ms-md-3">Author</h6>
                    <p className="ps-md-5 ms-md-3">{data?.author}</p>
                  </div>
                  <div>
                    <h6 className="blue-text ps-md-5 ms-md-3">Read</h6>
                    <p className="ps-md-5 ms-md-3">{data?.duration}</p>
                  </div>
                  <h6 className="blue-text ">
                    {new Date(data?.created_at).toLocaleDateString(
                      "en-US",
                      DATE_OPTIONS
                    )}
                  </h6>
                </div>
                <div className="d-flex mt-auto justify-content-between">
                  <div className="qu-linkd text-md-center ">
                    <h5>Social Media</h5>
                    <ul className="d-flex-al-jc social-med-blog">
                      <li className="mx-2">
                        <a
                          href="https://www.facebook.com/RiofinAssets/"
                          target="_blank"
                        >
                          <Image src={Facebook} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/riofinassets/"
                          target="_blank"
                        >
                          <Image src={Instagram} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/RiofinAssets"
                          target="_blank"
                        >
                          <Image src={Twitter} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.youtube.com/channel/UClMfNTpdgFqJrDTxcxJR6zA"
                          target="_blank"
                        >
                          <Image src={Youtube} />
                        </a>
                      </li>
                      <li>
                        {" "}
                        <a
                          href="https://www.linkedin.com/posts/riofin-assets-659207243_who-investment-investmentproperty-activity-6950342840002473984-yT2x"
                          target="_blank"
                        >
                          <Image src={Linkedin} />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="d-flex-al-jc">
                    <p className="blog-post-text" onClick={nextBlogHandler}>
                      Next post
                      <span className="ps-2">
                        <GrNext />
                      </span>
                    </p>
                  </div>
                </div>
              </Col>
            </div>

            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(data?.description),
              }}
            ></p>

            {/* <ul>
              <li>
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. enim
                ad minim veniam, quis nostrud exercitation.{" "}
              </li>
              <li>
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. enim
                ad minim veniam, quis nostrud exercitation.{" "}
              </li>
              <li>
                {" "}
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. enim
                ad minim veniam, quis nostrud exercitation.{" "}
              </li>
            </ul> */}
          </div>
        </Col>
        {/* <Col md={4} xs={12} className="px-3 my-2 ">
          <div className="po-sta">
            <h5>Recent Posts</h5>
            <div className="rec-tab d-fle-al my-3">
              <Image src={Blog} />
              <div className="">
                <p>17 June 2021</p>
                <Link to="/blogdetails">
                  The Most Successful Investment with Riofin
                </Link>
              </div>
            </div>
            <div className="rec-tab d-fle-al my-3">
              <Image src={Blog} />
              <div className="">
                <p>17 June 2021</p>
                <Link to="/blogdetails">
                  The Most Successful Investment with Riofin
                </Link>
              </div>
            </div>
          </div>
        </Col> */}
        {/* <div className="rec-tab d-fle-al my-3">
          {/* <Image src={Blog} /> 
          <div className="">
            <p>
              <b style={{ color: "#FF4500" }}>
                Published on {moment(data?.created_at).format("DD-MM-YYYY")}
              </b>
            </p>
            <p style={{ color: "#FF4500", fontSize: "15px" }}>
              The Most Successful Investment with Riofin
            </p>
          </div>
        </div> */}
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default BlogDetails;
