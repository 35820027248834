import React from "react";
import { Col, Image } from "react-bootstrap";
import { GenerFaqimag, RealFaqimag, LeasFaqimag, Notepad } from "../assets/img";
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import ProgressBar from 'react-bootstrap/ProgressBar';
import GeneraFaq from '../Frontendcomponent/GeneraFaq';
import LeasFaq from '../Frontendcomponent/LeasFaq';
import RealFaq from '../Frontendcomponent/RealFaq';
const HelpCenter = () => {
    return (
        <div className="help-deal">
            <div className="w-90 ">
                <div className="d-flex-al help-ceny my-2"><Image src={Notepad} /><h5>Help Center</h5></div>
                {/* <p>Our Organisation offers innovative investment options which provides 4-5X higher returns than Fixed Deposits. Our paramount objective is to serve the nation and for its people to prosper</p> */}
            </div>

            <Tab.Container defaultActiveKey="generfaq">
                <div className="my-4 w-90 d-flex faq-secc">
                    <Col md={5} xs={12} className="px-3 my-2 faq-pro">
                        <div className="progra-line"></div>
                        <Nav variant="pills" className="gener-tabs flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="generfaq" className="d-flex-al">
                                    <Col className="box-des" xd={10}>
                                        <Image src={GenerFaqimag} className="me-4" />General FAQ's
                                    </Col>
                                    <Col className="pro-des px-3" xs={2} >
                                        <ProgressBar now={100} />
                                    </Col>

                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="realfaq" className="d-flex-al">
                                    <Col className="box-des" xs={10}>
                                        <Image src={RealFaqimag} className="me-4" />Real-Estate FAQ's
                                    </Col>
                                    <Col className="pro-des px-3" xs={2} >
                                        <ProgressBar now={100} />
                                    </Col>

                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="leasfaq" className="d-flex-al">
                                    <Col className="box-des" xs={10}>
                                        <Image src={LeasFaqimag} className="me-4" />Leasing FAQ's
                                    </Col>
                                    <Col className="pro-des px-3" xs={2} >
                                        <ProgressBar now={100} />
                                    </Col>

                                </Nav.Link>
                            </Nav.Item>
                        </Nav>

                    </Col>
                    <Col md={7} xs={12} className="px-3 my-2 ">
                        <Tab.Content>
                            <Tab.Pane eventKey="generfaq">
                                <GeneraFaq />
                            </Tab.Pane>
                            <Tab.Pane eventKey="realfaq">
                                <RealFaq />
                            </Tab.Pane>
                            <Tab.Pane eventKey="leasfaq">
                                <LeasFaq />
                            </Tab.Pane>

                        </Tab.Content>
                    </Col>

                </div>

            </Tab.Container>






        </div>
    );
};
export default HelpCenter;