import React from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Avis } from "../assets/img";
import { saveAs } from "file-saver";
import useToken from "../services/Storage/useToken";
import axios from "axios";
import moment from "moment/moment";

const CompletedInvest = ({ completed }) => {
  const imageclick = () => {
    let url = "/static/media/document.40cc17328763004eb4bd.jpg";
    saveAs(url, "document.jpg");
  };

  const { token } = useToken();

  const downloadPdfFile = async (id) => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      "Content-Type": "blob",
      Authorization: `Bearer ${useToken}`,
    };
    const config = {
      method: "get",
      url: `https://app-script.riofinassets.com/public/index.php/crm/invoice-download/${id}`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);

      const outputFilename = `invoice.pdf`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  return (
    <div className="invest-det d-flex my-3">
      {completed?.map((list) => {
        return (
          <Col className="px-3 my-2" md={6} xs={12}>
            <div className="invest-box">
              <div className="d-flex-al-jb">
                <Image src={list?.project?.img_url} className="inves_img" />

                <Button onClick={() => downloadPdfFile(list?.id)}>
                  Invoice
                </Button>
              </div>
              <div className="my-3">
                <p>{list?.project?.title}</p>

                <div className="d-flex">
                  <Col xs={6} className="px-2">
                    <h6>Invest ID</h6>
                    <h6>Invest type</h6>
                    <h6>Date</h6>
                    <h5>Invested Amount</h5>
                    <h6>Tenure</h6>
                  </Col>
                  <Col xs={6} className="px-2">
                    <h6>#00{list?.id}</h6>
                    <h6>{list?.type == 1 ? "Leasing" : "Real Estate"}</h6>
                    <h6>{moment(list?.created_at).format("DD/MM/YYYY")}</h6>
                    <h5>₹ {list?.invested_amount}</h5>
                    <h6>{list?.tenure} months</h6>
                  </Col>
                </div>
                <Link
                  state={{ id: list?.id }}
                  className="login-btn my-2"
                  to={
                    list?.type == 1
                      ? "/myinvestment/leasdetails"
                      : "/myinvestment/realdetails"
                  }
                >
                  Details
                </Link>
              </div>
            </div>
          </Col>
        );
      })}

      {/* <Col className="px-3 my-2" md={6} xs={12}>
        <div className="invest-box">
          <div className="d-flex-al-jb">
            <Image src={Avis} />
            <Button onClick={imageclick}>Invoice</Button>
          </div>
          <div className="my-3">
            <p>
              The lorem ipsum is a placeholder text used in publishing and
              graphic design.{" "}
            </p>

            <div className="d-flex">
              <Col xs={6} className="px-2">
                <h6>Invest ID</h6>
                <h6>Invest type</h6>
                <h6>Date</h6>
                <h5>Return Amount</h5>
                <h6>Tenure</h6>
              </Col>
              <Col xs={6} className="px-2">
                <h6>#007</h6>
                <h6>Real Estate</h6>
                <h6>15/11/2022</h6>
                <h5>₹ 30,000</h5>
                <h6>36 months</h6>
              </Col>
            </div>
            <Link className="login-btn my-2" to="/myinvestment/realdetails">
              Details
            </Link>
          </div>
        </div>
      </Col>
      <Col className="px-3 my-2" md={6} xs={12}>
        <div className="invest-box">
          <div className="d-flex-al-jb">
            <Image src={Avis} />
            <Button onClick={imageclick}>Invoice</Button>
          </div>
          <div className="my-3">
            <p>
              The lorem ipsum is a placeholder text used in publishing and
              graphic design.{" "}
            </p>

            <div className="d-flex">
              <Col xs={6} className="px-2">
                <h6>Invest ID</h6>
                <h6>Invest type</h6>
                <h6>Date</h6>
                <h5>Return Amount</h5>
                <h6>Tenure</h6>
              </Col>
              <Col xs={6} className="px-2">
                <h6>#007</h6>
                <h6>Leasing</h6>
                <h6>15/11/2022</h6>
                <h5>₹ 30,000</h5>
                <h6>36 months</h6>
              </Col>
            </div>
            <Link className="login-btn my-2" to="/myinvestment/leasdetails">
              Details
            </Link>
          </div>
        </div>
      </Col>
      <Col className="px-3 my-2" md={6} xs={12}>
        <div className="invest-box">
          <div className="d-flex-al-jb">
            <Image src={Avis} />
            <Button onClick={imageclick}>Invoice</Button>
          </div>
          <div className="my-3">
            <p>
              The lorem ipsum is a placeholder text used in publishing and
              graphic design.{" "}
            </p>

            <div className="d-flex">
              <Col xs={6} className="px-2">
                <h6>Invest ID</h6>
                <h6>Invest type</h6>
                <h6>Date</h6>
                <h5>Return Amount</h5>
                <h6>Tenure</h6>
              </Col>
              <Col xs={6} className="px-2">
                <h6>#007</h6>
                <h6>Real Estate</h6>
                <h6>15/11/2022</h6>
                <h5>₹ 30,000</h5>
                <h6>36 months</h6>
              </Col>
            </div>
            <Link className="login-btn my-2" to="/myinvestment/realdetails">
              Details
            </Link>
          </div>
        </div>
      </Col>
      <Col className="px-3 my-2" md={6} xs={12}>
        <div className="invest-box">
          <div className="d-flex-al-jb">
            <Image src={Avis} />
            <Button onClick={imageclick}>Invoice</Button>
          </div>
          <div className="my-3">
            <p>
              The lorem ipsum is a placeholder text used in publishing and
              graphic design.{" "}
            </p>

            <div className="d-flex">
              <Col xs={6} className="px-2">
                <h6>Invest ID</h6>
                <h6>Invest type</h6>
                <h6>Date</h6>
                <h5>Return Amount</h5>
                <h6>Tenure</h6>
              </Col>
              <Col xs={6} className="px-2">
                <h6>#007</h6>
                <h6>Leasing</h6>
                <h6>15/11/2022</h6>
                <h5>₹ 30,000</h5>
                <h6>36 months</h6>
              </Col>
            </div>
            <Link className="login-btn my-2" to="/myinvestment/leasdetails">
              Details
            </Link>
          </div>
        </div>
      </Col> */}
    </div>
  );
};
export default CompletedInvest;
