import React from "react";
import Accordion from "react-bootstrap/Accordion";
const RealFaq = () => {
  return (
    <div className="ac-faq">
      <h5>Real Esate FAQ's</h5>
      <p>
        Our Organisation offers innovative investment options which provides
        4-5X higher returns than Fixed Deposits. Our paramount objective is to
        serve the nation and for its people to prosper
      </p>

      <Accordion>
        <Accordion.Item eventKey="0" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            What is fractional real estate investment?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Fractional ownership in real estate describes an investment process wherein a number of investors join together to invest in a real estate asset so that all of them can benefit from a share of the income that the asset generates and any appreciation in the value of the property.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            Are investors allowed to see the lease related agreements?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Yes. The investors can view all the asset-related documents including the Lease/Rental/Tenancy Agreement/ Deed or Leave & License Agreement uploaded on the investor’s respective dashboards.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            What is the selection criteria for the assets?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Our selection criteria include factors like property value, growth potential, clear title, legal compliance, regulatory compliance, risks factors, etc. However, investors are invited to perform their own due diligence, before making any decision, since every investment decision is prone to some inherent risk factors.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="my-3">
          <div className="acd-before">
            <Accordion.Header>What returns do investors get?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Investors enjoy regular returns, or capital appreciation, or both as mentioned during the investment. The frequency of the returns pay-out i.e., monthly or quarterly are also decided prior to the investment.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="my-3">
          <div className="acd-before">
            <Accordion.Header>What kind of properties are available on RIOFIN?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              RIOFIN offers different kinds of Real Estate Assets like Residential, Commercial. Assets that were previously only accessible to institutional owners are now easily accessible to all potential investors through RIOFIN.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="my-3">
          <div className="acd-before">
            <Accordion.Header>How to track the growth of the investment property?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Our exclusive investor portal can be used for tracking the investment, checking regular updates, accessing transactional info, property documents etc. Depending on the product offering investors have chosen, they can contact the asset manager assigned, for any additional queries.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6" className="my-3">
          <div className="acd-before">
            <Accordion.Header>What types of returns can I expect?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              The returns vary from product to product, typically starting from 12% per annum. The expected IRR and yield will be disclosed prior to investment only to avoid any inconvenience.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7" className="my-3">
          <div className="acd-before">
            <Accordion.Header>How does RIOFIN shortlist properties?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Our team examines various metrics like yield, location, quality of asset, scope of capital appreciation, micro and macro market indicators, legality of title and earning potential. We rely on the extensive experience of our team to grab the best deals available.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8" className="my-3">
          <div className="acd-before">
            <Accordion.Header>How long does RIOFIN Hold these investments for?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Generally, a commercial real estate asset will require 3-5 years to appreciate. While an early exit is possible through our liquidity options, this could adversely affect the total return on your investment.{" "}
            </p>            
             <p>
              {" "}
              Investors can choose to sell investments (after an initial 6 month lock-in period) through RIOFIN’s Resale Market.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
export default RealFaq;
