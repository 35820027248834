import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ErrorHandler from "../services/Constant/ErrorHandler";
import capitalService from "../services/service/capital";
import { toast, Toaster } from "react-hot-toast";
const RaiseForm = () => {
  const [inputs, setInputs] = useState({});
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInputs((values) => ({ ...values, [name]: value }));
  };

  const raiseCapital = () => {
    let formData = new FormData();

    formData.append("name", inputs?.name);
    formData.append("email", inputs?.email);

    formData.append("company_name", inputs?.company_name);
    formData.append("company_website_link", inputs?.company_website_link);

    formData.append("financing_type", inputs?.financing_type);
    formData.append("financing_at", "lease");

    formData.append("stack_percentage", inputs?.stack_percentage);
    formData.append("annual_revenue", inputs?.annual_revenue);
    formData.append("cash_run_away", inputs?.cash_run_away);

    formData.append("hear_about_us", inputs?.hear_about_us);
    formData.append("financing_vc", inputs?.financing_vc);

    if (
      inputs?.name &&
      inputs?.email &&
      inputs?.company_name &&
      inputs?.company_website_link &&
      inputs?.financing_type &&
      inputs?.stack_percentage &&
      inputs?.annual_revenue &&
      inputs?.cash_run_away &&
      inputs?.hear_about_us &&
      inputs?.financing_vc
    ) {
      capitalService
        .capitalAdd(formData)
        .then((res) => {
          toast.success(res.data.message);
          navigate("/raisecapital/succesraise");
        })
        .catch((e) => ErrorHandler(e));
    } else if (!inputs?.name) {
      toast.error("Enter the Name");
    } else if (!inputs?.email) {
      toast.error("Enter the email");
    } else if (!inputs?.company_name) {
      toast.error("Enter the company_name");
    } else if (!inputs?.company_website_link) {
      toast.error("Enter the company_website_link");
    } else if (!inputs?.financing_type) {
      toast.error("select the field");
    } else if (!inputs?.stack_percentage) {
      toast.error("select the field");
    } else if (!inputs?.annual_revenue) {
      toast.error("select the field");
    } else if (!inputs?.cash_run_away) {
      toast.error("select the field");
    } else if (!inputs?.hear_about_us) {
      toast.error("select the field");
    } else if (!inputs?.financing_vc) {
      toast.error("select the field");
    }
  };
  return (
    <div className="for-des">
      <Toaster position="top-right" reverseOrder={false} />
      <h5>Associate With Us</h5>
      <input
        onChange={handleChange}
        value={inputs?.name}
        name="name"
        className="form-control"
        type="name"
        placeholder="Your Name"
      />
      <input
        onChange={handleChange}
        value={inputs?.email}
        name="email"
        className="form-control"
        type="email"
        placeholder="Your Email"
      />
      <input
        onChange={handleChange}
        value={inputs?.company_name}
        name="company_name"
        className="form-control"
        type="type"
        placeholder="Company Legal Name"
      />
      <input
        onChange={handleChange}
        value={inputs?.company_website_link}
        name="company_website_link"
        className="form-control"
        type="type"
        placeholder="Company Website Link"
      />
      <select
        onChange={handleChange}
        value={inputs?.financing_type}
        name="financing_type"
        aria-label="Default"
        className="form-select"
      >
        <option value="">Which Type Of financing Your are looking at</option>
        <option value="Lease financing for movable fixed asset">
          Lease financing for movable fixed asset
        </option>
        <option value="Equity capital">Equity capital</option>
        <option value="Inventory financing or working capital gap financing">
          Inventory financing or working capital gap financing
        </option>
        <option value="Debt/loan">Debt/loan</option>
      </select>
      <select
        onChange={handleChange}
        value={inputs?.stack_percentage}
        name="stack_percentage"
        className="form-select"
      >
        <option value="">O% stake held by VC/PE investors in compony</option>
        <option value="Nil">Nil</option>
        <option value="Up to 10%">Up to 10%</option>
        <option value="11-25%">11-25%</option>
        <option value="26-50%">26-50%</option>
        <option value="More than 51%">More than 51%</option>
      </select>
      <select
        onChange={handleChange}
        value={inputs?.annual_revenue}
        name="annual_revenue"
        aria-label="Default select  "
        className="form-select"
      >
        <option>Annual Revenue Rate</option>
        <option value="Up to 5 INR 5 crores">Up to 5 INR 5 corers</option>
        <option value="INR 5 < ARR =< INR 25 crores">{`INR 5 < ARR <= INR 25 crores`}</option>
        <option value=">INR 25 crores">{`> INR 25 crores`}</option>
      </select>
      <select
        onChange={handleChange}
        value={inputs?.cash_run_away}
        name="cash_run_away"
        aria-label="Default select  "
        className="form-select"
      >
        <option value="">Cash Runway</option>
        <option value="Runaway =< 9 months">{`Runaway =< 9 months`}</option>
        <option value="9 < Runaway  =< 15 months">{`9 < Runaway  =< 15 months`}</option>
        <option value="Runaway > 15 months">{`Runaway > 15 months`}</option>
      </select>
      <select
        onChange={handleChange}
        value={inputs?.hear_about_us}
        name="hear_about_us"
        aria-label="Default select  "
        className="form-select"
      >
        <option value="">Where did you hear about us?</option>
        <option value="Reference">Reference</option>
        <option value="Word mouth">Word mouth</option>
        <option value="Google">Google</option>
        <option value="linked in">linked in</option>
      </select>
      <label className="my-2">
        Which Type Of financing Your are looking at
      </label>
      <div className="d-flex">
        <div className="form-check me-2">
          <input
            value="yes"
            onChange={handleChange}
            name="financing_vc"
            type="radio"
            className="form-check-input"
            id="radio2"
          />
          Yes
        </div>
        <div className="form-check">
          <input
            value="no"
            onChange={handleChange}
            name="financing_vc"
            type="radio"
            className="form-check-input"
            id="radio2"
          />
          No
        </div>
      </div>
      <Link
        className="login-btn my-2"
        //  to="/raisecapital/succesraise"
        to="#"
        onClick={raiseCapital}
      >
        Submit
      </Link>
    </div>
  );
};
export default RaiseForm;
