import React from "react";
import { Col, Image } from "react-bootstrap";
import { Step1, Step2, Step3, Step4, Step5, Step6, Step7 } from "../assets/img";
const HowtoInvest = () => {
    return (

        <div className="d-flex my-3">
            <Col lg={4}   md={6}   xs={12} className="px-3 my-3">
                <div className="how-box">
                    <Image src={Step1} />
                    <h6>Step : 1</h6>
                    <h4>Registration</h4>
                </div>
            </Col>
            <Col lg={4}   md={6}   xs={12} className="px-3 my-3">
                <div className="how-box">
                    <Image src={Step2} />
                    <h6>Step : 2</h6>
                    <h4>KYC Verification</h4>
                </div>
            </Col>
            <Col lg={4}   md={6}   xs={12} className="px-3 my-3">
                <div className="how-box">
                    <Image src={Step3} />
                    <h6>Step : 3</h6>
                    <h4>Choose available
                        investment</h4>
                </div>
            </Col>
            <Col lg={4}   md={6}   xs={12} className="px-3 my-3">
                <div className="how-box">
                    <Image src={Step4} />
                    <h6>Step : 4</h6>
                    <h4>Invest Via Payment Gateway</h4>
                </div>
            </Col>
            <Col lg={4}   md={6}   xs={12} className="px-3 my-3">
                <div className="how-box">
                    <Image src={Step5} />
                    <h6>Step : 5</h6>
                    <h4>Invest Via Payment Gateway</h4>
                </div>
            </Col>
            <Col lg={4}   md={6}   xs={12} className="px-3 my-3">
                <div className="how-box">
                    <Image src={Step6} />
                    <h6>Step : 6</h6>
                    <h4>Leasing machinery to MSME's /
                        Purchasing Commercial (or) Retail Property</h4>
                </div>
            </Col>
            <Col lg={4} md={6}   xs={12} className="px-3 my-3">
                <div className="how-box">
                    <Image src={Step7} />
                    <h6>Step : 7</h6>
                    <h4>Sharing lease rental / profits from Real-Estate</h4>
                </div>
            </Col>
        </div>

    );
};
export default HowtoInvest;