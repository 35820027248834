import httpClient from "../Constant/httpClient";
import { URL } from "../Constant/URL";

const dashboardCount = () => {
  return httpClient.get(URL.DASHBOARD_COUNT);
};

const dashboardAmount = () => {
  return httpClient.get(URL.DASHBOARD_AMOUNT);
};

const overView = (data) => {
  return httpClient.get(URL.INVESTMENT, data);
};

const graph = () => {
  return httpClient.get(URL.DEPARTMENT_GRAPH);
};

const dashboardServices = {
  dashboardCount,
  dashboardAmount,
  overView,
  graph,
};

export default dashboardServices;
