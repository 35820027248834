import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  DashTolam,
  DashTolin,
  DashOnproject,
  DashComproject,
  CirDash,
  CirDashy,
  CirDashb,
  CirDasho,
  Dashearn,
  DashLeas,
  DashLeas1,
  Dashreal,
  PlatForm,
  KYCImag,
} from "../assets/img";
import TopHeading from "../component/TopHeading";
import OverviewTable from "../component/OverviewTable";
import InverstGraph from "../component/InverstGraph";
import CompleteKyc from "../component/CompleteKyc";
import dashboardServices from "../services/service/dashboardServices";
import ErrorHandler from "../services/Constant/ErrorHandler";
import useKyc from "../services/Storage/userKyc";
const Dashboard = () => {
  const { userKyc, setUserKyc } = useKyc();

  // console.log("userKyc", userKyc);

  const [list, setList] = useState();

  const [data, setData] = useState();
  const [graphs, setGraphs] = useState();

  const dashCount = () => {
    dashboardServices
      .dashboardCount()
      .then((res) => {
        setList(res.data);
      })
      .catch((e) => ErrorHandler(e));

    dashboardServices
      .dashboardAmount()
      .then((res) => {
        setData(res.data);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    dashCount();
  }, []);

  return (
    <div className="dash-page  ">
      {/* <Carousels /> */}
      <TopHeading type="dashboard" />
      {userKyc == 2 ? null : <CompleteKyc />}
      <div className="d-flex-al w-90  my-4">
        <Col lg={3} sm={6} md={4} xs={12} className="px-2 mt-2 mb-2">
          <div className="dash-box box-first">
            <div className="icon-box d-flex-al-jc">
              <Image src={DashTolam} />
            </div>
            <div className="count-value ">
              <h5>{Math.round(data?.total_invested_amount)}</h5>
              <p>Total Invested </p>
            </div>
            <Image src={CirDash} className="cir-dash" />
          </div>
        </Col>
        <Col lg={3} sm={6} md={4} xs={12} className="px-2 mt-2 mb-2">
          <div className="dash-box box-two">
            <div className="icon-box d-flex-al-jc">
              <Image src={DashTolin} />
            </div>
            <div className="count-value ">
              <h5>{Math.round(data?.total_accont_value)}</h5>
              <p>Total Income</p>
            </div>
            <Image src={CirDashy} className="cir-dash" />
          </div>
        </Col>
        <Col lg={3} sm={6} md={4} xs={12} className="px-2 mt-2 mb-2">
          <div className="dash-box box-three">
            <div className="icon-box d-flex-al-jc">
              <Image src={DashOnproject} />
            </div>
            <div className="count-value ">
              <h5>
                {parseInt(list?.active_leasing_count) +
                  parseInt(list?.active_real_estate_count)}
              </h5>
              <p>On Going Projects</p>
            </div>
            <Image src={CirDashb} className="cir-dash" />
          </div>
        </Col>
        <Col lg={3} sm={6} md={4} xs={12} className="px-2 mt-2 mb-2">
          <div className="dash-box box-four">
            <div className="icon-box d-flex-al-jc">
              <Image src={DashComproject} />
            </div>
            <div className="count-value ">
              <h5>
                {parseInt(list?.past_leasing_count) +
                  parseInt(list?.past_real_estate_count)}
              </h5>
              <p>Completed Projects</p>
            </div>
            <Image src={CirDasho} className="cir-dash" />
          </div>
        </Col>
      </div>
      <div className="earn-inv w-90 ">
        <h4>Earn more with every investment</h4>
        <h6>
          Build your Future with Investments, Take your Invest Strategy to the
          next level
        </h6>
        <div className="d-flex">
          <Col md={8} xs={12} className="px-2">
            <p>
              Our Organisation offers innovative investment options which
              provides maximum returns than Fixed Deposits. Our paramount
              objective is to serve the nation and for its people to prosper
            </p>
            <h5>View Your Investments</h5>
            <div className="d-flex">
              <Col md={6} xs={12} className="px-2">
                <div className="leas-box mt-3">
                  <div className="d-flex-al my-2">
                    <div className="cir-cle d-flex-al-jc me-3">
                      <Image src={DashLeas1} />
                    </div>
                    <h6 style={{ color: "#0a3f89" }}>Leasing</h6>
                  </div>
                  <p>
                    {/* Leasing is a simple contractual arrangement calling for the
                    lessee (user) to pay the lessor... (owner) regular payments
                    in exchange for use of an asset over an agreed period of
                    time. */}
                    <b>
                      {" "}
                      Invest in Equipment Leasing to the MSME Indurtries to
                      Boost Our Indian Economy & Your Investment Portfolio.
                    </b>
                  </p>
                </div>
              </Col>
              <Col md={6} xs={12} className="px-2">
                <div className="leas-box mt-3">
                  <div className="d-flex-al my-2">
                    <div className="real-box   d-flex-al-jc me-3">
                      <Image src={DashLeas} />
                    </div>
                    <h6 style={{ color: "#0a3f89" }}>Real Estate</h6>
                  </div>
                  <p>
                    {/* Riofin has partnered with leading commercial property
                    fractionalization platforms ("CRE Platforms") to provide
                    investors these investment opportunities at a minimum
                    ,b>investment amount. */}
                    <b>
                      Enjoy Fractional Ownership in Commercial, Residential And
                      Agricultural Real Estate with Best ROI & Property
                      Apperication.
                    </b>
                  </p>
                </div>
              </Col>
            </div>
          </Col>
          <Col md={4} xs={12}>
            <Image src={Dashearn} className="w-100" />
          </Col>
        </div>
        <Link to="/invest" className="login-btn mt-3">
          Invest Now
        </Link>
      </div>
      <div className="over-view w-90">
        <h5>Overview</h5>

        <OverviewTable />
      </div>
      <div className="you-inves w-90 ">
        <h5>Your Investments</h5>
        <InverstGraph />
      </div>
      <div className="gen-info">
        {/* <h5>We are platform for ways to grow your wealth</h5> */}
        <h5>
          RIOFIN ASSETS Diversifies Your Investment To Build Strong Portfolio
        </h5>
        {/* <p>
          We will help them participate confidently in the public markets and
          build generational wealth through the power of compounding.
        </p> */}
        <p>
          "Opportunities Come Infrequently. When It Rains Gold, Put Out The
          Bucket, Not The Thimble." - Warren Buffett
        </p>
        <Image src={PlatForm} className="w-100" />
      </div>
    </div>
  );
};
export default Dashboard;
