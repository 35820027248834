import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { Blog } from "../assets/img";
import OwlCarousel from "react-owl-carousel";
import Button from "react-bootstrap/Button";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import DOMPurify from "dompurify";
import frontendService from "../services/service/frontEnd";
import ErrorHandler from "../services/Constant/ErrorHandler";
const BlogCarousel = () => {
  // { fetch, data, setData }
  const [data, setData] = useState([]);

  const navigate = useNavigate();

  const blogdata = () => {
    frontendService
      .blogList()
      .then((res) => {
        let temp = res.data?.blogs.filter((b) => b.status == 1);
        // console.log("temp", temp);
        setData(temp.reverse());
      })
      .catch((e) => ErrorHandler(e));
  };

  const blogInd = (data) => {
    // console.log("data", data);
    // navigate("/blogdetails");
    navigate("/blogdetails", { state: data });
  };

  useEffect(() => {
    setTimeout(() => {
      blogdata();
    }, 1000);
  }, []);

  const options = {
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: false,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1000: {
        items: 3,
      },
    },
  };

  // console.log("data", data);
  return (
    <div className="custom-index">
      <OwlCarousel className="owl-theme" margin={30} nav {...options}>
        {data?.map((list, index) => (
          <div className="item" key={index}>
            <div className="blog-box box-shadow-custom">
              <Image src={list?.img_url} />
              <div className="blog-ses ">
                <h4>{list?.heading}</h4>
                {/* <h>{list?.id}</h> */}

                {/* <p
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(list?.description.slice(0, -2000)),
                }}
              > */}
                <p
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(list?.description),
                  }}
                >
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. enim
                ad minim veniam, quis nostrud exercitation. */}
                </p>
                {/* <Link className="btn-web  mt-3" to="/blogview"> */}
                {/* <Link className="btn-web  mt-3" onClick={() => blogInd(list?.id)}>
                View More
              </Link> */}
                <Button
                  className="btn-web  mt-3"
                  onClick={() => blogInd(list.id)}
                >
                  View More
                </Button>
              </div>
            </div>
          </div>
        ))}
      </OwlCarousel>
    </div>
  );
};

export default BlogCarousel;
