import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/fontawesome-free-regular";
import { Logo, ProfileImg, UserImg, Logout, Referico } from "../assets/img";
import LoginServices from "../services/service/login";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { UserContext } from "../services/Context/userContext";
import { toast, Toaster } from "react-hot-toast";
import usePermissions from "../services/Storage/user";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import userProfileServices from "../services/service/profile";

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [notify, setNotify] = useState("");

  // const { user } = useContext(UserContext);
  // console.log("user", user);

  const { user } = usePermissions();

  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const notifyStatus = () => {
    userProfileServices.notificationsStatus().then((res) => {
      // console.log("setNotify", res);
      setNotify(res.data);
    });
  };

  useEffect(() => {
    notifyStatus();
  }, []);

  const logout = () => {
    LoginServices.logout()
      .then((res) => {
        toast.success(res.data.message);

        localStorage.clear();
        navigate("/home");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => ErrorHandler(e));
  };

  return (
    <nav className="navbar navbar-expand-lg nav-design   px-md-4 px-2">
      <Toaster position="top-right" reverseOrder={false} />

      <button
        className="navbar-toggler toggle col-6"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#collapsibleNavbar"
      >
        <span className="line-toggle"></span>
        <span className="line-toggle"></span>
        <span className="line-toggle"></span>
      </button>
      <Link className="navbar-brand col-3 col-lg-2 " to="/">
        <Image src={Logo} />
      </Link>

      <div
        className="  collapse navbar-collapse justify-content-center min-nav"
        id="collapsibleNavbar"
      >
        <ul className="navbar-nav nav_a">
          {/* <li className="nav-item ab-dez">
            <Link
              data-bs-toggle="collapse"
              data-bs-target=".navbar-collapse.show"
            >
              <AiOutlineCloseCircle />
            </Link>
          </li> */}

          <li className="nav-item inverst-ment">
            <button
              onClick={() => navigate("/invest")}
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              className={
                splitLocation[1] === "invest"
                  ? "nav-link linking"
                  : "nav-link linking"
              }
            >
              Invest
            </button>
          </li>

          <li className="nav-item inverst-ment">
            <button
              onClick={() => navigate("/myinvestment")}
              data-bs-toggle="collapse"
              data-bs-target="#collapsibleNavbar"
              className={
                splitLocation[1] === "myinvestment"
                  ? "nav-link linking "
                  : "nav-link linking"
              }
            >
              My Investment
            </button>
          </li>

          <li className="nav-item dropdown">
            <a
              className="nav-link dropdown-toggle"
              data-bs-toggle="dropdown"
              href="#"
            >
              Learn
            </a>
            <ul className="dropdown-menu">
              <button
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
                onClick={() => navigate("/frontleasing")}
                className="dropdown-items1 w-100 text-start bg-transparent"
              >
                Product
              </button>{" "}
              <button
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
                onClick={() => navigate("/blog")}
                className="dropdown-items1 w-100 text-start bg-transparent"
              >
                Blog
              </button>
              <button
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
                onClick={() => navigate("/about")}
                className="dropdown-items1 w-100 text-start bg-transparent"
              >
                About Us
              </button>
              <button
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
                onClick={() => navigate("/faq")}
                className="dropdown-items1 w-100 text-start bg-transparent"
              >
                FAQ
              </button>
            </ul>
            {/* <ul className="dropdown-menu">
              <li>
                <Link className="dropdown-item" to="/frontleasing">
                  {" "}
                  Product
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/blog">
                  {" "}
                  Blog
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/about">
                  {" "}
                  About Us
                </Link>
              </li>
              <li>
                <Link className="dropdown-item" to="/faq">
                  {" "}
                  FAQ
                </Link>
              </li>
            </ul> */}
          </li>
          {/* <li className="nav-item">
            <Link
              className={
                splitLocation[1] === "supporticket"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/supporticket"
            >
              All support ticket
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link
              className={
                splitLocation[1] === "raisecapital"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/raisecapital"
            >
              Raise capital
            </Link>
          </li> */}
          {/* <li className="nav-item">
            <Link
              className={
                splitLocation[1] === "referearn"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/referearn"
            >
              Refer & Earn
            </Link>
          </li> */}
        </ul>
      </div>
      <div className="head-user d-flex-al-jb">
        <div className="ref_box ">
          <Link to="/referearn">
            <Image src={Referico} className=" d-md-none d-block" />
            <span className="d-md-block d-none"> Refer & Earn</span>
          </Link>
        </div>
        <Link to="/notification" className="icon-bg ">
          <FontAwesomeIcon icon={faBell} />
          {notify?.read_status === 1 ? (
            <span className="badge badge-pill badge-danger"></span>
          ) : (
            ""
          )}
        </Link>
        <div className="dropdown d-flex-al ">
          <Dropdown>
            <Dropdown.Toggle
              className=" "
              variant="success"
              id="dropdown-basic"
            >
              <Link className="user-bg  ">
                <Image src={user?.user_detail?.img_url} />
              </Link>
              <h3 className="user-name d-md-block d-none">{user?.name}</h3>
            </Dropdown.Toggle>

            <Dropdown.Menu className="drop-img">
              <Dropdown.Item href="#/action-1">
                <Link to="/profile">
                  <li>
                    <Image src={UserImg} /> Profile
                  </li>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item href="#/action-1">
                <Link onClick={logout}>
                  <li>
                    <Image src={Logout} /> Logout
                  </li>
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>

          {/* <Link type="button" className="user-bg ">
            <Image src={ProfileImg} />
          </Link> */}
          {/* <Link>
            <h3 data-bs-toggle="dropdown" className="dropdown-toggle user-name">
              {user?.name}
            </h3>
          </Link> */}
          {/* <ul className="dropdown-menu drop-img">
            <Link className="dropdown-item" to="/profile">
              <li>
                <Image src={UserImg} /> Profile
              </li>
            </Link>
            <li>
              <Link
                onClick={logout}
                className="dropdown-item"
                //  to="/home"
              >
                <Image src={Logout} /> Logout
              </Link>
            </li>
          </ul> */}
        </div>
      </div>
    </nav>
  );
};
export default Navbar;
