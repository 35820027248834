import React from "react";
const TopHeading = (props) => {
  return (
    <div className="top-head my-4">
      {props.type === "dashboard" && <h5>Dashboard</h5>}
      {props.type === "invest" && <h5>Riofin Investment</h5>}
      {props.type === "activeleasview" && <h5>Riofin Investment</h5>}
      {props.type === "activerealview" && <h5>Riofin Investment</h5>}
      {props.type === "pastleasview" && <h5>Riofin Investment</h5>}
      {props.type === "pastrealview" && <h5>Riofin Investment</h5>}
      {props.type === "myinvestment" && <h5>My Investment</h5>}
      {props.type === "ongoinginvest" && <h5>My Investment</h5>}
      {props.type === "completeinvest" && <h5>My Investment</h5>}
      {props.type === "supporticket" && <h5>All Support Ticket</h5>}
      {props.type === "editsupport" && <h5>All Support Ticket</h5>}
      {props.type === "referearn" && <h5>Refer & Earn</h5>}
      {props.type === "raisecapital" && <h5>Raise capital</h5>}
      {props.type === "notification" && <h5>Notification</h5>}
      {props.type === "profile" && <h5>Profile</h5>}
     
       
    </div>
  );
};

export default TopHeading;
