import React, { useContext, useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link, useLocation, uselocation, useNavigate } from "react-router-dom";
import LoginLeft from "../component/LoginLeft";
import { Logo } from "../assets/img";
import PNotify_1 from "pnotify/dist/es/PNotify";
import LoginServices from "../services/service/login";
import ErrorHandler from "../services/Constant/ErrorHandler";
import useToken from "../services/Storage/useToken";
import httpClient from "../services/Constant/httpClient";
import { Toaster, toast } from "react-hot-toast";

import { UserContext } from "../services/Context/userContext";
import usePermissions from "../services/Storage/user";
import useKyc from "../services/Storage/userKyc";

const VerifyOtp = ({ data }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { token, setToken } = useToken();

  const { user, setUser } = usePermissions();
  const { userKyc, setUserKyc } = useKyc();
  // const { user, setUser } = useContext(UserContext);
  const [otp, setOtp] = useState();
  const [timer, setTimer] = useState(45);
  const [value, setValue] = useState(false);

  const verifyUser = localStorage.getItem("USER");

  if (token) {
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || "";
  }

  useEffect(() => {
    if (location?.state?.otp) {
      // toast(location?.state?.otp, {
      //   duration: 5000,
      // });
    }
  }, []);

  const resendOtp = () => {
    LoginServices.login({ email_mobile: location?.state?.user || verifyUser })
      .then((res) => {
        // toast(res?.data?.message, {
        //   duration: 5000,
        // });
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    if (timer === 0) {
      setTimer(null);
      setValue(true);
    } else if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer]);

  const verify_otp = () => {
    let formData = new FormData();

    formData.append("email_mobile", location?.state?.user || verifyUser);
    formData.append("verification_code", otp);

    LoginServices.verifyOtp(formData)
      .then((res) => {
        if (res.data.message == "otp verified successfully") {
          toast.success("OTP verified successfully");
        }
        // toast.success(res.data.message);
        // console.log("res", res.data?.existing_user);
        if (res.data?.token) {
          setTimeout(() => {
            localStorage.setItem("TOKEN", res?.data?.token);
            setUser(res?.data?.existing_user);
            setUserKyc(res?.data?.existing_user?.kyc_verified);
            // setUser(res?.data?.user);
            // data(res?.data?.user);
            navigate("/");
            httpClient.defaults.headers.common["Authorization"] =
              `Bearer ${res?.data?.token}` || "";
            setToken(res?.data?.token);
          }, 1000);
        } else if (res.data.user_type == "new") {
          toast("Please Register Your Account");
          navigate("/register", { state: location?.state?.user || verifyUser });
        }
      })
      .catch((e) => ErrorHandler(e));
  };

  return (
    // <UserContext.Provider value={{ user }}>
    <div className="login-page  ">
      <Toaster position="top-right" reverseOrder={false} />

      {/* <Col xs={12} className="my-3  ">
        <div className="logo-img">
          <Link to="/home">
            <Image className="" src={Logo} />
          </Link>
        </div>
      </Col> */}
      <div className="  login-ses cent ">
        <Col xs={12} md={5}>
          <LoginLeft />
        </Col>
        <Col xs={12} md={5}>
          <div className="login-form ">
            <Link to="/home">
              <Image className="" src={Logo} />
            </Link>
            <h5>OTP Verification</h5>
            <h6>Please Enter Verification Code</h6>
            <form>
              <input
                onFocus={(e) =>
                  e.target.addEventListener(
                    "wheel",
                    function (e) {
                      e.preventDefault();
                    },
                    { passive: false }
                  )
                }
                onChange={(e) => setOtp(e.target.value)}
                className="form-control"
                type="number"
                placeholder="OTP"
              />
              <p className="log-dont text-start">
                I didn't receive the OTP {timer}
                <Link onClick={resendOtp} to="#">
                  {timer ? "" : "Resend"}
                </Link>
              </p>
              <Link
                to="#"
                onClick={verify_otp}
                //  to="/dashboard"

                className="login-btn"
              >
                {" "}
                Proceed{" "}
              </Link>
              {/* <p className="log-dont">
                Don't have an account?
                <Link
                  to="#"
                  // to="/register"
                  className="ps-2"
                >
                  SignUp
                </Link>
              </p> */}
            </form>
          </div>
        </Col>
      </div>
    </div>
    // </UserContext.Provider>
  );
};
export default VerifyOtp;
