import React, { useEffect, useState } from "react";
import TopHeading from "../component/TopHeading";
import { Button, Col, Image, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Refer } from "../assets/img";
import Dropdown from "react-bootstrap/Dropdown";
import ReferStatic from "../component/ReferStatic";
import ReferDetatils from "../component/ReferDetatils";
import { FacebookIcon, WhatsappIcon, TwitterIcon } from "react-share";
import usePermissions from "../services/Storage/user";
import ErrorHandler from "../services/Constant/ErrorHandler";
import referalServices from "../services/service/referal";
import { toast, Toaster } from "react-hot-toast";
import investmentServices from "../services/service/investment";
import { FiCopy } from "react-icons/fi";
import copy from "copy-to-clipboard";

const ReferEarn = () => {
  const { user } = usePermissions();
  const [whatsupModal, setWhatsupModal] = useState(false);
  const [referNumber, setReferNumber] = useState("");
  const [list, setList] = useState();
  const [amount, setAmount] = useState();
  const [email, setEmail] = useState();

  const getData = () => {
    referalServices
      .referalView()
      .then((res) => {
        setList(res.data);
      })
      .catch((e) => ErrorHandler(e));
  };

  // INVESTMENT

  const [ongoing, setOngoing] = useState();
  const [manual, setManual] = useState();

  const invesData = () => {
    investmentServices
      .myInvestment()
      .then((res) => {
        // console.log("myInvestment", res);
        let temp = res.data?.manual_pay_my_investments.filter(
          (i) => i.status == 2
        );
        // console.log("temp", temp);
        setOngoing(res.data?.my_investment_ongoing);
        setManual(temp);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    getData();
    invesData();
  }, []);

  // console.log("list", list);

  // console.log("ongoing", ongoing);

  const registerURL = `🔗 https://riofinassets.com/#/register?referralcode=${user?.referral_code}`;

  const referralCode = `🔑 Referral Code: ${user?.referral_code} .`;

  const referSendHandler = () => {
    if (referNumber !== "" && user?.referral_code != "")
      window.open(
        `https://web.whatsapp.com/send?autoload=1&app_absent=0&phone=${referNumber}&text=
      🎉 Join RIOFIN and Diversify your Investments! 
      
      🚀 Your friend ${user?.name} is enjoying the benefits of RIOFIN's Alternative Investment platform and thinks you'll love it too. Get started now and experience the security and returns of physical assets!
      ${registerURL}


      ${referralCode}Invest in CRE with fractional ownership for maximum profits and portfolio growth. Embrace this golden opportunity for a brighter future and seize it with open arms!

      Happy investing with RIOFIN! 💼💰`
      );
    setTimeout(() => {
      setReferNumber(" ");
    }, 20000);
  };
  // console.log("manual", manual);

  // const amountData = (data) => {
  //   if (data <= 500) {
  //     toast.error("Entered Amount should be Greater Than 500");
  //     setAmount(data);
  //   }
  // };

  //  requset amount

  const requsetAmount = () => {
    // console.log("amount", amount);
    // if (amount <= 499) {
    //   toast.error("Entered Amount should be Greater Than 500");
    // }

    referalServices
      .referalRequest({
        requested_amount: amount,
        // list?.pending_amount
      })
      .then((res) => {
        if (res?.data?.status == "success") {
          toast.success(
            // "Your request has been successfully raised, admin will review the details and you will get an update soon"
            "Your request for  Referral Amount withdrawal is successful"
          );
        }
        // setTimeout(() => {
        //   toast.success(res.data.message);
        // }, 2000);

        setAmount("");
      })
      .catch((e) => {
        toast.error(e.response.data?.message);
      });
  };

  // copy text

  const copytext = () => {
    copy(user?.referral_code);
    toast.success("Text Copied SuccessFully");
  };

  // mail send

  const referalInvite = () => {
    let formdata = new FormData();
    formdata.append("email", email);
    referalServices
      .referalInvite(formdata)
      .then((res) => {
        // console.log(res.data);
        if (res.data.message == "Mail Send Successfully") {
          toast.success(res.data.message);
          setEmail("");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((e) => {
        if (e) {
          // console.log("e", );
          toast.error(e.response.data?.message);
        }
        // ErrorHandler(e);
      });
  };

  return (
    <div className="ref-earn">
      <Toaster position="top-right" reverseOrder={false} />

      <TopHeading type="referearn" />
      <div className="w-90 d-flex-al my-3">
        <Col md={6} xs={12} className="px-3 my-2">
          <Image src={Refer} className="w-100" />
        </Col>
        <Col md={6} xs={12} className="px-3 my-2">
          <h5>Invite Your Friends And Earn Money</h5>
          <div className="ref-box">
            <div className="  my-2">
              {/* <h4>Refer Now</h4> */}
              <div className="ref-side">
                <div className="earn-amt">
                  <h4>Earned Amount</h4>
                  <h6>₹ {list?.earned_amount}</h6>
                </div>
                <div className="earn-amt">
                  <h4>Available Amount</h4>
                  <h6>₹ {list?.pending_amount}</h6>
                </div>
              </div>
              <div className="my-3">
                <h4> Enter Your Amount</h4>
                <div className="d-flex">
                  <div className="ref_box">
                    <input
                      type="number"
                      name="text"
                      value={amount}
                      placeholder="Enter the amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />

                    {ongoing?.length == 0 && manual?.length == 0 ? (
                      <Button
                        onClick={() =>
                          toast("One step away to get your earning")
                        }
                        className=""
                      >
                        Request
                      </Button>
                    ) : (
                      <Button
                        onClick={
                          amount <= 499 && list?.pending_amount < amount
                            ? ""
                            : requsetAmount
                        }
                        className=""
                      >
                        Request
                      </Button>
                    )}
                    {/* <Button
                      onClick={
                        amount <= 499 && list?.pending_amount < amount
                          ? ""
                          : requsetAmount
                      }
                      className=""
                    >
                      Requested
                    </Button> */}
                  </div>
                </div>
                {amount && list?.pending_amount == 0 ? (
                  <span className="text-danger">
                    Your balance is not sufficient
                  </span>
                ) : amount <= 499 ? (
                  <span className="text-danger">
                    Minimum withdrawal is above Rs.500
                  </span>
                ) : list?.pending_amount < amount ? (
                  <span className="text-danger">
                    earn amount must not be below {list?.pending_amount}
                  </span>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="ref-btn my-3">
              <h6>You can earn ₹10,000 and more! </h6>
              <p>
                {/* For withdraw your balance amount from your Vault to your Bank
                account you need request a ticket. */}
                To transfer your referral earnings from your Vault to your Bank
                account simply submit a withdrawal request.
              </p>
              <div className="ref_boxs">
                <input
                  type="text"
                  name=" "
                  className="form-control"
                  placeholder="Enter friend's email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Link className="inv_btn" onClick={referalInvite}>
                  Invite
                </Link>
              </div>
            </div>

            <div className="my-3">
              <h4>Share your referral code</h4>
              <div className="d-flex">
                <div className="ref_box">
                  <input
                    type="text"
                    name="referralCode"
                    disabled
                    value={`RIO${user?.referral_code}`}
                  />
                  <FiCopy style={{ cursor: "pointer" }} onClick={copytext} />
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    onClick={() => setWhatsupModal(!whatsupModal)}
                  >
                    Refer Now
                  </Dropdown.Toggle>

                  {/* <Dropdown.Menu className="mt-3">
                    <Dropdown.Item>Share this link via</Dropdown.Item>
                    <div className="d-flex-al-jb">
                      <Dropdown.Item href="/link">
                        <TwitterIcon size={32} round={true} />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <FacebookIcon size={32} round={true} />
                      </Dropdown.Item>
                      <Dropdown.Item>
                        <WhatsappIcon size={32} round={true} />
                      </Dropdown.Item>
                    </div>
                  </Dropdown.Menu> */}
                </Dropdown>
              </div>
            </div>
          </div>
        </Col>
      </div>
      <ReferStatic />
      <ReferDetatils data={list} />
      <Modal
        size="sm"
        centered
        show={whatsupModal}
        onHide={() => {
          setWhatsupModal(!whatsupModal);
          setReferNumber("");
        }}
      >
        <Modal.Header className="border-0 py-0" closeButton>
          <h6 className="ms-auto">
            Send Refer Code{" "}
            <WhatsappIcon style={{ width: "24px", borderRadius: "5px" }} />
          </h6>
        </Modal.Header>
        <Modal.Body className="py-0">
          <input
            className="form-control"
            value={referNumber}
            onChange={(e) => setReferNumber(e.target.value)}
            placeholder="Please Enter Referer Number"
          />
        </Modal.Body>
        <Modal.Footer className="border-0">
          <Button
            variant="secondary"
            onClick={() => {
              setWhatsupModal(!whatsupModal);
              setReferNumber("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={referSendHandler}>
            Send
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default ReferEarn;
