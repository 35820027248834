import React from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LessLogo } from "../assets/img";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/fontawesome-free-regular";
import DOMPurify from "dompurify";
import InfiniteScroll from "react-infinite-scroll-component";

const ActiveRealEst = ({ realEstate, daatLength, fetch, leasingList }) => {
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  return (
    <Col
      xs={12}
      className="active-leas"
      next={fetch}
      dataLength={5}
      hasMore={daatLength?.current_page <= daatLength?.last_page}
    >
      {realEstate?.map((item, index) => {
        return (
          <>
            <Col sm={12}>
              <div className="leasing-ti">
                <div className="d-flex-al-jb my-2">
                  <h5>{item?.title}</h5>
                  <Link
                    state={{
                      id: item?.id,
                      reallen: realEstate?.length,
                      leaselen: leasingList?.length,
                    }}
                    to="/invest/moreinvest"
                    className="login-btn"
                  >
                    Know More
                  </Link>
                </div>{" "}
                <p
                  className="line-control-paragragh-box"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(item?.description.slice(0, 250)),
                  }}
                ></p>
              </div>
            </Col>
            {/* <div className="d-flex flex-wrap"> */}
            {item?.project?.map((list, index) => {
              return index < 2 ? (
                <Col lg={6} xs={12} className="px-2 my-2" key={index}>
                  <div className="inves-box">
                    <Image src={list.img_url} className="inves_img" />
                    <p
                      className="modernWay"
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(list?.content),
                      }}
                    ></p>
                    <h6>{list?.title}</h6>
                    {/* <div className="d-flex-al-jb"> */}
                    <p>
                      Monthly Return - <span>{list?.monthly_percentage} %</span>
                    </p>
                    <p>
                      Due Date -{" "}
                      <span>
                        {new Date(list?.enrolment_deadline).toLocaleDateString(
                          "en-US",
                          DATE_OPTIONS
                        )}
                      </span>
                    </p>
                    {/* </div> */}
                    <div className="d-flex-al-jb">
                      {/* <div className="due-date">
                          <p>Pre Tax Return</p>
                          <h5>{list?.monthly_percentage} %</h5>
                        </div> */}
                      <div className="due-date">
                        <p>Exp.Return</p>
                        <h5>{list?.interest_range} </h5>
                      </div>
                      <div className="due-date">
                        <p>Tenure</p>
                        <h5>{list?.tenure} months</h5>
                      </div>
                    </div>

                    <ProgressBar now={list?.total_percentage} />
                    <div className="d-flex-al-jb mt-3 compl-leas">
                      {/* <h5>
                          {list?.total_invested_amount} / {list?.total_value}
                        </h5> */}
                      <h5>
                        {list?.total_value == list?.total_invested_amount ? (
                          <span style={{ color: "red", fontSize: "16px" }}>
                            SPV Fully Funded
                          </span>
                        ) : (
                          <h5>
                            {Math.round(list?.total_invested_amount)}
                            {"/"}
                            {Math.round(list?.total_value)}
                          </h5>
                        )}
                      </h5>
                      <h6>{list?.total_percentage} % Completed</h6>
                    </div>
                    <Button>
                      Minimum Investment ₹ {Math.round(list?.min_value)}
                    </Button>
                    <div className="view-detail d-flex-al-jc">
                      <Link
                        state={{ id: list?.id }}
                        to="/invest/activerealview"
                        className="d-flex-al"
                      >
                        View Details{" "}
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                </Col>
              ) : null;
            })}
            {/* </div> */}
          </>
        );
      })}
    </Col>
  );
};
export default ActiveRealEst;
