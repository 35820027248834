import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Avis } from "../assets/img";
import { saveAs } from "file-saver";
import ErrorHandler from "../services/Constant/ErrorHandler";
import investmentServices from "../services/service/investment";
import { toast, Toaster } from "react-hot-toast";
import useToken from "../services/Storage/useToken";
import axios from "axios";
import BlogDetails from "../FrontendRoutes/BlogDetails";
import moment from "moment/moment";

const OngoingInvest = ({ ongoing, manual, fetch }) => {
  const { token } = useToken();
  const [proof, setProof] = useState();
  const [transactionId, setTransactionId] = useState();
  const ref = useRef();
  // console.log("transactionId", transactionId);
  // console.log("manual", manual);

  const fileClick = (e, id, url, status) => {
    if (status == 2 || url) {
      saveAs(url, "document.jpg");
    } else {
      setTransactionId(id);
      ref.current.click();
    }
  };
  // Your proof is rejected. Kindly check and upload the Details Again.

  const imageclick = () => {
    let url = "/static/media/document.40cc17328763004eb4bd.jpg";
    saveAs(url, "document.jpg");
  };

  const downloadInvoice = (id) => {
    investmentServices
      .invoiceDownload(id)
      .then((response) => {
        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = "SamplePDF.pdf";
          alink.click();
        });
      })
      .catch((e) => ErrorHandler(e));
  };

  const downloadPdfFile = async (id) => {
    // Its important to set the 'Content-Type': 'blob' and responseType:'arraybuffer'.
    const headers = {
      "Content-Type": "blob",
      Authorization: `Bearer ${token}`,
    };
    const config = {
      method: "get",
      url: `https://app-script.riofinassets.com/public/index.php/crm/invoice-download/${id}`,
      responseType: "arraybuffer",
      headers,
    };

    try {
      const response = await axios(config);

      const outputFilename = `invoice.pdf`;

      // If you want to download file automatically using link attribute.
      const url = URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", outputFilename);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      throw Error(error);
    }
  };

  const uploadProof = () => {
    investmentServices
      .uploadProof(transactionId, { proof: proof })
      .then((res) => {
        fetch();
        toast.success(res.data.message);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    if (proof) {
      uploadProof();
    }
  }, [proof]);

  return (
    <div className="invest-det d-flex my-3">
      <Toaster position="top-right" reverseOrder={false} />
      {/* {manual?.map((list, index) => {
        return (
          <Col key={index} className="px-3 my-2" md={6} xs={12}>
            <div className="invest-box">
              <div className="d-flex-al-jb">
                <div>
                  <Image src={list?.project?.img_url} className="inves_img" />
                </div>

                <div>
                  <span
                    className={
                      list?.status == 3
                        ? "btn btn-danger"
                        : list?.status == 2
                        ? "btn btn-success"
                        : "btn btn-warning"
                    }
                  >
                    {list?.status == 3
                      ? "rejected"
                      : list?.status == 2
                      ? "verified"
                      : "Pending"}
                  </span>
                </div>
              </div> */}

      {/* <div className="d-flex-al-jb">
                {/* <div className="my-3 d-flex"> 

                {/* </div> 
                <Image src={list?.project?.img_url} className="inves_img" />
                <Button
                  onClick={(e) => {
                    fileClick(e, list?.id, list?.img_url, list?.status);
                  }}
                >
                  {list?.status == 3 || !list?.img_url ? "Upload" : "Proof"}

                  {/* {list?.img_url ? "Proof" : "Upload"} 
                </Button>
                <input
                  onChange={(e) => setProof(e.target.files[0])}
                  ref={ref}
                  type="file"
                  className="d-none"
                />
                {/*
                <Button onClick={() => downloadPdfFile(list?.id)}>
                  Invoice
                </Button> 
                {list?.status == 3 ? (
                  <h6 className="text-danger">
                    Your proof Has been rejected. Kindly check Re upload
                  </h6>
                ) : null}
              </div> */}
      {/* <div className="my-3">
                <p>{list?.project?.title}</p>

                <div className="d-flex">
                  <Col xs={6} className="px-2">
                    <h6>Invest ID</h6>
                    <h6>Invest type</h6>
                    <h6>Date</h6>
                    <h5>Invested Amount</h5>
                    <h6>Tenure</h6>
                  </Col>
                  <Col xs={6} className="px-2">
                    <h6>#00{list?.id}</h6>
                    <h6>
                      {list?.project_details?.type == 1
                        ? "Leasing"
                        : "Real Estate"}
                    </h6>
                    <h6>{moment(list?.date).format("DD/MM/YYYY")}</h6>
                    <h5>{list.project_details?.invested_amount}</h5>
                    <h6>{list?.project_details?.tenure} months</h6>
                  </Col>
                </div>
                <Link
                  state={{ id: list?.id, type: "manual", data: list }}
                  className="login-btn my-2"
                  to={
                    list?.project_details?.type == 1
                      ? "/myinvestment/leasdetails"
                      : "/myinvestment/realdetails"
                  }
                >
                  Details
                </Link>
              </div>
            </div>
          </Col>
        );
      })} */}

      {ongoing?.map((list, index) => {
        return (
          <Col key={index} className="px-3 my-2" md={6} xs={12}>
            <div className="invest-box">
              <div className="d-flex-al-jb">
                <Image src={list?.project?.img_url} className="inves_img" />

                {/* <Button onClick={() => downloadPdfFile(list?.id)}>
                  Invoice
                </Button> */}
                <Link
                  state={{ id: list?.id }}
                  style={{
                    backgroundColor: "green",
                    borderRadius: "7px",
                    height: "40px",
                    width: "90px",
                    textAlign: "center",
                    padding: "7px",
                  }}
                  to={
                    list?.type == 1
                      ? "/myinvestment/leasdetails"
                      : "/myinvestment/realdetails"
                  }
                >
                  Invoice
                </Link>
              </div>
              <div className="my-3">
                <p>{list?.project?.title}</p>

                <div className="d-flex">
                  <Col xs={6} className="px-2">
                    <h6>Invest ID</h6>
                    <h6>Invest type</h6>
                    <h6>Date</h6>
                    <h5>Invested Amount</h5>
                    <h6>Tenure</h6>
                  </Col>
                  <Col xs={6} className="px-2">
                    <h6>#00{list?.id}</h6>
                    <h6>{list?.type == 1 ? "Leasing" : "Real Estate"}</h6>
                    <h6>{moment(list?.created_at).format("DD/MM/YYYY")}</h6>
                    <h5>₹ {Math.round(list?.invested_amount)}</h5>
                    <h6>{list?.tenure} months</h6>
                  </Col>
                </div>
                <Link
                  state={{ id: list?.id }}
                  className="login-btn my-2"
                  to={
                    list?.type == 1
                      ? "/myinvestment/leasdetails"
                      : "/myinvestment/realdetails"
                  }
                >
                  Details
                </Link>
              </div>
            </div>
          </Col>
        );
      })}
    </div>
  );
};
export default OngoingInvest;
