export const TOKEN = "TOKEN";
export const USER_APPLICATION_ID = "USER_APPLICATION_ID";
export const USER_DETAILS = "USER_DETAILS";
export const USER_KYC = "USER_KYC";

export const validEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
// export const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+\.[a-z]{2,3}$');
export const validPassword = new RegExp("^(?=.*?[A-Za-z])(?=.*?[0-9]).{6,}$");
export const validMobilNo = new RegExp("^[6-9]{1}[0-9]{9}$");
export const validText = new RegExp("^[a-zA-Z ]*$");
export const validAadharNumber = new RegExp("^[0-9]{12}$");
