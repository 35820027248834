import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
import { Riofin } from "../assets/img";
import  InvestScroll from  "../Frontendcomponent/InvestScroll";
import dashboardServices from "../services/service/dashboardServices";
const project = [
  {
    tax: "5.5%",
    month: "30 months",
    amount: "30,000",
  },
];
const InvestProject = () => {
  const [projectDetail] = useState(project);

  const [deal, setDeal] = useState();

  // OUR DEALS

  const deals = () => {
    let param = {
      status: 1,
    };
    dashboardServices.overView({ params: param }).then((res) => {
      let temp = [];
      res.data?.leasing_investments.map((l) => temp.push(l));
      res.data?.real_estate_investments.map((r) => temp.push(r));
      setDeal(temp);
    });
  };

  // console.log("res", deal);

  useEffect(() => {
    deals();
  }, []);

  return (
    <>     
  
               <InvestScroll/> 
              {/* <Col md={4} xs={12} className="px-3 my-3">
                <div className="inve-box">
                  <div className="inv_oh">
                  <Image src={list?.img_url} className="w-100" />
                  </div>
                  <div className="d-flex-al-jb my-3">
                    <span>
                      <b>{list?.type == 1 ? "Leasing" : "Real Estate"}</b>
                    </span>
                    <table>
                      <thead>
                        <tr>
                          <td>{list?.type == 1 ? "Pre" : "Post"} Tax Return</td>
                          <td>Tenure</td>
                          <td>Minimum amount</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={index}>
                          <th>
                            {list?.project[0]?.interest_percentage ||
                              list?.project[0]?.tax_percentage}
                          </th>
                          <th>{list?.project[0]?.tenure}</th>
                          <th>{list?.project[0]?.min_value}</th>
                        </tr>
                      </tbody>
                    </table>
                    <Link className="btn-web  mt-3" to="/login">
                      Invest Now
                    </Link>
                  </div>
                </div>
              </Col> */}
                      </>
                   

   

  );
};
export default InvestProject;

{
  /* <Col md={4} xs={12} className="px-3 my-3">
        <div className="inve-box">
          <Image src={Riofin} className="w-100" />
          <div className="d-flex-al-jb my-3">
            <table>
              <thead>
                <tr>
                  <td>Pre Tax Return</td>
                  <td>Tenure</td>
                  <td>Minimum amount</td>
                </tr>
              </thead>
              <tbody>
                {projectDetail.map((list, index) => (
                  <tr key={index}>
                    <th>{list.tax}</th>
                    <th>{list.month}</th>
                    <th>{list.amount}</th>
                  </tr>
                ))}
              </tbody>
            </table>
            <Link className="btn-web  mt-3" to="/login">
              Invest Now
            </Link>
          </div>
        </div>
      </Col>
      <Col md={4} xs={12} className="px-3 my-3">
        <div className="inve-box">
          <Image src={Riofin} className="w-100" />
          <div className="d-flex-al-jb my-3">
            <table>
              <thead>
                <tr>
                  <td>Pre Tax Return</td>
                  <td>Tenure</td>
                  <td>Minimum amount</td>
                </tr>
              </thead>
              <tbody>
                {projectDetail.map((list, index) => (
                  <tr key={index}>
                    <th>{list.tax}</th>
                    <th>{list.month}</th>
                    <th>{list.amount}</th>
                  </tr>
                ))}
              </tbody>
            </table>
            <Link className="btn-web  mt-3" to="/login">
              Invest Now
            </Link>
          </div>
        </div>
      </Col> */
}
