import React from "react";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import Regulations from "../Frontendcomponent/Regulations";
import HowtoInvest from "../Frontendcomponent/HowtoInvest";
const QuickInvest = () => {
    return (
        <div className="quick-page margin-t">
            <div className="top-bans">
                <TopBanner />
                <FrontHeading type="quickinvest" />
            </div>
            <div className="my-3 how-inv w-90">
                <h5>How to Invest</h5>
                <HowtoInvest />
            </div>
            <div className="my-3 ">
                <Regulations />
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};
export default QuickInvest;