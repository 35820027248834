import React from "react";
import { Link } from "react-router-dom";
import { Image } from "react-bootstrap";
import { UseClient } from "../assets/img";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { useEffect } from "react";
import frontendService from "../services/service/frontEnd";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { useState } from "react";

const Testimonial = () => {
  const [data, setData] = useState([]);

  const testimonialData = () => {
    frontendService
      .testimonialList()
      .then((res) => {
        let temp = res.data?.testimonials.reverse();
        setData(temp);
      })
      .catch((e) => ErrorHandler(e));
  };

  // console.log("data", data);

  useEffect(() => {
    setTimeout(() => {
      testimonialData();
    }, 800);
  }, []);

  const options = {
    responsiveClass: true,
    nav: false,
    dots: false,
    autoplay: true,
    smartSpeed: 1000,
    autoplayTimeout: 2000,
    responsive: {
      0: {
        items: 1,
      },

      600: {
        items: 2,
      },

      1000: {
        items: 3,
      },
    },
  };
  return (
    <div className="owl-car w-90 custom-index">
      {/* <OwlCarousel className="owl-theme" margin={30} nav {...options}> */}
      <OwlCarousel className="owl-theme " loop margin={30} nav {...options}>
        {data.map((list, index) => (
          <div className="item " key={index}>
            <div className="tex-box   box-shadow-custom">
              <div className="my-2 clien-cir ">
                <Image src={list?.img_url} />
              </div>
              <h4>{list?.title}</h4>
              <div className="d-flex-al-jc">
                <h6 className="pe-2">{list?.extra_details}</h6>
                {/* <h6>Chennai</h6> */}
              </div>

              <p>{list?.description}</p>

              {/* <h6>Hyderabed</h6> */}
            </div>
          </div>
        ))}

        {/* <div className="item">
          <div className="tex-box">
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. enim
              ad minim veniam, quis nostrud exercitation.
            </p>
            <div className="my-2 clien-cir ">
              <Image src={UseClient} />
            </div>
            <h4>Er.Jehangird Sayed </h4>
            <h6>Hyderabed</h6>
          </div>
        </div> */}
      </OwlCarousel>
    </div>
  );
};

export default Testimonial;
