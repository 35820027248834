import React from "react";
import { Col, Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import Description from "../component/Description";
import PastInvestNow from "../component/PastInvestNow";
import { LessLogo, InvestBan } from "../assets/img";
import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';

const PastLeasView = () => {

    return (
        <div className="inve-view">
            <TopHeading type="pastleasview" />
            <div className="d-flex w-90">
                <Col lg={8} xs={12} className="px-2  my-3">
                    <div className="inves-head">
                        <h5>Perfectly lift up your portfolio by investing with us in
                            LADCO GALVANIZERS</h5>
                        <Image src={LessLogo} />
                        <div className="inves-baner">
                            <Image src={InvestBan} className="w-100" />
                            <Button>Minimum Investment ₹ 10,473.1
                            </Button>
                        </div>
                        <div className="during-date">
                            <div className=" due-dat my-2">
                                <h6><span>Due Date - </span>12 Nov 2022</h6>
                            </div>
                            <div className="d-flex-al-jb inv-details my-3">
                                <Col md={3}>
                                    <p>Pre Tax Return</p>
                                    <h6>5.5%</h6>
                                </Col>
                                <Col md={3} >
                                    <p>Tenure</p>
                                    <h6>20 months</h6>
                                </Col>
                                <Col md={6}>
                                    <ProgressBar now={100} />
                                    <div className="d-flex-al-jb mt-3 compl-leas">
                                        <h6>1 Cr / 3.3 Cr</h6>
                                        <h4>100% Completed</h4>
                                    </div>
                                </Col>

                            </div>
                        </div>
                        <Description />
                    </div>
                </Col>
                <Col lg={4} xs={12} className="px-2 my-3">
                    <PastInvestNow />
                </Col>
            </div>

        </div>
    );
};
export default PastLeasView;