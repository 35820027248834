import React, { useEffect } from "react";
import { Col, Image, Nav, Row, Tab } from "react-bootstrap";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import GrowthCount from "../Frontendcomponent/GrowthCount";
import BannerCarousels from "../Frontendcomponent/BannerCarousels";
import Riowork from "../Frontendcomponent/Riowork";
import HowRiofinWork from "../Frontendcomponent/HowRiofinWork";
import Testimonial from "../Frontendcomponent/Testimonial";
import ClinetLogo from "../Frontendcomponent/ClinetLogo";
import BlogCarousel from "../Frontendcomponent/BlogCarousel";
import Footer from "../Frontendcomponent/Footer";
import {
  Growth,
  Howriofin,
  Layer,
  Banner,
  Flipping,
  CRE,
  NonMark,
  Fixeds,
  Gifing,
} from "../assets/img";
import frontendService from "../services/service/frontEnd";
import ErrorHandler from "../services/Constant/ErrorHandler";
import Carousel from "react-bootstrap/Carousel";
import { useState } from "react";
// import jQuery,{} from "jquery";
const Home = () => {
  const [data, setData] = useState([]);

  const blogdata = () => {
    frontendService
      .blogList()
      .then((res) => {
        let temp = res.data?.blogs.reverse();
        setData(temp);
      })
      .catch((e) => ErrorHandler(e));
  };






  //   useEffect(() => {
  //     blogdata();
  //   }, []);

  //   console.log(blog);

  // var tabChange = function () {
  //   var tabs = ('.nav-pills > .nav-item');
  //   var active = tabs.filter('.active');
  //   var next = active.next('.nav-item').length ? active.next('.nav-item').find('a') : tabs.filter(':first-child').find('a');

  //   next.tab('show')
  // }
  // var tabCycle = setInterval(tabChange, 1000)

  //   (function () {
  //     ('.nav-pills a').click(function (e) {
  //       e.preventDefault();
  //       clearInterval(tabCycle);
  //       (this).tab('show')
  //       setTimeout(function () {
  //         tabCycle = setInterval(tabChange, 1000)
  //       }, 1000);
  //     });
  //   });
  return (
    <div className="home-page margin-t">
      <FrontHeading type="home" />
      <BannerCarousels />
      {/* <GrowthCount /> */}
      <div className="grow-inve my-3 w-90 d-flex-al">
        <Col md={6} xs={12} className="px-2 my-2">
          <div className="grow-con">
            <h5>GROW YOUR INVESTMENT</h5>
            <p>
              Invest in physical assets such as Equipment, Machineries &
              Properties (CRE & Retail).{" "}
            </p>
            <p>
              Riofin is an investment platform that offers curated investment
              opportunities in lease finance & with a low minimum investment
              amount and fixed returns.
            </p>
          </div>
        </Col>
        <Col md={6} xs={12} className="px-2 my-2">
          <Image src={Growth} className="w-100" />
        </Col>
      </div>

      <div className="rio-works my-3">
        <h6>WHAT IS RIOFIN ASSETS</h6>
        <p>
          Riofin is an investment platform that offers curated investment
          <span>
            {" "}
            opportunities in Machineries / Equipment Leasing & CRE
          </span>{" "}
          <br /> along with Fractional Ownership with a low minimum investment
          amount and fixed returns.
        </p>
        <div className="my-4 d-flex-al-jc w-90 inver_deses">
          {/* <Col md={5} xs={12} className="px-2 ">
            <div className="inves_deta w-90">
              <h5>Earn money, Inyerit and make </h5>
              <ul>
                <li>Non - marked linked</li>
                <li>Fixed predited income</li>
                <li>Best appreciation CRE</li>
                <li>Short - term Property flipping.</li>
              </ul>
            </div>
          </Col>
          <Col md={5} xs={12} className="px-2">
            <Carousel className="carousel-fade">
              <Carousel.Item>
                <Image src={NonMark} className="w-100" />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={Fixeds} className="w-100" />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={CRE} className="w-100" />
              </Carousel.Item>
              <Carousel.Item>
                <Image src={Flipping} className="w-100" />
              </Carousel.Item>
            </Carousel>
          </Col>   */}

          <Tab.Container id="left-tabs-example" defaultActiveKey={'first'} >
            <Col md={3} xs={12}>
              <Nav variant="pills" className="flex-column  nav-tabs">
                <Nav.Item className="rio_li">
                  <Nav.Link className="rio_tab rio_tab2" eventKey="first">
                    Non - marked linked
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="rio_li">
                  <Nav.Link className="rio_tab rio_tab2" eventKey="second">
                    Fixed predited income
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="rio_li">
                  <Nav.Link className="rio_tab rio_tab2" eventKey="third">
                    Best appreciation CRE
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="rio_li">
                  <Nav.Link className="rio_tab rio_tab2" eventKey="fourth">
                    Short - term Property flipping
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col md={9} xs={12}>
              <Tab.Content className="rio_tabc">
                <Tab.Pane eventKey="first" className="rio_tabtext">
                  <Image className="mar_k" src={NonMark} />
                  <p>
                    Offering guaranteed high quality fixed income opportunities
                    that go beyond equities, fixed deposits, real-estate, and
                    gold.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="rio_tabtext">
                  <Image src={Fixeds} />
                  <p>
                    Predictable returns help you plan your financial goals
                    better. Earn returns ranging from 12% to 36%.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className="rio_tabtext">
                  <Image src={CRE} />
                  <p>
                    Commercial real estate (CRE) is the best investment
                    opportunities which provide strong collateral and High
                    income growth.
                  </p>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className="rio_tabtext">
                  <Image src={Flipping} />
                  <p>
                    We Purchase assets with a short holding period with the
                    intent of selling it for a quick profit rather than holding
                    on for long-term.
                  </p>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>


        </div>
      </div>
      <div className="rio-work my-2 w-90">
        {/* <p>WHAT WE PROVIDE</p> */}

        <div className="d-flex-al">
          <Col md={4} xs={12} className="my-2 p-3">
            <h5>RIOFIN WORKS</h5>
            <p>
              We do the heavy-lifting to make investment easy for you.Get access
              to curated, risk-assessed opportunities designed for retail
              investors.
            </p>
          </Col>
          <Col md={8} xs={12} className="my-2 p-3">
            <Riowork />
          </Col>
        </div>
      </div>
      <div className="d-flex-al w-90 my-3">
        <Col xs={12} md={6} className="my-2 px-3">
          <Image src={Gifing} className="w-90" />
        </Col>
        <Col xs={12} md={6} className="my-2 px-3">
          <div className="how-rio">
            <h5>Start Investing in just 1 minute</h5>
            <p>
              Get access to curated, risk-assessed opportunities designed for
              retail investors.
            </p>
            <HowRiofinWork />
          </div>
        </Col>
      </div>
      <div className="testi-monial my-3">
        <h6>TESTIMONIAL</h6>
        <h5>Unconditional Love with Riofin</h5>
        <Testimonial />
      </div>
      {/* <div className="my-5 client-sess d-flex-al w-90">
        <Col xs={12} md={3} className="px-2">
          <h5>OUR CLIENTS</h5>
          // <p>Simple Testimonal with thumbnail image and box shadow.</p> 
        </Col>
        <Col xs={12} md={9} className="px-2">
          <ClinetLogo />
        </Col>
      </div> */}
      <div className="my-4 blog-sess w-90 text-center">
        <h5>Knowledge Center</h5>
        <BlogCarousel fetch={blogdata} data={data} setData={setData} />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default Home;
