import React from "react";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import WhyRealEsate from "../Frontendcomponent/WhyRealEsate";
import { Col, Image, Button } from "react-bootstrap";
import { Tree, Invtree } from "../assets/img";
const FrontRealEstate = () => {
  return (
    <div className="our-deal margin-t">
      {/* <div className="top-bans  ">
                <div className="baner-page">
                    <Image src={Tree} className="w-100" />

                </div>
                <FrontHeading type="frontleasing" />
            </div> */}
      <div className="leas_dest ">
        <div className="d-flex-al w-90">
          <Col xs={12} className="my-5 text-center">
            <h1>
              Real -Estate is the Purest form of Entrepreneurship –Brian Buffini
            </h1>
            <p className="text-center">
              Riofin provides real estate investment in Fractional Ownership
            </p>
            {/* <Button
                            className="btn-web  mt-3"
                            to=""
                        >
                            Explore opportunities
                        </Button> */}
          </Col>
          <Col lg={7} xs={12} className="px-3 my-4">
            <div className="my-2">
              <p>Riofin Assets is an excellent choice for those looking to invest in commercial real estate (CRE), Residential properties & Bank Auction Properties. With a track record of successful investments in properties across various asset classes, Riofin Assets has established itself as a reliable and experienced player in the Overall Real-Estate market.</p>
              <ul className="ps-3">
                <li>1. Commercial Real Estate / Residential Real Estate  ( PROPERTY Flipping & Rental Income)</li>
                <li>2. Auctioned Properties from Banks</li>
                <li>3. Layouts in Metro cities & Developing cities</li>
                <li>
                  4. Construction (Both CRE & Residential)
                </li>
              </ul>
              <h4>Why you have to choose Asset Backed Real Estate?</h4>
              <p>
                Fractional ownership in Real estate is a great way to invest in
                small and enjoy best profits from Bigger assets.
              </p>
              <p>
                Real estate is a distinct asset class that many experts agree
                should be a part of a well-diversified portfolio. This is
                because real estate does not usually closely correlate with
                stocks, bonds, or commodities. Real estate investments can also
                produce income from rents or mortgage payments in addition to
                the potential for capital gains.
              </p>
            </div>
          </Col>
          <Col lg={5} xs={12} className="px-3 my-4">
            <div className="d-flex-al-ja">
              <div className="cou_tree">
                <Image src={Invtree} />
                <div className="coud_leas my-2">
                  <p>EXPECTED ROI </p>
                  <h5>12% - 36% </h5>
                </div>
                <div className="coud_leas1 my-2">
                  <p>TENURE </p>
                  <h5>4 - 48 months </h5>
                </div>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <div className="my-4 w-90">
        <WhyRealEsate />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default FrontRealEstate;
