import React from "react";
import { Col, Image } from "react-bootstrap";
import {
  GenerFaqimag,
  RealFaqimag,
  Notepad,
  Funds,
  OurMis,
  Missipn,
  Non,
  Whowe,
  Whos,
  Secure,
  Transparency,
  Liq,
  Profit,
} from "../assets/img";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import ProgressBar from "react-bootstrap/ProgressBar";
const AboutGoal = () => {
  return (
    <div className="help-deal my-4">
      <div className="w-90 ">
        <div className="d-flex-al help-ceny my-2">
          <Image src={Notepad} />
          <h5>Investment made simple for you</h5>
        </div>
        <p>
          Our Organisation offers innovative investment options which provides
          maximum returns than Fixed Deposits. Our paramount objective is to
          serve the nation and for its people to prosper
        </p>
        <div className=" help-ceny my-2">
          <h4>Strength of our Organization</h4>
        </div>
        <p>
          Directors of RIOFIN Assets having <b>23 plus years of Experience </b>{" "}
          in the field of Equipment Leasing to MSME Industries, Real Estate
          Investments, Bank Auction Property Handling, Finance and Investment
          Planning.
        </p>
      </div>
      <Tab.Container defaultActiveKey="ourmission">
        <div className="my-4 w-90 d-flex faq-secc">
          <Col md={5} xs={12} className="px-3 my-2 faq-pro">
            <div className="progra-line"></div>
            <Nav variant="pills" className="gener-tabs flex-column">
              <Nav.Item>
                <Nav.Link eventKey="ourmission" className="d-flex-al">
                  <Col className="box-des" xd={10}>
                    <Image src={GenerFaqimag} className="me-4" />
                    Our Mission
                  </Col>
                  <Col className="pro-des px-3" xs={2}>
                    <ProgressBar now={100} />
                  </Col>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="ourvission" className="d-flex-al">
                  <Col className="box-des" xs={10}>
                    <Image src={RealFaqimag} className="me-4" />
                    Our Vision
                  </Col>
                  <Col className="pro-des px-3" xs={2}>
                    <ProgressBar now={100} />
                  </Col>
                </Nav.Link>
              </Nav.Item>

              {/* <Nav.Item>
                <Nav.Link eventKey="whatwe" className="d-flex-al">
                  <Col className="box-des" xs={10}>
                    <Image src={Funds} className="me-4" />
                    What We do
                  </Col>
                  <Col className="pro-des px-3" xs={2}>
                    <ProgressBar now={100} />
                  </Col>
                </Nav.Link>
              </Nav.Item> */}
              <Nav.Item>
                <Nav.Link eventKey="whowe" className="d-flex-al">
                  <Col className="box-des" xs={10}>
                    <Image src={Whowe} className="me-4" />
                    What We Do
                  </Col>
                  <Col className="pro-des px-3" xs={2}>
                    <ProgressBar now={100} />
                  </Col>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col md={7} xs={12} className="px-3 my-2 ">
            <Tab.Content>
              <Tab.Pane eventKey="ourmission">
                <div className="our-vis text-center">
                  <Image src={Missipn} className="w-50" />

                  <h5>Our Mission </h5>
                  <p>
                    To usher the rural and semi-urban masses of India towards
                    their financial goals. Instilling a sense of confidence in
                    their minds, enabling them to believe that “no dream is too
                    big, no challenge unconquerable”. Today, as one of the
                    leading wealth building organizations in India, we take
                    pride in ourselves to have touched many such lives.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="ourvission">
                <div className="our-vis text-center">
                  <Image src={OurMis} className="w-50" />
                  <h5>OUR VISION</h5>
                  <p>
                    Empowering thousands of ambitious individuals and
                    enterprises with personalised finance options for a wide
                    range of requirements, aiding their development in diverse
                    ventures thereby facilitating them to scale up in life and
                    live their dreams.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="whatwe">
                <div className="our-vis text-center">
                  <Image src={Non} className="w-50" />
                  <h5>What we do</h5>
                  <p>
                    Riofin is an investment platform that offers curated
                    investment opportunities in lease finance & with a low
                    minimum investment amount and Higher fixed returns to the
                    retail Investors. And to provide best returns by
                    participating in high quality fixed income opportunities
                    that go beyond Traditional investments like STOCKS, Bonds,
                    equities, fixed deposits, and gold.
                  </p>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="whowe">
                <div className="our-vis text-center">
                  <Image src={Whos} className="w-50" />
                  <h5> We made Investment simple for you</h5>
                  <p>
                    Riofin is an investment platform that offers curated
                    investment opportunities in lease finance & with a low
                    minimum investment amount and Higher fixed returns to the
                    retail Investors. And to provide best returns by
                    participating in high quality fixed income opportunities
                    that go beyond Traditional investments like STOCKS, Bonds,
                    equities, fixed deposits, and gold At RIOFIN ASSETS, we are
                    committed to providing our investors with superior
                    risk-adjusted returns while maintaining the highest
                    standards of integrity and transparency. We believe in
                    building long-term partnerships with our investors.
                  </p>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </div>
      </Tab.Container>
      <div className="my-2 d-flex w-90 abouts_iconss">
        <Col md={3} sm={6} xs={12} className="px-2 my-2">
          <div className="box-iocn my-3">
            <Image src={Transparency} className=" " />
          </div>
          <h5>HIGH TRANSPERENCY</h5>
        </Col>
        <Col md={3} sm={6} xs={12} className="px-2 my-2">
          <div className="box-iocn my-3">
            <Image src={Liq} className=" " />
          </div>
          <h5>HIGH LIQUIDITY</h5>
        </Col>
        <Col md={3} sm={6} xs={12} className="px-2 my-2">
          <div className="box-iocn my-3">
            <Image src={Secure} className=" " />
          </div>
          <h5>HIGH SECURITY</h5>
        </Col>
        <Col md={3} sm={6} xs={12} className="px-2 my-2">
          <div className="box-iocn my-3">
            <Image src={Profit} className=" " />
          </div>
          <h5>HIGH PROFITS</h5>
        </Col>
      </div>
      <div className="my-4 w-90 our-team">
        <h5>OUR TEAM</h5>
        <p>
          {" "}
          Our team combines deep industry knowledge with a disciplined
          investment approach to identify and execute on attractive alternative
          investment opportunities.
        </p>
        <h5>We have Dedicated Team for our organizational operations</h5>
        <p>
          {/* Portfolio Management,  */}
          Legal and Compliance , Due Diligence, Finance and Accounting,
          Information Technology, Human Resources, Finance and Accounting &
          Sales and Business Development Team.{" "}
        </p>
        <p>
          Our investment strategy focuses on identifying opportunities in niche
          markets and industries where we can leverage our experience and
          expertise to create value for our investors.{" "}
        </p>
      </div>
    </div>
  );
};
export default AboutGoal;
