import React from "react";
import FrondNavbar from "../navbar/FrondNavbar";
import { Outlet } from "react-router-dom";
const FrontLayout = () => {
    return (
        <div className="front-design">

            <div className="top-navbar">
                <FrondNavbar />
            </div>
            <div className="body-web">

                <Outlet />
            </div>

        </div>
    );
};

export default FrontLayout;
