import React from "react";
import { Image, Col } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import RaiseForm from "../component/RaiseForm";
import RaiseExpand from "../component/RaiseExpand"; 
const RaiseCapital = () => {
    return (
        <div className="raise-cap">
            <TopHeading type="raisecapital" />
            <div className="my-4 w-90 d-flex-al">
                <Col md={6} xs={12} className="px-3 my-2">
                     <RaiseExpand/>
                </Col>
                <Col md={6} xs={12} className="px-3 my-2">
                    <div className="raise-box">
                        <RaiseForm />
                    </div>

                </Col>

            </div>
        </div>
    );
};
export default RaiseCapital;