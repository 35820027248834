import React from "react";
import { Image } from "react-bootstrap";
import { Terms, Coins, Dots } from "../assets/img";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import TopBanner from "../Frontendcomponent/TopBanner";
import Footer from "../Frontendcomponent/Footer";
const TermsCondition = () => {
    return (
        <div className="privact-page margin-t">
            <div className="top-bans">
                <TopBanner />
                <FrontHeading type="termscondition" />
            </div>
            <div className="my-4 w-90   privacy-ses">
                <Image src={Terms} className="pri-img" />
                <h5>Terms of Use</h5>
                <ul className="pad-les">
                    <li className="text-start">
                        <b>1. General</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />This document is an electronic record in terms of Information Technology Act, 2000 and rules made there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                            <li> <Image src={Coins} />This Privacy Policy (“Privacy Policy”) applies to the collection, receipt, storage, usage, processing, disclosure, transfer and protection (“Utilization”) of your Personal Information (defined below) when the user of the Website (“User” or “you”) uses our Website or avails any services offered by Riofin through the Website.</li>
                            <li> <Image src={Coins} />Thank you for visiting [www.riofin.in] (the “Website”). Riofin is registered as RIO GLOBAL FINTECH PRIVATE LIMITED having its registered office address at No.15A, 4th Floor, City Vista, Tower A, Suite No.178, Fountain Road, Kharadi Pune Pune MH 411014 IN (“Riofin”, “we”, “us” or “our”). We are committed to protecting and respecting the privacy of every person who shares information with it or whose information it receives.</li>
                            <li> <Image src={Coins} />https://RIOFIN ASSETSassets.com/ (“Website”) is an internet-based portal/platform licensed to and operated by RIOFIN ASSETS  to provide technology solutions for financial products and alternate investment opportunities. Use of the Website is offered to You conditioned on acceptance of all the terms, conditions and notices contained in these Terms along with any amendments made by RIOFIN ASSETS  at its sole discretion and posted on the Website, including by way of imposing an additional charge for access to or use of a Service.</li>
                            <li> <Image src={Coins} />You agree that RIOFIN ASSETS  may, at any time, modify or discontinue all or part of the Website or the services thereunder. RIOFIN ASSETS  shall not be required to notify You, whether as a registered user or otherwise, of any changes made to the Website or the services thereunder.</li>
                            <li> <Image src={Coins} />RIOFIN ASSETS  may require You to provide Your consent to the Terms of Use in a specified manner prior to any further use of the Website and the Services provided on the Website. If no such separate consent is sought, Your continued use of the Website will constitute Your acceptance of the Terms of Use including those changes made, if any.
                            </li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>2. Services</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />This document is an electronic record in terms of Information Technology Act, 2000 and rules made there under as applicable and the amended provisions pertaining to electronic records in various statutes as amended by the Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</li>
                            <li> <Image src={Coins} />This Privacy Policy (“Privacy Policy”) applies to the collection, receipt, storage, usage, processing, disclosure, transfer and protection (“Utilization”) of your Personal Information (defined below) when the user of the Website (“User” or “you”) uses our Website or avails any services offered by Riofin through the Website.</li>
                            <li> <Image src={Coins} />Thank you for visiting [www.riofin.in] (the “Website”). Riofin is registered as RIO GLOBAL FINTECH PRIVATE LIMITED having its registered office address at No.15A, 4th Floor, City Vista, Tower A, Suite No.178, Fountain Road, Kharadi Pune Pune MH 411014 IN (“Riofin”, “we”, “us” or “our”). We are committed to protecting and respecting the privacy of every person who shares information with it or whose information it receives.</li>
                            <li> <Image src={Coins} /> The Website is an internet based online platform/portal to provide technology solutions for financial products and alternative investment opportunities (“Services”).</li>
                            <li> <Image src={Coins} /> You agree and acknowledge that RIOFIN ASSETS  is merely acting as facilitator of alternative investment opportunities and facilitating alternative investment requirements of the Users registered on the Website AND NOT borrowing or lending nor providing or arranging any credit enhancement or credit guarantee.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>3. Eligibility to Use</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />Any individual, company including a body corporate, LLP (limited liability partnership), partnership firm, trust who is capable/competent to form a binding contract under the laws of India can register on the Website.</li>
                            <li> <Image src={Coins} />The Services are not available to minors under the age of eighteen (18) or to any Users suspended or removed from RIOFIN ASSETS  system by RIOFIN ASSETS  for any reason whatsoever. If You do not conform to the above qualification, You shall not be permitted to avail of the Services or use the Website. Notwithstanding the foregoing, if You are below the age of eighteen (18) years, You may avail the Services provided by RIOFIN ASSETS  through Your legal guardian in accordance with the applicable laws. You agree and acknowledge that We shall, in no event, be liable to verify your age or extent of parental guidance.</li>
                            <li> <Image src={Coins} /> You acknowledge that RIOFIN ASSETS  reserves the right to refuse access to use the Services offered at the Website to new Users or to terminate access granted to existing Users at any time without according any reasons for doing so.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>4. User Account, Password, and Security</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />You shall avail the Services made available by RIOFIN ASSETS  either by creating an Account (“Registered User”) or without an Account (“Free User”). However, You acknowledge that, being a Free User You may not be able to enjoy all the Services as provided by RIOFIN ASSETS .</li>
                            <li> <Image src={Coins} />In the event You wish to become a Registered User, You have to create an Account with RIOFIN ASSETS  by providing certain required User information (“User Account”) through your personal mobile number including valid e-mail ID or any such other information as may be required for valid registration (collectively, the “Account Information”). The Services may also be subject to procedures for use of the Website, rules, additional terms of service, or other disclaimer & notices, if any (“Additional Terms”). If there is any conflict between the Terms of Use and the Additional Terms, the Additional Terms shall take precedence in relation to that service.</li>
                            <li> <Image src={Coins} /> You acknowledge that You will be responsible for maintaining the confidentiality of the Account Information, and are fully responsible for all activities that occur under Your User Account. You agree to (a) immediately notify RIOFIN ASSETS  of any unauthorized use of Your Account Information or any other breach of security, and (b) ensure that You exit from Your User Account at the end of each session. RIOFIN ASSETS  cannot and shall not be liable for any loss or damage arising from Your failure to comply with this Section. Further, You understand that You may be held liable for losses incurred by RIOFIN ASSETS  or any other user of or visitor to the Website due to authorized or unauthorized use of Your Account as a result of Your failure in keeping Your Account Information secure and confidential.</li>
                            <li> <Image src={Coins} /> You shall ensure that the Account Information provided by You in the Website’s registration form is complete, accurate and up-to-date. Use of another user’s Account Information for availing the Services is expressly prohibited.</li>
                            <li> <Image src={Coins} /> If You provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or RIOFIN ASSETS  has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, RIOFIN ASSETS  has the right to suspend or terminate Your User Account and refuse any and all current or future use of the Website (or any portion thereof).</li>

                        </ul>
                    </li>
                    <li className="text-start">
                        <b>5. User Obligations</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />You agree to use the website and Services only for lawful purposes. Further, You agree not to take any action that might compromise the security of the website or the Services, or render the website or Services inaccessible to others or otherwise cause damage to the website or Services.</li>
                            <li> <Image src={Coins} />You agree not to add to, subtract from, or otherwise modify the website or to attempt to access any Services or to use the website or the Services in any manner that might interfere with the rights of third parties. RIOFIN ASSETS  reserves the right to monitor your use of the website as well as your investment activities by use of the Services availed from the website.</li>
                            <li> <Image src={Coins} />3.During the use of the website or Services offered on the website, You shall not post, host, display, upload, modify, publish, transmit, update or share any information that:</li>
                            <li>
                                <ul className="term-cond">
                                    <li>1. Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;</li>
                                    <li>2. Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information through any bookmark, tag or keyword;</li>
                                    <li> <Image src={Dots} /> Publish, post, upload, distribute or disseminate any inappropriate, profane, defamatory, infringing, obscene, indecent or unlawful topic, name, material or information through any bookmark, tag or keyword;</li>
                                    <li>1. Upload files, material or any information in violation of any confidentiality agreement with respect to a third party;</li>
                                    <li>2. Upload or distribute files that contain viruses, corrupted files, or any other similar software or programs that may damage the operation of the Website or another’s computer;</li>
                                    <li>3. Engage in any activity that interferes with or disrupts access to the Website or the Services (or the servers and networks which are connected to the Website);</li>
                                    <li> <Image src={Dots} /> Attempt to gain unauthorized access to any portion or feature of the Website, any other systems or networks connected to the Website, to any of RIOFIN ASSETS ’s server, or to any of the Services offered on or through the Website, by hacking, password mining or any other illegitimate means;</li>
                                    <li> <Image src={Dots} /> Probe, scan or test the vulnerability of the Website or any network connected to the Website, nor breach the security or authentication measures on the Website or any network connected to the Website. You may not reverse look-up, trace or seek to trace any information on any other user, of or visitor to, the Website, or any other customer of RIOFIN ASSETS , including any RIOFIN ASSETS  Account not owned by You, to its source, or exploit the Website or Service or information made available or offered by or through the Website, in any way whether or not the purpose is to reveal any information, including but not limited to personal identification information, other than Your own information, as provided for by the Website;</li>
                                    <li>1. Disrupt or interfere with the security of, or otherwise cause harm to, the Website, systems resources, accounts, passwords, servers or networks connected to or accessible through the Websites/App or any affiliated or linked sites;</li>
                                    <li>2. Collect or store data about other users in connection with the prohibited conduct and activities set forth in this Section.</li>
                                    <li>3. Use the Website or any material or Content for any purpose that is unlawful or prohibited by these Terms of Use, or to solicit the performance of any illegal activity or other activity which infringes the rights of RIOFIN ASSETS  or other third parties;</li>
                                    <li> <Image src={Dots} />Violate any code of conduct or other guidelines or applicable laws in India, which may be applicable for or to any particular Service;</li>
                                    <li> <Image src={Dots} />Violate the Terms of Use including but not limited to the Privacy Policy or any applicable Additional Terms of the Website contained herein or elsewhere;</li>
                                    <li>1. You agree to use the Services, Website and the materials provided therein only for purposes that are permitted by: (a) the Terms of Use; and (b) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdictions.</li>
                                    <li>2. You should take all responsibility for your own actions in utilizing the Services and RIOFIN ASSETS  shall not be liable for any such action.</li>
                                    <li>3. You are solely responsible for the content and information shared by You on the website, specifically the content You post or upload including but not limited to, videos, photographs, images, comments, data, text, other material and information, and associated trademarks and copyrightable works. RIOFIN ASSETS  does not endorse and has no or limited control over such contents. Contents is not necessarily reviewed by RIOFIN ASSETS  prior to posting and does not necessarily reflect the opinions or policies of RIOFIN ASSETS . RIOFIN ASSETS  makes no warranties, express or implied, as to the such contents or to the accuracy, integrity and reliability of such contents or any material or information that You transmit to other Users.</li>
                                    <li>4. You agree to strictly adhere to all limitations on dissemination, use and reproduction of any materials that You access on the Website in accordance with the terms hereof.</li>
                                    <li>5. You agree not to access (or attempt to access) the Website and the materials or Services by any means other than through 5.the interface that is provided by RIOFIN ASSETS . You shall not use any deep-link, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to access, acquire, copy or monitor any portion of the Website or Content (as defined below), or in any way reproduce or circumvent the navigational structure or presentation of the Website, materials or any Content, to obtain or attempt to obtain any materials, documents or information through any means not specifically made available through the Website.</li>
                                    <li>6. You agree that You are solely responsible to RIOFIN ASSETS  and to any third party for any breach of Your obligations under this Terms of Use and for the consequences (including any loss or damage which RIOFIN ASSETS  or its affiliates may suffer) for any such breach.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>6. Use of Materials and Intellectual Property Rights</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} /> The Website and the processes, and their selection and arrangement, including but not limited to all text, design, layout, graphics, user interfaces, visual interfaces, sounds and music (if any), artwork and computer code (collectively, the “Content”) on the Website is licensed to and controlled by RIOFIN ASSETS  and the design, structure, selection, coordination, expression, look and feel and arrangement of such Content is protected by copyright, trademark and various other intellectual property rights. You may not use, download or install any software available at the Website, unless otherwise expressly permitted by these Terms of Use or by the express written permission of RIOFIN ASSETS .</li>
                            <li> <Image src={Coins} /> The trademarks, logos and service marks displayed on the Website (“Marks”) are the property of AI RIO GLOBAL FINTECH PRIVATE LIMITED ( CIN: U74140PN2022PTC211767 )which is licensed to RIOFIN ASSETS . You shall not use the Marks, in any manner whatsoever, without the prior consent of RIOFIN ASSETS .</li>
                            <li> <Image src={Coins} /> Unless otherwise indicated or anything contained to the contrary or any proprietary material owned by a third party and so expressly mentioned, You understand and agree that AI RIO GLOBAL FINTECH PRIVATE LIMITED ( CIN: U74140PN2022PTC211767 )(which is licensed to RIOFIN ASSETS ) owns all intellectual property rights to and into the Website, including, without limitation, any and all rights, title and interest in and to copyright, related rights, patents, utility models, designs, know-how, trade secrets and inventions (patent pending), goodwill, source code, meta tags, databases, text, content, graphics, icons, and hyperlinks.</li>
                            <li> <Image src={Coins} />Except as expressly provided herein, You acknowledge and agree that You shall not use, in any manner whatsoever, any Content through any medium without obtaining the necessary authorization from RIOFIN ASSETS or thirty party owner of such Content. You agree that by use of the Website or by availing the Services You shall not obtain any right or interest on the materials and Content.</li>
                            <li> <Image src={Coins} />You also acknowledge that any violation of the intellectual property rights of RIOFIN ASSETS may expose You to legal proceedings in this regard.
                            </li>
                            <li> <Image src={Coins} />Except as expressly indicated to the contrary in any applicable Additional Terms, RIOFIN ASSETS hereby grants You a non-exclusive, freely revocable (upon notice from RIOFIN ASSETS), non-transferable access to view, download and print the contents or any other materials available on the Website, subject to the following conditions:
                            </li>
                            <li>
                                <ul className="term-cond">
                                    <li>1. You may access and use the materials solely for personal, informational, and internal purposes, in accordance with the Terms of Use;</li>
                                    <li>2. You shall not modify or alter any Content or any other materials available on the Website; and</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>7. Modification in Terms</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />RIOFIN ASSETS reserves the right at all times to discontinue or modify any of the Terms of Use as may be deemed necessary or desirable without prior notification to you. Such changes may include, among other things, the adding of certain fees or charges. The revised Terms of Use shall be made available on the Website. If we make changes to our Terms of Use and you continue to use our Site, you are impliedly agreeing to the Terms of Use and Privacy Policy expressed herein. Any such deletions or modifications shall be effective immediately upon RIOFIN ASSETS’s posting thereof. Any use of website by User after such posting shall be deemed to constitute acceptance by the User of such modifications.</li>
                            <li> <Image src={Coins} />Your use of the Website and the Services is subject to the most current version of the Terms of Use made available on the Website at the time of such use. You are requested to regularly visit the Website view the most current Terms of Use. You can determine when RIOFIN ASSETS last modified the Terms of Use by referring to the “Last Updated” legend herein. It shall be Your responsibility to check these Terms of Use periodically for changes.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>8. Third Party Content</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />The Website makes available general third party information such as, product and service catalogue, advertisements including videos, images and photographs of various products and/or services and other data from external third party sources including web-site links (“Third Party Content”). The provision of Third-Party Content is for general informational or advertisement purposes only. You acknowledge that the Third-Party Content provided to You is obtained from sources believed to be reliable. RIOFIN ASSETS does not provide any guarantee with respect to any the Third-Party Content and RIOFIN ASSETS shall not be held liable for any loss suffered by You based on Your reliance on or use of such data.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>9. Disclaimer of Warranties & Liability</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />You expressly understand and agree that, to the maximum extent permitted by applicable law:</li>
                            <li>
                                <ul className="term-cond">
                                    <li>1. The Website, Services, Content and other materials are provided by RIOFIN ASSETS on an “as is” basis without warranty of any kind, express, implied, statutory or otherwise, including the implied warranties of accuracy, reliability or fitness for a particular purpose.</li>
                                    <li>2. Without limiting the foregoing, RIOFIN ASSETS makes no warranty that (a) the Website or the Services will meet Your requirements or Your use of the Website or the Services will be uninterrupted, timely, secure or error-free; (ii) the results that may be obtained from the use of the Website, Services or materials will be effective, accurate or reliable; (iii) the quality of the Website, Services or other materials will meet your expectations; or that (iv) any errors or defects in the Website, Services or other materials will be corrected. No advice or information, whether oral or written, obtained by You from RIOFIN ASSETS or through or from use of the Services shall create any warranty not expressly stated in the Terms of Use.</li>
                                    <li>3. RIOFIN ASSETS also disclaims all liability with respect to the misuse, loss, modification or unavailability of any user content.</li>
                                    <li>4. Upon registering on the Website, You are responsible for maintaining the confidentiality of Your User Account or Account Information in connection with the Website (identification and password information), and for restricting access to your computer. You agree to accept responsibility for all activities that occur under Your member identification and password and RIOFIN ASSETS will not be liable for any loss that You may incur as a consequence of unauthorized use of Your User Account or Account Information in connection with the Website or any Services or materials, either with or without Your knowledge.</li>
                                    <li>5. RIOFIN ASSETS has endeavored to ensure that all the information on the Website is correct, but RIOFIN ASSETS neither warrants nor makes any representations regarding the quality, accuracy or completeness of any data, information or service.</li>
                                    <li>6. RIOFIN ASSETS shall not be held responsible for non-availability of the Website during periodic maintenance operations or any unplanned suspension of access to the Website that may occur due to technical reasons or for any reason beyond RIOFIN ASSETS’s control.</li>
                                    <li>7. The advertisement available on Website or sent through e-mail to You with respect to the third-party website or the products and services are for information purpose only. RIOFIN ASSETS shall not be liable for such third-party products or service or content.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>10. Indemnification and Limitation of Liability</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />You agree to indemnify, defend and hold harmless RIOFIN ASSETS including but not limited to its affiliates, directors agents and employees from and against any and all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by RIOFIN ASSETS that arise out of, result from, or may be payable by virtue of, any breach or non-performance of any representation, warranty, covenant or agreement made or obligation to be performed by You pursuant to these Terms of Use including the Privacy Policy or any Additional Terms. Further, You agree to hold RIOFIN ASSETS harmless against any claims made by any third party due to, or arising out of, or in connection with, Your use (including misuse) of the Website, any claim that Your material caused damage to a third party, Your violation of the Terms of Use including the Privacy Policy or any Additional Terms, or Your violation of any rights of another.</li>
                            <li><Image src={Coins} />Notwithstanding anything to contrary, RIOFIN ASSETS’s entire liability to You under this Terms of Use including the Privacy Policy or any Additional Terms or otherwise shall be the refund of the money charged from You for any specific Service (if any), under which the unlikely liability arises.</li>
                            <li><Image src={Coins} />In no event shall RIOFIN ASSETS, its officers, directors, employees, partners, suppliers or any other person(s)/entity(ies) associated/connected with it be liable to You for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, breach of contract or warranty, negligence or other tortious action, or any other claim arising out of or in connection with Your use of or access to the Website, Services or materials.</li>
                            <li><Image src={Coins} />The limitations and exclusions in this section apply to the maximum extent permitted by applicable laws in India.</li>

                        </ul>
                    </li>
                    <li className="text-start">
                        <b>11. Violation of the Terms of Use</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />You agree that, without prejudice to any other right/remedy available to RIOFIN ASSETS under law or otherwise, RIOFIN ASSETS may, in its sole discretion and without prior notice, terminate Your access to the Website and block Your future access to the Website if RIOFIN ASSETS determines that You have violated these Terms of Use including Privacy Policy or Additional Terms. You also agree that any violation by You of these Terms of Use including Privacy Policy or Additional Terms will constitute an unlawful and unfair business practice, and will cause irreparable harm to RIOFIN ASSETS, for which monetary damages would be inadequate, and You consent to RIOFIN ASSETS obtaining any injunctive or equitable relief that RIOFIN ASSETS deems necessary or appropriate in such circumstances. These remedies are in addition to any other remedies RIOFIN ASSETS may have at law or in equity.</li>
                            <li><Image src={Coins} />You agree that RIOFIN ASSETS may, in its sole discretion, and without prior notice, terminate Your access to the Website, for cause, which includes (but is not limited to): (1) requests by law enforcement or other government agencies; (2) a request by You (self-initiated account deletions); (3) discontinuance or material modification of the Website or any service offered on or through the Website; (4) unexpected technical issues or problems; (5) on complaint received by any User on any violation of the terms hereunder and if RIOFIN ASSETS is satisfied as to the breach or violation complained or (6) if You breach any of the provisions of the Terms of Use, the Privacy Policy or any other terms, conditions, or policies that may be applicable to You from time to time (or have acted in a manner that clearly shows that You do not intend to, or are unable to, comply with the same). The decision of RIOFIN ASSETS shall be final in all such matters.</li>
                            <li><Image src={Coins} />Except as may be set forth in any Additional Terms applicable to a particular Service, termination of Your Account may include: (i) removal of access to all offerings within the Website or with respect to the Services; (ii) deletion of Your materials and Account Information, including Your personal information, log-in ID and password, and all related information, files and materials associated with or inside Your User Account (or any part thereof); and (iii) barring of further use of the Services.</li>
                            <li><Image src={Coins} />If RIOFIN ASSETS does take any legal action against You as a result of Your violation of these Terms of Use, RIOFIN ASSETS will be entitled to recover from You, and You agree to pay, all reasonable attorneys’ fees and costs of such action, in addition to any other relief granted to RIOFIN ASSETS.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>12. Termination</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />The Terms of Use will continue to apply until terminated by either You or RIOFIN ASSETS as set forth below. If You want to terminate Your agreement with RIOFIN ASSETS, You may do so by (i) not accessing/using the Website; or (ii) closing Your accounts for all of the Services that You use, where RIOFIN ASSETS has made this option available to You.</li>
                            <li><Image src={Coins} />RIOFIN ASSETS may, at any time, with or without notice, terminate the Terms of Use (or portion thereof, such as any individual Additional Terms) if:</li>
                            <li>
                                <ul>
                                    <li>1. RIOFIN ASSETS  is required to do so by law (for example, where the provision of the Services to You is, or becomes, unlawful);</li>
                                    <li>2. The provision of the Services to You by RIOFIN ASSETS is, in RIOFIN ASSETS’s opinion, no longer commercially viable;</li>
                                    <li><Image src={Dots} />RIOFIN ASSETS has elected to discontinue, with or without reason, access to the Website, the Services (or any part thereof); or</li>
                                    <li>1. RIOFIN ASSETS may also terminate or suspend all or a portion of Your User Account or access to the Services with or without reason.</li>
                                    <li>2. You agree that all terminations shall be made at the sole discretion of RIOFIN ASSETS and that RIOFIN ASSETS shall not be liable to You or any third party for any termination of Your Account (and accompanying deletion of Your Account Information), or Your access to the Website and Services.</li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>13. Governing Law</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />These Terms of Use and all transactions entered into on or through the Website and the relationship between You and RIOFIN ASSETS shall be governed in accordance with the laws of India without reference to conflict of laws principles. Courts at Bangalore, India shall have the exclusive jurisdiction over the subject matters of this Terms of Use,</li>
                            <li><Image src={Coins} />You agree that all claims, differences and disputes arising under or in connection with or in relation hereto the Website, the Terms of Use or any transactions entered into on or through the Website or the relationship between You and RIOFIN ASSETS shall be subject to the award of the sole arbitrator to be appointed by the President- Karnataka Arbitration Centre in accordance with its rules and subject to the provisions of the Arbitration and Conciliation Act, 1996 as amended from time to time. The venue of such Arbitration shall be city of Bangalore, India and language of Arbitration shall be English.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>14. Report Abuse</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />In the event You come across any abuse or violation of these Terms of Use including any Privacy Policy or Additional Terms or if You become aware of any objectionable content on the Website, please report to RIOFIN ASSETS customer support.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>15. Privacy Policy</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />The User hereby consents, expresses and agrees that they have read and fully understands the Privacy Policy of RIOFIN ASSETS in respect of the Website. You further consent that the terms and contents of such Privacy Policy are acceptable to You.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>16. Policy on Pricing of Product(s)/Service(s)</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />Registering on our Website is free for users. RIOFIN ASSETS does not charge any fee for browsing its Website, however since we are an alternative investment facilitation platform we charge for investment opportunities facilitated from our platform and pricing of each opportunities listed differs from one to other. All prices are inclusive of applicable taxes and levies, if any. For detailed pricing of currently listed opportunities please explore ‘Opportunities’ tab of our Website.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>17. Policy on Refund & Cancellation</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />RIOFIN ASSETS is an alternative asset ownership and investment awareness platform and as such refunds and cancellations are not applicable. Investing in Third Party Products listed on our platform is Your independent decision and as such You can choose to withdraw from investing: (a) any-time before making payment; or (b) where investment has already been made, post completion of any lock-in period as per the terms of the relevant investment and applicable laws. Your mere browsing of our Website, perusal of Third Party Products listed or showing interest to invest in Third Party Products listed on Website does not create any contractual right/obligation whatsoever. Further, any investment by you in any Third Party Products is directly received by the relevant investee entity and / or the third party, and accordingly such investee entity and or the third party, as the case may be, is solely responsible for any refund, return of investment, or any other payment of a similar nature to be received by you. Just like RIOFIN ASSETS does not collect payment from you for any Third-Party Products, RIOFIN ASSETS has no role to play in any subsequent refund or cancellation. </li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>18. Communications</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />Upon your express consent by way of clicking on the check box provided by RIOFIN ASSETS for this purpose, You expressly agree to receive communications (including promotional materials) by way of telephones, SMS, e-mails from RIOFIN ASSETS relating to any Services provided through the Website.</li>
                        </ul>
                    </li>
                    <li className="text-start">
                        <b>19. General Provisions</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />Notice: All notices to RIOFIN ASSETS shall be served by email or by general notification on the Website. Any notice provided to RIOFIN ASSETS pursuant to the Terms of Use should be sent to RIOFIN ASSETS ’s customer support (https://RIOFINASSETSassets.com/.)</li>
                            <li> <Image src={Coins} />Assignment: You cannot assign or otherwise transfer the Terms of Use, or any rights granted hereunder to any third party. RIOFIN ASSETS ’s rights under the Terms of Use including the Privacy Policy or Additional Terms are freely transferable by RIOFIN ASSETS to any third parties without the requirement of seeking Your consent.</li>
                            <li> <Image src={Coins} />Severability: If, for any reason, a court of competent jurisdiction finds any provision of the Term of Use, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms of Use shall continue in full force and effect.</li>
                            <li> <Image src={Coins} />Waiver: Any failure by RIOFIN ASSETS to enforce or exercise any provision of the Terms of Use, or any related right, shall not constitute a waiver by RIOFIN ASSETS of that provision or right.</li>
                            <li> <Image src={Coins} />Relationship: You shall not be deemed either directly or indirectly to be the employer, franchiser, contractor or principal of RIOFIN ASSETS or of the associates, representatives, staff/employees of RIOFIN ASSETS and these Terms has been executed expressly and solely on a principal-to-principal arrangement.</li>

                        </ul>
                    </li>
                    <li className="text-start">
                        <b>20. Contact Us</b>
                        <ul className="term-cond">
                            <li> <Image src={Coins} />If You have any questions, complaints or comments about the Website and/or Services including the Privacy Policy and other policies or questions, then please contact Us with Our customer service team at support@riofinassets.com</li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="footer">
                <Footer />
            </div>
        </div>
    );
};
export default TermsCondition;