import { useState } from "react";
import { USER_KYC } from "../Constant/Constant";

export default function useKyc() {
  const getuserKyc = () => {
    const userKyc = JSON.parse(localStorage.getItem(USER_KYC));

    return userKyc;
  };

  const [userKyc, setUserKyc] = useState(getuserKyc());

  const saveUserKyc = (userKyc) => {
    localStorage.setItem(USER_KYC, JSON.stringify(userKyc));
    setUserKyc(userKyc);
  };

  return {
    setUserKyc: saveUserKyc,
    userKyc,
  };
}
