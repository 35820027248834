import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import Description from "../component/Description";
import InvestNow from "../component/InvestNow";
import { LessLogo, InvestBan } from "../assets/img";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import investmentServices from "../services/service/investment";
import { useLocation } from "react-router-dom";
import ErrorHandler from "../services/Constant/ErrorHandler";

const ActiveLeasView = () => {
  const [data, setData] = useState();
  const [date, setDate] = useState();

  const location = useLocation();
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  // console.log(date);

  const getData = () => {
    investmentServices
      .investView(location?.state?.id)
      .then((res) => {
        setData(res.data?.investment);

        const date = new Date(res.data?.investment?.enrolment_deadline);

        date.toDateString();
        // console.log(date);

        // setDate(date);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    // if (location?.state?.id) {
    getData();
    // }
  }, []);

  // console.log;

  return (
    <div className="inve-view">
      <TopHeading type="activeleasview" />
      <div className="d-flex w-90">
        <Col lg={8} xs={12} className="px-2  my-3">
          <div className="inves-head">
            <h5>{data?.title}</h5>
            {/* <Image src={LessLogo} /> */}
            <div className="inves-baner">
              <Image src={data?.img_url} className="w-100" />
              <Button>
                Minimum Investment ₹ {Math.round(data?.min_value)}
              </Button>
            </div>
            <div className="during-date">
              <div className="d-flex-al-jb due-dat my-2 ">
                <h6>
                  <span>Due Date - </span>
                  {new Date(data?.enrolment_deadline).toLocaleDateString(
                    "en-US",
                    DATE_OPTIONS
                  )}

                  {/* {data?.enrolment_deadline} */}
                </h6>
                <h6>
                  <span>Exp Return - </span>
                  {data?.interest_range}
                </h6>
              </div>
              <div className="d-flex-al-jb inv-details my-3">
                <Col md={3}>
                  <p>Monthly Pre Tax Return</p>
                  <h6>{data?.monthly_percentage} %</h6>
                </Col>
                <Col md={3}>
                  <p>Tenure</p>
                  <h6>{data?.tenure} months</h6>
                </Col>
                <Col md={6}>
                  <ProgressBar now={data?.total_percentage} />
                  <div className="d-flex-al-jb mt-3 compl-leas">
                    {/* <h6>
                      {" "}
                      {data?.total_invested_amount} / {data?.total_value}
                    </h6>
                    <h4>{data?.total_percentage}% Completed</h4> */}
                    <h6>
                      {data?.total_value == data?.total_invested_amount ? (
                        <span style={{ color: "red", fontSize: "16px" }}>
                          SPV Fully Funded
                        </span>
                      ) : (
                        <h5>
                          {Math.round(data?.total_invested_amount)}
                          {"/"}
                          {Math.round(data?.total_value)}
                        </h5>
                      )}
                    </h6>
                  </div>
                </Col>
              </div>
            </div>
            <Description data={data} />
          </div>
        </Col>
        <Col lg={4} xs={12} className="px-2 my-3">
          <InvestNow data={data} />
        </Col>
      </div>
    </div>
  );
};
export default ActiveLeasView;
