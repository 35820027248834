import httpClient from "../Constant/httpClient";
import { URL } from "../Constant/URL";

const departmentList = () => {
  return httpClient.get(URL.DEPARTMENT_LIST);
};

const addTicket = (data) => {
  return httpClient.post(URL.STORE_TICKET, data);
};

const viewTicket = () => {
  return httpClient.get(URL.ADD_TICKET);
};

const ticketReplyView = (id) => {
  return httpClient.get(URL.TICKET_VIEW + id);
};

const ticketReply = (data) => {
  return httpClient.post(URL.REPLY_TICKET, data);
};

const supportServices = {
  departmentList,
  addTicket,
  viewTicket,
  ticketReply,
  ticketReplyView,
};

export default supportServices;
