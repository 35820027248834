import React from "react";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import InvestProject from "../Frontendcomponent/InvestProject";
const OurDeals = () => {
  return (
    <div className="our-deal margin-t">
      <div className="top-bans">
        <TopBanner />
        <FrontHeading type="ourdeals" />
      </div>
      <div className="my-4 how-inv w-90">
        <h5>View Our Deals</h5>
        <InvestProject />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default OurDeals;
