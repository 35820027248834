import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import Description from "../component/Description";
import InvestNow from "../component/InvestNow";
import { LessLogo, InvestBan } from "../assets/img";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useLocation } from "react-router-dom";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { ToastContainer, toast } from "react-toastify";

const ActiveLeasView = () => {
  const [data, setData] = useState();
  // console.log(
  //   "🚀 ~ file: ActiveLeasView.js:16 ~ ActiveLeasView ~ data,:",
  //   data
  // );
  const [minAmount, setMinAmount] = useState();
  const [edit, setEdit] = useState(false);

  const location = useLocation();
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  const getData = () => {
    investmentServices
      .investView(location?.state?.id)
      .then((res) => {
        setData(res.data.investment);

        let balance_amount =
          parseInt(res.data?.investment?.total_value) -
          parseInt(res.data?.investment?.total_invested_amount);

        if (balance_amount < res.data?.investment?.min_value) {
          setMinAmount(balance_amount);
          setEdit(true);
        } else {
          setMinAmount(res.data?.investment?.min_value);
        }

        // console.log(balance_amount, res.data?.investment?.min_value);
      })
      .catch((e) => ErrorHandler(e));
  };

  // console.log("data", data);

  useEffect(() => {
    if (location?.state?.id) {
      getData();
    }
  }, []);

  return (
    <div className="inve-view">
      <TopHeading type="activeleasview" />
      <ToastContainer />
      <div className="d-flex w-90">
        <Col lg={8} xs={12} className="px-2  my-3">
          <div className="inves-head">
            <h5>{data?.title}</h5>
            {/* <Image src={LessLogo} /> */}
            <div className="inves-baner">
              <Image src={data?.img_url} className="w-100" />
              <Button>Minimum Investment ₹ {Math.round(minAmount)}</Button>
            </div>
            <div className="during-date">
              <div className=" due-dat my-2">
                <h6>
                  <span>Due Date - </span>
                  {/* {data?.enrolment_deadline} */}
                  {new Date(data?.enrolment_deadline).toLocaleDateString(
                    "en-US",
                    DATE_OPTIONS
                  )}
                </h6>
              </div>
              <div className="d-flex-al-jb inv-details my-3">
                <Col md={3}>
                  <p>Pre Tax Return</p>
                  <h6>{data?.interest_percentage} %</h6>
                </Col>
                <Col md={3}>
                  <p>Tenure</p>
                  <h6>{data?.tenure} Months</h6>
                </Col>
                <Col md={6}>
                  <ProgressBar now={data?.total_percentage} />
                  <div className="d-flex-al-jb mt-3 compl-leas">
                    {/* <h6>
                      {data?.total_invested_amount} {"/"} {data?.total_value}
                    </h6> */}
                    <h6>
                      {data?.total_invested_amount == data?.total_value ? (
                        <>
                          <span style={{ color: "red", fontSize: "16px" }}>
                            SPV Fully Funded
                          </span>
                          <h4> {data?.total_percentage} % Completed</h4>
                        </>
                      ) : (
                        <>
                          {Math.round(data?.total_invested_amount)}
                          {"/"}
                          {Math.round(data?.total_value)}
                          <h4> {data?.total_percentage} % Completed</h4>
                          {/* {data?.total_invested_amount}
                          {"/"}
                          {data?.total_value} */}
                          {/* <h4> {data?.total_percentage} % Completed</h4> */}
                        </>
                      )}
                    </h6>
                    {/* <h4> {data?.total_percentage} % Completed</h4> */}
                  </div>
                </Col>
              </div>
            </div>

            <Description data={data} />
          </div>
        </Col>
        <Col lg={4} xs={12} className="px-2 my-3">
          <InvestNow minamount={minAmount} input={edit} data={data} />
        </Col>
      </div>
    </div>
  );
};
export default ActiveLeasView;
