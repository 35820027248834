import React, { useEffect, useState } from "react";
import chatBoxServices from "../services/service/chatBox";
import { Link } from "react-router-dom";
import { Col, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/fontawesome-free-regular";

import {
  Logo,
  FavIcon,
  Instagram,
  Twitter,
  Facebook,
  Email,
  Map,
  Call,
  Apple,
  Apps,
  Digital,
  Youtube,
  Linkedin,
} from "../assets/img";
import axios from "axios";
const Footer = () => {
  useEffect(() => {
    // axios
    //   .post(
    //     "https://app.expressad.in/api/createinstance.php?access_token=ce2a8e1d3256ec1691ec8c56d5bc1d7e"
    //   )
    //   .then((res) => {
    //     console.log("chat-ins_id-res", res);
    //   });
  }, []);
  return (
    <div className="foot-sess">
      <div className="d-flex  fot-page w-90 mt-3">
        <Col lg={4} sm={8} xs={12} className="px-2 my-4 ">
          <Link to="/home">
            <Image src={Logo} className="logo-img" />
          </Link>

          {/* <script>
            {(function (w, d) {
              w.CollectId = "63ee3c3fbc9f372ada3e0cd2";
              var h = d.head || d.getElementsByTagName("head")[0];
              var s = d.createElement("script");
              s.setAttribute("type", "text/javascript");
              s.async = true;
              s.setAttribute("src", "https://collectcdn.com/launcher.js");
              h.appendChild(s);
            })(window, document)}
            ;
          </script> */}

          <h5>ABOUT US</h5>
          <p>
            Riofin is an investment platform that offers curated investment
            opportunities in lease finance & with a low minimum investment
            amount and fixed returns.
          </p>
        </Col>
        <Col lg={2} sm={4} xs={12} className="px-3 my-4 ">
          <div className="qu-link">
            <h5>Quick Links</h5>
            <ul>
              <li>
                <Link to="/privacypolicy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/termscondition">Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/cookiespolicy">Cookies Policy</Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg={3} sm={6} xs={12} className="px-3 my-4 ">
          <div className="qu-link">
            <h5>GET IN TOUCH WITH US</h5>
            <ul>
              <li>
                <Image src={Call} />
                <a href="tel:+91 6374712624" target="_blank">
                  +91 6374712624
                </a>
              </li>
              <li>
                <Image src={Email} />
                {/* <a href="mailto:support@riofin.in" target="_blank">
                  support@riofin.in
                </a> */}
                <a href="mailto:support@riofinassets.com" target="_blank">
                  support@riofinassets.com
                </a>
              </li>
              <li>
                <Image src={Map} />
                <Link to="#">
                  # 15A, 4th Floor, City Vista, Kharadi, Pune,
                  Maharashtra-411014.
                </Link>
              </li>
            </ul>
          </div>
        </Col>
        <Col lg={3} sm={6} xs={12} className="px-3 my-4 ">
          <div className="qu-linkd text-md-center">
            <h5>Social Media</h5>
            <ul className="d-flex-al-jc social-med">
              <li>
                <a
                  href="https://www.facebook.com/RiofinAssets/"
                  target="_blank"
                >
                  <Image src={Facebook} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/riofinassets/"
                  target="_blank"
                >
                  <Image src={Instagram} />
                </a>
              </li>
              <li>
                <a href="https://twitter.com/RiofinAssets" target="_blank">
                  <Image src={Twitter} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/channel/UClMfNTpdgFqJrDTxcxJR6zA"
                  target="_blank"
                >
                  <Image src={Youtube} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/posts/riofin-assets-659207243_who-investment-investmentproperty-activity-6950342840002473984-yT2x"
                  target="_blank"
                >
                  <Image src={Linkedin} />
                </a>
              </li>
            </ul>
            <div className="aps_img d-flex-al-jc">
              <p>powered by</p>
              <a
                href="https://www.digitalocean.com/"
                target="_blank"
                className="  col-12 my-2"
              >
                <Image src={Digital} />
              </a>
              <div className="ap_play">
                <a
                  href="https://www.apple.com/in/app-store/"
                  target="_blank"
                  className="col-md-6 col-12 my-2"
                >
                  <Image src={Apple} />
                </a>
                <a
                  // href="https://play.google.com/store/games"
                  href="https://play.google.com/store/apps/details?id=com.riofin"
                  target="_blank"
                  className="col-md-6 col-12 my-2"
                >
                  <Image src={Apps} />
                </a>
              </div>
            </div>
          </div>
        </Col>
        <Col xs={12}>
          <div className="d-flex align-items-center">
            <Col xs={12} md={8}>
              <h6>
                Copyright@2023
                <Link to="/home">
                  <Image src={FavIcon} className="copy-img" />
                </Link>
                Riofin . All rights Reserves
              </h6>
            </Col>
            {/* <Col xs={12} sm={8} md={4} className="mx-auto d-flex flex-column">
              <div className="chat-box">
                <input className="form-control mt-auto" />
              </div>
              <FontAwesomeIcon
                icon={faComments}
                className="fs-1 mx-auto  pointer"
              />
            </Col> */}
          </div>
        </Col>
      </div>
    </div>
  );
};

export default Footer;
