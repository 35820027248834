// export const BASE_URL = "https://dev-script.riofin.in/public/index.php/";
export const BASE_URL = "https://app-script.riofinassets.com/public/index.php/";
export const CHAT_BASE_URL = "https://app.expressad.in/api/";
// export const BASE_URL = "192.168.1.24/riofin_scriptlaravel-new/public/index.php/";
// export const BASE_URL = "http://192.168.1.33/riofin_scriptlaravel-new/public/";
// export const BASE_URL = "http://192.168.1.20/riofin/public/";

export const URL = {
  REQUEST_OTP: "user/request-otp",

  VERIFY_OTP: "user/check-otp",

  REGISTER: "user/register",

  LOGOUT: "user/logout",

  KYC_VIEW: "user/view-kyc",

  // NOTIFICATIONS

  NOTIFICATIONS: "user/user-notification",
  NOTIFICATIONS_CLEAR: "user/clear-notification",
  NOTIFICATIONS_STATUS: "user/notification-viewed",

  // DASHBOARD

  DASHBOARD_AMOUNT: "user/total-amount",

  DASHBOARD_COUNT: "user/active-past-investment/count",

  DASHBOARD_SETTING: "user/settings",

  //INVESTMENT

  INVESTMENT: "user/investment",

  MORE_INVESTMENTS: "user/investment-project/",

  INVESTMENT_EDIT: "user/investment-view/",

  MY_INVESTMENT: "user/my-investment",

  USER_INVEST: "user/user-invest",

  TRANSACTION_STATUS: "user/transaction-status",

  MYIVESTMENT_INVOICE: "crm/invoice-download/",

  USER_PROOF_UPLOAD: "user/upload-proof/",

  MANUAL_PAY_VIEW: "user/manual-pay-my-investment/",

  //DEPARTMENT

  DEPARTMENT_LIST: "user/ticket-departments",

  DEPARTMENT_GRAPH: "user/revenue-graph",

  //TICKET

  ADD_TICKET: "user/tickets",

  STORE_TICKET: "user/ticket-store",

  TICKET_VIEW: "user/ticket-show/",

  REPLY_TICKET: "user/ticket-message",

  //RAISE CAPITAL

  RAISE_CAPITAL: "user/raise-capital",

  //FAQ LIST

  FAQ_LIST: "user/faqs",

  //BANNERS

  BANNER_LIST: "user/banner",

  //BLOGS_LIST

  //PARTNERS

  PARTNERS: "user/partners",

  BLOG_LIST: "user/blogs",

  BLOG_VIEW: "user/blog-view",

  // CONTACTUS

  CONTACTUS: "user/enquiry-send",

  //TESTIMONIAL

  TESTIMONIAL: "user/testimonials ",

  //PROFILE UPDATE

  PROFILE_UPDATE: "user/profile-update",

  BANK_UPDATE: "user/bank-kyc",

  KYC_VERIFY: "user/personal-kyc",

  // DOWNLOAD PDF

  DOWNLOAD_PDF: "download-pdf?",

  //REFRALS

  REFRAL_VIEW: "user/referal/show",

  REFERAL_REQUEST: "user/referal-request",

  REFERAL_INVITE: "user/referal-mail-send",

  // CHAT BOX

  CREATE_INSTANCE: "createinstance.php?",
  CHAT_ACCESS_TOCKEN: " ce2a8e1d3256ec1691ec8c56d5bc1d7e",
  GET_QR_CODE: "getqrcode.php?",
  CHAT_WEBHOOK: "setwebhook.php?",
  REBOOT_CHAT: "reboot.php?",
  RESET_CHAT: "resetinstance.php?",
  RECONNET_CHAT: "reconnect.php?",
  SENT_DIRECT_CHAT_MESSAGE: "send.php?",
  SEND_MEDIA_FILE: "send.php?",
  CHAT_MEDIA_URL: "https://i.pravatar.cc",
  SENT_GROUP_MESSAGE: "sendgroupmsg.php?",
};
