import React from "react";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import WhyLeasing from "../Frontendcomponent/WhyLeasing";
import { Col, Image, Button } from "react-bootstrap";
import { Tree, Invtree } from "../assets/img";
const FrontLeasing = () => {
  return (
    <div className="our-deal margin-t">
      {/* <div className="top-bans  ">
                <div className="baner-page">
                    <Image src={Tree} className="w-100" />

                </div>
                <FrontHeading type="frontleasing" />
            </div> */}
      <div className="leas_dest ">
        <div className="d-flex-al w-90">
          <Col xs={12} className="mt-5 mb-3 text-center">
            <h1>Asset Backed Leasing</h1>
            <p className="text-center">
              Passive income with ease by putting assets to work on lease
            </p>
            {/* <Button
                            className="btn-web  mt-3"
                            to=""
                        >
                            Explore opportunities
                        </Button> */}
          </Col>
          <Col lg={7} xs={12} className="px-3 my-4">
            <div className="my-2">
              <h4>Why you have to choose Asset Backed Leasing?</h4>
              <p>
                Asset backed leasing is the best alternative investment option
                than traditional investment.
              </p>
              <p>
                Leasing is a viable option for a variety of assets, including
                machineries, Heavy Vehicles, land & buildings.
              </p>
            </div>
            <div className="my-2">
              <h4>MSME</h4>
              <p>
                MSME is the Back Bone of our INDIAN ECONOMY. We Riofin helps
                Best MSME industries all around our country to boost our
                economy.
              </p>
              <p>
                MSMEs contribute more than 29% to the GDP and are responsible
                for 50% of the country's total exports. They are also
                accountable for one-third of India's manufacturing output. MSMEs
                employ more than 11 crore people, and the aim is to grow this
                number to 15 crore in the coming years.
              </p>
            </div>
          </Col>
          <Col lg={5} xs={12} className="px-lg-3 px-0 my-4">
            <div className="cou_tree">
              <Image src={Invtree} />
              <div className="coud_leas my-2">
                <p>EXPECTED ROI </p>
                <h5>16% - 28% </h5>
              </div>
              <div className="coud_leas1 my-2">
                <p>TENURE </p>
                <h5>12- 36 months </h5>
              </div>
            </div>
          </Col>
        </div>
      </div>
      <div className="my-4 w-90">
        <WhyLeasing />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default FrontLeasing;
