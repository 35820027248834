import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { EditIcon } from "../assets/img";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/fontawesome-free-regular";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import TicketModal from "../component/TicketModal";

const AllSupporTicket = ({ data, getData }) => {
  // const [ticketView] = useState(ticket);
  return (
    <div className="new-ticket">
      <div className="ticket-page">
        <TicketModal getData={getData} />
      </div>
      <table>
        <thead>
          <tr>
            <th>ID </th>
            <th>TITLE </th>
            <th>PRIORITY</th>
            <th>STATUS</th>
            <th>ACTION</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((list, index) => (
            <tr key={index}>
              <td>{list.id}</td>
              <td className="d-grid">
                <b>{list.title}</b>
                {/* <span>
                  <FontAwesomeIcon icon={faCalendarAlt} className="pe-2" />
                  Created at Mon,04 jul 2022
                </span> */}
              </td>
              <td>
                {" "}
                <CircularProgressbar value={66} text={`${list.priority}`} />
              </td>
              <td>
                <Button>{list.status == 1 ? "Open" : "Closed"}</Button>
              </td>
              <td>
                <Link state={{ id: list?.id }} to="/supporticket/editsupport">
                  <Image src={EditIcon} />
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
export default AllSupporTicket;
