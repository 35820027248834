import React from "react"; 
import TopHeading from "../component/TopHeading";   
import SupporDetails from "../component/SupporDetails";
const EditSupport = () => {
    return (
        <div className="sup-ticket">
            <TopHeading type="supporticket" />
            <div className="w-80">  
            <SupporDetails/>
            </div>
        </div>
    );
};
export default EditSupport;