import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Increase, InvestTot } from "../assets/img";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import PastLeasing from "./PastLeasing";
import PastRealEst from "./PastRealEst";
import Transpatent from "../component/Transpatent";
import ErrorHandler from "../services/Constant/ErrorHandler";
import investmentServices from "../services/service/investment";

const PastInvest = () => {
  const [leasing, setLeasing] = useState([]);
  const [realEstate, setRealEstate] = useState([]);
  const [offset, setOffset] = useState(1);

  const [leasePagination, setLeasePagination] = useState();
  const [realPagination, setRealPagination] = useState();

  const investList = () => {
    investmentServices
      .investList({ params: { status: 2, page: parseInt(offset) } })
      .then((res) => {
        // console.log("past.res", res.data);
        setOffset(offset + 1);
        setLeasing((pre) => [...pre, ...res.data?.leasing_investments]);
        setLeasePagination(res.data?.leasing_pagination_meta);
        setRealPagination(res.data?.real_estate_pagination_meta);
        setRealEstate((pre) => [...pre, ...res.data?.real_estate_investments]);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    // if (leasePagination?.last_page <= offset || realPagination?.last_page) {
    investList();
    // }
  }, []);

  return (
    <div className="invest-conten">
      <Tab.Container id="left-tabs-example" defaultActiveKey="leasing">
        <div className="d-flex">
          <Col lg={4} md={5} xs={12} className="px-2">
            <div className="sticky-fun">
              <Nav variant="pills" className="flex-column invest-list">
                <Nav.Item>
                  <Nav.Link eventKey="leasing" className="d-flex-al-jb">
                    <span>Leasing </span>
                    {leasing?.length}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="real" className="d-flex-al-jb">
                    <span>Real Estate </span>
                    {realEstate?.length}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              {/* <Transpatent /> */}
            </div>
          </Col>
          <Col lg={8} md={7} xs={12} className="px-2 my-3">
            <Tab.Content>
              <Tab.Pane eventKey="leasing">
                <PastLeasing
                  fetch={investList}
                  leasingList={leasing}
                  realEstate={realEstate}
                  daatLength={leasePagination}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="real">
                <PastRealEst
                  fetch={investList}
                  daatLength={realPagination}
                  realEstate={realEstate}
                  leasingList={leasing}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          {/* <Col lg={8} md={7} xs={12} className="px-2 my-3">
            <Tab.Content>
              <Tab.Pane eventKey="leasing">
                <div className="leasing-ti">
                  <h5>Leasing Deals with Riofin</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.{" "}
                  </p>
                </div>

                <PastLeasing
                  fetch={investList}
                  leasingList={leasing}
                  daatLength={leasePagination}
                  realEstate={realEstate}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="real">
                <div className="leasing-ti">
                  <h5>Real Estate Deals with Riofin</h5>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua.{" "}
                  </p>
                </div>

                <PastRealEst
                  fetch={investList}
                  realEstate={realEstate}
                  leasingList={leasing}
                  daatLength={realPagination}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col> */}
        </div>
      </Tab.Container>
    </div>
  );
};
export default PastInvest;
