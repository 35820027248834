import React, { useEffect, useState } from "react";
import TopHeading from "../component/TopHeading";
import NoTicket from "../component/NoTicket";
import AllSupporTicket from "../component/AllSupporTicket";
import supportServices from "../services/service/support";
import ErrorHandler from "../services/Constant/ErrorHandler";

const SupporTicket = () => {
  const [list, setList] = useState([]);

  const getData = () => {
    supportServices
      .viewTicket()
      .then((res) => {
        setList(res.data.tickets.reverse());
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="sup-ticket">
      <TopHeading type="supporticket" />
      <div className="w-90  ">
        {/* <NoTicket /> */}
        <AllSupporTicket data={list} getData={getData} />
      </div>
    </div>
  );
};
export default SupporTicket;
