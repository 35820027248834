import axios from "axios";

import { CHAT_BASE_URL, URL } from "./URL";
export default axios.create({
  baseURL: CHAT_BASE_URL,
  headers: {
    // Authorization: `Bearer ${URL.CHAT_ACCESS_TOCKEN}`,
    "Content-type": "multipart/form-data",
    Accept: "application/json",
  },
});
