import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../Frontendcomponent/Footer";
import { Outlet } from "react-router-dom";
const Layout = ({ data }) => {
  // console.log("data", data);
  return (
    <div className="page-design">
      <div className="top-header">
        <Navbar data={data} />
      </div>
      <div className="body-design  ">
        <Outlet />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
