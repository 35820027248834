import moment from "moment/moment";
import React from "react";
import { Col } from "react-bootstrap";
const LeasPaymentDetails = ({ data }) => {
  // console.log("data", data);
  let endDate = data?.project_details?.monthly_payments.slice(-1)[0];
  let endDates = data?.user_investment_monthly_payment?.slice(-1)[0];

  // console.log(endDate);
  // console.log(endDates);

  // let endDate = temp.slice(-1)[0]
  // console.log("end", endDate);
  // console.log("LeasPaymentDetails", data);
  return (
    <div className="invest-now">
      <div className="  leasing-calc">
        <div className="d-flex  ">
          <Col xs={6} className="  text-start px-2">
            <h6>Invest ID</h6>
          </Col>
          <Col xs={6} className="   text-start px-2">
            <h6>#00{data?.id}</h6>
          </Col>
          <Col xs={6} className="  text-start px-2">
            <h6>Date</h6>
          </Col>
          <Col xs={6} className="   text-start px-2">
            <h6>{moment(data?.created_at).format("DD/MM/YYYY")}</h6>
          </Col>
          <Col xs={6} className="  text-start px-2">
            <h5>Invested Amount</h5>
          </Col>
          <Col xs={6} className="   text-start px-2">
            <h5>
              ₹{" "}
              {data?.project_details?.invested_amount || data?.invested_amount}
            </h5>
          </Col>
          <Col xs={6} className="  text-start px-2">
            <h6>Tenure</h6>
          </Col>
          <Col xs={6} className="   text-start px-2">
            <h6>{data?.project?.tenure} months</h6>
          </Col>

          <Col xs={6} className="  text-start px-2">
            <h6>Tenure end date</h6>
          </Col>
          <Col xs={6} className="   text-start px-2">
            <h6>
              {endDate?.data == null
                ? moment(endDates?.date).format("DD/MM/YYYY")
                : moment(endDate?.date).format("DD/MM/YYYY")}
            </h6>
          </Col>
          <Col xs={6} className="  text-start px-2">
            <h6>Payment Method</h6>
          </Col>
          <Col xs={6} className="   text-start px-2">
            <h6>
              {data?.project_details?.payment_gateway == 1
                ? "Manual Pay"
                : "Cash Free" || data?.payment_gateway == 1
                ? "Manual Pay"
                : "Cash Free"}
            </h6>
          </Col>
          {data?.post_tax == 0 ? null : (
            <>
              <Col xs={6} className="  text-start ">
                <h5 className="tol-des px-2">
                  {data?.post_tax == 0 ? null : (
                    <> Post tax returns (Tax Deductible P.A.)</>
                  )}
                </h5>
              </Col>
              <Col xs={6} className="   text-start ">
                <h5 className="tol-des px-2">
                  {data?.post_tax == 0 ? null : (
                    <>{data?.project?.tax_percentage} %</>
                  )}
                </h5>
              </Col>
            </>
          )}

          <Col xs={6} className="  text-start px-2">
            <h5>{data?.post_tax == 0 ? "Pre" : "Post"} Tax Returns</h5>
          </Col>
          <Col xs={6} className="   text-start px-2">
            <h5>
              ₹{" "}
              {Math.round(data?.project_details?.final_amount) ||
                Math.round(data?.matured_amount)}
            </h5>
          </Col>
        </div>
      </div>
    </div>
  );
};
export default LeasPaymentDetails;
