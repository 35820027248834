import React from "react";
import { Col, Image } from "react-bootstrap";
import { ReferMoney, ReferUser, ReferSpread } from "../assets/img";

const ReferStatic = () => {
  return (
    <div className="ref-deta">
      <h6>
        Refer your friends to invest and earn 1% of their every investments!
        Share your refferal link and start earning reward today. No limits to
        the number of referrals you can make.
      </h6>
      <h4>HOW IT WORKS?</h4>
      <h5>Start earning money in 3 simple steps!</h5>
      <div className="w-90 d-flex my-3">
        <Col md={4} xs={12} className="px-2">
          <Image src={ReferSpread} />
          <h6>Spread the Word</h6>
          <p>
            Inform people about Riofin using video, blogs, and social media.
          </p>
        </Col>
        <Col md={4} xs={12} className="px-2">
          <Image src={ReferUser} />
          <h6>Get new user</h6>
          <p>Get your friends and followers to sign up as a Riofin customer.</p>
        </Col>
        <Col md={4} xs={12} className="px-2">
          <Image src={ReferMoney} />
          <h6>Earn Money </h6>
          <p>
            You can earn a cash reward for all the investments from your
            friends.
          </p>
        </Col>
      </div>
    </div>
  );
};
export default ReferStatic;
