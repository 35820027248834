import React, { useState } from "react";
import { Image } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/fontawesome-free-regular";
import Button from "react-bootstrap/Button";
import { Calendar, DownArrow } from "../assets/img";
import { toast, Toaster } from "react-hot-toast";
import { paymenAsset } from "../assets/img";
import investmentServices from "../services/service/investment";

import html2PDF from "jspdf-html2canvas";
import { jsPDF } from "jspdf";
import moment from "moment/moment";

const payment = [
  {
    month: "1",
    duedate: "06 Jan 2023",
    amount: "₹1,00,000",
  },
  {
    month: "2",
    duedate: "06 Feb 2023",
    amount: "₹1,00,000",
  },
  {
    month: "3",
    duedate: "05 march 2023",
    amount: "₹1,00,000",
  },
  {
    month: "4",
    duedate: "26 Jan 2023",
    amount: "₹1,00,000",
  },
];
const Paymentmodal = ({ months, amountInvest, data, minamount }) => {
  const [paymentmodal, setPayModal] = useState(false);
  const [paymentDetail] = useState(payment);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  // console.log("months", months?.all_months?.slice(-1).pop().date);
  const last_invested_date = months?.all_months?.slice(-1).pop().date;

  let lastDayOfMonth = new Date(
    last_invested_date.getFullYear(),
    last_invested_date.getMonth() + 1,
    0
  );
  lastDayOfMonth = new Date(lastDayOfMonth).toLocaleDateString(
    "en-US",
    DATE_OPTIONS
  );

  // console.log("lastDayOfMonth", lastDayOfMonth);
  // console.log("months?.final_amount", months?.final_amount);

  const showMonths = () => {
    let amount = minamount ? minamount : data?.min_value;
    if (amountInvest >= data?.min_value || amountInvest >= minamount) {
      // console.log(data?.min_value, minamount, amountInvest);
      setPayModal(true);
    } else {
      toast.error(
        `entered Amount Shouldn't less Than Min Amount ${Math.round(amount)}`
      );
    }
  };

  const pdfDownloader = () => {
    let formdata = new FormData();
    months?.all_months?.map(
      (list, index) => (
        formdata.append(`monthly_payments[${index}[amount]`, list?.amount),
        formdata.append(`Monthly_payments[${index}[date]`, list?.amount),
        formdata.append(
          "total_amount",
          months?.final_amount || months?.monthly_total_amount
        )
      )
    );
    investmentServices.pdfDownloader(formdata).then((res) => {
      console.log("res", res);
    });
  };

  // const downloadPdfDocument = (rootElementId) => {
  //   const input = document.getElementById(rootElementId);
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png");
  //     const pdf = new jsPDF();
  //     pdf.addImage(imgData, "JPEG", 0, 0);
  //     pdf.save("download.pdf");
  //   });
  // };

  const date = new Date();

  const real = parseInt(amountInvest) + parseInt(months?.monthly_total_amount);

  // console.log("date", moment(data).format("DD/MM/YYYY"));

  return (
    <div className="payme-but">
      <Toaster position="top-right" reverseOrder={false} />

      <Button onClick={() => showMonths()}>
        {" "}
        <FontAwesomeIcon icon={faCalendarAlt} /> View Payment Schedule
      </Button>
      <Modal
        className="payment-modal"
        show={paymentmodal}
        size="lg"
        centered
        onHide={() => setPayModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="d-flex-al-jb moday-tit">
          <div className="d-flex-al">
            <Image src={Calendar} />
            <h6>Visualize Return</h6>
          </div>
          {/* <Button >
            <Image src={DownArrow} /> Download
          </Button> */}
          <GenericPdfDownloader
            downloadFileName="CustomPdf"
            rootElementId="testId"
          />

          <div id="testId" style={{ width: "100%", padding: "10px" }}>
            <img
              src={paymenAsset}
              style={{ width: "250px", marginBottom: "10px" }}
              alt="payment-icon"
            />
            <div className="container">
              <div className="d-flex justify-content-between">
                <p className="asset-date">
                  Date : {moment(date).format("DD/MMM/YYYY")}
                </p>
                <p className="asset-amount">
                  Invested Capital + ROI :{" "}
                  {Math.round(months?.final_amount) || Math.round(real)} INR
                </p>
              </div>
              <p className="asset-date">Invest Amount : {amountInvest}</p>
            </div>
            <table className="my-3 paydetail-view">
              <tr>
                <td style={{ color: "#0a3f89", padding: "15px" }}>
                  {" "}
                  <b>Months</b>
                </td>
                <td style={{ color: "#0a3f89", padding: "15px" }}>
                  <b>Tentative Date</b>
                </td>
                <td style={{ color: "#0a3f89", padding: "15px" }}>
                  <b>Amount</b>
                </td>
              </tr>{" "}
              <tbody>
                {months?.all_months?.map((list, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {" "}
                      {new Date(list?.date).toLocaleDateString(
                        "en-US",
                        DATE_OPTIONS
                      )}
                    </td>
                    <td>{Math.round(list?.amount)}</td>
                  </tr>
                ))}
                <tr className="total-tit">
                  <td></td>
                  <td>{lastDayOfMonth}</td>
                  <td>{amountInvest}</td>
                </tr>
                <tr className="total-tit">
                  <td colSpan={2}>Total Profit</td>
                  <td>₹ {Math.round(months?.monthly_total_amount)}</td>
                  {/* {console.log(months?.monthly_total_amount)} */}
                </tr>
                {data?.type == 1 ? (
                  <tr className="total-tit">
                    <td colSpan={2}>Final Amount</td>
                    <td>₹ {Math.round(months?.final_amount)}</td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Paymentmodal;

const GenericPdfDownloader = ({ rootElementId, downloadFileName }) => {
  const downloadPdfDocument = () => {
    const input = document.getElementById(rootElementId);

    html2PDF(input, {
      jsPDF: {
        format: "a4",
      },

      imageType: "image/jpeg",
      output: "./pdf/generate.pdf",
    });
  };

  // console.log("res", downloadPdfDocument);
  // console.log("r", downloadFileName);

  return (
    <Button onClick={() => downloadPdfDocument()}>
      <Image src={DownArrow} /> Download
    </Button>
  );
};
