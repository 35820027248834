import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import { DashLeas, Dashreal } from "../assets/img";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Chart from "react-apexcharts";
import { LineChart } from "react-light-chart";
import dashboardServices from "../services/service/dashboardServices";

const InverstGraph = () => {
  const [investment, setInvesment] = useState();
  const [revenue, setRevenue] = useState();
  const [graphs, setGraphs] = useState();

  const graph = () => {
    dashboardServices.graph().then((res) => {
      // setInvesment(res.data.investment);
      // setRevenue(res.data.revenue);
      let month = [];
      for (let n in res.data?.investment) {
        month.push(n);
      }
      setGraphs(month.reverse());
      let data = res.data.investment;

      let inves = [];
      for (let y in data) {
        inves.push(data[y]);
      }
      // console.log('inves', inves);
      setInvesment(inves);
      let temp = res.data?.revenue;
      let arr = [];
      for (let z in temp) {
        arr.push(Math.round(temp[z]));
      }
      // console.log('setRevenue', arr);
      setRevenue(arr);
    });
  };

  console.log("investment", investment);
  console.log("revenue", revenue);
  console.log("graphs", graphs);

  useEffect(() => {
    graph();
  }, []);

  const chartData = {
    height: 300,
    type: "line",
    options: {
      chart: {
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 2,
        curve: "smooth",
      },
      xaxis: {
        // type: 'datetime',
        categories: graphs,
      },
      colors: ["#73b4ff", "#59e0c5", "#ff869a"],
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          gradientToColors: ["#4099ff", "#2ed8b6", "#ff5370"],
          shadeIntensity: 0.5,
          type: "horizontal",
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 100],
        },
      },
      markers: {
        size: 5,
        colors: ["#4099ff", "#2ed8b6"],
        opacity: 0.9,
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
      yaxis: {
        min: 10,
        max: 70,
      },
    },
    series: [
      {
        name: "Investment",
        data: investment,
        // data: [parseInt(investment)]
      },
      {
        name: "Revenue",
        data: revenue,
        // data: [parseInt(revenue)]
      },
    ],
  };

  const style = { width: 700, height: 500 };
  const data = [
    10,
    12,
    5,
    3,
    6,
    9,
    8,
    7,
    5,
    2,
    1,
    undefined,
    4,
    undefined,
    6,
    5,
    3,
    2,
    5,
  ];
  const data1 = [
    13,
    12,
    5,
    3,
    6,
    9,
    8,
    7,
    5,
    2,
    1,
    undefined,
    4,
    undefined,
    6,
    5,
    3,
    2,
    5,
  ];
  return (
    <Tab.Container defaultActiveKey="activeoffer">
      <div className=" ">
        <Chart {...chartData} />
        {/* <Nav variant="pills" className="invest-type my-3">
          <Nav.Item>
            <Nav.Link eventKey="activeoffer" className="d-flex-al-jb">
              <Image src={DashLeas} className="me-2" />
              Active Offers{" "}
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="pastoffer" className="d-flex-al-jb">
              <Image src={Dashreal} className="me-2" />
              Past Offers{" "}
            </Nav.Link>
          </Nav.Item>
        </Nav> */}

        {/* <Tab.Content>
          <Tab.Pane eventKey="activeoffer">
            <LineChart data={data} style={style} className="foo" />
          </Tab.Pane>
          <Tab.Pane eventKey="pastoffer">
            <LineChart data={data1} style={style} className="foo" />
          </Tab.Pane>
        </Tab.Content> */}
      </div>
    </Tab.Container>
  );
};
export default InverstGraph;
