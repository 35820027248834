import React from "react";
import Accordion from "react-bootstrap/Accordion";
const LeasFaq = () => {
  return (
    <div className="ac-faq">
      <h5>Leasing FAQ's</h5>
      <p>
        Our Organisation offers innovative investment options which provides
        4-5X higher returns than Fixed Deposits. Our paramount objective is to
        serve the nation and for its people to prosper
      </p>

      <Accordion>
        <Accordion.Item eventKey="0" className="my-3">
          <div className="acd-before">
            <Accordion.Header>What is equipment leasing?</Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Equipment leasing is a financing option that allows businesses to rent equipment for a certain period of time in exchange for regular payments. At the end of the lease term, the equipment is returned to the lessor or may be purchased for a predetermined amount.
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            What types of equipment can be leased by RIOFIN?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Almost any type of equipment can be leased, including but not limited to, machinery, vehicles, office equipment, medical equipment, technology, and more.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            What are the benefits of equipment leasing?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Equipment leasing can provide several benefits to businesses, such as preserving cash flow, providing access to the latest equipment without a large upfront cost, flexible payment options, and potential tax benefits.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            How long are equipment lease terms?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Equipment lease terms can vary, but typically range from one to five years.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            What happens at the end of the equipment lease term?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              At the end of the lease term, the equipment is typically returned to the lessor or can be purchased for a predetermined amount.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            Can equipment leases be structured to include maintenance and repairs?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              Yes, many equipment leases include maintenance and repair services as part of the agreement.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
         <Accordion.Item eventKey="6" className="my-3">
          <div className="acd-before">
            <Accordion.Header>
            What is the difference between a capital lease and an operating lease?
            </Accordion.Header>
          </div>
          <Accordion.Body>
            <p>
              {" "}
              A capital lease is structured like a loan and allows the lessee to purchase the equipment at the end of the lease term. An operating lease is more like a rental agreement and does not provide an option to purchase the equipment at the end of the lease term.{" "}
            </p>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};
export default LeasFaq;
