import React from "react";
import { Col } from "react-bootstrap";
import moment from "moment/moment";
const RealPaymentDetails = ({ data }) => {
  let endDate = data?.project_details?.monthly_payments.slice(-1)[0];
  let endDates = data?.user_investment_monthly_payment?.slice(-1)[0];

  // console.log("RealPaymentDetails", data);
  // console.log("endDate", endDate);
  return (
    <div className="invest-now">
      <div className="  leasing-calc">
        <div className="d-flex  ">
          <Col xs={6} className="text-start">
            <h6>Invest ID</h6>
            <h6>Date</h6>
            <h5>Invested Amount</h5>
            <h6>Tenure</h6>
            <h6>Tenure end date</h6>
            <h6>Expected Return</h6>
            <h6>Monthly Return</h6>
            <h6>Payment Method</h6>
            {/* {data?.type == 2 ? <h6>Interest Range</h6> : null} */}
            {/* <h6 className="tol-des">
              {data?.post_tax == 0 ? "Pre" : "Post"} tax returns
            </h6> */}
            {/* <h5>{data?.post_tax == 0 ? "Pre" : "Post"} Tax Returns</h5> */}
          </Col>
          <Col xs={6} className=" text-start">
            <h6>#00{data?.id}</h6>
            <h6>{moment(data?.created_at).format("DD/MM/YYYY")}</h6>
            <h5>
              ₹{" "}
              {Math.round(data?.project_details?.invested_amount) ||
                Math.round(data?.invested_amount)}
            </h5>
            <h6>{data?.project_details?.tenure || data?.tenure} months</h6>
            <h6>
              {endDate?.date == null
                ? moment(endDates?.date).format("DD/MM/YYYY")
                : moment(endDate?.date).format("DD/MM/YYYY")}
            </h6>
            {/* <h6>{data?.project_details?.interest_range}</h6> */}
            <h6>{data?.project?.interest_range}%</h6>
            <h6>{data?.project?.monthly_percentage}%</h6>
            <h6>
              {data?.project_details?.payment_gateway == 1
                ? "Manual Pay"
                : "Cash Free" || data?.payment_gateway == 1
                ? "Manual Pay"
                : "Cash Free"}
            </h6>
            {/* {data?.type == 2 ? <h6>{data?.project?.interest_range}</h6> : null} */}
            {/* <h6 className="tol-des">25.4%</h6> */}
            {/* <h5>₹ {data?.matured_amount || data?.total_invested_amount}</h5> */}
          </Col>
        </div>
      </div>
    </div>
  );
};
export default RealPaymentDetails;
