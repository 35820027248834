import React, { useEffect, useState } from "react";
import { Col, Image, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Growpay } from "../assets/img";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { toast, Toaster } from "react-hot-toast";
import Kycmodal from "./Kycmodal";
import $ from "jquery";
import { Kycimage } from "../assets/img";
import useKyc from "../services/Storage/userKyc";
import moment from "moment/moment";

const ChospayModal = ({ months, data, amount, tax }) => {
  const navigate = useNavigate();
  const { userKyc, setUserKyc } = useKyc();
  const [chospayModal, setChospayModal] = useState(false);
  const [mode, setMode] = useState("");
  const [Benificary, setBenificary] = useState(false);
  const [requested, setRequested] = useState(false);
  const [tost, setTost] = useState();
  const [kycmodal, setKycModal] = useState(false);
  const [err, setErr] = useState();

  // console.log("ChospayModal", data);

  // console.log("months?.final_amount", months?.final_amount);

  const [formatted, setFormatted] = useState([]);

  //   console.log(new Date(months?.all_months?.date).toISOString().split("T")[0]);

  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };

  //   var date = new Date("Wed, 04 May 2022");

  // // Get year, month, and day part from the date
  // var year = date.toLocaleString("default", { year: "numeric" });
  // var month = date.toLocaleString("default", { month: "2-digit" });
  // var day = date.toLocaleString("default", { day: "2-digit" });

  // // Generate yyyy-mm-dd date string
  // var formattedDate = day + "-" + month + "-" + year;

  //   {new Date(list?.date).toLocaleDateString(
  //     "en-US",
  //     DATE_OPTIONS
  //   )}

  //   useEffect(() => {

  //   }, []);

  //   console.log(formatted);

  const success = function (data) {
    console.log(data);
    setChospayModal(false);

    if (data.order && data.order.status == "PAID") {
      console.log(data);
      alert(data.transaction.txMsg);
      investmentServices
        .transactionStatus({ transaction_id: data.order.orderId, status: 2 })
        .then((res) => {
          toast.success(res.data.message);
        })
        .catch((e) => ErrorHandler(e));

      // $.ajax({
      //   url: "checkstatus.php?order_id=" + data.order.orderId,
      //   success: function (result) {
      //     if (result.order_status == "PAID") {
      //       alert("Order PAID");

      //       // investmentServices
      //       //   .transactionStatus({ transaction_id: data.order.status, status: 2 })
      //       //   .then((res) => {
      //       //     toast.success(res.data.message);
      //       //   })
      //       //   .catch((e) => ErrorHandler(e));
      //     }
      //     console.log(data);
      //   },
      // });
    } else {
      //order is still active
      console.log(data);
      alert("Order is ACTIVE");
    }
  };
  let failure = function (data) {
    console.log(data);
    alert(data.transaction.txMsg);
    setChospayModal(false);

    investmentServices
      .transactionStatus({ transaction_id: data.order.orderId, status: 3 })
      .then((res) => {
        // toast.success(res.data.message);
      })
      .catch((e) => ErrorHandler(e));

    // data.order.orderId
    // orderId
    // alert(data.order.errorText);
  };

  const dropConfig = {
    components: ["order-details", "card", "netbanking", "app", "upi"],
    onSuccess: success,
    onFailure: failure,
    style: {
      backgroundColor: "#ffffff",
      color: "#11385b",
      fontFamily: "Lato",
      fontSize: "14px",
      errorColor: "#ff0000",
      theme: "light", //(or dark)
    },
  };

  const payOut = () => {
    // if (!amount) {
    //   toast.error("enter the amount");
    // }
    let formdata = new FormData();

    formdata.append("project_id", data?.id);
    formdata.append("amount", data?.total_value);
    formdata.append("type", data?.type);
    formdata.append("tenure", data?.tenure);
    // formdata.append("proof", data?.tenure);

    formdata.append("tax_profit_percentage", data?.tax_percentage);

    formdata.append("post_tax", tax ? 1 : 0);
    formdata.append("invested_amount", amount);
    // formdata.append("");

    if (data?.type == 1) {
      formdata.append("interest_percentage", data?.interest_percentage);
    }

    if (data?.type == 2) {
      formdata.append("per_month_amount", data?.id);
    }

    if (data?.type == 1) {
      formdata.append("matured_amount", months?.monthly_total_amount);
    }

    if (mode == "cash_free") {
      formdata.append("payment_gateway", 2);
    }

    if (mode == "manual_pay") {
      formdata.append("payment_gateway", 1);
    }

    months?.all_months?.map((item, index) => {
      console.log(item?.amount);
      formdata.append(`user_monthly_payments[${index}][amount]`, item?.amount);
    });

    let temp = [];

    months?.all_months?.map((list) => {
      let date = new Date(list?.date);
      var year = date.toLocaleString("default", { year: "numeric" });
      var month = date.toLocaleString("default", { month: "2-digit" });
      var day = date.toLocaleString("default", { day: "2-digit" });

      // Generate yyyy-mm-dd date string
      var formattedDate = year + "-" + month + "-" + day;

      temp.push(formattedDate);

      //   console.log(formattedDate);
    });

    temp?.map((list, index) => {
      formdata.append(`user_monthly_payments[${index}][date]`, list);
    });

    formdata.append("final_amount", months?.final_amount);

    let transaction = 0;

    investmentServices
      .userInvest(formdata)
      .then((res) => {
        // console.log("res", res);
        let transaction_id = res.data.transaction_id;

        transaction = res.data.transaction_id;

        const paymentDom = document.getElementById("payment-form");

        let paymentSessionId = res.data.payment_session_id;
        // const cf = new global.Cashfree(paymentSessionId);
        // cf.redirect();
        if (mode == "cash_free") {
          if (paymentSessionId == "") {
            $.ajax({
              url: "fetchtoken.php",
              success: function (result) {
                paymentSessionId = result["payment_session_id"];
                const cashfree = new global.Cashfree(paymentSessionId);
                cashfree.drop(paymentDom, dropConfig);
              },
            });
          } else {
            const cashfree = new global.Cashfree(paymentSessionId);
            cashfree.drop(paymentDom, dropConfig);
          }
        } else if (mode == "manual_pay") {
          setChospayModal(false);
          toast.success(res.data.message);
          // console.log("res.data", res.data?.message);
          // setTost(res.data.message);

          // setBenificary(true);
          setRequested(true);
        }
        // investmentServices
        //   .transactionStatus({ transaction_id: transaction_id, status: 2 })
        //   .then((res) => {
        //     toast.success(res.data.message);
        //   })
        //   .catch((e) => ErrorHandler(e));
      })
      .catch((e) => {
        setErr(e?.response?.data?.message);
        // investmentServices
        //   .transactionStatus({ transaction_id: transaction, status: 3 })
        //   .then((res) => {
        //     toast.success(res.data.message);
        //   })
        //   .catch((e) => ErrorHandler(e));
        // console.log("e", e.response.data.message);
        if (
          e?.response?.data?.message ===
          "Please Completed your KYC Verification"
        ) {
          setKycModal(true);
        } else if (
          e?.response?.data?.message ===
          "Please Completed your KYC Verification"
        ) {
          setKycModal(true);
        }
        ErrorHandler(e);
      });
  };

  const fun = () => {
    if (mode == "cash_free") {
      payOut();
    } else setBenificary(true);
  };

  const rend = () => {
    payOut();
    if (userKyc == 1) {
      setBenificary(false);
      setKycModal(true);
    } else {
      navigate("/myinvestment");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  // console.log("err", verify?.kyc_verified);

  return (
    <div className="mt-3 con-btn">
      <Toaster position="top-right" reverseOrder={false} />
      {/* {requested ? (
        <Link to="/myinvestment" className="btn">
          Requested
        </Link>
      ) : (
        <Button onClick={() => setChospayModal(true)}> Continue</Button>
      )} */}
      {amount >= data?.min_value && data?.total_value >= amount ? (
        <Button onClick={() => setChospayModal(true)}> Continue</Button>
      ) : (
        <Button onClick={() => toast.error("Enter a valid number")}>
          {" "}
          Continue
        </Button>
      )}
      {/* <Button
        onClick={
          amount >= data?.min_value
            ? () => setChospayModal(true)
            : toast.error("enter the amount")
        }
      >
        {" "}
        Continue
      </Button> */}

      <Modal
        className="payment-modal"
        show={chospayModal}
        size="md"
        centered
        onHide={() => setChospayModal(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="d-flex-al chos-mod">
          <Col md={6} xs={12}>
            <Image src={Growpay} className="w-100" />
          </Col>
          <Col md={6} xs={12} className="px-3">
            <div className="choos-pay">
              <h5>Choose Payment Option</h5>
              <Form>
                {}
                {["radio"].map((type) => (
                  <div key={`inline-${type}`} className="mb-3">
                    {data?.manual_payment == 0 ? (
                      <Form.Check
                        className="col-12"
                        inline
                        value="manual_pay"
                        onChange={(e) => setMode(e.target.value)}
                        label="Manual Pay"
                        name="group1"
                        type={type}
                        id={`inline-${type}-1`}
                      />
                    ) : data?.manual_payment == 1 ? (
                      <Form.Check
                        className="col-12"
                        inline
                        value="cash_free"
                        onChange={(e) => setMode(e.target.value)}
                        label="Online Pay"
                        name="group1"
                        type={type}
                        id={`inline-${type}-2`}
                      />
                    ) : data?.manual_payment == 2 ? (
                      <>
                        <Form.Check
                          className="col-12"
                          inline
                          value="manual_pay"
                          onChange={(e) => setMode(e.target.value)}
                          label="Manual Pay"
                          name="group1"
                          type={type}
                          id={`inline-${type}-1`}
                        />
                        <Form.Check
                          className="col-12"
                          inline
                          value="cash_free"
                          onChange={(e) => setMode(e.target.value)}
                          label="Online Pay"
                          name="group1"
                          type={type}
                          id={`inline-${type}-2`}
                        />
                      </>
                    ) : (
                      ""
                    )}
                    {/* <Form.Check
                      className="col-12"
                      inline
                      value="manual_pay"
                      onChange={(e) => setMode(e.target.value)}
                      label="Manual Pay"
                      name="group1"
                      type={type}
                      id={`inline-${type}-1`}
                    /> */}
                  </div>
                ))}
              </Form>
              {/* <div className="col-12" id="payment-form"></div> */}
              <Link
                onClick={fun}
                //   to="/invest/activeleasview/"
                className="btn"
              >
                Submit
              </Link>
            </div>
          </Col>
          <Col sm={12}>
            <div className="col-12" id="payment-form"></div>
          </Col>
        </div>
      </Modal>
      <Modal
        className="payment-modal"
        size="lg"
        centered
        show={Benificary}
        onHide={() => setBenificary(false)}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton></Modal.Header>

        <div className="kyc-pop">
          {/* <Image src={Kycimage} /> */}
          <h5 className="d-flex justify-content-start">Payment to </h5>
          <Row>
            <Col sm={6}>
              <p>Beneficiary Name</p>
              <p>Account Number</p>
              <p>IFSC Code</p>
              <p>Branch</p>
            </Col>
            <Col sm={6}>
              <p>{data?.project_detail?.account_name}</p>
              <p>{data?.project_detail?.account_number}</p>
              <p>{data?.project_detail?.ifsc_code}</p>
              <p>{data?.project_detail?.branch_name}</p>
            </Col>
            <Col sm={12} className="d-flex justify-content-start">
              <p className="text-success">
                Note:{" "}
                <span className="text-dark">
                  {/* Thank you! your Investment Booked.For Manual pay you need to
                  contact admin and get information about payment */}
                  Thank You ! Your Investment is Initiated. For Manual Payment
                  Kindly add beneficiary above Provided SPV’s Current account
                  for this project and transfer the funds.
                  <br />
                  Then upload the Transaction Receipt in My Investment Manual
                  Section Page.
                </span>
              </p>
            </Col>
          </Row>
          {/* <Link onClick={() => setKycModal(false)} className="btn">
            Close
          </Link> */}
          <button className="btn" style={{ color: "white" }} onClick={rend}>
            Request
          </button>
        </div>
      </Modal>
      <div className="mt-3 con-btn">
        {/* <Button onClick={() => setKycModal(true)}> Continue</Button> */}
        <Modal
          className="payment-modal"
          size="lg"
          centered
          show={kycmodal}
          onHide={() => setKycModal(false)}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <Modal.Header closeButton></Modal.Header>

          <div className="kyc-pop">
            <Image src={Kycimage} />
            <h5>KYC Needed</h5>
            <p>You need to complete KYC requirements to invest</p>
            <Link to="/profile" className="btn">
              Complete KYC
            </Link>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default ChospayModal;
