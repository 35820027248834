import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/fontawesome-free-regular";
import { Logo, ProfileImg, UserImg, Logout, Referico } from "../assets/img";
import LoginServices from "../services/service/login";
import ErrorHandler from "../services/Constant/ErrorHandler";
import { UserContext } from "../services/Context/userContext";
import { toast, Toaster } from "react-hot-toast";
import usePermissions from "../services/Storage/user";
import Dropdown from "react-bootstrap/Dropdown";
import { AiOutlineCloseCircle } from "react-icons/ai";
import userProfileServices from "../services/service/profile";

const FrondNavbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const [notify, setNotify] = useState("");

  const { user } = usePermissions();

  const logout = () => {
    LoginServices.logout()
      .then((res) => {
        toast.success(res.data.message);

        localStorage.clear();
        navigate("/home");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((e) => ErrorHandler(e));
  };

  const notifyStatus = () => {
    userProfileServices.notificationsStatus().then((res) => {
      setNotify(res.data);
    });
  };

  useEffect(() => {
    notifyStatus();
  }, []);

  return (
    <div className="fron-nav fixed-top zindex_custom">
      <nav className="navbar navbar-expand-lg nav-design p-lg-3 px-3 py-0 shadow_custom">
        {user == null ? (
          <Link className="navbar-brand logo_imag logo_image_cust" to="/home">
            <Image src={Logo} />
          </Link>
        ) : (
          <Link className="navbar-brand logo_imag logo_image_cust" to="/">
            <Image src={Logo} />
          </Link>
        )}
        {/* <Link className="navbar-brand logo_imag" to="/home">
          <Image src={Logo} />
        </Link> */}
        <button
          className="navbar-toggler toggle"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#collapsibleNavbar"
        >
          <span className="line-toggle"></span>
          <span className="line-toggle"></span>
          <span className="line-toggle"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-end min-nav"
          id="collapsibleNavbar"
        >
          <ul className="navbar-nav nav_a">
            {/* <li className="nav-item ab-dez">
              <Link
                data-bs-toggle="collapse"
                data-bs-target=".navbar-collapse.show"
              >
                <AiOutlineCloseCircle />
              </Link>
            </li> */}
            {user == null ? null : (
              <li className="nav-item">
                <button
                  onClick={() => navigate("/invest")}
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  className={
                    splitLocation[1] === "invest"
                      ? "nav-link active"
                      : "nav-link "
                  }
                >
                  Invest
                </button>
              </li>
            )}
            <li className="nav-item ">
              <button
                onClick={() => navigate("/home")}
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
                className={
                  splitLocation[1] === "home" ? "nav-link active" : "nav-link "
                }
              >
                Home
              </button>
            </li>
            {/* <ScrollAnimation animateIn='flipInY'
              animateOut='flipOutY'>
              <h1>
                animateOut
              </h1>
            </ScrollAnimation> */}

            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link  dropdown-toggle "
                data-bs-toggle="dropdown"
              >
                Hot Deals
              </a>
              <ul className="dropdown-menu">
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  onClick={() => navigate("/frontleasing")}
                  className="dropdown-items1 w-100 text-start bg-transparent"
                >
                  Leasing
                </button>{" "}
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  onClick={() => navigate("/frontrealestate")}
                  className="dropdown-items1 w-100 text-start bg-transparent"
                >
                  Real Estate
                </button>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                href="#"
                className="nav-link  dropdown-toggle "
                data-bs-toggle="dropdown"
              >
                About us
              </a>
              <ul className="dropdown-menu">
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  onClick={() => navigate("/about")}
                  className="dropdown-items1 w-100 text-start bg-transparent"
                >
                  Our Organization
                </button>{" "}
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  onClick={() => navigate("/ownership")}
                  className="dropdown-items1 w-100 text-start bg-transparent"
                >
                  Fractional ownership{" "}
                </button>
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  onClick={() => navigate("/blog")}
                  className="dropdown-items1 w-100 text-start bg-transparent"
                >
                  Blog
                </button>
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  onClick={() => navigate("/faq")}
                  className="dropdown-items1 w-100 text-start bg-transparent"
                >
                  FAQ 's
                </button>
                <button
                  data-bs-toggle="collapse"
                  data-bs-target="#collapsibleNavbar"
                  onClick={() => navigate("/contactus")}
                  className="dropdown-items1 w-100 text-start bg-transparent"
                >
                  Contact
                </button>
              </ul>
              {/* <ul className="dropdown-menu">
                <li><Link className="dropdown-item" to="/frontraise"> Raise Capital</Link></li>
                <li>
                  <Link className="dropdown-item" to="/about">
                    {" "}
                    Our Organization
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/ownership">
                    {" "}
                    Fractional ownership{" "}
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/blog">
                    {" "}
                    Blog
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/faq">
                    {" "}
                    FAQ 's
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/contactus">
                    {" "}
                    Contact
                  </Link>
                </li>
              </ul> */}
            </li>
            {/* <li className="nav-item dropdown">
             <Link
                to="/frontinvest"
                className={
                  splitLocation[1] === "frontinvest"
                    ? "nav-link active"
                    : "nav-link  dropdown-toggle "
                }
                data-bs-toggle="dropdown"
              >
                Our Deals
              </Link>
              <ul className="dropdown-menu">
                {/* <li ><Link className=" dropdown-item"
                                    to="/quickinvest"> Quick Invest</Link></li> 
                <li>
                  <Link className="dropdown-item" to="/ourdeals">
                    {" "}
                    Deals
                  </Link>
                </li>
                <li>
                  <Link className="dropdown-item" to="/raisecapital">
                    {" "}
                    Raise Capital
                  </Link>
                </li>
              </ul>
            </li> */}
            {/* <li className="nav-item  ">
                            <Link to="/ourpartners"
                                className={
                                    splitLocation[1] === "ourpartners" ? "nav-link active" : "nav-link "
                                }   >Our Partners</Link>

                        </li> */}
            <li className="nav-item">
              <button
                onClick={() => navigate("/ourdeals")}
                data-bs-toggle="collapse"
                data-bs-target="#collapsibleNavbar"
                className={
                  splitLocation[1] === "login" ? "nav-link active" : "nav-link "
                }
              >
                Our Deals
              </button>
            </li>

            {user == null ? (
              <>
                <li className="nav-item">
                  <Link
                    to="/login"
                    className={
                      splitLocation[1] === "login"
                        ? "nav-link active"
                        : "nav-link  "
                    }
                  >
                    Sign In
                  </Link>
                </li>
                <li className="nav-item  ">
                  <Link
                    to="/login"
                    className={
                      splitLocation[1] === "login"
                        ? "nav-link active"
                        : "nav-link fro_bt"
                    }
                  >
                    Invest Now
                  </Link>
                </li>
              </>
            ) : (
              <>
                <li className="nav-item  ">
                  <Link
                    to="/referearn"
                    className={
                      splitLocation[1] === "login"
                        ? "nav-link active"
                        : "nav-link fro_bt"
                    }
                  >
                    Refer & Earn
                  </Link>
                </li>
                <li className="nav-item  ">
                  <div className="head-user d-flex-al-jb">
                    {/* <div className="ref_box ">
                    <Link to="/referearn">
                      <Image src={Referico} className=" d-md-none d-block" />
                      <span className="d-md-block d-none"> Refer & Earn</span>
                    </Link>
                  </div> */}
                    {/* <Link to="/notification" className="icon-bg ">
                    <FontAwesomeIcon icon={faBell} />
                    {notify?.read_status === 1 ? (
                      <span className="badge badge-pill badge-danger"></span>
                    ) : (
                      ""
                    )}
                  </Link> */}
                    <div className="dropdown d-flex-al ">
                      <Dropdown className="d-flex-al ">
                        <Dropdown.Toggle
                          className="res_new p-0 px-3"
                          variant="success"
                          id="dropdown-basic"
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            color: "#000 !important",
                            width: "auto !important",
                            alignItems: "center",
                          }}
                        >
                          <Link className="user-bg  ">
                            <Image
                              src={user?.user_detail?.img_url}
                              width={50}
                            />
                          </Link>
                          <h3 className="user-name d-md-block d-none">
                            {user?.name}
                          </h3>
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="drop-img">
                          <Dropdown.Item href="#/action-1">
                            <Link to="/profile">
                              <li>
                                <Image src={UserImg} /> Profile
                              </li>
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item href="#/action-1">
                            <Link onClick={logout}>
                              <li>
                                <Image src={Logout} /> Logout
                              </li>
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </li>
              </>
            )}
          </ul>
        </div>
      </nav>
    </div>
  );
};
export default FrondNavbar;
