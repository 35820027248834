import httpClient from "../Constant/httpClient";
import { URL } from "../Constant/URL";

const faqList = () => {
  return httpClient.get(URL.FAQ_LIST);
};

const bannersList = () => {
  return httpClient.get(URL.BANNER_LIST);
};

const blogList = () => {
  return httpClient.get(URL.BLOG_LIST);
};

const blogView = (id) => {
  return httpClient.get(URL.BLOG_VIEW + "/" + id);
};

const testimonialList = () => {
  return httpClient.get(URL.TESTIMONIAL);
};

const partnersList = () => {
  return httpClient.get(URL.PARTNERS);
};

const contactUs = (data) => {
  return httpClient.post(URL.CONTACTUS, data);
};

const dashboardSetting = () => {
  return httpClient.get(URL.DASHBOARD_SETTING);
};

const frontendService = {
  faqList,
  bannersList,
  blogList,
  blogView,
  testimonialList,
  partnersList,
  contactUs,
  dashboardSetting,
};

export default frontendService;
