import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import ActiveInvest from "../component/ActiveInvest";
import PastInvest from "../component/PastInvest";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { PassOff, ActiveOff } from "../assets/img";
import investmentServices from "../services/service/investment";
import ErrorHandler from "../services/Constant/ErrorHandler";

const InvestList = () => {
  const [leasing, setLeasing] = useState();
  const [realEstate, setRealEstate] = useState([]);

  const investList = () => {
    investmentServices
      .investList(1)
      .then((res) => {
        setLeasing(res.data?.leasing_investments);
        setRealEstate(res.data?.real_estate_investments);
      })
      .catch((e) => ErrorHandler(e));
  };

  useEffect(() => {
    investList();
  }, []);

  return (
    <Nav variant="pills" className="col-12 invest-type">
      <Nav.Item>
        <Nav.Link eventKey="activeoff" className="d-flex-al-jb">
          <Image src={ActiveOff} className="me-2" />
          Active Offers{" "}
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link eventKey="pastoff" className="d-flex-al-jb">
          <Image src={PassOff} className="me-2" />
          Past Offers{" "}
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};
export default InvestList;
