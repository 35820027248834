import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import FrontHeading from "../Frontendcomponent/FrontHeading";
import Footer from "../Frontendcomponent/Footer";
import TopBanner from "../Frontendcomponent/TopBanner";
import { FaqImage } from "../assets/img";
import Accordion from "react-bootstrap/Accordion";
import HelpCenter from "../Frontendcomponent/HelpCenter";
import frontendService from "../services/service/frontEnd";
import ErrorHandler from "../services/Constant/ErrorHandler";

const FAQ = () => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    frontendService
      .faqList()
      .then((res) => {
        setFaq(res.data?.faqs);
      })
      .catch((e) => ErrorHandler(e));
  }, []);

  return (
    <div className="faq-deal margin-t">
      <div className="top-bans">
        <TopBanner />
        <FrontHeading type="faq" />
      </div>
      <div className="my-4 w-90 d-flex flex-wrap faq-secc">
        <Col md={6} xs={12} className="px-3 my-2 ">
          <h5>FAQ's</h5>
          <p>
            Our Organisation offers innovative investment options which provides
            maximum returns than Fixed Deposits. Our paramount objective is to
            serve the nation and for its people to prosper
          </p>
          <div className="ac-faq  ">
            <Accordion>
              {faq?.map((list, index) => (
                <Accordion.Item
                  eventKey={list?.id}
                  className="my-3"
                  key={index}
                >
                  <div className="acd-before">
                    <Accordion.Header>{list?.question} ?</Accordion.Header>
                  </div>
                  <Accordion.Body>
                    <p>{list?.description}</p>
                  </Accordion.Body>
                </Accordion.Item>
              ))}

              {/* <Accordion.Item eventKey="1" className="my-3">
                <div className="acd-before">
                  <Accordion.Header>
                    What is the authenticity of RIOFIN?
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <p>
                    
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item> */}
              {/* <Accordion.Item eventKey="2" className="my-3">
                <div className="acd-before">
                  <Accordion.Header>
                    What is the authenticity of RIOFIN?
                  </Accordion.Header>
                </div>
                <Accordion.Body>
                  <p>
                    {" "}
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.{" "}
                  </p>
                </Accordion.Body>
              </Accordion.Item> */}
            </Accordion>
          </div>
        </Col>
        <Col md={6} xs={12} className="px-3 my-2">
          <div className="faq_img">
            <Image src={FaqImage} className="w-90" />
          </div>
        </Col>
      </div>
      <HelpCenter />
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};
export default FAQ;
