import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import TopHeading from "../component/TopHeading";
import { InvestBan } from "../assets/img";
import DOMPurify from "dompurify";
import { useLocation } from "react-router-dom";

const Benifits = () => {
  const location = useLocation();

  console.log(location);

  return (
    <div className="inve-page">
      <TopHeading type="invest" />
      <div className="my-3 w-90">
        <div className="leasing-ti">
          <Image src={InvestBan} className="w-100 my-2" />
          <h5>{location?.state?.title}</h5>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(location?.state?.content),
            }}
          ></p>
        </div>
      </div>
    </div>
  );
};
export default Benifits;
