import React from "react";
import { Col, Image } from "react-bootstrap";
import { ProcReg, Benifit } from "../assets/img";
const Regulations = () => {
    return (
        <div className="ben-fits">
            <div className="proce-bg">
                <div className="d-flex-al my-3 w-90">
                    <Col md={5} xs={12} className="px-3 my-3">
                        <Image src={ProcReg} className="w-100" />
                    </Col>
                    <Col md={7} xs={12} className="px-3 my-3">
                        <h4>Process & Regulations</h4>
                        <p>Enjoy a fully online investment experience with deal discovery, payments, KYC, documentation and portfolio management done securely  with the best-in-class partners. The investment made by you and your co-investors to purchase an asset is via a Limited Liability Partnership or LLP. To avoid any confusion, each set of assets is held in a separate LLP and each LLP only has one leasing partner/lessee. The process of formation as well as the operation of the LLP is governed by the Limited Liability Partnership Act, 2008 as set out by the Ministry of Corporate Affairs.</p>
                    </Col>
                </div>
            </div>

            <div className="d-flex-al my-3 w-90">

                <Col md={7} xs={12} className="px-3 my-3">
                    <h4>Tax Benefits</h4>
                    <p>Under the Income Tax Act, the following are acceptable tax deductions that can be recorded against lease income.</p>
                    <p>Depreciation: The Income Tax Act specifies different depreciation rates for different assets based on their useful life</p>
                    <p>Input tax credit: GST paid on purchase of the asset can be claimed as an input tax credit and set-off against GST paid on lease income</p>
                    <p>The LLP set-up for each investment is able to claim the tax deduction from depreciation and the input tax credit for each lease.</p>
                </Col>
                <Col md={5} xs={12} className="px-3 my-3">
                    <Image src={Benifit} className="w-100" />
                </Col>
            </div>
        </div>
    );
};
export default Regulations;