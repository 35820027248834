import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import ToggleSwitch from "../component/ToggleSwitch";
import Paymentmodal from "../component/Paymentmodal";
import Kycmodal from "../component/Kycmodal";
import ChospayModal from "../component/ChospayModal";
import { DownArrow } from "../assets/img";
import { useLocation } from "react-router";
import { toast, Toaster } from "react-hot-toast";
import moment from "moment/moment";

const InvestNow = ({ data, input, minamount }) => {
  let num = data?.min_value;
  const [amountInvest, setAmountInvest] = useState(0);
  // console.log("InvestNow", data);
  // const [amountInvest, setAmountInvest] = useState(
  //   parseInt(data?.min_value) || Number(data?.min_value)
  // );
  const [tax, setTax] = useState(false);

  const [schedule, setSchedule] = useState();

  const location = useLocation();

  // console.log("schedule", schedule);

  // useEffect(() => {
  //   let balance_amount =
  //     parseInt(data?.total_value) - parseInt(data?.total_invested_amount);

  //   if (balance_amount < data?.min_value) {
  //     setAmountInvest(balance_amount);
  //   }
  // }, []);

  // console.log(location.pathname);

  // console.log(minamount);

  // amount,posttax,interest,tax,tenure

  function leasing(e) {
    let tenure = data?.tenure;
    let interest = data?.interest_percentage;
    let bank_interest = data?.bank_rate_percentage;

    let taxPercent = data?.tax_percentage;
    // let amount = parseInt(e.target.value);

    let amount = parseInt(amountInvest);

    let posttax = tax ? 1 : 0;

    // console.log(tenure, interest, taxPercent, amount, posttax);

    let interest_new;

    let bank_ins_am;

    let current_bank_interest;
    if (posttax == 0) {
      var current_interest = interest;
      current_bank_interest = bank_interest;

      // BALA

      interest_new = (amount * interest) / 100;

      bank_ins_am = (amount * bank_interest) / 100;
    } else {
      current_bank_interest = bank_interest - taxPercent;
      var current_interest = interest - taxPercent;

      // BALA

      interest_new = (amount * current_interest) / 100;

      bank_ins_am = (amount * bank_interest) / 100;
    }

    var date1 = new Date(); //date
    //date1.setDate(date1.getDate() + 1);

    if (date1.getMonth() == 11) {
      //to get no of days correctly

      var days = new Date(date1.getFullYear() + 1, 0, 0).getDate();
    } else {
      var days = new Date(
        date1.getFullYear(),
        date1.getMonth() + 1,
        0
      ).getDate();
    }

    const d = new Date(); //current date + one month
    d.setMonth(d.getMonth() + 1);
    d.setDate(1);

    const first_month_date = new Date(); //current date + one month for display
    first_month_date.setMonth(first_month_date.getMonth() + 1);
    first_month_date.setDate(1);

    var interestamount = (amount * current_interest) / 100; //per annum
    var monthly = interestamount / 12; //per month
    var day_amount = monthly / days; //per day

    let bank_interest_amount = (amount * current_bank_interest) / 100;
    let bank_monthly = bank_interest_amount / 12;
    let bank_day_amount = bank_monthly / days;

    //calculate time difference
    var time_difference = d.getTime() - date1.getTime();
    //calculate days difference by dividing total milliseconds in a day
    var days_difference = time_difference / (1000 * 60 * 60 * 24);

    var balance_days = parseInt(days_difference.toFixed(0)) - 1; //balance days

    var first_month_amount = day_amount * balance_days; //first month amount

    let bank_first_month_amt = bank_day_amount * balance_days;

    // NEW CHANGE 26-05-2023 START

    let needtax = tax ? 1 : 0;

    if (needtax == 0) {
      var tax_amount = 0;
    } else {
      var taxinterestamount = (amount * taxPercent) / 100; //per annum tax
      var tax_amount = taxinterestamount / 12; //per month tax
      var tax_day_amount = tax_amount / days; //per day tax
    }

    if (needtax == 0) {
      var first_month_tax = 0; //first month amount
    } else {
      var first_month_tax = tax_day_amount.toFixed(2) * balance_days; //first month tax
    }

    // NEW CHANGE 26-05-2023 END

    var loopmonths = [];

    for (let i = 0; i < tenure; i++) {
      if (i == 0) {
        var sss = intermediateLaeseMonths(d);
      } else {
        var senddate = new Date(loopmonths[i - 1].date);

        var sss = intermediateLaeseMonths(senddate);
      }

      loopmonths[i] = {
        date: sss,
        amount: monthly.toFixed(2),
        tax_amount: tax_amount,
      }; //monthly dates (except first month)
    }

    var first_m_details = {
      date: first_month_date,
      amount: first_month_amount.toFixed(2),
      tax_amount: first_month_tax,
    };

    var all_months = [first_m_details].concat(loopmonths);

    var monthly_total_amount = first_month_amount + monthly * tenure; //total monthly amount

    // NEW CHANGE 26-05-2023 START

    var monthly_total_amount_tax = first_month_tax + tax_amount * tenure; //total tax monthly amount

    // NEW CHANGE 26-05-2023 END

    let bank_monthly_total_amount =
      bank_first_month_amt + bank_monthly * tenure;

    let final_bank_amount = bank_monthly_total_amount + amount;

    var final_amount = monthly_total_amount + amount; //total amount

    // console.log(final_amount);

    // BALA

    let month_inter = interest_new / 12;

    let month_bank_fd = bank_ins_am / 12;

    let final_inter = month_inter * tenure;

    let final_bank_fd = month_bank_fd * tenure;

    var send_items = {
      all_months: all_months,
      monthly_total_amount: monthly_total_amount.toFixed(2),
      final_amount: final_amount.toFixed(2),
      final_bank_amount: final_bank_amount.toFixed(2),
      total_amount_tax: monthly_total_amount_tax,
      bank_fd: Math.round(final_bank_fd),
      interest_fd: Math.round(final_inter),
    };

    // console.log(send_items);
    setSchedule(send_items);
    return send_items;
  }

  function realestate() {
    let tenure = data?.tenure;
    let interest = data?.monthly_percentage;
    let bank_interest = data?.bank_rate_percentage;
    let taxPercent = data?.tax_percentage;
    let amount = parseInt(amountInvest);

    let posttax = tax ? 1 : 0;

    // console.log(tenure, interest, taxPercent, amount, posttax);

    let bank_monthly;

    // BALA

    let interest_new;

    let bank_ins_am;

    if (posttax == 0) {
      var monthly = (amount * interest) / 100; //per month
      bank_monthly = (amount * bank_interest) / 100;

      // BALA

      interest_new = (amount * interest) / 100;

      bank_ins_am = (amount * bank_interest) / 100;
    } else {
      var monthly_a = (amount * interest) / 100; //per month
      let bank_monthly_a = (amount * bank_interest) / 100;

      var annum_tax = (amount * taxPercent) / 100; //per annum tax

      var monthly_tax = annum_tax / 12; //per monthly tax

      bank_monthly = bank_monthly_a - monthly_tax;

      var monthly = monthly_a - monthly_tax;

      // BALA

      interest_new = (amount * taxinterestamount) / 100;

      bank_ins_am = (amount * bank_interest) / 100;
    }

    var date1 = new Date(); //date
    //date1.setDate(date1.getDate() + 1);

    if (date1.getMonth() == 11) {
      //to get no of days correctly

      var days = new Date(date1.getFullYear() + 1, 0, 0).getDate();
    } else {
      var days = new Date(
        date1.getFullYear(),
        date1.getMonth() + 1,
        0
      ).getDate();
    }

    const d = new Date(); //current date + one month
    d.setMonth(d.getMonth() + 1);
    d.setDate(1);

    const first_month_date = new Date(); //current date + one month for display
    first_month_date.setMonth(first_month_date.getMonth() + 1);
    first_month_date.setDate(1);

    var day_amount = monthly / days; //per day
    let bank_day_amt = bank_monthly / days;

    // NEW CHANGE 26-05-2023 START

    let needtax = tax ? 1 : 0;

    if (needtax == 0) {
      var tax_amount = 0;
    } else {
      var taxinterestamount = (amount * taxPercent) / 100; //per annum tax
      var tax_amount = taxinterestamount / 12; //per month tax
      var tax_day_amount = tax_amount / days; //per day tax
    }

    if (needtax == 0) {
      var first_month_tax = 0; //first month amount
    } else {
      var first_month_tax = tax_day_amount * balance_days; //first month tax
    }

    // NEW CHANGE 26-05-2023 END

    //calculate time difference
    var time_difference = d.getTime() - date1.getTime();
    //calculate days difference by dividing total milliseconds in a day
    var days_difference = time_difference / (1000 * 60 * 60 * 24);

    var balance_days = parseInt(days_difference.toFixed(0)) - 1; //balance days

    var first_month_amount = day_amount * balance_days; //first month amount
    let bank_first_month_amt = bank_day_amt * balance_days;

    // NEW CHANGE 26-05-2023 START

    if (needtax == 0) {
      var first_month_tax = 0; //first month amount
    } else {
      var first_month_tax = tax_day_amount * balance_days; //first month tax
    }

    // NEW CHANGE 26-05-2023 END

    var loopmonths = [];

    for (let i = 0; i < tenure; i++) {
      if (i == 0) {
        var sss = intermediateMonths(d);
      } else {
        var senddate = new Date(loopmonths[i - 1].date);

        var sss = intermediateMonths(senddate);
      }

      loopmonths[i] = {
        date: sss,
        amount: monthly.toFixed(2),
        tax_amount: tax_amount,
      }; //monthly dates (except first month)
    }

    var first_m_details = {
      date: first_month_date,
      amount: first_month_amount.toFixed(2),
      tax_amount: first_month_tax,
    };

    var all_months = [first_m_details].concat(loopmonths);

    var monthly_total_amount = first_month_amount + monthly * tenure; //total monthly amount
    var final_amount = monthly_total_amount + amount; //total amount

    // NEW CHANGE 26-05-2023 START

    var monthly_total_amount_tax = first_month_tax + tax_amount * tenure; //total tax monthly amount

    // NEW CHANGE 26-05-2023 END

    let bank_monthly_total_amount =
      bank_first_month_amt + bank_monthly * tenure;
    let final_bank_amount = bank_monthly_total_amount + amount;

    // BALA

    let month_inter = interest_new / 12;

    let month_bank_fd = bank_ins_am / 12;

    let final_inter = interest_new * tenure;

    let final_bank_fd = month_bank_fd * tenure;

    var send_items = {
      all_months: all_months,
      monthly_total_amount: monthly_total_amount.toFixed(2),
      final_amount: final_amount.toFixed(2),
      final_bank_amount: final_bank_amount.toFixed(2),
      total_amount_tax: monthly_total_amount_tax,
      bank_fd: Math.round(final_bank_fd),
      interest_fd: Math.round(final_inter),
    };
    setSchedule(send_items);

    // console.log(send_items);
    return send_items;
  }

  function intermediateMonths(date) {
    var c = date;
    c.setMonth(c.getMonth() + 1);
    c.setDate(1);

    return c;
  }

  useEffect(() => {
    if (location?.pathname == "/invest/activerealview") {
      // if (amountInvest > data?.min_value) {
      realestate();
      // } else if (amountInvest < data?.min_value) {
      //   toast.error("entered Amount Shouldn't less Than Min Amount");
      // }
    } else if (location?.pathname == "/invest/activeleasview") {
      // if (amountInvest > data?.min_value) {
      leasing();
      // } else if (amountInvest < data?.min_value) {
      //   toast.error("entered Amount Shouldn't less Than Min Amount");
      // }
    }
  }, [amountInvest, tax]);

  function intermediateLaeseMonths(date) {
    var c = date;
    c.setMonth(c.getMonth() + 1);
    c.setDate(1);

    return c;
  }

  // console.log("amountInvest", amountInvest);

  // console.log("schedule", schedule);

  let min_value = data?.total_value - data?.total_invested_amount;

  const toDay = moment(new Date()).format("YYYY-MM-DD");

  return (
    <div className="invest-now">
      <Toaster position="top-right" reverseOrder={false} />

      <div className="inve-calc">
        <div className="inve-tit d-flex-al-jb">
          <h5>Invest Now</h5>
          {data?.tax_exception == "1" ? (
            <ToggleSwitch label="Notifications" tax={tax} setTax={setTax} />
          ) : null}
        </div>
        <div className="input-des my-3">
          <input
            // disabled={input}
            type="number"
            onChange={(e) => {
              setAmountInvest(parseInt(e.target.value));
            }}
            // value={input ? minamount : amountInvest}
            value={amountInvest}
          />
          {amountInvest == 0 ? (
            ""
          ) : data?.min_value >= amountInvest + 1 ? (
            <small className=" p-3 mb-2 text-danger">
              Investment amount must not be below {Math.round(data?.min_value)}{" "}
              *
            </small>
          ) : min_value < amountInvest ? (
            <small className=" p-3 mb-2 text-danger">
              Investment amount must not be below {Math.round(min_value)} *
            </small>
          ) : amountInvest % 1000 ? (
            <small className=" p-3 mb-2 text-danger">
              Investment amount must be a multiple of 1000 *
            </small>
          ) : (
            true
          )}
          <label className="rupes"> ₹ </label>
          <div className="d-flex-al-ja my-3">
            {/* <span
              onClick={() =>
                setAmountInvest(
                  amountInvest +
                    parseInt(
                      (data?.total_value - data?.total_invested_amount) / 4
                    )
                )
              }
            >
              +₹ {(data?.total_value - data?.total_invested_amount) / 4}
            </span> */}
            {/* <span
              onClick={() =>
                setAmountInvest(
                  amountInvest +
                    parseInt(
                      (data?.total_value - data?.total_invested_amount) / 2
                    )
                )
              }
            >
              +₹ {(data?.total_value - data?.total_invested_amount) / 2}
            </span>
            <span
              onClick={() =>
                setAmountInvest(
                  amountInvest +
                    parseInt(data?.total_value - data?.total_invested_amount)
                )
              }
            >
              +₹ {data?.total_value - data?.total_invested_amount}
            </span> */}
            <span
              onClick={() => setAmountInvest(amountInvest + parseInt(10000))}
            >
              +₹ 10,000
            </span>
            <span
              onClick={() => setAmountInvest(amountInvest + parseInt(25000))}
            >
              +₹ 25,000
            </span>
            <span
              onClick={() => setAmountInvest(amountInvest + parseInt(50000))}
            >
              +₹ 50,000
            </span>
          </div>
          <div className="arrow-des d-flex-al-jc">
            <Image src={DownArrow} />
          </div>
        </div>
      </div>
      <div className="my-3 leasing-calc">
        <div className="d-flex-al-jb my-2">
          <h6>
            {tax ? "Post" : "Pre"} Tax Returns ({data?.type == 2 ? "PM" : "PA"}{" "}
            %)
          </h6>
          {amountInvest &&
          data?.min_value <= amountInvest &&
          data?.total_value >= amountInvest ? (
            <h5>
              {data?.type == 2
                ? data?.monthly_percentage
                : data?.interest_percentage}
              %
            </h5>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex-al-jb my-2">
          <h6>{tax ? "Post" : "Pre"} Tax Returns</h6>
          {amountInvest &&
          data?.min_value <= amountInvest &&
          data?.total_value >= amountInvest ? (
            <h5>
              {" "}
              {data?.type == 2
                ? // ? Math.round(schedule?.monthly_total_amount)
                  Math.round(schedule?.final_amount)
                : Math.round(schedule?.final_amount)}
            </h5>
          ) : (
            ""
          )}
        </div>
        <div className="d-flex-al-jb mt-2">
          <h6>More than Fixed Deposit</h6>

          <h5>
            {amountInvest &&
            data?.min_value <= amountInvest &&
            data?.total_value >= amountInvest
              ? Math.abs(
                  // schedule?.final_bank_amount - schedule?.final_amount
                  schedule?.interest_fd - schedule?.bank_fd
                ).toFixed(0)
              : null}
          </h5>
        </div>
        <p style={{ textAlign: "start" }}>(Tenure {data?.tenure} Months)</p>

        {tax ? (
          <div className="d-flex-al-jb my-2">
            <h6>Taxes Applicable</h6>
            {amountInvest ? <h5>{data?.tax_percentage}%</h5> : ""}
          </div>
        ) : (
          ""
        )}
        {/* <div className="d-flex-al-jb my-2">
          <h6>Taxes Applicable</h6>
          <h5>{data?.tax_percentage}%</h5>
        </div> */}
        {/* <div className="d-flex-al-jb my-2 tol-des">
          <h6>Post tax returns</h6>
          <h5>₹ 189600</h5>
        </div> */}

        {/* <Kycmodal /> */}

        {data?.total_invested_amount == data?.total_value ||
        data?.enrolment_deadline == toDay ? null : (
          <>
            <Paymentmodal
              data={data}
              minamount={minamount}
              amountInvest={amountInvest}
              months={schedule}
            />
            <ChospayModal
              months={schedule}
              data={data}
              amount={amountInvest}
              tax={tax}
            />
          </>
        )}
      </div>
    </div>
  );
};
export default InvestNow;
