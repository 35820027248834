import httpClient from "../Constant/httpClient";
import { URL } from "../Constant/URL";

const capitalAdd = (data) => {
  return httpClient.post(URL.RAISE_CAPITAL, data);
};

const capitalService = {
  capitalAdd,
};

export default capitalService;
