import React, { useEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CountrySelect from "react-bootstrap-country-select";
import { LoginImage, Logo, RegBg } from "../assets/img";
import LoginServices from "../services/service/login";
import PNotify_1 from "pnotify/dist/es/PNotify";
import ErrorHandler from "../services/Constant/ErrorHandler";
import useToken from "../services/Storage/useToken";
import httpClient from "../services/Constant/httpClient";
import { Toaster, toast } from "react-hot-toast";
import usePermissions from "../services/Storage/user";
import LoginLeft from "../component/LoginLeft";
const Register = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [email, setEmail] = useState(false);
  const [mobile, setMobile] = useState(false);
  const [referal, setReferal] = useState(false);
  // console.log(location?.state);
  const { token, setToken } = useToken();
  const { user, setUser } = usePermissions();

  const [value, setValue] = useState(null);
  const [inputs, setInputs] = useState();
  const [country, setCountry] = useState([
    { name: "Afghanistan", code: "AF" },
    { name: "Åland Islands", code: "AX" },
    { name: "Albania", code: "AL" },
    { name: "Algeria", code: "DZ" },
    { name: "American Samoa", code: "AS" },
    { name: "AndorrA", code: "AD" },
    { name: "Angola", code: "AO" },
    { name: "Anguilla", code: "AI" },
    { name: "Antarctica", code: "AQ" },
    { name: "Antigua and Barbuda", code: "AG" },
    { name: "Argentina", code: "AR" },
    { name: "Armenia", code: "AM" },
    { name: "Aruba", code: "AW" },
    { name: "Australia", code: "AU" },
    { name: "Austria", code: "AT" },
    { name: "Azerbaijan", code: "AZ" },
    { name: "Bahamas", code: "BS" },
    { name: "Bahrain", code: "BH" },
    { name: "Bangladesh", code: "BD" },
    { name: "Barbados", code: "BB" },
    { name: "Belarus", code: "BY" },
    { name: "Belgium", code: "BE" },
    { name: "Belize", code: "BZ" },
    { name: "Benin", code: "BJ" },
    { name: "Bermuda", code: "BM" },
    { name: "Bhutan", code: "BT" },
    { name: "Bolivia", code: "BO" },
    { name: "Bosnia and Herzegovina", code: "BA" },
    { name: "Botswana", code: "BW" },
    { name: "Bouvet Island", code: "BV" },
    { name: "Brazil", code: "BR" },
    { name: "British Indian Ocean Territory", code: "IO" },
    { name: "Brunei Darussalam", code: "BN" },
    { name: "Bulgaria", code: "BG" },
    { name: "Burkina Faso", code: "BF" },
    { name: "Burundi", code: "BI" },
    { name: "Cambodia", code: "KH" },
    { name: "Cameroon", code: "CM" },
    { name: "Canada", code: "CA" },
    { name: "Cape Verde", code: "CV" },
    { name: "Cayman Islands", code: "KY" },
    { name: "Central African Republic", code: "CF" },
    { name: "Chad", code: "TD" },
    { name: "Chile", code: "CL" },
    { name: "China", code: "CN" },
    { name: "Christmas Island", code: "CX" },
    { name: "Cocos (Keeling) Islands", code: "CC" },
    { name: "Colombia", code: "CO" },
    { name: "Comoros", code: "KM" },
    { name: "Congo", code: "CG" },
    { name: "Congo, The Democratic Republic of the", code: "CD" },
    { name: "Cook Islands", code: "CK" },
    { name: "Costa Rica", code: "CR" },
    { name: "Cote D'Ivoire", code: "CI" },
    { name: "Croatia", code: "HR" },
    { name: "Cuba", code: "CU" },
    { name: "Cyprus", code: "CY" },
    { name: "Czech Republic", code: "CZ" },
    { name: "Denmark", code: "DK" },
    { name: "Djibouti", code: "DJ" },
    { name: "Dominica", code: "DM" },
    { name: "Dominican Republic", code: "DO" },
    { name: "Ecuador", code: "EC" },
    { name: "Egypt", code: "EG" },
    { name: "El Salvador", code: "SV" },
    { name: "Equatorial Guinea", code: "GQ" },
    { name: "Eritrea", code: "ER" },
    { name: "Estonia", code: "EE" },
    { name: "Ethiopia", code: "ET" },
    { name: "Falkland Islands (Malvinas)", code: "FK" },
    { name: "Faroe Islands", code: "FO" },
    { name: "Fiji", code: "FJ" },
    { name: "Finland", code: "FI" },
    { name: "France", code: "FR" },
    { name: "French Guiana", code: "GF" },
    { name: "French Polynesia", code: "PF" },
    { name: "French Southern Territories", code: "TF" },
    { name: "Gabon", code: "GA" },
    { name: "Gambia", code: "GM" },
    { name: "Georgia", code: "GE" },
    { name: "Germany", code: "DE" },
    { name: "Ghana", code: "GH" },
    { name: "Gibraltar", code: "GI" },
    { name: "Greece", code: "GR" },
    { name: "Greenland", code: "GL" },
    { name: "Grenada", code: "GD" },
    { name: "Guadeloupe", code: "GP" },
    { name: "Guam", code: "GU" },
    { name: "Guatemala", code: "GT" },
    { name: "Guernsey", code: "GG" },
    { name: "Guinea", code: "GN" },
    { name: "Guinea-Bissau", code: "GW" },
    { name: "Guyana", code: "GY" },
    { name: "Haiti", code: "HT" },
    { name: "Heard Island and Mcdonald Islands", code: "HM" },
    { name: "Holy See (Vatican City State)", code: "VA" },
    { name: "Honduras", code: "HN" },
    { name: "Hong Kong", code: "HK" },
    { name: "Hungary", code: "HU" },
    { name: "Iceland", code: "IS" },
    { name: "India", code: "IN" },
    { name: "Indonesia", code: "ID" },
    { name: "Iran, Islamic Republic Of", code: "IR" },
    { name: "Iraq", code: "IQ" },
    { name: "Ireland", code: "IE" },
    { name: "Isle of Man", code: "IM" },
    { name: "Israel", code: "IL" },
    { name: "Italy", code: "IT" },
    { name: "Jamaica", code: "JM" },
    { name: "Japan", code: "JP" },
    { name: "Jersey", code: "JE" },
    { name: "Jordan", code: "JO" },
    { name: "Kazakhstan", code: "KZ" },
    { name: "Kenya", code: "KE" },
    { name: "Kiribati", code: "KI" },
    { name: "Korea, Democratic People'S Republic of", code: "KP" },
    { name: "Korea, Republic of", code: "KR" },
    { name: "Kuwait", code: "KW" },
    { name: "Kyrgyzstan", code: "KG" },
    { name: "Lao People'S Democratic Republic", code: "LA" },
    { name: "Latvia", code: "LV" },
    { name: "Lebanon", code: "LB" },
    { name: "Lesotho", code: "LS" },
    { name: "Liberia", code: "LR" },
    { name: "Libyan Arab Jamahiriya", code: "LY" },
    { name: "Liechtenstein", code: "LI" },
    { name: "Lithuania", code: "LT" },
    { name: "Luxembourg", code: "LU" },
    { name: "Macao", code: "MO" },
    { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
    { name: "Madagascar", code: "MG" },
    { name: "Malawi", code: "MW" },
    { name: "Malaysia", code: "MY" },
    { name: "Maldives", code: "MV" },
    { name: "Mali", code: "ML" },
    { name: "Malta", code: "MT" },
    { name: "Marshall Islands", code: "MH" },
    { name: "Martinique", code: "MQ" },
    { name: "Mauritania", code: "MR" },
    { name: "Mauritius", code: "MU" },
    { name: "Mayotte", code: "YT" },
    { name: "Mexico", code: "MX" },
    { name: "Micronesia, Federated States of", code: "FM" },
    { name: "Moldova, Republic of", code: "MD" },
    { name: "Monaco", code: "MC" },
    { name: "Mongolia", code: "MN" },
    { name: "Montserrat", code: "MS" },
    { name: "Morocco", code: "MA" },
    { name: "Mozambique", code: "MZ" },
    { name: "Myanmar", code: "MM" },
    { name: "Namibia", code: "NA" },
    { name: "Nauru", code: "NR" },
    { name: "Nepal", code: "NP" },
    { name: "Netherlands", code: "NL" },
    { name: "Netherlands Antilles", code: "AN" },
    { name: "New Caledonia", code: "NC" },
    { name: "New Zealand", code: "NZ" },
    { name: "Nicaragua", code: "NI" },
    { name: "Niger", code: "NE" },
    { name: "Nigeria", code: "NG" },
    { name: "Niue", code: "NU" },
    { name: "Norfolk Island", code: "NF" },
    { name: "Northern Mariana Islands", code: "MP" },
    { name: "Norway", code: "NO" },
    { name: "Oman", code: "OM" },
    { name: "Pakistan", code: "PK" },
    { name: "Palau", code: "PW" },
    { name: "Palestinian Territory, Occupied", code: "PS" },
    { name: "Panama", code: "PA" },
    { name: "Papua New Guinea", code: "PG" },
    { name: "Paraguay", code: "PY" },
    { name: "Peru", code: "PE" },
    { name: "Philippines", code: "PH" },
    { name: "Pitcairn", code: "PN" },
    { name: "Poland", code: "PL" },
    { name: "Portugal", code: "PT" },
    { name: "Puerto Rico", code: "PR" },
    { name: "Qatar", code: "QA" },
    { name: "Reunion", code: "RE" },
    { name: "Romania", code: "RO" },
    { name: "Russian Federation", code: "RU" },
    { name: "RWANDA", code: "RW" },
    { name: "Saint Helena", code: "SH" },
    { name: "Saint Kitts and Nevis", code: "KN" },
    { name: "Saint Lucia", code: "LC" },
    { name: "Saint Pierre and Miquelon", code: "PM" },
    { name: "Saint Vincent and the Grenadines", code: "VC" },
    { name: "Samoa", code: "WS" },
    { name: "San Marino", code: "SM" },
    { name: "Sao Tome and Principe", code: "ST" },
    { name: "Saudi Arabia", code: "SA" },
    { name: "Senegal", code: "SN" },
    { name: "Serbia and Montenegro", code: "CS" },
    { name: "Seychelles", code: "SC" },
    { name: "Sierra Leone", code: "SL" },
    { name: "Singapore", code: "SG" },
    { name: "Slovakia", code: "SK" },
    { name: "Slovenia", code: "SI" },
    { name: "Solomon Islands", code: "SB" },
    { name: "Somalia", code: "SO" },
    { name: "South Africa", code: "ZA" },
    { name: "South Georgia and the South Sandwich Islands", code: "GS" },
    { name: "Spain", code: "ES" },
    { name: "Sri Lanka", code: "LK" },
    { name: "Sudan", code: "SD" },
    { name: "Suriname", code: "SR" },
    { name: "Svalbard and Jan Mayen", code: "SJ" },
    { name: "Swaziland", code: "SZ" },
    { name: "Sweden", code: "SE" },
    { name: "Switzerland", code: "CH" },
    { name: "Syrian Arab Republic", code: "SY" },
    { name: "Taiwan, Province of China", code: "TW" },
    { name: "Tajikistan", code: "TJ" },
    { name: "Tanzania, United Republic of", code: "TZ" },
    { name: "Thailand", code: "TH" },
    { name: "Timor-Leste", code: "TL" },
    { name: "Togo", code: "TG" },
    { name: "Tokelau", code: "TK" },
    { name: "Tonga", code: "TO" },
    { name: "Trinidad and Tobago", code: "TT" },
    { name: "Tunisia", code: "TN" },
    { name: "Turkey", code: "TR" },
    { name: "Turkmenistan", code: "TM" },
    { name: "Turks and Caicos Islands", code: "TC" },
    { name: "Tuvalu", code: "TV" },
    { name: "Uganda", code: "UG" },
    { name: "Ukraine", code: "UA" },
    { name: "United Arab Emirates", code: "AE" },
    { name: "United Kingdom", code: "GB" },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UM" },
    { name: "Uruguay", code: "UY" },
    { name: "Uzbekistan", code: "UZ" },
    { name: "Vanuatu", code: "VU" },
    { name: "Venezuela", code: "VE" },
    { name: "Viet Nam", code: "VN" },
    { name: "Virgin Islands, British", code: "VG" },
    { name: "Virgin Islands, U.S.", code: "VI" },
    { name: "Wallis and Futuna", code: "WF" },
    { name: "Western Sahara", code: "EH" },
    { name: "Yemen", code: "YE" },
    { name: "Zambia", code: "ZM" },
    { name: "Zimbabwe", code: "ZW" },
  ]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    e.preventDefault();
    const message =
      "Are you sure you want to leave? All provided data will be lost.";
    e.returnValue = message;
    setInputs((values) => ({ ...values, [name]: value }));
    return message;
  };

  useEffect(() => {
    window.addEventListener("beforeunload", handleChange);
    return () => {
      window.removeEventListener("beforeunload", handleChange);
    };
  }, []);

  useEffect(() => {
    let data = {};
    if (location?.state) {
      if (
        /^[a-zA-Z]+$/.test(location?.state) ||
        /[-’/`~!#*$@_%+=.,^&(){}[\]|;:”<>?\\]/g.test(location?.state)
      ) {
        setEmail(true);
        data.email = location?.state;
        setInputs(data);
      } else {
        setMobile(true);
        data.mobile = location?.state;
        setInputs(data);
      }
    }

    if (searchParams.get("referralcode")) {
      data.check_referral_code = searchParams.get("referralcode");
      setReferal(true);
      setInputs(data);
    }
  }, []);

  // if (window.confirm("Do you really want to leave?")) {
  //   window.open("exit.html", "Thanks for Visiting!");
  // }

  const registeruser = () => {
    let formData = new FormData();
    formData.append("name", inputs?.name);
    formData.append("mobile", inputs?.mobile);
    formData.append("email", inputs?.email);
    formData.append("kyc_verified", 1);
    formData.append("country", inputs?.country);
    formData.append("state", inputs?.state);
    formData.append("city", inputs?.city);
    formData.append("address", inputs?.address);
    formData.append("pincode", inputs?.pincode);

    if (inputs?.check_referral_code) {
      formData.append("check_referral_code", inputs?.check_referral_code);
    }

    if (
      inputs?.name &&
      inputs?.mobile &&
      inputs?.email &&
      inputs?.country &&
      inputs?.state &&
      inputs?.city &&
      inputs?.address &&
      inputs?.pincode
    ) {
      LoginServices.register(formData)
        .then((res) => {
          if (res.data.message === "The email has already been taken.") {
            toast.error(res.data.message);
          } else {
            toast.success(res.data.message);
            setUser(res.data?.user_detail);
            // console.log("res", res.data);
            localStorage.setItem("TOKEN", res?.data?.token);
            data(res?.data?.user);
            navigate("/");
            httpClient.defaults.headers.common["Authorization"] =
              `Bearer ${res?.data?.token}` || "";
            setToken(res.data?.token);
          }
        })
        .catch((e) => ErrorHandler(e));
    } else if (!inputs?.name) {
      toast.error("Please Enter Name");
    } else if (!inputs?.mobile) {
      toast.error("Please Enter Mobile Number");
    } else if (!inputs?.email) {
      toast.error("Please Enter Email Address");
    } else if (!inputs?.country) {
      toast.error("Please Select Country");
    } else if (!inputs?.state) {
      toast.error("Please Enter State");
    } else if (!inputs?.city) {
      toast.error("Please Enter City");
    } else if (!inputs?.address) {
      toast.error("Please Enter Address");
    } else if (!inputs?.pincode) {
      toast.error("Please Enter Pincode");
    }
  };

  if (token) {
    httpClient.defaults.headers.common["Authorization"] =
      `Bearer ${token}` || "";
  }

  // console.log("input", inputs?.check_referral_code);

  return (
    <div className="login-page  ">
      <Toaster position="top-right" reverseOrder={false} />

      {/* <Col xs={12} className="my-3  ">
        <div className="logo-img">
          <Link to="/home">
            <Image className="" src={Logo} />
          </Link>
        </div>
      </Col> */}
      <div className="login-ses  cent ">
        <Col xs={12} md={5}>
          <LoginLeft />
        </Col>

        <Col xs={12} md={5}>
          <div className="login-form ">
            <Link to="/home">
              <Image className="" src={Logo} />
            </Link>
            <h5>Get Register with Riofin</h5>
            <form>
              <input
                onChange={handleChange}
                className="form-control"
                type="name"
                name="name"
                placeholder="Name"
                value={inputs?.name}
              />

              <input
                disabled={email}
                onChange={handleChange}
                className="form-control"
                name="email"
                type="mail"
                value={inputs?.email}
                placeholder="Email"
              />
              <input
                disabled={mobile}
                onChange={handleChange}
                className="form-control"
                value={inputs?.mobile}
                name="mobile"
                type="number"
                placeholder="Mobile Number"
              />
              <input
                onChange={handleChange}
                className="form-control"
                type="text"
                name="check_referral_code"
                placeholder="Referal Code (optional)"
                value={inputs?.check_referral_code}
                disabled={referal}
              />
              <select
                onChange={handleChange}
                name="country"
                aria-label="Default"
                className="form-control"
              >
                <option value="">Select Country</option>
                {country.map((item, i) => (
                  <option key={i} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </select>

              {/* <CountrySelect
              value={value}
              // onChange={(e) => console.log(e.target)}
            /> */}

              <input
                className="form-control"
                onChange={handleChange}
                name="state"
                type="state"
                placeholder="State"
              />
              <input
                className="form-control"
                onChange={handleChange}
                name="city"
                type="city"
                placeholder="City"
              />
              <input
                className="form-control"
                onChange={handleChange}
                name="address"
                type="city"
                placeholder="Address"
              />
              <input
                className="form-control"
                onChange={handleChange}
                name="pincode"
                type="pincode"
                placeholder="Pincode"
              />
              <Link
                onClick={registeruser}
                //  to="/dashboard"
                className="login-btn  "
              >
                {" "}
                Continue
              </Link>
            </form>
          </div>
        </Col>
      </div>
    </div>
  );
};
export default Register;
