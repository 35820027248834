import React, { useEffect, useLayoutEffect, useState } from "react";
import { Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LessLogo, InvestBan } from "../assets/img";
import ProgressBar from "react-bootstrap/ProgressBar";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/fontawesome-free-regular";
import DOMPurify from "dompurify";

const MoreActiveLeasing = ({ data }) => {
  // const [list, setList] = useState([]);
  const DATE_OPTIONS = {
    weekday: "short",
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  return (
    <div className="active-leas d-flex">
      <Col sm={12}>
        <div className="leasing-ti">
          <Image src={data[0]?.investment?.img_url} className="w-100 my-2" />
          <h5>{data[0]?.investment?.title}</h5>
          <p
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(data[0]?.investment?.description),
            }}
          ></p>
          <div className="my-3 d-flex">
            <Link
              state={{
                content: data[0]?.investment?.how_it_works,
                title: data[0]?.investment?.title,
              }}
              className="mx-2 invoice-btn btn"
              to="/howits"
            >
              How It Work
            </Link>
            <Link
              state={{
                content: data[0]?.investment?.benifits,
                title: data[0]?.investment?.title,
              }}
              className="mx-2 invoice-btn btn"
              to="/bninoenifits"
            >
              Leasing Via Riofin
            </Link>
          </div>
        </div>
      </Col>

      {data?.map((list, index) => {
        return (
          <Col key={index} lg={6} xs={12} className="px-2 my-2">
            <div className="inves-box">
              <Image src={list?.img_url} className="inves_img" />

              <p
                className="modernWay"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(list?.content),
                }}
              ></p>
              <p>
                Due Date -{" "}
                <span>
                  {new Date(list?.enrolment_deadline).toLocaleDateString(
                    "en-US",
                    DATE_OPTIONS
                  )}
                </span>
              </p>
              <div className="d-flex-al-jb">
                <div className="due-date">
                  <p>Pre Tax Return</p>
                  <h5>
                    {list?.type == 2
                      ? list?.monthly_percentage
                      : list?.interest_percentage}{" "}
                    %
                  </h5>
                </div>
                <div className="due-date">
                  <p>Tenure</p>
                  <h5>{list?.tenure} months</h5>
                </div>
              </div>
              <ProgressBar now={list?.total_percentage} />
              <div className="d-flex-al-jb mt-3 compl-leas">
                <h5>
                  {" "}
                  {list?.total_invested_amount} / {list?.total_value}
                </h5>
                <h6>{list?.total_percentage} % Completed</h6>
              </div>
              <Button>Minimum Investment ₹ {list?.min_value}</Button>
              <div className="view-detail d-flex-al-jc">
                <Link
                  state={{ id: list?.id }}
                  to="/invest/activeleasview"
                  className="d-flex-al"
                >
                  View Details <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </Link>
              </div>
            </div>
          </Col>
        );
      })}

      {/* <Col lg={6} xs={12} className="px-2 my-2  ">
        <div className="inves-box">
          <Image src={LessLogo} />
          <h6>
            The lorem ipsum is a placeholder text used in publishing and graphic
            design.{" "}
          </h6>
          <p>
            Due Date - <span>12 Nov 2022</span>
          </p>
          <div className="d-flex-al-jb">
            <div className="due-date">
              <p>Pre Tax Return</p>
              <h5>5.5%</h5>
            </div>
            <div className="due-date">
              <p>Tenure</p>
              <h5>20 months</h5>
            </div>
          </div>
          <ProgressBar now={40} />
          <div className="d-flex-al-jb mt-3 compl-leas">
            <h5>1 Cr / 3.3 Cr</h5>
            <h6>45% Completed</h6>
          </div>
          <Button>Minimum Investment ₹ 10,473.1</Button>
          <div className="view-detail d-flex-al-jc">
            <Link to="/invest/activeleasview" className="d-flex-al">
              View Details <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </Link>
          </div>
        </div>
      </Col>
      <Col lg={6} xs={12} className="px-2 my-2  ">
        <div className="inves-box">
          <Image src={LessLogo} />
          <h6>
            The lorem ipsum is a placeholder text used in publishing and graphic
            design.{" "}
          </h6>
          <p>
            Due Date - <span>12 Nov 2022</span>
          </p>
          <div className="d-flex-al-jb">
            <div className="due-date">
              <p>Pre Tax Return</p>
              <h5>5.5%</h5>
            </div>
            <div className="due-date">
              <p>Tenure</p>
              <h5>20 months</h5>
            </div>
          </div>
          <ProgressBar now={40} />
          <div className="d-flex-al-jb mt-3 compl-leas">
            <h5>1 Cr / 3.3 Cr</h5>
            <h6>45% Completed</h6>
          </div>
          <Button>Minimum Investment ₹ 10,473.1</Button>
          <div className="view-detail d-flex-al-jc">
            <Link to="/invest/activeleasview" className="d-flex-al">
              View Details <FontAwesomeIcon icon={faArrowAltCircleRight} />
            </Link>
          </div>
        </div>
      </Col> */}
    </div>
  );
};
export default MoreActiveLeasing;
